.page-body {

	// Sidebar Menu
	&.skin-aero {
		// Aero
		.xenon-sidebar-skin-variant(#558C89; #ECECEA; #FFF; #5f9a97; #558C89; #255e5b);
	}

	&.skin-navy {
		// Navy
		.xenon-sidebar-skin-variant(#2c3e50; #a7bfd6; #FFF; #34495e; #2c3e50; #ff4e50);
	}

	&.skin-facebook {
		// Facebook
		.xenon-sidebar-skin-variant(#3b5998; #8b9dc3; #FFF; #4160a0; #3b5998; #8b9dc3);
	}

	&.skin-turquoise {
		// Turquoise
		.xenon-sidebar-skin-variant(#16a085; #96ead9; #FFF; #1daf92; #16a085; #0f7e68);
	}

	&.skin-lime {
		// Lime
		.xenon-sidebar-skin-variant(#8cc657; #FFF; #FFF; #95cd62; #8cc657; #70a93c);
	}

	&.skin-green {
		// Green
		.xenon-sidebar-skin-variant(#27ae60; #a2f9c7; #FFF; #2fbd6b; #27ae60; #1c954f);
	}

	&.skin-purple {
		// Purple
		.xenon-sidebar-skin-variant(#795b95; #c2afd4; #FFF; #81629f; #795b95; #5f3d7e);
	}

	&.skin-white {
		// White
		.xenon-sidebar-skin-variant(#FFF; #666; #95cd62; #EEE; #95cd62; #555; #eee);
	}

	&.skin-concrete {
		// Concrete
		.xenon-sidebar-skin-variant(#a8aba2; #666; #a40f37; #b8bbb3; #a40f37; #323232);
	}

	&.skin-watermelon {
		// Watermelon
		.xenon-sidebar-skin-variant(#b63131; #f7b2b2; #FFF; #c03737; #b63131; #32932e);
	}

	&.skin-lemonade {
		// Lemonade
		.xenon-sidebar-skin-variant(#f5c150; #ffeec9; #FFF; #ffcf67; #f5c150; #d9a940);
	}



	// Horizontal Menu
	&.horizontal-menu-skin-aero {
		.xenon-horizontal-menu-skin-variant(#558C89; #ECECEA; #FFF; #5f9a97);
	}

	&.horizontal-menu-skin-navy {
		.xenon-horizontal-menu-skin-variant(#2c3e50; #a7bfd6; #FFF; #34495e);
	}

	&.horizontal-menu-skin-facebook {
		.xenon-horizontal-menu-skin-variant(#3b5998; #8b9dc3; #FFF; #4160a0);
	}

	&.horizontal-menu-skin-turquoise {
		.xenon-horizontal-menu-skin-variant(#16a085; #96ead9; #FFF; #1daf92);
	}

	&.horizontal-menu-skin-lime {
		.xenon-horizontal-menu-skin-variant(#8cc657; #FFF; #FFF; #95cd62);
	}

	&.horizontal-menu-skin-green {
		.xenon-horizontal-menu-skin-variant(#27ae60; #a2f9c7; #FFF; #2fbd6b);
	}

	&.horizontal-menu-skin-purple {
		.xenon-horizontal-menu-skin-variant(#795b95; #c2afd4; #FFF; #81629f);
	}

	&.horizontal-menu-skin-white {
		.xenon-horizontal-menu-skin-variant(#FFF; #666; #95cd62; #EEE);
	}

	&.horizontal-menu-skin-concrete {
		.xenon-horizontal-menu-skin-variant(#a8aba2; #666; #a40f37; #b8bbb3);
	}

	&.horizontal-menu-skin-watermelon {
		.xenon-horizontal-menu-skin-variant(#b63131; #f7b2b2; #FFF; #c03737);
	}

	&.horizontal-menu-skin-lemonade {
		.xenon-horizontal-menu-skin-variant(#f5c150; #ffeec9; #FFF; #ffcf67);
	}



	// User Info Navbar Menu
	&.user-info-navbar-skin-aero {
		.xenon-user-info-navbar-skin-variant(#558C89; #ECECEA; #FFF; #5f9a97);
	}

	&.user-info-navbar-skin-navy {
		.xenon-user-info-navbar-skin-variant(#2c3e50; #a7bfd6; #FFF; #34495e);
	}

	&.user-info-navbar-skin-facebook {
		.xenon-user-info-navbar-skin-variant(#3b5998; #8b9dc3; #FFF; #4160a0);
	}

	&.user-info-navbar-skin-turquoise {
		.xenon-user-info-navbar-skin-variant(#16a085; #96ead9; #FFF; #1daf92);
	}

	&.user-info-navbar-skin-lime {
		.xenon-user-info-navbar-skin-variant(#8cc657; #FFF; #FFF; #95cd62);
	}

	&.user-info-navbar-skin-green {
		.xenon-user-info-navbar-skin-variant(#27ae60; #a2f9c7; #FFF; #2fbd6b);
	}

	&.user-info-navbar-skin-purple {
		.xenon-user-info-navbar-skin-variant(#795b95; #c2afd4; #FFF; #81629f);
	}

	&.user-info-navbar-skin-white {
		.xenon-user-info-navbar-skin-variant(#FFF; #666; #95cd62; #EEE);
	}

	&.user-info-navbar-skin-concrete {
		.xenon-user-info-navbar-skin-variant(#a8aba2; #666; #a40f37; #b8bbb3);
	}

	&.user-info-navbar-skin-watermelon {
		.xenon-user-info-navbar-skin-variant(#b63131; #f7b2b2; #FFF; #c03737);
	}

	&.user-info-navbar-skin-lemonade {
		.xenon-user-info-navbar-skin-variant(#f5c150; #ffeec9; #FFF; #ffcf67);
	}
}


// Sidebar Skin
.xenon-sidebar-skin-variant(@bg-color; @text-color; @text-active-color; @border-color; @primary; @secondary; @scroll-color: #fff) {

	.btn-primary {
		background: @primary;

		&:hover {
			background: darken(@primary,5%);
			border-color:  darken(@primary,5%);
		}

		&:active, &:focus {
			background: darken(@primary,15%);
			border-color:  darken(@primary,15%);
		}
	}

	.btn {

		&.btn-secondary { .xenon-btn-style(@secondary, #FFF); }

	}

	.settings-pane {
		background: @border-color;
		color: @text-color;

		> a[data-toggle="settings-pane"] {
			color: @text-color;

			&:hover {
				color: #FFF;
			}
		}


		.user-info {

			.user-details {

				h3 {
					a {
						color: @text-active-color;
					}

					.user-status {
					}
				}

				.user-title {
					color: fade(@text-active-color, 65%);
				}

			}
		}

		.links-block {

			&.left-sep {
				border-left: 1px solid @bg-color;
			}

			h4 {
				color: @text-active-color;

				span {
					display: inline-block;
					border-bottom: 1px solid @border-color;
				}

				a {
					color: @text-active-color;

					&:hover {
						color: @text-color;
					}
				}
			}


			ul {

				li {

					a {
						color: @text-color;

						&:hover {
							color: @text-active-color;
						}
					}
				}
			}
		}

	}

	.sidebar-menu {
		background: @bg-color;

		.logo-env {
			border-bottom: 1px solid @border-color;

			.settings-icon {
				a {
					color: @text-color;

					&:hover {
						color: @text-active-color;
					}
				}
			}
		}


		// Main Menu
		.main-menu {
			// Links
			a {
				color: @text-color;
				border-bottom: 1px solid @border-color;

				&:hover {
					color: @text-active-color;
				}
			}

			// Active
			li.active > a {
				color: @text-active-color;
			}

			// With Subs
			li.has-sub {

				> a {

					&:before {
						color: lighten(@border-color, 25%);
					}
				}
			}
		}

		// Scrollbar
		.ps-scrollbar-x-rail .ps-scrollbar-x,
		.ps-scrollbar-y-rail .ps-scrollbar-y {
			background-color: @scroll-color;
		}


		// Collapsed sidebar
		@media screen and (min-width: @screen-sm) {

			&.collapsed {
				+ .main-content {

					.user-info-navbar {

						.user-info-menu {

							a[data-toggle="sidebar"] {
								color: @userinfo-link-active-color;
							}
						}
					}
				}

				.main-menu {
					> li {
						&.active, li.active {
							background-color: darken(@bg-color, 3%);
						}

						> ul {
							background: @bg-color;
						}

					}
				}
			}
		}
	}

	.page-loading-overlay {
		background-color: @bg-color;
	}

	&.login-page {
		@main-border-color: lighten(#323435, 2%);

		background: @bg-color;
		color: #fff;

		.login-form {
			//background: lighten(@brand-primary, 1%);

			&.fade-in-effect {
				.form-group {
					@fg-delay: 150ms;

				}
			}

			.login-header {

				.logo {
					span {
						color: @text-color;
					}
				}

				p {
					color: @text-color;
				}
			}

			.form-group {
				.control-label {
					color: @text-color;
				}

				.form-control {
					&.input-dark {
						background: darken(@bg-color,5%);
						border: 1px solid transparent;
						color: @text-color;
						.placeholder(@text-active-color);
					}

					&.error {
						border-color: fade(@brand-danger, 50%);
					}
				}

				.btn {
					&.btn-dark {
						border-color: @border-color;
						background-color: @bg-color;
						color: @text-active-color;

						&:hover {
							background-color: fade(@border-color, 30%);
						}
					}
				}
			}

			.login-footer {
				margin-top: 45px;

				a {
					color: @text-active-color;

					&:hover {
						color: lighten(@text-color, 15%);
					}
				}

				.info-links {
					@color: fade(@text-color, 70%);

					font-size: @font-size-small - 1;
					margin-top: @base-padding/2;
					color: @text-color;

					a {
						color: @text-color;

						&:hover {
							color: @text-active-color;
						}
					}
				}
			}
		}


		.external-login {
			position: relative;
			margin-bottom: @default-padding;
			padding: 0 @default-padding;

			a {
				display: block;
				color: #fff;
				background: @brand-primary;
				padding: @base-padding+3 @base-margin;
				font-size: @font-size-base + 2;
				margin-bottom: @base-padding;

				i {
					margin-right: @base-padding/2;
				}

				&:hover {
					background: lighten(@brand-primary, 10%);
				}

				&.facebook {
					@color: #3b5998;

					background-color: @color;

					&:hover {
						background-color: darken(@color, 10%);
					}
				}

				&.twitter {
					@color: #55acee;

					background-color: @color;

					&:hover {
						background-color: darken(@color, 10%);
					}
				}

				&.gplus {
					@color: #dd4b39;

					background-color: @color;

					&:hover {
						background-color: darken(@color, 10%);
					}
				}
			}
		}


		&.login-light {
			background: @background-color;

			.errors-container, .external-login {
				padding: 0;
			}

			.form-group {

				label.error {
					color: fade(@main-text-color, 80%);
				}
			}

			.login-form {
				background: #fff;
				color: @main-text-color;

				.login-footer {

					a {
						color: @secondary-color;

						&:hover {
							color: darken(@secondary-color, 10%);
						}
					}

					.info-links {
						@color: fade(@main-text-color, 70%);

						a {
							color: @color;

							&:hover {
								color: @main-text-color;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: @screen-sm){
			padding-top: 0;

			.login-form,
			.errors-container,
			.external-login {
				padding-left: @base-padding;
				padding-right: @base-padding;
			}

			.errors-container {
				margin-top: @base-margin;
			}

			&.login-light {

				.login-form {
					padding-left: @default-padding;
					padding-right: @default-padding;
				}

				.errors-container,
				.external-login {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.row {
				margin: 0;
			}
		}
	}

	&.lockscreen-page {
		background: @bg-color;

		.lockcreen-form {
			.form-group {
				p {
					color: @text-color;
				}

				h3 {
					color: @text-color;
				}

				.input-group {

					.form-control {
						border: 1px solid @border-color;
						.placeholder(fade(@text-active-color, 50%));
						color: @text-active-color;
					}

					.input-group-btn {

						.btn {
							color: @text-active-color;
							border: 1px solid @border-color;

							&:hover,
							&:focus {
								background-color: fade(@border-color, 30%);
							}
						}
					}
				}
			}

		}

	}

	&.settings-pane-open {

		.sidebar-menu {

			.sidebar-menu-inner {

				.logo-env {

					.settings-icon {
						background: @border-color;
						.box-shadow(~"0px 0px 0px 10px @{border-color}, 0px -10px 0px 10px @{border-color}, 0px -20px 0px 10px @{border-color}, 0px -30px 0px 10px @{border-color}");
					}
				}
			}
		}
	}

	// Added in v1.3
	.sidebar-user-info {
		background-color: darken(@bg-color, 5%);
		border-bottom-color: @border-color;

		.user-profile {

			span {
				color: @text-color;

				strong {
					color: @text-active-color;
				}
			}
		}

		.user-links {
			border-top-color: @border-color;

			li {

				a {
					color: @text-color;

					&:hover {
						color: @text-active-color;
						background-color: fade(@border-color, 25%);
					}
				}

				&.active a {
					color: @text-active-color;
					background-color: fade(@border-color, 25%);
				}

				+ li {

					a {
						border-left-color: @border-color;
					}
				}
			}
		}

		@media screen and (min-width: @screen-sm-max){

			.sidebar-menu.collapsed & {

				.sidebar-user-info-inner {

					.user-profile {

						span {
							background-color: @main-active-color;
						}
					}

					.user-links {
						background-color: @main-active-color;
					}


				}
			}
		}
	}
}


// Horizontal Menu
.xenon-horizontal-menu-skin-variant(@bg-color; @text-color; @text-active-color; @border-color) {

	.navbar {

		&.horizontal-menu {
			background: @bg-color;
			.box-shadow(none);


			.navbar-inner {
				.navbar-brand {
					.settings-pane-open & a[data-toggle="settings-pane"] {

						i {
							color: @text-color;
						}
					}

					a {
						&[data-toggle="settings-pane"] {
							i {
								color: @text-color;
							}

							&:hover {

								i {
									color: @text-active-color;
								}
							}
						}
					}
				}

				@media screen and (min-width: @screen-sm){

					.navbar-nav {
						&.click-to-expand {

							.has-sub {

								&:hover > a:before {
									.rotate(0deg);
								}

								&.expanded > a:before {
									.rotate(90deg);
								}
							}
						}

						a {
							color: @text-color;
						}

						li:hover > a,
						li.active > a {
							color: @text-active-color;
						}

						> li {
							border: 0px;

							&.active,
							&:hover {
								border-color: transparent;
								background-color: @border-color;
							}

							&.active {

								+ li:hover {
									border-left-color: transparent;
								}
							}

							> ul {
								border: none;
								.box-shadow(~"0 2px 8px rgba(0,1,1,.05)");
							}
						}


						// First Level
						ul {
							background: @border-color;

							> li {

								> a {
									color: @text-color;

									&:after {
										background: lighten(@border-color,5%);
									}
								}

								&.active > a {
									color: @text-active-color;
									background: @bg-color;
								}

								&.has-sub {

									> a {

										&:before {
											color: @text-color;
										}
									}
								}

								ul {
									background: lighten(@border-color,5%);
									border-top: 1px solid lighten(@border-color,8%);
									border-bottom: 1px solid lighten(@border-color,8%);

									ul {
										@bg: lighten(@border-color, 2%);
										@border: lighten(@border-color, 4%);

										background: @bg;
										border-top-color: @border;
										border-bottom-color: @border;

										a:after {
											background-color: @border;
										}

										ul {
											@bg: lighten(@border-color, 4%);
											@border: lighten(@border-color, 4%);

											background: @bg;
											border-top-color: @border;
											border-bottom-color: @border;

											a:after {
												background-color: @border;
											}

										}
									}
								}

							}
						}
					}

				}
				> .nav {

					> li {

						&:hover {
							background: @border-color;
						}

						i {
							color: @text-color;
						}

						> a {
							border: 0px;

							&:hover {
								background: @border-color;
								color: @text-active-color;

								i {
									color: @text-active-color;
								}
							}

							&.notification-icon {

								&:before {
									background: @secondary-color;
								}

								&:focus {
									background: @border-color;
								}

								&.notification-icon-messages {

									&:before {
										background-color: @xe-purple;
									}
								}
							}

							&[data-toggle="chat"] {
								i {

									.chat-open & {
										color: @text-active-color;
									}
								}
							}
						}

						&.open > a.notification-icon {
							//background: @horizontal-menu-link-active-bg-color;
							background-color: @border-color;
							border-color: lighten(@border-color,5%);
							color: @text-color;

							i {
								color: @text-active-color;
							}

							&:before {
								display: block;
							}
						}

						> ul {
							border-color: @border-color;
						}

						// User Profile
						&.user-profile {
							&:extend(.user-info-navbar .user-info-menu > li.user-profile all);

							> a {
								&:focus {
									background-color: @border-color;
								}

								span {
									color: @text-color;

									&:hover {
										color: @text-active-color;
									}
								}

							}

							&.open > a {
								background-color: @border-color;
								border-color: @border-color;
							}
						}
					}
				}

			}
		}
	}

	.page-loading-overlay {
		background-color: @bg-color;
	}

	// Mobile
	@media screen and (max-width: @screen-sm) {
		.navbar {
			&.horizontal-menu {
				background: @bg-color;
				color: @text-color;

				.navbar-inner {
					> .navbar-nav {
						a {
							color: @text-color;
							border-color: @border-color;

							&:hover, &:active, &:focus {
								color: @text-active-color;
							}
						}
					}
					> .nav {
						&.navbar-mobile {
							a {
								color: @text-color;

								&:hover,
								&:active,
								&:focus {
									color: @text-active-color;
								}

							}
						}
					}
				}
			}
		}
	}
}


// User Info Navbar Skins
.xenon-user-info-navbar-skin-variant(@bg-color; @text-color; @text-active-color; @border-color) {

	// User Info Navbar
	.user-info-navbar {
		background-color: @bg-color;

		.user-info-menu {

			// Root level
			> li {
				border: 0px solid transparent;



				&.hover-line {

					&:before {
						background: @text-color;
					}
				}

				> a {
					color: @text-color;
					border-bottom: 1px solid transparent;

					&:hover {
						color: @text-active-color;
					}
				}

				> form {
					border-bottom: 1px solid transparent;
				}

				// Search field
				&.search-form {

					.btn {
						color: @text-color;

						&:hover {
							color: @text-active-color;
						}

						&:active,
						&:focus {
							.box-shadow(none);
						}
					}

					.search-field {
						border: 1px solid @border-color;
						background: lighten(@background-color, 2%);
						.placeholder(@main-text-color);

						&:focus {
							+ .btn {
								color: @text-active-color;
							}
						}
					}

				}


				// Toggled dropdown
				&.open {
					border-left-color: transparent;
					border-right-color: transparent;
					z-index: 15;

					> a {
						color: @text-active-color;
					}
				}

				// Dropdowns
				.dropdown-menu {
					background: @border-color;
					border: 1px solid transparent;

					.dropdown-menu-list {

						li {
							a {
								color: @text-color;;

								&:after {
									background: lighten(@border-color,5%);
								}

								&:hover {
									color: @text-active-color;
								}
							}

							&.active {

								a {
									color: @text-active-color;
								}
							}
						}
					}

					> .top {

						a {
							color: @text-color;

							&:hover {
								color: @text-active-color;
							}
						}

						p {
							color: @text-color;
							border-bottom: 1px solid lighten(@border-color,5%);
						}
					}

					> .external {

						&:after {
							background-color: lighten(@border-color,5%);
						}

						a {
							color: @text-color;
							background-color: lighten(@border-color,5%);

							&:hover {
								color: @text-active-color;
							}
						}
					}

					&.messages {
						.dropdown-menu-list li {

							&:after {
								background-color: lighten(@border-color,5%);
							}

							&.active:after {
								background-color: @brand-success;
							}
						}
					}

					&.notifications {

						.dropdown-menu-list {
							max-height: @userinfo-notifications-dropdown-height;

							> li {

								> a {

									> i {
										background: @border-color;
										color: #FFF;
									}

									.line {
										padding-left: 45px;
									}
								}


								&.notification-secondary > a > i {
									background-color: #FFF;
									color: #444;
									.box-shadow(0 0 0 1px #eee);
								}
							}
						}
					}

					&.user-profile-menu {

						li {

							a {
								color: @text-color;
								border-bottom: 1px solid lighten(@border-color,5%);

								&:hover {
									color: @text-active-color;
								}

							}

							&.last {
								background: lighten(@border-color,5%);

								&:after {
									background: lighten(@border-color,5%);
								}

								> a {
									border-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}