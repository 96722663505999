
span.twitter-typeahead {
	width: 100%;
	
	.tt-dropdown-menu {
		background: #fff;
		border: 1px solid @input-border;
		width: 100%;
		margin-top: -1px;
		max-height: 250px;
		overflow: auto;
		&:extend(.dropdown-menu);
	}
	
	.tt-suggestion.tt-cursor {
		background-color: #fafafa;
	}
	
	.tt-hint {
		color: lighten(@main-text-color, 20%);
	}
	
	.empty-message {
		padding: 5px @default-padding/2 - 2;
		color: lighten(@main-text-color, 15%);
	}
	
	.tt-suggestion > p {
		&:extend(.dropdown-menu > li > a);
		padding: 5px (@default-padding/2 - 2);
		
		.tt-highlight {
			color: darken(@main-text-color, 10%);
		}
		
		  &:hover,
		  &:focus {
		  	background: #fafafa;
		  	cursor: pointer;
			&:extend(.dropdown-menu > .active > a);
		  }
	}
	
	.input-group & {
		display: block !important;
		
	    .tt-dropdown-menu {
			top:32px !important;
		}
	}
	
	.input-group.input-group-lg & {
		.tt-dropdown-menu {
			top:44px !important;
		}
	}
	
	.input-group.input-group-sm & {
	.tt-dropdown-menu {
			top:28px !important;
		}
	}
}