@wysihtml5-height: 250px;

.wysihtml5 {
	min-height: @wysihtml5-height;
}

.wysihtml5-toolbar {

	.btn {
		margin-bottom: 0;
		
		&.btn-white {
			
			&:active,
			&:focus,
			&.active {
				border-color: darken(@default-border-color, 2%) !important;
			}
			
			&.wysihtml5-command-active {
				background: lighten(@input-border, 4%);
				.box-shadow(none);
			}
			
			&.dropdown-toggle {
			
				i {
					position: relative;
					top: 1px;
				}
			}
		}
	}
	
	li.html-code-icon {
		float: right;
	}
	
	.dropdown-menu {
		border-color: @input-border;
	}
	
	a.wysihtml5-colors-title {
		padding-top: 3px !important;
		padding-left: 35px !important;
	}
	
	div.wysihtml5-colors {
		width: 25px !important;
	}
}

.wysihtml5-sandbox {
	min-height: @wysihtml5-height;
	padding: @base-padding @base-padding+5 !important;
	
	&::-webkit-scrollbar {
            width: 5px;
    }
    &::-webkit-scrollbar-track {
            width: 5px;
            background-color: darken(#ededed, 5%);
    }
    &::-webkit-scrollbar-thumb {
            background-color: darken(#ebebeb, 18%);
    }
}

.modal .bootstrap-wysihtml5-insert-link-url {
	margin-bottom: @base-padding+2 !important;
	
	+ label {
		.user-select(none);
		
		input {
			top: 4px;
			margin-right: @base-padding !important;
		}
	}
}