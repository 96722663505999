.dropdown-menu {
	
	&.daterangepicker {
		background: #fafafa;
		.dropdown-menu.daterangepicker > .no-border-radius;
		
		&:has(.ranges) .calendar {
			display: none;
		}
		
		&.opensright {
			
			&:before {
				border-right: 7px solid transparent;
				border-bottom: 7px solid @input-border;
				border-left: 7px solid transparent;
				border-bottom-color: @input-border;
			}
			
			&:after {
				border-right: 6px solid transparent;
				border-bottom: 6px solid #fafafa;
				border-left: 6px solid transparent;
			}
		}
		
		.calendar-time {
			background: #fff;
			border: 1px solid @input-border;
			.dropdown-menu.daterangepicker > .no-border-radius;
		}
		
		.no-border-radius {
			border-color: @input-border;
			.border-radius(@border-radius-base);
		}
		
		.range_inputs input {
			color: @main-text-color;
			outline: none;
		}
		
		.calendar-date,
		.range_inputs input,
		table > thead > tr > th,
		table > thead > tr > td,
		table > tbody > tr > th,
		table > tbody > tr > td,
		table > tbody > tr > td.start-date.end-date {
			.dropdown-menu.daterangepicker > .no-border-radius;
		}
		
		.calendar-date {
			
			table {
				
				> thead {
					
					th {
						color: @main-text-color;
					}
					
					.month {
						color: @text-color;
					}
					
					.next, .prev {
						
						i:before {
							font-family: 'fontawesome';
							content: '\f105';
							font-size: 11px;
						}
						
						&.prev i:before {
							content: '\f104';
						}
						
						&:hover {
							background-color: #f5f5f5;
						}
					}
				}
				
				> tbody {
					
					> tr {
						
						> td {
							color: @main-text-color;
							.user-select(none);
							
							&:hover {
								background-color: #f5f5f5;
							}
							
							&.available {
								color: darken(@main-text-color, 10%);
							}
							
							&.off {
								color: lighten(@main-text-color, 15%);
							}
							
							&.disabled {
								background-color: #fafafa;
								color: lighten(@main-text-color, 15%);
								cursor: not-allowed;
							}
							
							&.in-range {
								background-color: fade(@secondary-color, 10%);
							}
							
							&.active {
								background-color: @secondary-color;
								color: #fff;
							}
						}
					}
				}
			}
		}
		
		.btn-default {
			border-color: @input-border;
		}
		
		.ranges {
			
			ul {
				
				li {
					background: #fff;
					border-color: @input-border;
					color: @main-text-color;
					padding: 4px @base-padding - 2;
					font-size: @font-size-small;
					text-align: center;
					line-height: 1;
					.border-radius(@border-radius-base);
					
					&:hover {
						//color: @text-color;
						background: #f5f5f5;
					}
					
					&.active {
						color: #fff;
						background: @secondary-color;
						border-color: @secondary-color;
					}
				}
			}
		}
	}
}

.daterange-inline {
	background: #f5f5f5;
	border: 1px solid @input-border;
	padding: @base-padding;
	cursor: pointer;
	
	i {
		color: @text-color;
		margin-right: @base-padding/2;
	}
	
	&:hover {
		color: @text-color;
	}
}