.notes-env {
	position: relative;
	margin-bottom: @default-padding;
	
	.notes-header {
		margin-bottom: @base-padding;
	}
	
	.notes-list {
		@wp_width: 74%;
		@wp_bg: #fff;
		@nl_bg: #ededed;
		@wp_line_bg: darken(@nl_bg, 5%);
		
		position: relative;
		//background: @nl_bg;
		//border: 1px solid @default-border-color;
		&:extend(.clearfix all);
		
		.write-pad, .list-of-notes {
			.box-sizing(border-box);
		}
		
		.list-of-notes {
			float: left;
			width: 100% - @wp_width;
			list-style: none;
			margin: 0;
			padding: 0;
			padding-bottom: @base_margin;
			
			li {
				position: relative;
				//padding: @base_margin;
				padding-right: @base-padding;
				padding-bottom: @base-padding;
				
				a {
					display: block;
					border: 1px solid @wp_line_bg;
					background: @wp_bg;
					padding: @base-padding @default-padding/2;
					
					strong, span {
						display: block;
						
						& + strong, & + span {
							padding-top: 8px;
						}
					}
					
					strong {
						word-break: break-all;
						background: #f5f5f5;
						margin: -@default-padding/2;
						margin-top: -@base-padding;
						margin-bottom: 0;
						padding: @base-padding - 2 @base-padding;
						border-bottom: 1px solid @wp_line_bg;
						font-size: @font-size-base - 1;
						
						@media screen and (max-width: @screen-sm){
							margin-bottom: -@base-padding;
							border-bottom: 0;
						}
					}
					
					span {
						.text-overflow();
						font-size: @font-size-small;
					}
					
					em {
						float: right;
					}
					
					&:hover {
						background: #f5f5f5 !important;
						border-color: darken(@wp_line_bg, 5%);
						
						span, strong {
							color: @text_color_light;
						}
					}
				}
				
				.note-close {
					position: absolute;
					right: 1px + @base-padding;
					top: 1px;
					border: none;
					background: none;
					outline: none;
					background: #f5f5f5;
					border: 1px solid @wp_line_bg;
					border-top: 0;
					border-right: 0;
					.opacity(0);
				}
				
				&:hover .note-close {
					.opacity(1);
				}
				
				.content {
					display: none;
				}
				
				&.current {
					
					a {
						background: #fff;
					}
				}
			}
			
			li + .no-notes {
				display: none;
			}
		}
		
		.write-pad {
			float: right;
			width: @wp_width;
			background: @wp_bg;
			position: relative;
							
			&:after {
				display: block;
				content: '';
				position: absolute;
				left: 95px;
				top: 0;
				bottom: 0;
				background: #f9d4d1;
				width: 1px;
			}
			
			textarea {
				background: transparent;
				border: none;
				background: url(../images/notes-lines.png) left top;
				background-attachment: local;
				min-height: 780px;
				font: 14px/52px @font-family-sans-serif, serif;
				border: 1px solid @default-border-color;
				max-height: 1500px;
				padding-left: 125px;
				padding-right: 50px;
				
				&::-webkit-scrollbar {
			            width: 5px;
			    }
			    &::-webkit-scrollbar-track {
			            width: 5px;
			            background-color: darken(@nl_bg, 5%);
			    }
			    &::-webkit-scrollbar-thumb {
			            background-color: darken(#ebebeb, 18%);
			    }
			}
		}
	}
	
	& + footer.main {
		border-top: 0;
		padding-top: @base_padding;
	}
}



// Medium Screens
@media (max-width: @screen-md-min) {

	.notes-env {
		
		.notes-list {
			@wp_width: 65%;
			
			.list-of-notes {
				width: 100% - @wp_width;
			}
		
			.write-pad {
				width: @wp_width;
				
				textarea {
					padding-left: 50px;
				}
				
				&:after {
					left: 35px;
				}
			}
		}
	}
}

// Small Screens
@media (max-width: @screen-sm-min) {
	
	body .notes-env {
		
		.notes-list {
			@wp_width: 100%;
			
			.list-of-notes, .write-pad {
				width: 100%;
				float: none;
			}
			
			.list-of-notes {
				padding-bottom: @base_padding;
				
				li {
					padding: 0;
					padding-top: @base_padding;
					padding-bottom: 0;
					
					a {
						span {
							display: none;
						}
					}
					
					.note-close {
						right: 1px;
						top: @base-padding + 1;
					}
				}
			}
			
			.write-pad {
				
				textarea {
					min-height: 400px; 
					max-height: 600px; 
				}
			}
		}
	}
}