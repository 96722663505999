.popover,
.tooltip {
	
	+ .btn {
		margin-left: @base-padding/2;
	}
}

.popover {
	padding: 0;
	border: 1px solid @default-border-color;
	.box-shadow(none);
	
	&.popover-primary { .xenon-popover-style(@brand-primary); }
	&.popover-secondary { .xenon-popover-style(@secondary-color); }
	&.popover-info { .xenon-popover-style(@brand-info); }
	&.popover-warning { .xenon-popover-style(@brand-warning); }
	&.popover-danger { .xenon-popover-style(@brand-danger); }
	&.popover-success { .xenon-popover-style(@brand-success); }
	&.popover-purple { .xenon-popover-style(@xe-purple); }
	&.popover-blue { .xenon-popover-style(@xe-blue); }
	&.popover-red { .xenon-popover-style(@xe-red); }
	&.popover-black { .xenon-popover-style(@xe-blue); }
}

.tooltip {
	
	.tooltip-inner {
		padding: @base-padding @base-padding+5;
	}
	
	&.tooltip-primary { .xenon-tooltip-style(@brand-primary); }
	&.tooltip-secondary { .xenon-tooltip-style(@secondary-color); }
	&.tooltip-info { .xenon-tooltip-style(@brand-info); }
	&.tooltip-warning { .xenon-tooltip-style(@brand-warning); }
	&.tooltip-danger { .xenon-tooltip-style(@brand-danger); }
	&.tooltip-success { .xenon-tooltip-style(@brand-success); }
	&.tooltip-purple { .xenon-tooltip-style(@xe-purple); }
	&.tooltip-blue { .xenon-tooltip-style(@xe-blue); }
	&.tooltip-red { .xenon-tooltip-style(@xe-red); }
	&.tooltip-black { .xenon-tooltip-style(@xe-blue); }
}

.xenon-popover-style(@bg)
{
		
	.popover-title {
		background-color: @bg;
		color: #FFF;
		position: relative;
	}
	
	&.top,
	&.bottom {
	
		.popover-title {
			margin: -1px;
		}
	}
	
	&.bottom .arrow:after { border-bottom-color: @bg; }
}

.xenon-tooltip-style(@bg)
{
	.tooltip-inner {
		background-color: @bg;
	}
	
	&.top .tooltip-arrow { border-top-color: @bg; }
	&.bottom .tooltip-arrow { border-bottom-color: @bg; }
	&.left .tooltip-arrow { border-left-color: @bg; }
	&.right .tooltip-arrow { border-right-color: @bg; }
}