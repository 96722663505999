// Xenon Based Mixins
.xenon-btn-style(@bg; @txt: #FFF; @txt-hover: @txt)
{
	@border: darken(@bg, 5%);
	background-color: @bg;
	color: @txt;
	
	&:hover {
		background-color: @border;
		text-color: @txt-hover;
	}
	
	&:active,
	&:focus {
		background-color: @border;
		border-color: darken(@border, 2%);
	}
}

.xenon-btn-icon-standalone(@color; @bg-pct: 2%; @border-pct: 5%; @border-hover-pct: 6%)
{	
	&.btn-icon-standalone {
		
		i {
			background-color: darken(@color, @bg-pct);
			border-right-color: darken(@color, @border-pct);
		}
		
		&.btn-icon-standalone-right {
			
			i {
				border-left-color: darken(@color, @border-pct);
			}
			
			&:hover {
				
				i {
					border-left-color: darken(@color, @border-hover-pct);
				}
			}
		}
		
		&:hover {
		
			i {
				border-right-color: darken(@color, @border-hover-pct);
			}
		}
	}
}



// Input Unstyled
.input-unstyled() {
	border: 0;
	background: none;
	padding: 0;
	height: auto;
}



// Uppercase
.upper() {
	text-transform: uppercase;
}