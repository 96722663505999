.search-env {
	
	form {
		position: relative;
		margin-bottom: @default-padding;
		
		.form-control {
			padding-right: 50px;
		}
		
		button[type="submit"] {
			position: absolute;
			right: 0;
			top: 0;
			line-height: @input-height-large;
			padding: 0 @default-padding/2;
			font-size: @font-size-base + 3;
			color: @main-text-color;
			outline: none;
		}
	}
	
	.nav.tabs-vertical {
		width: 200px;
		
		@media screen and (max-width: @screen-sm){
			width: 50px;
			min-width: 50px;
		}
		
		> li {
			
			> a {
				background: none;
				font-size: @font-size-base + 1;
				text-align: left;
				
				&:after {
					display: block;
					content: '';
					height: 1px;
					margin-top: 5px;
					background-color: @default-border-color;
					position: absolute;
					left: @default-padding/2;
					right: @default-padding/2;
					bottom: 0;
				}
			}
		
			&.active {
				
				> a {
					font-weight: bold;
					color: @brand-primary;
					
					&:after {
						display: none;
					}
				}
			}
			
			&:last-child > a:after {
				display: none;
			}
		}
	}
	
	
	.tab-content {
		
		.tab-pane {
			
			h2 {
				margin: 0;
				font-size: @font-size-h2 - 3;
				border-bottom: 1px solid #f5f5f5;
				padding-bottom: @default-padding;
				margin-top: -@base-padding;
				margin-bottom: @default-padding;
				
				small {
					display: block;
					padding-top: @base-padding;
					color: #999;
					font-size: @font-size-base;
				}
			}
			
			.results {
				
				li {
					border-bottom: 1px solid #f5f5f5;
					padding-bottom: @default-padding;
					margin-bottom: @default-padding;
				
					h3 {
						margin: 0;
						margin-bottom: @default-padding/2;
					}
					
					p {
						color: lighten(@main-text-color, 10%);
					}
					
					.link {
						color: @secondary-color;
						
						&:hover {
							color: darken(@secondary-color, 10%);
						}
					}
					
					+ li {
						margin-bottom: @default-padding;
					}
				}
			}
			
			.pagination {
				margin: 0;
			}
		}
	}
}