.google-map-env {
	background: #e5e5e5;
	margin-bottom: @default-padding;
		
	~ .main-footer {
		display: none !important;
	}
	
	&.fill {
		margin: -@default-padding;
		
		.map-toolbar > .row {
			margin-left: 0;
			margin-right: 0;
		}
	}
	
	.map-toolbar {
		padding: @base-margin @default-padding/2;
		background: #fff;
		
		+ .gmap {
			border-top: 1px solid #ccc;
		}
	}
	
	.gmap + .map-toolbar {
		border-top: 1px solid @border-radius-base;
	}
	
	
	.full-page-google-map {
		border-top: 1px solid @default-border-color;
		
		> div {
			width: 100%;
			height: 500px;
		}
	}
}


.jvectormap-label {
    position: absolute;
    display: none;
    background: @brand-primary;
    color: white;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px @base-padding - 3;
    z-index: 1000 !important;
    .border-radius(@border-radius-base);
}

.jvectormap-zoomin, 
.jvectormap-zoomout {
	@s: 14px;
	
    position: absolute;
    left: 10px;
    border: 1px solid lighten(@default-border-color, 4%);
    background: #fafafa;
    padding: 3px;
    color: @main-text-color;
    width: @s * 2;
    height: @s * 2;
    cursor: pointer;
    line-height: @s + 5;
    text-align: center;
    .border-radius(@border-radius-base);
    
    &:hover {
	    background-color: darken(#fafafa, 3%);
    }
    
    &:active {
	    .box-shadow(inset 0px 0px 5px fade(#000, 10%));
    }
}

.jvectormap-zoomin {
    top: 10px;
}

.jvectormap-zoomout {
    top: 40px - 3;
}