// Import Bootstrap Variables & Mixins
@import "bs-less/variables.less";
@import "bs-less/mixins.less";

// LessHat
@import "other-less/lesshat.less";

// Xenon Basic UI
@import "xenon-core.less";

// Forms
@import "xenon-forms.less";

// Xenon Extra Components
@import "xenon-components.less";

// Xenon Skins
@import "xenon-skins.less";

.table-large {
  max-width: none;
  word-wrap: break-word;
}

.validate-has-error .select2-selection__rendered, .validate-has-error .select2-selection {
  border: 1px solid #a94442;
  border-radius: 4px;
}

span.validate-has-error {
  color: #a94442;
}

.background1 {

  background: #000 url("/assets/images/background/leaderboard.jpg") center center;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
}

    .background2 {

  background: #000 url("/assets/images/background/flag-green.jpg")center center;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
}
    .table-super-condensed {
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            padding: @table-super-condensed-cell-padding;
          }
        }
      }
    }