.tocify {
	@tocify-bg: lighten(@hover_bg, 2%);
	@tocify-border: darken(@hover_bg, 1%);
	
	border: 1px solid @default-border-color;
	background: #fff;
	//overflow: hidden;
	//margin-top: @default-padding/2;
	.border-radius(@border-radius-base);
	
	&.fixed {
		@top: 15px;
		
		position: fixed;
		top: @top;
		
		.navbar.horizontal-menu + .page-container & {
			top: @horizontal-menu-height + @top + @base-padding;
		}
	}
		
	@media (max-width: @screen-sm-max) {
		top: 0px;
		position: relative !important;
		margin-bottom: @base-margin + @base-padding;
		
		.tocify-item.active > a {
			
			&:before,
			&:after {
				display: none !important;
			}
		}
	}
	
	li {
		border-top: 1px solid darken(@tocify-border, 5%);
		
		a {
			color: @main-text-color;
			
			&:hover {
				background: @tocify-bg;
				color: @text-color;
			}
		}
	}
	
	> ul {
		
		&:first-child > li {
			border-top: 0;
		}
		
		ul li a {
			padding-left: 20px + 10px;
		}
		
		ul {
			background: lighten(@tocify-bg, 1%);
			
			ul li a {
				padding-left: 20px + 10px + 10px;
			}
		}
		
		&:last-child { border-bottom: 0; }
	}
	
	.tocify-item.active {
		
		> a {
			@s: 6px;
			@t: 8;
			
			position: relative;
			background: darken(@tocify-bg, 3.5%);
			color: @text-color;
			
			
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: @s + @t;
				right: -1px;
				width: 0;
				height: 0;
				border-color: transparent @background-color transparent transparent;
				border-style: solid;
				border-width: @s;
			}
			
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: @s - 1 + @t;
				right: -1px;
				margin-left: -2px;
				width: 0;
				height: 0;
				border-color: transparent @default-border-color transparent transparent;
				border-style: solid;
				border-width: @s + 1;
			}
		}
	}
}

.tocify-extend-page {
	display: none !important;
}