@widget-spacing: 20px;

.xe-widget {
	position: relative;
	
	// Counters
	&.xe-counter {
		background: #fff;
		padding: @base-margin + 8;
		line-height: 1;
		display: table;
		width: 100%;
		margin-bottom: @widget-spacing;
		
		.xe-icon,
		.xe-label {
			display: table-cell;
			vertical-align: middle;
			padding: @base-margin - 2;
		}
		
		.xe-icon {
			width: 1%;
			
			i {
				@s: 50px;
				
				display: block;
				background: @secondary-color;
				color: #fff;
				text-align: center;
				font-size: 25px;
				line-height: @s;
				.size(@s);
				.border-radius(50%);
			}
			
			+ .xe-label {
				padding-left: 0;
			}
		}
		
		.xe-label {
			
			.num {
				display: block;
				font-size: 28px;
				font-weight: normal;
				color: darken(@main-text-color, 35%);
			}
			
			span {
				display: block;
				font-style: normal;
				font-size: @font-size-small - 2;
				text-transform: uppercase;
				color: @main-text-color;
				margin-top: @base-padding/2;
			}
			
			+ .xe-icon {
				padding-left: 0;
			}
		}
		
		&.xe-counter-primary { .xe-counter-variant(@brand-primary, #fff); }
		&.xe-counter-success { .xe-counter-variant(@brand-success, #fff); }
		&.xe-counter-warning { .xe-counter-variant(@brand-warning, #fff); }
		&.xe-counter-info { .xe-counter-variant(@brand-info, #fff); }
		&.xe-counter-danger { .xe-counter-variant(@brand-danger, #fff); }
		&.xe-counter-blue { .xe-counter-variant(@xe-blue, #fff); }
		&.xe-counter-red { .xe-counter-variant(@xe-red, #fff); }
		&.xe-counter-purple { .xe-counter-variant(@xe-purple, #fff); }
		&.xe-counter-orange { .xe-counter-variant(@xe-orange, #fff); }
		&.xe-counter-yellow { .xe-counter-variant(@xe-yellow, #fff); }
		&.xe-counter-turquoise { .xe-counter-variant(@xe-turquoise, #fff); }
		&.xe-counter-white { .xe-counter-variant(#fff, darken(@main-text-color, 30%)); .xe-icon i { border: 1px solid @default-border-color; } }
		&.xe-counter-gray { .xe-counter-variant(@xe-gray, #555); }
		&.xe-counter-pink { .xe-counter-variant(@xe-pink, #fff); }
	}
	
	&.xe-counter-block {
		color: #fff;
		background: @secondary-color;
		margin-bottom: @widget-spacing;
		
		.xe-upper {
			&:extend(.xe-widget.xe-counter all);
			margin: 0;
			border-spacing: 0;
			border: 0;
			background: @secondary-color;
			
			.xe-icon,
			.xe-label {
				padding-bottom: 0;
			}
			
			.xe-icon {
				text-align: center;
				
				i {
					background: @secondary-color;
					font-size: 32px;
				}
			}
			
			.xe-label {
				
				.num {
					color: #fff;
				}
				
				span {
					color: fade(#fff, 70%);
				}
			}
		}
		
		.xe-lower {
			padding: @default-padding/2 @default-padding;
			
			.border {
				height: 1px;
				background: fade(#fff, 50%);
				margin-bottom: @base-padding;
			}
			
			span, strong {
				font-size: @font-size-small - 2;
				text-transform: uppercase;
				display: block;
			}
			
			span {
				color: fade(#fff, 70%);
			}
			
			strong {
				font-weight: normal;
			}
		}
		
		&.xe-counter-block-primary { .xe-counter-block-variant(@brand-primary; #fff); }
		&.xe-counter-block-success { .xe-counter-block-variant(@brand-success; #fff); }
		&.xe-counter-block-warning { .xe-counter-block-variant(@brand-warning; #fff); }
		&.xe-counter-block-danger { .xe-counter-block-variant(@brand-danger; #fff); }
		&.xe-counter-block-info { .xe-counter-block-variant(@brand-info; #fff); }
		&.xe-counter-block-purple { .xe-counter-block-variant(@xe-purple; #fff); }
		&.xe-counter-block-blue { .xe-counter-block-variant(@xe-blue; #fff); }
		&.xe-counter-block-red { .xe-counter-block-variant(@xe-red; #fff); }
		&.xe-counter-block-orange { .xe-counter-block-variant(@xe-orange; #fff); }
		&.xe-counter-block-turquoise { .xe-counter-block-variant(@xe-turquoise; #fff); }
		&.xe-counter-block-pink { .xe-counter-block-variant(@xe-pink; #fff); }
		&.xe-counter-block-yellow { .xe-counter-block-variant(@xe-yellow; #fff); }
		&.xe-counter-block-gray { .xe-counter-block-variant(darken(@xe-gray, 10%), #666); }
		&.xe-counter-block-white { .xe-counter-block-variant(#fff; darken(@main-text-color, 30%)); }
	}
	
	
	// Progress Counter
	&.xe-progress-counter {
		position: relative;
		color: #fff;
		background: @secondary-color;
		margin-bottom: @widget-spacing;
		overflow: hidden;
		&:extend(.xe-widget.xe-counter-block all);
		
		.xe-background {
			position: absolute;
			left: -40%;
			bottom: -10%;
			color: #fff;
			font-size: 150px;
			.opacity(.1);
			
			i {
				line-height: 1;
				display: block;
			}
		}
		
		.xe-upper {
			
			.xe-icon {
				
				i {
					font-size: 28px;
					background: none;
				}
			}
			
			.xe-label {
				
				span {
					padding: 0;
					padding-bottom: @base-padding/2;
				}
				
				.num {
					font-size: 20px;
				}
			}
		}
		
		.xe-progress {
			height: 2px;
			position: relative;
			background: fade(#000, 10%);
			margin: 0 @default-padding;
			margin-top: @base-padding;
			
			.xe-progress-fill {
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				bottom: 0;
				background: #fff;
			}
		}
		
		&.xe-progress-counter-primary { .xenon-progress-counter-variant(@brand-primary; #fff); }
		&.xe-progress-counter-success { .xenon-progress-counter-variant(@brand-success; #fff); }
		&.xe-progress-counter-info { .xenon-progress-counter-variant(@brand-info; #fff); }
		&.xe-progress-counter-danger { .xenon-progress-counter-variant(@brand-danger; #fff); }
		&.xe-progress-counter-warning { .xenon-progress-counter-variant(@brand-warning; #fff); }
		&.xe-progress-counter-pink { .xenon-progress-counter-variant(@xe-pink; #fff); }
		&.xe-progress-counter-turquoise { .xenon-progress-counter-variant(@xe-turquoise; #fff); }
		&.xe-progress-counter-red { .xenon-progress-counter-variant(@xe-red; #fff); }
		&.xe-progress-counter-orange { .xenon-progress-counter-variant(@xe-orange; #fff); }
		&.xe-progress-counter-yellow { .xenon-progress-counter-variant(@xe-yellow; #fff); }
		&.xe-progress-counter-purple { .xenon-progress-counter-variant(@xe-purple; #fff); }
	}
	
	
	// Vertical Counter
	&.xe-vertical-counter {
		background: @secondary-color;
		padding: @base-margin;
		padding-top: @base-margin * 2;
		margin-bottom: @widget-spacing;
		color: #fff;
		
		.xe-icon {
			font-size: 75px;
			text-align: center;
			padding-bottom: @default-padding/2;

			i {
				display: block;
				line-height: 1.4;
			}
		}
		
		.xe-label {
			padding-top: @default-padding/2;
			text-align: center;
			
			.num {
				display: block;
				font-weight: normal;
				font-size: @font-size-h3;
			}
			
			span {
				display: block;
				padding-top: @base-padding/2;
				text-transform: uppercase;
				font-size: @font-size-base - 1;
				color: fade(#fff, 95%);
			}
		}
		
		&.xe-vertical-counter-primary { .xenon-vertical-counter-variant(@brand-primary; #fff); }
		&.xe-vertical-counter-success { .xenon-vertical-counter-variant(@brand-success; #fff); }
		&.xe-vertical-counter-danger { .xenon-vertical-counter-variant(@brand-danger; #fff); }
		&.xe-vertical-counter-warning { .xenon-vertical-counter-variant(@brand-warning; #fff); }
		&.xe-vertical-counter-info { .xenon-vertical-counter-variant(@brand-info; #fff); }
		&.xe-vertical-counter-purple { .xenon-vertical-counter-variant(@xe-purple; #fff); }
		&.xe-vertical-counter-orange { .xenon-vertical-counter-variant(@xe-orange; #fff); }
		&.xe-vertical-counter-red { .xenon-vertical-counter-variant(@xe-red; #fff); }
		&.xe-vertical-counter-blue { .xenon-vertical-counter-variant(@xe-blue; #fff); }
		&.xe-vertical-counter-turquoise { .xenon-vertical-counter-variant(@xe-turquoise; #fff); }
		&.xe-vertical-counter-pink { .xenon-vertical-counter-variant(@xe-pink; #fff); }
		&.xe-vertical-counter-yellow { .xenon-vertical-counter-variant(@xe-yellow; #fff); }
		&.xe-vertical-counter-gray { .xenon-vertical-counter-variant(darken(@xe-gray, 10%); #555); }
		&.xe-vertical-counter-white { .xenon-vertical-counter-variant(#fff; darken(@main-text-color, 30%)); }
	}
	
	
	// Todo List
	&.xe-todo-list {
		background: #fff;
		padding: @base-margin+5 @default-padding;
		margin-bottom: @widget-spacing;
		
		.xe-header {
			display: table;
			width: 100%;
			border-bottom: 1px solid #eee;
			margin-bottom: @default-padding/2;
			
			.xe-icon,
			.xe-label {
				display: table-cell;
				vertical-align: top;
				padding-bottom: @base-margin;
			}
			
			.xe-icon {
				width: 1%;
				
				i {
					display: block;
					line-height: 1;
					font-size: 28px;
				}
			}
			
			.xe-label {
				padding-left: @default-padding/2;
				
				span, strong {
					display: block;
					line-height: 1.1;
				}
				
				span {
					font-size: @font-size-small;
					.opacity(.8);
				}
				
				strong {
					font-size: @font-size-base + 2;
				}
			}
		}
		
		.xe-body {
			padding-top: @base-padding/2;
			padding-bottom: @base-padding;
			
			label {
				.user-select(none);
				
				> span {
					position: relative;
					display: inline-block;
					color: darken(@main-text-color, 10%);
				}
			}
			
			li {
				margin: 2px 0;
				
				&.done {
					
					label {
						
						> span {
							.opacity(0.5);
							//color: @main-text-color;
							
							&:after {
								display: block;
								position: absolute;
								left: 0;
								right: 0;
								top: 9px;
								height: 1px;
								background: darken(@main-text-color, 5%);
								content: '';
							}
						}
					}
				}
			}
		}
		
		&.xe-todo-list-primary { .xenon-todo-list-variant(@brand-primary; #fff); }
		&.xe-todo-list-success { .xenon-todo-list-variant(@brand-success; #fff); }
		&.xe-todo-list-warning { .xenon-todo-list-variant(@brand-warning; #fff); }
		&.xe-todo-list-danger { .xenon-todo-list-variant(@brand-danger; #fff); }
		&.xe-todo-list-info { .xenon-todo-list-variant(@brand-info; #fff); }
		&.xe-todo-list-secondary { .xenon-todo-list-variant(@secondary-color; #fff); }
		&.xe-todo-list-turquoise { .xenon-todo-list-variant(@xe-turquoise; #fff); }
		&.xe-todo-list-pink { .xenon-todo-list-variant(@xe-pink; #fff); }
		&.xe-todo-list-red { .xenon-todo-list-variant(@xe-red; #fff); }
		&.xe-todo-list-blue { .xenon-todo-list-variant(@xe-blue; #fff); }
		&.xe-todo-list-purple { .xenon-todo-list-variant(@xe-purple; #fff); }
		&.xe-todo-list-yellow { .xenon-todo-list-variant(@xe-yellow; #fff); }
	}
	
	
	// Status Update
	&.xe-status-update {
		background: #55acee;
		color: #fff;
		margin-bottom: @widget-spacing;
		padding: @default-padding;
		padding-bottom: @base-margin;
		
		.xe-header {
			display: table;
			width: 100%;
			
			.xe-icon,
			.xe-nav {
				display: table-cell;
				vertical-align: top;
				color: #fff;
				padding-bottom: @base-margin;
			}
			
			.xe-icon {
				
				i {
					font-size: 48px;
				}
			}
			
			.xe-nav {
				text-align: right;
				
				a {
					position: relative;
					margin-right: -@base-padding;
					margin-top: -@base-padding;
					color: #fff;
					padding: 0 @base-padding - 2;
					display: inline-block;
					line-height: 32px;
					//&:extend(.transall);
					
					&:hover {
						background: fade(#fff, 10%);
					}
					
					+ a {
						margin-left: @base-padding;
					}
				}
			}
		}
		
		.xe-body {
			
			li {
				display: none !important;
					
				.status-date {
					display: block;
					font-size: @font-size-base + 3;
					.opacity(.5);
					padding-bottom: @base-padding;
				}
				
				p {
					font-size: @font-size-base + 1;
					color: #fff;
					.opacity(.8);
				}
				
				&.active {
					display: block !important;
				}
			}
		}
		
		.xe-footer {
			text-transform: uppercase;
			font-size: @font-size-small - 1;
			padding-top: @default-padding/2;
			
			a {
				color: #fff;
				.opacity(.8);
				
				&:hover {
					.opacity(1);
				}
			}
		}
		
		&.xe-status-update-facebook { .xenon-status-update-variant(#3b5998); }
		&.xe-status-update-google-plus { .xenon-status-update-variant(#dd4b39); }
	}
	
	
	// Single News
	&.xe-single-news {
		margin-bottom: @widget-spacing;
		position: relative;
		
		.xe-image {
		
			img {
				display: block;
				width: 100%;
			}
			
			.xe-gradient {
				position: absolute;
				left: 0; right: 0; top: 0; bottom: 0;
				.background-image(linear-gradient(to top, #000 0%, fade(#000,5%) 100%));
				&:extend(.transall);
			}
		}
		
		.xe-details {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: @base-margin;
			
			.category {
				font-size: @font-size-small - 1;
				text-transform: uppercase;
				margin-bottom: @base-padding;
				
				a {
					color: #fff;
					.opacity(.5);
					
					&:hover {
						.opacity(1);
					}
				}
			}
				
			h3 {
				margin: 0;
				padding: 0;
				margin-bottom: @base-padding;
				font-size: @font-size-h3 - 4;
				
				a {
					color: #fff;
					.opacity(.8);
					
					&:hover {
						.opacity(1);
					}
				}
			}
			
			time {
				position: relative;
				display: block;
				color: #fff;
				font-size: @font-size-base;
				margin-bottom: -20px;
				.transition(~"all 350ms cubic-bezier(0.175, 0.885, 0.320, 1.275)");
				.opacity(0);
			}
		}
	
		&:hover {
			
			.xe-details {
				
				time {
					margin-bottom: 0;
					.opacity(.7);
				}
			}
		}
	}
	
	
	// Conversations
	&.xe-conversations {
		position: relative;
		background: #fff;
		margin-bottom: @widget-spacing;
		padding: @default-padding;
		
		.xe-bg-icon {
			position: absolute;
			right: @base-margin;
			top: @base-margin;
			
			i {
				font-size: 80px;
				display: block;
				line-height: 1;
				.opacity(.1);
			}
		}
		
		.xe-header {
			display: table;
			width: 100%;
			border-bottom: 1px solid #eee;
			
			.xe-icon,
			.xe-label {
				display: table-cell;
				vertical-align: middle;
				padding-bottom: @base-margin;
			}
			
			.xe-icon {
				width: 1%;
				
				i {
					display: block;
					line-height: 1;
					font-size: 37px;
				}
			}
			
			.xe-label {
				padding-left: @base-padding;
				
				h3 {
					display: block;
					margin: 0;
					padding: 0;
					
					small {
						padding-top: @base-padding/2;
						font-size: @font-size-base;
						display: block;
						.opacity(.5);
					}
				}
			}
		}
		
		.xe-body {
			border-bottom: 1px solid #eee;
			
			.xe-user-img {
				float: left;
				display: block;
				padding-right: @base-padding;
			}
			
			.xe-comment-entry {
				display: table;
				width: 100%;
				
				.xe-user-img,
				.xe-comment {
					display: table-cell;
					vertical-align: top;
					padding-top: @base-padding;
					padding-bottom: @base-padding;
				}
				
				.xe-user-img {
					
					img {
						display: block;
					}
				}
				
				.xe-comment {
					width: 99%;
					padding-left: @base-padding/2 + 5;
					
					.xe-user-name {
						display: block;
						margin-bottom: @base-padding/2;
						
						&:hover strong {
							color: darken(@main-text-color, 10%);
						}
					}
					
					p {
						font-size: @font-size-small;
					}
				}
			}
			
			ul {
				
				> li {
					margin: @base-padding 0;
					border-bottom: 1px solid #eee;
					
					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}
		
		.xe-footer {
			
			a {
				margin-top: @base-margin;
				display: block;
				font-size: @font-size-small - 1;
				color: darken(@main-text-color, 10%);
				text-align: center;
				text-transform: uppercase;
				
				&:hover {
					color: darken(@main-text-color, 20%);
				}
			}
		}
	}
	
	
	// Weather
	&.xe-weather {
		position: relative;
		background: #459ec4;
		color: #fff;
		overflow: hidden;
		margin-bottom: @widget-spacing;
		&:extend(.clearfix all);
		
		.xe-background {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			
			img {
				display: block;
				line-height: 1;
				width: 100%;
				position: relative;
			}
			
			&.xe-background-animated {			
				left: -10%;
				top: -20%;
					
				img {
					width: 120%;
					.animation(weatherBgMove 10s infinite linear);
					.animation-direction(alternate);
				}
				
				.keyframes(~"weatherBgMove, 0% { left: 0%; } 50% { left: 10%; } 100% { left: 0%; }");
			}
			
			@media screen and (max-width: @screen-sm){
				top: auto;
				bottom: 0;
				
				&.xe-background-animated {
					top: auto;
					bottom: 0;
				}
			}
		}
		
		@weekdays-width: 35%;
		
		.xe-current-day {
			position: absolute;
			z-index: 2;
			right: @weekdays-width;
			top: 0;
			left: 0;
			bottom: 0;
			padding: @default-padding;
			
			.xe-now {
				margin-bottom: @base-margin;
				&:extend(.clearfix all);
				
				.xe-temperature {
					display: table;
					float: left;
					
					.xe-icon,
					.xe-label {
						display: table-cell;
						vertical-align: middle;
					}
					
					.xe-icon {
						
						i {
							display: block;
							line-height: 1;
							font-size: 35px;
						}
					}
					
					.xe-label {
						padding-left: @base-padding;
						line-height: 1;
						text-transform: uppercase;
						color: fade(#fff, 80%);
						font-size: @font-size-small - 1;
						
						strong {
							display: block;
							padding-top: 2px;
							font-size: @font-size-h3;
							line-height: 1;
							color: #fff;
							font-weight: normal;
						}
					}
				}
				
				.xe-location {
					float: right;
					text-align: right;
					
					h4 {
						margin: 0;
						padding-bottom: @base-padding/2;
						color: fade(#fff, 95%);
					}
					
					time {
						color: fade(#fff, 80%);
						font-size: @font-size-small;
					}
				}
			}
			
			.xe-forecast {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 0 @default-padding;
				padding-bottom: @default-padding;
				
				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					display: table;
					width: 100%;
					
					> li {
						position: relative;
						display: table-cell;
						vertical-align: middle;
						width: 1%;
						
						&:after {
							content: '';
							display: block;
							width: 1px;
							top: 32px;
							left: 0;
							bottom: 0;
							background: fade(#fff, 25%);
							position: absolute;
						}
							
						&:first-child:after {
							display: none;
						}
					}
				}
				
				.xe-forecast-entry {
					text-align: center;
					
					time {
						display: inline-block;
						background: fade(#000, 15%);
						padding: 5px 5px;
						line-height: 1;
						color: fade(#fff, 70%);
						font-size: @font-size-small - 1;
						&:extend(.transall);
					}
					
					.xe-icon {
						
						i {
							display: block;
							line-height: 42px;
							font-size: 20px;
						}
					}
					
					.xe-temp {
						display: block;
						font-weight: normal;
						font-size: @font-size-base + 1;
						color: fade(#fff, 85%);
					}
					
					&:hover {
						
						time,
						.xe-temp {
							color: #fff;
						}
					}
				}
			}
			
			
			
			@media screen and (max-width: @screen-sm) {
				position: relative;
				width: 100%;
				
				.xe-forecast {
					position: relative;
					margin-top: 90px;
					padding: 0;
				}
			}
		}
		
		.xe-weekdays {
			float: right;
			position: relative;
			z-index: 2;
			background: fade(#000, 5%);
			padding: @default-padding/2 @default-padding;
			width: @weekdays-width;
			
			ul {
				
				> li {
					position: relative;
					
					&:after {
						content: '';
						display: block;
						position: absolute;
						right: 0;
						left: 0;
						bottom: 0;
						height: 1px;
						background: fade(#fff, 25%);
					}
					
					&:last-child:after {
						display: none;
					}
				}
			}
			
			
			.xe-weekday-forecast {
				display: table;
				width: 100%;
				cursor: default;
				
				.xe-temp,
				.xe-day,
				.xe-icon {
					display: table-cell;
					vertical-align: middle;
					padding: @default-padding/2 0;
				}
				
				.xe-temp {
					width: 20%;
					font-size: 14px;
					color: fade(#fff, 50%);
					&:extend(.transall);
				}
				
				.xe-icon {
					text-align: right;
					font-size: 20px;
					
					i {
						display: block;
						line-height: 1;
					}
				}
				
				&:hover {
					
					.xe-temp {
						color: fade(#fff, 95%);
					}
				}
			}
			
			@media screen and (max-width: @screen-sm) {
				float: none;
				width: 100%;
				
				.xe-weekday-forecast {
					
					.xe-temp {
						width: 10%;
					}
				}
			}
		}
	}
	
	
	// Map
	&.xe-map-stats {
		background: #385868;
		color: #fff;
		margin-bottom: @widget-spacing;
		display: table;
		width: 100%;
		&:extend(.clearfix all);
		
		@details-width: 35%;
		
		.xe-map,
		.xe-details {
			display: table-cell;
			vertical-align: top;
		}
		
		.xe-map {
			width: 100% - @details-width;
			position: relative;
			
			> div {
				@spacing: @default-padding - 10;
				
				position: absolute;
				top: @spacing;
				bottom: @spacing;
				left: @spacing;
				right: @spacing;
				
				.jvectormap-zoomin,
				.jvectormap-zoomout {
					.size(22px);
					line-height: 22px - 2;
					padding: 0;
					border: 0;
					
					&.jvectormap-zoomout {
						top: 32px;
						border-top: 1px solid fade(#ccc, 80%);
					}
				}
			}
		}
		
		.xe-details {
			width: @details-width;
			background: fade(#000, 30%);
			padding: @default-padding;
			
			> .xe-label {
				
				h3 {
					margin: 0;
				}
				
				p {
					margin: 0;
					padding-top: @base-padding/2;
					color: fade(#fff, 80%);
				}
			}
			
			ul {
				margin-top: @base-margin;
				
				li {
					position: relative;
					padding: @base-padding @base-padding/2;
					border-top: 1px solid fade(#fff, 10%);
				}
			}
			
			.xe-map-data {
				display: block;
				
				.label {
					display: inline-block;
					text-align: center;
					min-width: 30px;
					margin-right: @base-padding/2;
					vertical-align: middle;
				}
				
				.xe-label {
					font-size: @font-size-base + 1;
				}
			}
		}
		
		@media screen and (max-width: @screen-sm){
			display: block;
			
			.xe-map {
				min-height: 300px;
			}
			
			.xe-map,
			.xe-details {
				width: 100%;
				display: block;
			}
		}
	}
}


.xe-counter-variant(@bg-color; @text-color)
{
	.xe-icon {
		
		i {
			background-color: @bg-color;
			color: @text-color;
		}
	}
}

.xe-counter-block-variant(@bg-color; @text-color)
{
	background: @bg-color;
	
	.xe-upper {
		background: @bg-color;
		
		.xe-icon i {
			background-color: @bg-color;
			color: @text-color;
		}
		
		.xe-label {
			
			.num {
				color: @text-color;
			}
			
			span {
				color: fade(@text-color, 70%);
			}
		}
	}
		
	.xe-lower {

		.border {
			background: fade(@text-color, 50%);
		}
		
		span {
			color: fade(@text-color, 70%);
		}
		
		strong {
			color: @text-color;
		}
	}
}

.xenon-progress-counter-variant(@bg-color; @text-color)
{
	background-color: @bg-color;
		
	.xe-background {
		color: @text-color;
	}
	
	.xe-upper {
		background-color: transparent;
	}
}

.xenon-vertical-counter-variant(@bg-color; @text-color)
{
	background: @bg-color;
	color: @text-color;
		
	.xe-label {

		span {
			color: fade(@text-color, 95%);
		}
	}
}

.xenon-todo-list-variant(@bg-color; @text-color)
{
	@pct: 70%;
	
	background: @bg-color;
	color: @text-color;
	
	.xe-header {
		border-bottom: 1px solid fade(@text-color, @pct);
	}
	
	.xe-body {
		
		.cbr-replaced {
			
			.cbr-state {
				border: 1px solid fade(@text-color, @pct);
			}
		}
			
		label {
			
			> span {
				color: @text-color;
			}
		}
			
		li {
			
			&.done {
				
				label {
					
					> span {
						.opacity(.8);
						
						&:after {
							background: fade(@text-color, @pct);
						}
					}
				}
			}
		}
	}
	
	.xe-footer {
		
		.form-control {
			background: transparent;
			border: 1px solid fade(@text-color, @pct);
			color: @text-color;
			.placeholder(fade(@text-color, 90%));
		}
	}
}

.xenon-status-update-variant(@bg-color)
{
	background-color: @bg-color;
}