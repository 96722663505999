.panel {
	position: relative;
	background: #ffffff;
	padding: @base-margin @default-padding;
	border: 0;
	margin-bottom: @default-padding;
	.box-shadow(none);
	
	&.panel-headerless .panel-body {
		padding-top: 0;
	}
	
	.panel-heading {
		position: relative;
		padding: 0;
		margin: 0;
		background: none;
		font-size: @font-size-h4;
		padding-bottom: @default-padding/2;
		border-bottom: 2px solid #f5f5f5;
		&:extend(.clearfix all);
		
		> .panel-title {
			float: left;
			font-size: @font-size-h4;
				
			> a {
				color: inherit;
			}
		}
		
		> .panel-options {
			float: right;
			font-size: @font-size-base;
			
			a {
				display: inline-block;
				color: @main-text-color;
				margin-left: @base-padding - 3;
				line-height: 1;
				
				i {
					line-height: 1;
				}
				
				&[data-toggle="panel"] {
					
					span {
						display: block;
						font-size: 16px;
						width: 10px;
						text-align: center;
					
						&.expand-icon {
							display: none;
							font-size: 18px;
						}
					}
				}
				
				&[data-toggle="remove"] {
					font-size: 18px;
				}
				
				&:first-child {
					margin-left: 0;
				}
			}
			
			// Tabs
			.nav-tabs {
				border: 0;
				
				> li {
					border: 0;
					
					> a {
						padding: 0;
						border: 0;
						margin-left: @base-padding + 5;
						color: @main-text-color;
						background: none;
						
						&:hover {
							background: none;
						}
					}
					
					&.active > a {
						color: darken(@main-text-color, 30%);
					}
				}
			}
		}
	}
	
	.panel-body {
		padding: 0;
		padding-top: @base-margin;
		color: @main-text-color;
		
		&.no-padding {
			padding: 0;
			margin-top: -2px;
			margin-left:  -@default-padding;
			margin-right:  -@default-padding;
			margin-bottom: -@base-margin;
		}
		
		&.panel-border {
			border-top: 1px solid #f5f5f5;
			padding-top: @default-padding;
		}
		
		p {
			margin-bottom: 0;
			
			+ p {
				margin-top: @default-padding/2;
			}
		}
	}
	
	.panel-disabled {
		position: absolute;
		left: 0; right: 0; top: 0; bottom: 0;
		background: fade(#FFF, 70%);
	}
	
	// Collapsed panel
	&.collapsed {
		
		.panel-heading {
			
			.panel-options {
				
				a[data-toggle="panel"] {
					
					.collapse-icon {
						display: none;
					}
					
					.expand-icon {
						display: block;
					}
				}
			}
		}
		
		> .panel-body, > .table {
			display: none;
		}
	}
	
	
	// Panel Border
	&.panel-border {
		border: 1px solid #cccccc;
	}
	
	
	// Panel Shadow
	&.panel-shadow {
		.box-shadow(~"0 1px 1px rgba(0,1,1,.08)");
	}
	
	
	// Panel Styles
	&.panel-default {
		
	}
	
	&.panel-inverted {
		background: @brand-primary;
		color: #FFF;
		
		> .panel-heading {
			border-bottom-color: #393c3e;
		}
		
		> .panel-disabled {
			background: fade(@brand-primary, 50%);
		}
	}
	
	&.panel-tabs {
		background: none;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: @default-padding - @base-margin;
		padding-top: 7px;
		
		> .panel-heading {
			border-bottom: 0;
			
			.nav-tabs {
				position: relative;
				margin-bottom: -@base-margin - 1;
				margin-right: -2px;
				
				> li {
					
					> a {
						position: relative;
						background-color: #f5f5f5;
						padding: @base-padding + 5 @base-margin;
						margin-left: @base-padding/2;
						top: -6px;
					}
					
					&:hover, &.active {
						
						> a {
							background-color: #FFF;
						}
					}
				}
			}
		}
		
		> .panel-body {
			background: #FFF;
			padding: @base-margin @default-padding;
		}
	}
	
	
	&.panel-flat {
		background: none;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		
		.panel-heading {
			border-bottom-color: #dddddd;
		}
		
		.panel-disabled {
			background: fade(@background-color, 60%);
		}
	}
	
	&.panel-success { .xe-panel-variation(@brand-success; #FFF); }
	&.panel-info { .xe-panel-variation(@brand-info; #FFF); }
	&.panel-warning { .xe-panel-variation(@brand-warning; #FFF); }
	&.panel-danger { .xe-panel-variation(@brand-danger; #FFF); }
	
	&.panel-red { .xe-panel-variation(@xe-red; #FFF); }
	&.panel-black { .xe-panel-variation(@xe-black; #FFF); }
	&.panel-purple { .xe-panel-variation(@xe-purple; #FFF); }
	&.panel-blue { .xe-panel-variation(@xe-blue; #FFF); }
	&.panel-light-blue { .xe-panel-variation(@xe-light-blue; #FFF); }
	&.panel-gray { .xe-panel-variation(darken(@xe-gray, 5%); darken(@main-text-color, 15%)); }
	
	// Different styling panels
	&.panel-color {
		
		.panel-heading {
			margin-top: -@base-margin;
			margin-left: -@default-padding;
			margin-right: -@default-padding;
			padding: @base-margin @default-padding;
			border-bottom: 0;
		}
		
		&.collapsed {
			
			> .panel-heading {
				margin-bottom: -@base-margin;
			}
		}
	}
}



.xe-panel-variation(@bg-color; @text-color)
{	
	.panel-heading {
		background-color: @bg-color;
		color: @text-color;
		
		> .panel-title {
			color: @text-color;
			
			> a {
				color: inherit;
			}
		}
		
		> .panel-options {
			color: @text-color;
			
			a {
				color: fade(@text-color, 60%);
				
				&:hover {
					color: @text-color;
				}
			}
		}
	}
	
	> .panel-disabled {
		background-color: fade(@bg-color, 15%);
	}
}