html body {

	// Range Slider
	.ui-rangeSlider {
		@h: 10px;
		@bg: @secondary-color;
		@border: 2px;
		
		.ui-rangeSlider-container {
			height: @h;
			background: #ebebeb;
			.border-radius(@border-radius-base);
		
			.ui-rangeSlider-bar {
				background: @bg;
				height: @h;
			}
			
			.ui-rangeSlider-handle {
				position: relative;
				background: @bg;
				.size(@h);
				.border-radius(@border-radius-base);
				border: 0px solid #FFF;
				padding: @h - 2;
				margin-top: -@h/2 - @border;
				
				&.ui-rangeSlider-leftHandle {
					.translateX(-100px);
				}
				
				&.ui-rangeSlider-rightHandle {}
			}
		}
			
		.ui-rangeSlider-label  {
			visibility: hidden;
		}
	}
	
	
	.slider {
		@bg: #ebebeb;
		@range-bg: @secondary-color;
		@border: #fff;
		@s: 8px;
		
		background: @bg;
		height: @s;
		margin-bottom: @default-padding;
		.border-radius(@border-radius-base);
		
		.ui-slider-handle {
			background: @range-bg;
			top: -3px;
			border: 0;
			.size(@s + 6);
			margin-top: 0;
			outline: none;
			cursor: pointer !important;
			
			+ .ui-slider-handle { // Right Handle
				
			}
			
			.ui-label {
				position: absolute;
				left: -50%;
				top: @s + 10px;
				color: darken(@main-text-color, 10%);
				margin-left: @s/2;
				white-space: nowrap;
			}
		}
		
		.ui-slider-range {
			background: @range-bg;
			.border-radius(@border-radius-base);
		}
		
		&.ui-slider-horizontal {
			
			.ui-slider-handle {
				
				.ui-label {
					margin-top: 0px;
				}
			}
		}
		
		&.ui-slider-vertical {
			height: 250px;
			width: @s;
			
			.ui-slider-handle {
				top: auto;
				margin-left: 0.5px;
				
				.ui-label {
					top: -1px;
					left: @s + 10px;
				}
			}
		}
		
		.slider-variant(@bg; @range-bg; @border);
		
		
		&.slider-primary { .slider-variant(@bg; @brand-primary; #fff); }
		&.slider-secondary { .slider-variant(@bg; @secondary-color; #fff); }
		&.slider-success { .slider-variant(@bg; @brand-success; #fff); }
		&.slider-info { .slider-variant(@bg; @brand-info; #fff); }
		&.slider-danger { .slider-variant(@bg; @brand-danger; #fff); }
		&.slider-warning { .slider-variant(@bg; lighten(#f7d227, 10%); #fff); }

		&.slider-purple { .slider-variant(@bg; @xe-purple; #fff); }
		&.slider-blue { .slider-variant(@bg; @xe-blue; #fff); }
		&.slider-red { .slider-variant(@bg; @xe-red; #fff); }
		&.slider-gray { .slider-variant(@bg; darken(@xe-gray, 25%); #fff); }
		
	}
}



// Slider Variant
.slider-variant(@bg; @range-bg; @border)
{
	background: @bg;
	
	.ui-slider-handle {
		background: darken(@range-bg, 10%);
		border: 0;//2px solid @border;
	}
	
	.ui-slider-range, .ui-fill {
		background: @range-bg;
	}
}
