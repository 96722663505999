.draggable-portlets {
	
	.sorted {
		min-height: 100px;
		
		.ui-sortable-placeholder {
			background: fade(#ffffcc, 70%);
			border: 1px dashed @default-border-color;
			visibility: visible !important;
		}
		
		
		> .panel {
			.transition(~"opacity 200ms ease-in-out, transform 200ms ease-in-out");
			
			.panel-heading {
				cursor: pointer;
			}
		}
	}
	
	&.dragging {
		
		.sorted {
			
			> .panel {
				.transform(~"scale(.97)");
				.opacity(.5);
				
				&.ui-sortable-helper {
					.transform(~"scale(1.03)");
					.opacity(1);
				}
			}
		}
	}
}