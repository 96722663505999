@grayDarker: #222;
@grayDark: #333;
@white: #fff;
@orange: #f89406;

@white: #fff;
@hover_bg: #f5f5f5;

div.datepicker {
	padding: 4px;
	.border-radius(@border-radius-base);
	&-inline {
		width: 206px;//220px;
	}
	direction: ltr;
	&&-rtl {
		direction: rtl;
		table tr td span {
			float: right;
		}
	}
	&-dropdown {
		top: 0;
		left: 0;
		&:before {
			content: '';
			display: inline-block;
			border-left:   7px solid transparent;
			border-right:  7px solid transparent;
			border-bottom: 7px solid @input-border;
			border-top:    0;
			border-bottom-color: @input-border;
			position: absolute;
		}
		&:after {
			content: '';
			display: inline-block;
			border-left:   6px solid transparent;
			border-right:  6px solid transparent;
			border-bottom: 6px solid @white;
			border-top:    0;
			position: absolute;
		}
		&.datepicker-orient-left:before   { left: 6px; }
		&.datepicker-orient-left:after    { left: 7px; }
		&.datepicker-orient-right:before  { right: 6px; }
		&.datepicker-orient-right:after   { right: 7px; }
		&.datepicker-orient-top:before    { top: -7px; }
		&.datepicker-orient-top:after     { top: -6px; }
		&.datepicker-orient-bottom:before {
			bottom: -7px;
			border-bottom: 0;
			border-top:    7px solid @input-border;
		}
		&.datepicker-orient-bottom:after {
			bottom: -6px;
			border-bottom: 0;
			border-top:    6px solid @white;
		}
	}
	>div {
		display: none;
	}
	&.days div.datepicker-days {
		display: block;
	}
	&.months div.datepicker-months {
		display: block;
	}
	&.years div.datepicker-years {
		display: block;
	}
	table{
		margin: 0;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	td,
	th{
		text-align: center;
		width: 20px;
		height: 20px;
		.border-radius(@border-radius-base);

		border: none;
	}
	// Inline display inside a table presents some problems with
	// border and background colors.
	.table-striped & table tr {
		td, th {
			background-color:transparent;
		}
	}
	table tr td {
		
		&.day {
			color: darken(@main-text-color, 20%);
		}
		
		&.day:hover {
			background: #f5f5f5;
			cursor: pointer;
			color: @text-color;
		}
		&.old,
		&.new {
			color: lighten(@main-text-color, 10%);
		}
		&.disabled,
		&.disabled:hover {
			background: none;
			color: @gray-light;
			color: @main-text-color;
			cursor: default;
		}
		&.today,
		&.today:hover,
		&.today.disabled,
		&.today.disabled:hover {
			@todayBackground: lighten(@text-color, 30%);
			.buttonBackground(@todayBackground, spin(@todayBackground, 20));
			color: #000;
		}
		&.today:hover:hover { // Thank bootstrap 2.0 for this selector...
			// TODO: Bump min BS to 2.1, use @textColor in buttonBackground above
			color: #000;
		}
		&.today.active:hover {
			color: #fff;
		}
		&.range,
		&.range:hover,
		&.range.disabled,
		&.range.disabled:hover {
			background:@gray-lighter;
			.border-radius(0);
		}
		&.range.today,
		&.range.today:hover,
		&.range.today.disabled,
		&.range.today.disabled:hover {
			@todayBackground: mix(@orange, @gray-lighter, 50%);
			.buttonBackground(@todayBackground, spin(@todayBackground, 20));
			.border-radius(0);
		}
		&.selected,
		&.selected:hover,
		&.selected.disabled,
		&.selected.disabled:hover {
			.buttonBackground(lighten(@gray-light, 10), darken(@gray-light, 10));
			color: #fff;
			text-shadow: 0 -1px 0 rgba(0,0,0,.25);
		}
		&.active,
		&.active:hover,
		&.active.disabled,
		&.active.disabled:hover {
			//.buttonBackground(@btn-primary-bg, spin(@btn-primary-bg, 20));
			background: @secondary-color;
			color: @btn-primary-color;
			text-shadow: 0 -1px 0 rgba(0,0,0,.25);
		}
		span {
			display: block;
			width: 23%;
			height: 54px;
			line-height: 54px;
			float: left;
			margin: 1%;
			cursor: pointer;
			.border-radius(@border-radius-base);
			&:hover {
				background: #f5f5f5;
			}
			&.disabled,
			&.disabled:hover {
				background:none;
				color: @gray-light;
				cursor: default;
			}
			&.active,
			&.active:hover,
			&.active.disabled,
			&.active.disabled:hover {
				//.buttonBackground(@btn-primary-bg, spin(@btn-primary-bg, 20));
				background: @secondary-color;
				color: #fff;
				//text-shadow: 0 -1px 0 rgba(0,0,0,.25);
			}
			&.old,
			&.new {
				color: lighten(@main-text-color, 10%);
			}
		}
	}

	th.datepicker-switch {
		width: 145px;
	}

	thead tr:first-child th,
	tfoot tr th {
		cursor: pointer;
		&.datepicker-switch {
			color: @text-color;
		}
		&:hover{
			background: #f5f5f5;
		}
	}
	/*.dow {
		border-top: 1px solid #ddd !important;
	}*/

	// Basic styling for calendar-week cells
	.cw {
		font-size: 10px;
		width: 12px;
		padding: 0 2px 0 5px;
		vertical-align: middle;
	}
	thead tr:first-child th.cw {
		cursor: default;
		background-color: transparent;
	}
}
.input-append,
.input-prepend {
	&.date {
		.add-on i {
			display: block;
			cursor: pointer;
			width: 16px;
			height: 16px;
		}
	}
}
.input-daterange {
	input {
		text-align:center;
	}
	input:first-child {
		.border-radius(@border-radius-base 0 0 @border-radius-base);
	}
	input:last-child {
		.border-radius(0 @border-radius-base @border-radius-base 0);
	}
	.add-on {
		display: inline-block;
		width: auto;
		min-width: 16px;
		height: @line-height-base;
		padding: 4px 5px;
		font-weight: normal;
		line-height: @line-height-base;
		text-align: center;
		text-shadow: 0 1px 0 @white;
		vertical-align: middle;
		background-color: @gray-lighter;
		border: 1px solid #ccc;
		margin-left:-5px;
		margin-right:-5px;
	}
}



.datepicker-inline {
	border: 1px solid @input-border;
	
	&.no-border {
		border-color: transparent;
	}
	
	table {
		width: 195px;
	}
}

.no-border .datepicker-inline {
	border: 0;
	padding: 0;
}


.input-group-addon {
	
	.color-preview {		
		.size(16px);
		background: #FFF;
		display: inline-block;
		border: 1px solid @input-border;
	}
}

.buttonBackground(@startColor, @endColor, @textColor: #fff, @textShadow: 0 -1px 0 rgba(0,0,0,.25)) { // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  //.gradientBar(@startColor, @endColor, @textColor, @textShadow);
  *background-color: @endColor; /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  .reset-filter(); // in these cases the gradient won't cover the background, so we override
  
  &.switch-left {
	  background-color: @endColor;
	  color: @textColor;
  }
  
  &.switch-right {
	  background-color: @hover_bg;
	  color: @main-text-color;
	  
	  &:hover {
	  	  background-color: @hover_bg;//lighten(@hover_bg, 5%);//fade(@endColor, 40%);
		  color: @main-text-color;
	  }
  }
  
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: @textColor;
    //background-color: @endColor;
    *background-color: darken(@endColor, 5%);
  }

// IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
  &:active,
  &.active {
    background-color: darken(@endColor, 10%) e("\9");
  }
}