.calendar-env {
	position: relative;
	margin-bottom: @default-padding;
	&:extend(.clearfix all);
	
	.calendar-sidebar {
		
		form {
			margin-bottom: @base-margin;
			
			.form-control {
				padding: @default-padding/2 + 5 @default-padding/2;
				height: @input-height-base + 5;
				width: 100%;
			}
		}
		
		@media screen and (max-width: @screen-sm-min) {
			margin-top: @default-padding;
			
			form {
				margin-bottom: 0;
			}
		}
	
		.calendar-list {
			margin: 0;
			margin-bottom: @default-padding;
			
			@media screen and (max-width: @screen-sm) {
				margin-top: @base-padding;
			}
			
			
			li {

				a {
					display: block;
					color: @main-text-color;
					padding-bottom: @base-padding - 3;
					
					&:hover {
						color: darken(@main-text-color, 15%);
					}
					
					.badge {
						margin-top: 0px;
						display: block;
						text-align: left;
						font-size: @font-size-small;
						padding: @base-padding - 3 @base-padding;
						.text-overflow();
					}
				}
				
				&.list-header {
					font-size: @font-size-small - 2;
					padding: @base-padding 0;
					font-weight: bold;
					color: @main-text-color;
					border-bottom: 0;
					.upper;
				}
				
				&.ui-draggable-dragging {
					
					.badge {
						.opacity(.6);
					}
				}
				
				&.ui-draggable-handle {
					
					.badge {
						cursor: move;
					}
				}
			}
		}
	}
	
	.calendar-main {
		@default-border-color: #eee;
		
		position: relative;
		background: #fff;
		padding: @default-padding;
		z-index: 5;
		
		// Full Calendar
		.fc {
				
			.fc-popover, .fc-row, hr, tbody, td, th, thead {
				border-color: @default-border-color;
			}
			
			.fc-toolbar {
				padding-bottom: @default-padding/2;
				
				.right-border(){
					@dist: 25%;
							
					content: '';
					display: block;
					position: absolute;
					right: -1px;
					top: @dist;
					bottom: @dist;
					width: 1px;
					background: @default-border-color;
				}
				
				.fc-left {
					
					h2 {
						font-size: @font-size-h3 + 1;
						
						&:before {
							content: '\f133';
							font-family: 'fontawesome';
							display: inline-block;
							margin-right: @base-padding;
						}
					}
				}
				
				.fc-right {
					margin-top: 5px;
					
					> .fc-button-group:last-child {
						margin-right: -@default-padding/2;
					}
				}
				
				@media screen and (max-width: @screen-sm-min){
					
					.fc-left, .fc-right {
						float: none;
						width: 100%;
						.clearfix;
					}
					
					.fc-left {
					
						h2 {
							text-align: center;
							width: 100%;
							float: none;
						}
					}
					
					.fc-right {
						position: relative;
						margin-top: @base-padding;
						text-align: center;
						top: @base-padding + 2;
						
						.fc-button-group {
							display: inline-block;
							float: none;
						}
					}
				}
				
				.fc-button-group {
					background: none;
					border: 0;
					
					.fc-month-button,
					.fc-basicWeek-button, 
					.fc-agendaWeek-button, 
					.fc-agendaDay-button,
					.fc-basicDay-button {
						
						&.fc-state-active {
							color: @text-color;
						}
					}
					
					.fc-month-button {
						
						&:after {
							.right-border();
						}
					
						+ .fc-agendaWeek-button {
							
							&:after {
								.right-border();
							}
							
							+ .fc-agendaDay-button {
							}
						}
					
						+ .fc-basicWeek-button {
							
							&:after {
								.right-border();
							}
						}
					}
				}
				
				.fc-button {
					border: 0;
					background: none;
					padding: 0 @base-padding;
					margin: 0;
					height: auto;
					position: relative;
					outline: none;
					color: @main-text-color;
					.box-shadow(none);
					
					.fc-icon {
						font-size: @font-size-base;
						font-family: 'fontawesome';
					}
					
					&.fc-next-button,
					&.fc-prev-button {
						
						&:hover {
							color: @text-color;
						}
					}
					
					&.fc-prev-button {
					
						&:after {
							.right-border();
						}
					}
				}
			}
			
			.fc-view-container > .fc-basic-view > table {
				
				> thead > tr > td {
					
					&.fc-widget-header {
						border: 0;
						border-bottom: 1px solid @default-border-color;
					}
					
					.fc-day-header {
						border: 0;
						padding-bottom: @base-padding;
						color: darken(@main-text-color, 15%);
						font-weight: normal;
					}
				}
				
				> tbody > tr > .fc-widget-content {
					border: 0;
					border-bottom: 1px solid @default-border-color;
					
					.fc-day-number {
						padding-top: @base-padding;
						padding-right: @base-padding;
						color: #949494;
					}
				}
			}
			
			.fc-popover {
				.box-shadow(none);
				
				.fc-header {
					padding: @base-padding/2;
				}
				
				.fc-body {
					
					.fc-event-container {
						
						a {
							margin-left: 2px;
							margin-right: 2px;
						}
					}
				}
			}
					
			.fc-today {
				background-color: #fafafa;
				color: @brand-primary;
			}			
					
			.fc-event {
				background: @secondary-color;
				border: 0;
				.border-radius(0);
				padding: 1px 3px;
		
				&.event-color-primary { background-color: @brand-primary; }
				&.event-color-success { background-color: @brand-success; }
				&.event-color-danger { background-color: @brand-danger; }
				&.event-color-warning { background-color: @brand-warning; }
				&.event-color-info { background-color: @brand-info; }
				&.event-color-red { background-color: @xe-red; }
				&.event-color-blue { background-color: @xe-blue; }
				&.event-color-purple { background-color: @xe-purple; }
				&.event-color-gray { background-color: darken(@xe-gray, 35%); }
				&.event-color-black { background-color: @xe-black; }
			}
		}
	}
	
	// Floating
	.calendar-right {
		float: right !important;
		
		@media screen and (max-width: @screen-sm-min) {
			float: none !important;
		}
	}
	
	.calendar-left {
		float: left !important;
		
		@media screen and (max-width: @screen-sm-min) {
			float: none !important;
		}
	}
}