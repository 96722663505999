/*///////////////////////////////////////
// Code By Jordan Robert Dobson
//
// JordanDobson.com
// @jordandobson
// jordandobson@gmail.com
////////////////////////////////////////*/

.xenon-iswitch-generate(@switch-width: 38px; @switch-size: 22px; @switch-space: 2px; @extra-spacing: 0px)
{

	@base-font-size: 10px;
	
	@switch-off-padding: 0px;
	@switch-on-padding:  @switch-size - @switch-space - 4 + @extra-spacing;
	@switch-button-size: @switch-size - (@switch-space*2);
	
	@switch-off-border: #eee;
	@switch-on-color: @xe-gray;
	@switch-toggle-color: #fff;
	
	@switch-animation-bounce-distance: 0px;

	input[type="checkbox"]& {
	  font-size: @base-font-size;
	  position: relative;
	  display: inline-block;
	  width:         @switch-width;
	  height:        @switch-size;
	  line-height:   @switch-size;
	  border-radius: @switch-size/2;
	  margin:  0;
	  padding: 0;
	  box-shadow: inset 0 0 0 @switch-space @switch-off-border;
	  outline: 1px solid transparent !important;
	  cursor: pointer;
	  border: none;
	  background: transparent;
	  user-select: none;
	  -webkit-tap-highlight-color:rgba(0,0,0,0);
	  -webkit-touch-callout: none;
	  .appearance(none);
	  margin-right: 4px;
	  margin-bottom: (@padding-base-vertical + 1);
	
	  transition: box-shadow .3s ease-in-out, padding .25s ease-in-out;
	  transition-delay: .1s, 0s;
	
	  &:checked {
	    transition-delay: 0s, 0s;
	    box-shadow: inset 0 0 0 @switch-size/1.75 @switch-on-color !important;
	    padding-left: @switch-on-padding;
	  }
	  
	  // Styles
	  &.iswitch-primary:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @brand-primary !important; }
	  &.iswitch-secondary:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @secondary-color !important; }
	  &.iswitch-info:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @brand-info !important; }
	  &.iswitch-warning:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @brand-warning !important; }
	  &.iswitch-danger:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @brand-danger !important; }
	  &.iswitch-success:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @brand-success !important; }
	  &.iswitch-red:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @xe-red !important; }
	  &.iswitch-blue:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @xe-blue !important; }
	  &.iswitch-purple:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @xe-purple !important; }
	  &.iswitch-pink:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @xe-pink !important; }
	  &.iswitch-turquoise:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @xe-turquoise !important; }
	  &.iswitch-yellow:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @xe-yellow !important; }
	  &.iswitch-orange:checked { box-shadow: inset 0 0 0 @switch-size/1.75 @xe-orange !important; }
	  &.iswitch-gray:checked { box-shadow: inset 0 0 0 @switch-size/1.75 darken(@xe-gray, 10%) !important; }
	  
	    /* Animations if supported */  
	  &.hit{         .animation(~"slide-off .35s ease both");      }
	  &.hit:checked{ .animation(~"slide-on  .35s ease both .05s"); }
	
	  &:before,
	  &:after{ content: ""; }
	  
	  &:after{
	    /* Increases hit area */
	    @size-adjust: -(@switch-button-size/3);
	    
	    position: absolute;
	    top:    @size-adjust;
	    left:   @size-adjust;
	    bottom: @size-adjust;
	    right:  @size-adjust;
	  }
	  
	  &:before {
	    display: inline-block;
	    height: @switch-button-size;
	    width:  @switch-button-size;
	    margin: @switch-space 0 0 @switch-space;
	    background-color: @switch-toggle-color;
	    border-radius: @switch-button-size/2;
	    box-shadow: 0 @switch-space (@switch-space*2) 0 rgba(0,0,0,.20), 
	                0 0 (@switch-space/2) (@switch-space/2)rgba(0,0,0,.10);
	    .transition(~"all .1s ease .1s");
	  }
	
	  &:active:before{      transition: all .1s ease .05s; }
	  &.touch:hover:before{ transition: all .0s ease .00s; }
	  
	  &:active:before,
	  &.touch:hover:before{ width: @switch-button-size + (@switch-space); }
	  
	  &:active:checked:before,
	  &.touch:hover:checked:before{ margin-left: -(@switch-space*0);  }
	  
	}
	
	@keyframes slide-on {
	    0% { padding-left: @switch-off-padding; }
	   60% { padding-left: @switch-on-padding + @switch-animation-bounce-distance; }
	  100% { padding-left: @switch-on-padding; }
	}
	
	@keyframes slide-off{
	    0% { padding-left: @switch-on-padding;  text-indent: 0; }
	   60% { padding-left: @switch-off-padding; text-indent: -@switch-animation-bounce-distance; }
	  100% { padding-left: @switch-off-padding; text-indent: 0; }
	}
}

.iswitch {
	.xenon-iswitch-generate();
}

.iswitch-lg {
	.xenon-iswitch-generate(50px; 28px; 2px);
}

.iswitch-xlg {
	.xenon-iswitch-generate(85px; 38px; 2px; 15px);
}