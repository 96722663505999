.ps-container .ps-scrollbar-x-rail {
  position: absolute;
  /* please don't change 'position' */
  bottom: 3px;
  /* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 5px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
}
.ps-container:hover .ps-scrollbar-x-rail,
.ps-container.hover .ps-scrollbar-x-rail {
  zoom: 1;
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: alpha(opacity=60);
}
.ps-container .ps-scrollbar-x-rail:hover,
.ps-container .ps-scrollbar-x-rail.hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.ps-container .ps-scrollbar-x-rail.in-scrolling {
  background-color: rgba(255, 255, 255, 0.9);
}
.ps-container .ps-scrollbar-y-rail {
  position: absolute;
  /* please don't change 'position' */
  right: 2px;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 5px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
}
.ps-container:hover .ps-scrollbar-y-rail,
.ps-container.hover .ps-scrollbar-y-rail {
  zoom: 1;
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: alpha(opacity=60);
}
.ps-container .ps-scrollbar-y-rail:hover,
.ps-container .ps-scrollbar-y-rail.hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.ps-container .ps-scrollbar-y-rail.in-scrolling {
  background-color: #eee;
  zoom: 1;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);
}
.ps-container .ps-scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  bottom: 0;
  /* there must be 'bottom' for ps-scrollbar-x */
  height: 5px;
  background-color: #aaa;
  -o-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.ps-container.ie6 .ps-scrollbar-x {
  font-size: 0;
  /* fixed scrollbar height in xp sp3 ie6 */
}
.ps-container .ps-scrollbar-x-rail:hover .ps-scrollbar-x,
.ps-container .ps-scrollbar-x-rail.hover .ps-scrollbar-x {
  background-color: #999;
}
.ps-container .ps-scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  right: 0;
  /* there must be 'right' for ps-scrollbar-y */
  width: 5px;
  background-color: #aaa;
  -webkit-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
  -o-transition: background-color .2s linear;
  transition: background-color .2s linear;
}
.ps-container.ie6 .ps-scrollbar-y {
  font-size: 0;
  /* fixed scrollbar height in xp sp3 ie6 */
}
.ps-container .ps-scrollbar-y-rail:hover .ps-scrollbar-y,
.ps-container .ps-scrollbar-y-rail.hover .ps-scrollbar-y {
  background-color: #FAFAFA;
}
.ps-container.ie .ps-scrollbar-x,
.ps-container.ie .ps-scrollbar-y {
  visibility: hidden;
}
.ps-container.ie:hover .ps-scrollbar-x,
.ps-container.ie:hover .ps-scrollbar-y,
.ps-container.ie.hover .ps-scrollbar-x,
.ps-container.ie.hover .ps-scrollbar-y {
  visibility: visible;
}
.cbp_tmtimeline {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}
/* The line */
.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background: #afdcf8;
  left: 20%;
  margin-left: -10px;
}
/* The date/time */
.cbp_tmtimeline > li {
  position: relative;
}
.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 25%;
  padding-right: 100px;
  position: absolute;
}
.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}
.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #bdd0db;
}
.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 2.9em;
  color: #3594cb;
}
.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #6cbfee;
}
/* Right content */
.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  background: #3594cb;
  color: #fff;
  padding: 2em;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  position: relative;
  border-radius: 5px;
}
.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: #6cbfee;
}
.cbp_tmtimeline > li .cbp_tmlabel h2 {
  margin-top: 0px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
/* The triangle */
.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #3594cb;
  border-width: 10px;
  top: 10px;
}
.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #6cbfee;
}
/* The icons */
.cbp_tmtimeline > li .cbp_tmicon {
  width: 40px;
  height: 40px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: #46a4da;
  border-radius: 50%;
  box-shadow: 0 0 0 8px #afdcf8;
  text-align: center;
  left: 20%;
  top: 0;
  margin: 0 0 0 -25px;
}
/* Example Media Queries */
@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 30px 0;
    padding: 1em;
    font-weight: 400;
    font-size: 95%;
  }
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #3594cb;
    top: -20px;
  }
  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #6cbfee;
  }
  .cbp_tmtimeline > li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -55px 5px 0 0px;
  }
}
.dropdown-menu > li.checkbox-row {
  padding: 5px 20px;
}
.dropdown-menu li.checkbox-row {
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu li.checkbox-row label {
  font-weight: normal;
}
.dropdown-menu li.checkbox-row:hover,
.dropdown-menu li.checkbox-row input:hover,
.dropdown-menu li.checkbox-row label:hover {
  cursor: pointer;
}
.no-touch .dropdown-menu > .checkbox-row:hover,
.no-touch .dropdown-menu > .checkbox-row:active {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.btn-toolbar {
  margin-bottom: 5px;
}
.btn-toolbar .btn-default {
  background-color: #68b828;
  color: #fff;
  border: 1px solid transparent;
}
.btn-toolbar .btn-default.dropdown-toggle {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
}
.btn-toolbar .btn-primary {
  background-color: #508e1f;
}
.btn-toolbar .btn-group.open .dropdown-toggle {
  background-color: #508e1f;
  color: #fff;
}
.btn-toolbar .dropdown-menu {
  margin-top: 0;
}
.btn-toolbar .dropdown-menu .checkbox-row {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-toolbar .dropdown-menu .checkbox-row .cbr-replaced {
  margin: 0;
  margin-right: 5px;
}
.btn-toolbar .dropdown-menu .checkbox-row label {
  color: #979898;
  font-size: 12px;
  margin: 0;
}
.lt-ie8 .btn-toolbar {
  display: none;
}
.table-responsive {
  border-radius: 0px;
  border: 1px solid #eeeeee;
  margin-bottom: 18px;
}
.table-responsive > .table {
  margin-bottom: 0;
}
.table-responsive > .table-bordered {
  border: 0;
}
.table-responsive > .table-bordered > thead > tr > th:first-child,
.table-responsive > .table-bordered > tbody > tr > th:first-child,
.table-responsive > .table-bordered > tfoot > tr > th:first-child,
.table-responsive > .table-bordered > thead > tr > td:first-child,
.table-responsive > .table-bordered > tbody > tr > td:first-child,
.table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.table-responsive > .table-bordered > thead > tr > th:last-child,
.table-responsive > .table-bordered > tbody > tr > th:last-child,
.table-responsive > .table-bordered > tfoot > tr > th:last-child,
.table-responsive > .table-bordered > thead > tr > td:last-child,
.table-responsive > .table-bordered > tbody > tr > td:last-child,
.table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.table-responsive > .table-bordered > tbody > tr:last-child > th,
.table-responsive > .table-bordered > tfoot > tr:last-child > th,
.table-responsive > .table-bordered > tbody > tr:last-child > td,
.table-responsive > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}
.table-responsive[data-pattern="priority-columns"] {
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-radius: 0px;
  border: 1px solid #eeeeee;
  -webkit-overflow-scrolling: touch;
}
.table-responsive[data-pattern="priority-columns"] > .table {
  margin-bottom: 0;
}
.table-responsive[data-pattern="priority-columns"] > .table > thead > tr > th,
.table-responsive[data-pattern="priority-columns"] > .table > tbody > tr > th,
.table-responsive[data-pattern="priority-columns"] > .table > tfoot > tr > th,
.table-responsive[data-pattern="priority-columns"] > .table > thead > tr > td,
.table-responsive[data-pattern="priority-columns"] > .table > tbody > tr > td,
.table-responsive[data-pattern="priority-columns"] > .table > tfoot > tr > td {
  white-space: nowrap;
}
.table-responsive[data-pattern="priority-columns"] > .table-bordered {
  border: 0;
}
.table-responsive[data-pattern="priority-columns"] > .table-bordered > thead > tr > th:first-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tbody > tr > th:first-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tfoot > tr > th:first-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > thead > tr > td:first-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tbody > tr > td:first-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.table-responsive[data-pattern="priority-columns"] > .table-bordered > thead > tr > th:last-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tbody > tr > th:last-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tfoot > tr > th:last-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > thead > tr > td:last-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tbody > tr > td:last-child,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tbody > tr:last-child > th,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tfoot > tr:last-child > th,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tbody > tr:last-child > td,
.table-responsive[data-pattern="priority-columns"] > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}
.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority],
.mq.js.lt-ie10 .sticky-table-header th[data-priority],
.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority],
.mq.js.lt-ie10 .sticky-table-header td[data-priority] {
  display: none;
}
.lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="1"],
.lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="1"],
.lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="1"],
.lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="1"] {
  display: inline;
}
.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="1"],
.mq.js.lt-ie10 .sticky-table-header th[data-priority="1"],
.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="1"],
.mq.js.lt-ie10 .sticky-table-header td[data-priority="1"] {
  display: table-cell;
}
@media screen and (min-width: 480px) {
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="2"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="2"],
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="2"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="2"] {
    display: inline;
  }
  .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="2"],
  .mq.js.lt-ie10 .sticky-table-header th[data-priority="2"],
  .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="2"],
  .mq.js.lt-ie10 .sticky-table-header td[data-priority="2"] {
    display: table-cell;
  }
}
@media screen and (min-width: 640px) {
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="3"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="3"],
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="3"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="3"] {
    display: inline;
  }
  .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="3"],
  .mq.js.lt-ie10 .sticky-table-header th[data-priority="3"],
  .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="3"],
  .mq.js.lt-ie10 .sticky-table-header td[data-priority="3"] {
    display: table-cell;
  }
}
@media screen and (min-width: 800px) {
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="4"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="4"],
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="4"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="4"] {
    display: inline;
  }
  .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="4"],
  .mq.js.lt-ie10 .sticky-table-header th[data-priority="4"],
  .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="4"],
  .mq.js.lt-ie10 .sticky-table-header td[data-priority="4"] {
    display: table-cell;
  }
}
@media screen and (min-width: 960px) {
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="5"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="5"],
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="5"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="5"] {
    display: inline;
  }
  .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="5"],
  .mq.js.lt-ie10 .sticky-table-header th[data-priority="5"],
  .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="5"],
  .mq.js.lt-ie10 .sticky-table-header td[data-priority="5"] {
    display: table-cell;
  }
}
@media screen and (min-width: 1120px) {
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="6"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="6"],
  .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="6"],
  .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="6"] {
    display: inline;
  }
  .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="6"],
  .mq.js.lt-ie10 .sticky-table-header th[data-priority="6"],
  .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="6"],
  .mq.js.lt-ie10 .sticky-table-header td[data-priority="6"] {
    display: table-cell;
  }
}
table.table-small-font {
  font-size: 12px;
  -webkit-text-size-adjust: none;
  line-height: 1.5em;
}
table.focus-on tbody tr:hover {
  cursor: pointer;
}
table.focus-on tbody tr.unfocused th,
table.focus-on tbody tr.unfocused td {
  color: #777777;
  color: rgba(0, 0, 0, 0.4);
}
table.focus-on tbody tr.focused th,
table.focus-on tbody tr.focused td {
  background-color: #68b828;
  color: #fff;
  border-color: #63b026;
}
table th.col-hide,
table td.col-hide {
  display: none;
}
table th.col-show,
table td.col-show {
  display: inline;
}
.lt-ie9 table th.col-show,
.lt-ie9 table td.col-show {
  display: table-cell;
}
.lt-ie9 table.display-all th,
.lt-ie9 table.display-all td {
  display: inline !important;
}
table.display-all th,
table.display-all td {
  display: table-cell !important;
}
.sticky-table-header {
  background-color: #fff;
  border: none;
  border-radius: 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  position: absolute;
  top: 0;
  visibility: hidden;
  z-index: 990;
  overflow: hidden;
  min-width: 100%;
}
.sticky-table-header.fixed-solution {
  min-width: 0;
  position: fixed;
}
.lt-ie10 .sticky-table-header.fixed-solution {
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  box-sizing: content-box;
  border-bottom: none;
}
.sticky-table-header.border-radius-fix {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.sticky-table-header > .table {
  margin-bottom: 0;
}
.sticky-table-header > .table > thead > tr > th,
.sticky-table-header > .table > tbody > tr > th,
.sticky-table-header > .table > tfoot > tr > th,
.sticky-table-header > .table > thead > tr > td,
.sticky-table-header > .table > tbody > tr > td,
.sticky-table-header > .table > tfoot > tr > td {
  white-space: nowrap;
}
.sticky-table-header > .table-bordered {
  border: 0;
}
.sticky-table-header > .table-bordered > thead > tr > th:first-child,
.sticky-table-header > .table-bordered > tbody > tr > th:first-child,
.sticky-table-header > .table-bordered > tfoot > tr > th:first-child,
.sticky-table-header > .table-bordered > thead > tr > td:first-child,
.sticky-table-header > .table-bordered > tbody > tr > td:first-child,
.sticky-table-header > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.sticky-table-header > .table-bordered > thead > tr > th:last-child,
.sticky-table-header > .table-bordered > tbody > tr > th:last-child,
.sticky-table-header > .table-bordered > tfoot > tr > th:last-child,
.sticky-table-header > .table-bordered > thead > tr > td:last-child,
.sticky-table-header > .table-bordered > tbody > tr > td:last-child,
.sticky-table-header > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.sticky-table-header > .table-bordered > tbody > tr:last-child > th,
.sticky-table-header > .table-bordered > tfoot > tr:last-child > th,
.sticky-table-header > .table-bordered > tbody > tr:last-child > td,
.sticky-table-header > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}
.transall,
.sidebar-menu .main-menu a,
.navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"],
.navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i,
footer.main-footer .go-up a,
.user-info-navbar .user-info-menu > li > a,
.footer-sticked-chat .chat-conversations > li .chat-user,
.footer-sticked-chat .chat-conversations > li.open .conversation-window,
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li a span,
.breadcrumb.auto-hidden li a,
.icon-collection .fontawesome-icon-list .fa-hover .icon-str > i,
.icon-collection .fontawesome-icon-list .fa-hover .icon-str > span,
.form-wizard > .tabs > li a,
.form-wizard > .tabs > li a:after,
.droppable-area.dz-drag-hover,
.gallery-env .album-images .album-image .image-checkbox .cbr-replaced,
.login-page .login-form .form-group .control-label,
.login-page .login-form .form-group .form-control,
.lockscreen-page .lockcreen-form .form-group .input-group .form-control,
.xe-widget.xe-single-news .xe-image .xe-gradient,
.xe-widget.xe-weather .xe-current-day .xe-forecast .xe-forecast-entry time,
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-temp,
.sidebar-user-info .user-links li a {
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
.clearfix:before,
.clearfix:after,
.page-container .main-content .page-title:before,
.page-container .main-content .page-title:after,
.sidebar-menu .logo-env:before,
.sidebar-menu .logo-env:after,
.navbar.horizontal-menu .navbar-inner:before,
.navbar.horizontal-menu .navbar-inner:after,
footer.main-footer .footer-inner:before,
footer.main-footer .footer-inner:after,
.user-info-navbar .user-info-menu > li .dropdown-menu > .external a:before,
.user-info-navbar .user-info-menu > li .dropdown-menu > .external a:after,
#chat .chat-conversation .conversation-body > li:before,
#chat .chat-conversation .conversation-body > li:after,
.panel .panel-heading:before,
.panel .panel-heading:after,
.breadcrumb.auto-hidden:before,
.breadcrumb.auto-hidden:after,
.dataTables_wrapper .dataTables_length:before,
.dataTables_wrapper .dataTables_length:after,
.dataTables_wrapper .dataTables_filter:before,
.dataTables_wrapper .dataTables_filter:after,
.dataTables_wrapper .DTTT_container:before,
.dataTables_wrapper .DTTT_container:after,
.skin-color-palette:before,
.skin-color-palette:after,
.icon-collection .fontawesome-icon-list:before,
.icon-collection .fontawesome-icon-list:after,
.icon-collection .fontawesome-icon-list .fa-hover .icon-str:before,
.icon-collection .fontawesome-icon-list .fa-hover .icon-str:after,
.highlights li:before,
.highlights li:after,
.icheck-skins:before,
.icheck-skins:after,
.form-wizard:before,
.form-wizard:after,
.mailbox-env:before,
.mailbox-env:after,
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev:before,
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev:after,
.mailbox-env .mail-single .mail-single-header:before,
.mailbox-env .mail-single .mail-single-header:after,
.mailbox-env .mail-single .mail-single-info:before,
.mailbox-env .mail-single .mail-single-info:after,
.invoice-env:before,
.invoice-env:after,
.invoice-env .invoice-header:before,
.invoice-env .invoice-header:after,
.invoice-env .invoice-details:before,
.invoice-env .invoice-details:after,
.invoice-env .invoice-details .invoice-client-info:before,
.invoice-env .invoice-details .invoice-client-info:after,
.invoice-env .invoice-details .invoice-payment-info:before,
.invoice-env .invoice-details .invoice-payment-info:after,
.invoice-env .invoice-totals:before,
.invoice-env .invoice-totals:after,
.gallery-env:before,
.gallery-env:after,
.gallery-env .album-header:before,
.gallery-env .album-header:after,
.gallery-env .album-images .album-image:before,
.gallery-env .album-images .album-image:after,
.calendar-env:before,
.calendar-env:after,
.profile-env:before,
.profile-env:after,
.profile-env .user-timeline-stories .timeline-story header:before,
.profile-env .user-timeline-stories .timeline-story header:after,
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info:before,
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info:after,
.profile-env .user-timeline-stories .timeline-story .story-album:before,
.profile-env .user-timeline-stories .timeline-story .story-album:after,
.timeline-centered:before,
.timeline-centered:after,
.timeline-centered .timeline-entry .timeline-entry-inner:before,
.timeline-centered .timeline-entry .timeline-entry-inner:after,
.notes-env .notes-list:before,
.notes-env .notes-list:after,
.lockscreen-page .lockcreen-form:before,
.lockscreen-page .lockcreen-form:after,
.xe-widget.xe-weather:before,
.xe-widget.xe-weather:after,
.xe-widget.xe-weather .xe-current-day .xe-now:before,
.xe-widget.xe-weather .xe-current-day .xe-now:after,
.xe-widget.xe-map-stats:before,
.xe-widget.xe-map-stats:after,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:before,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:after {
  content: " ";
  display: table;
}
.clearfix:after,
.page-container .main-content .page-title:after,
.sidebar-menu .logo-env:after,
.navbar.horizontal-menu .navbar-inner:after,
footer.main-footer .footer-inner:after,
.user-info-navbar .user-info-menu > li .dropdown-menu > .external a:after,
#chat .chat-conversation .conversation-body > li:after,
.panel .panel-heading:after,
.breadcrumb.auto-hidden:after,
.dataTables_wrapper .dataTables_length:after,
.dataTables_wrapper .dataTables_filter:after,
.dataTables_wrapper .DTTT_container:after,
.skin-color-palette:after,
.icon-collection .fontawesome-icon-list:after,
.icon-collection .fontawesome-icon-list .fa-hover .icon-str:after,
.highlights li:after,
.icheck-skins:after,
.form-wizard:after,
.mailbox-env:after,
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev:after,
.mailbox-env .mail-single .mail-single-header:after,
.mailbox-env .mail-single .mail-single-info:after,
.invoice-env:after,
.invoice-env .invoice-header:after,
.invoice-env .invoice-details:after,
.invoice-env .invoice-details .invoice-client-info:after,
.invoice-env .invoice-details .invoice-payment-info:after,
.invoice-env .invoice-totals:after,
.gallery-env:after,
.gallery-env .album-header:after,
.gallery-env .album-images .album-image:after,
.calendar-env:after,
.profile-env:after,
.profile-env .user-timeline-stories .timeline-story header:after,
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info:after,
.profile-env .user-timeline-stories .timeline-story .story-album:after,
.timeline-centered:after,
.timeline-centered .timeline-entry .timeline-entry-inner:after,
.notes-env .notes-list:after,
.lockscreen-page .lockcreen-form:after,
.xe-widget.xe-weather:after,
.xe-widget.xe-weather .xe-current-day .xe-now:after,
.xe-widget.xe-map-stats:after,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:after {
  clear: both;
}
body {
  background-color: #eeeeee;
}
a {
  outline: none !important;
}
p {
  color: #979898;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}
.boxed-container {
  background: #ccc;
}
.boxed-container #chat .chat-inner {
  position: absolute !important;
}
.page-container {
  display: table;
  width: 100%;
  height: 100%;
  vertical-align: top;
  border-collapse: collapse;
  border-spacing: 0px;
  table-layout: fixed;
}
.boxed-container .page-container {
  max-width: 1140px;
  margin: 20px auto;
}
@media screen and (max-width: 768px) {
  .boxed-container .page-container {
    margin: 0;
  }
}
.page-container.container {
  max-width: 1140px;
  table-layout: auto;
}
.page-container .main-content {
  display: table-cell;
  position: relative;
  z-index: 1;
  padding: 30px;
  padding-bottom: 0;
  vertical-align: top;
  word-break: break-word;
  width: 100%;
  -webkit-transition: opacity 100ms ease-in-out;
  -moz-transition: opacity 100ms ease-in-out;
  -o-transition: opacity 100ms ease-in-out;
  transition: opacity 100ms ease-in-out;
}
.page-container .main-content.is-loading {
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
  -webkit-transition: opacity 300ms ease-in-out;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.boxed-container .page-container .main-content {
  background-color: #eeeeee;
}
.page-container .main-content:hover {
  z-index: 15;
}
.page-container .main-content .page-title {
  background: #f8f8f8;
  margin: -30px;
  margin-bottom: 30px;
  padding: 20px 0;
  -webkit-box-shadow: 0 1px 0 rgba(0,1,1,.08), inset 0 1px 0 #ededed;
  -moz-box-shadow: 0 1px 0 rgba(0,1,1,.08), inset 0 1px 0 #ededed;
  box-shadow: 0 1px 0 rgba(0,1,1,.08), inset 0 1px 0 #ededed;
}
@media screen and (max-width: 768px) {
  .page-container .main-content .page-title {
    padding: 15px 0;
  }
}
.page-container .main-content .page-title.full-width .title-env {
  width: 100%;
  padding-right: 30px;
}
.page-container .main-content .page-title .title-env,
.page-container .main-content .page-title .breadcrumb-env {
  display: block;
}
.page-container .main-content .page-title .title-env {
  float: left;
  padding-left: 30px;
}
.page-container .main-content .page-title .title-env .title {
  margin: 0;
  font-size: 27px;
}
@media screen and (max-width: 768px) {
  .page-container .main-content .page-title .title-env .title {
    font-size: 23px;
  }
  .page-container .main-content .page-title .title-env .title + p {
    font-size: 12px;
  }
}
@media screen and (max-width: 480px) {
  .page-container .main-content .page-title .title-env .title {
    font-size: 19px;
  }
}
.page-container .main-content .page-title .title-env .title + p {
  margin: 0;
  margin-top: 5px;
  color: #979898;
}
.page-container .main-content .page-title .breadcrumb-env {
  float: right;
  padding-right: 30px;
}
@media screen and (max-width: 480px) {
  .page-container .main-content .page-title .breadcrumb-env {
    clear: both;
    float: none;
    padding: 0 10px;
  }
}
.page-container .main-content .page-title .breadcrumb-env .breadcrumb {
  margin-bottom: 0;
  margin-top: 8px;
}
@media screen and (max-width: 768px) {
  .page-container .main-content .page-title .breadcrumb-env .breadcrumb {
    margin-top: 4px;
  }
  .page-container .main-content .page-title .breadcrumb-env .breadcrumb li a {
    font-size: 12px;
  }
}
.sidebar-menu {
  display: table-cell;
  position: relative;
  width: 300px;
  background: #2c2e2f;
  z-index: 1;
}
.sidebar-menu .logo-env {
  padding: 30px 45px;
  border-bottom: 1px solid #313437;
}
.sidebar-menu .logo-env .logo {
  float: left;
}
.sidebar-menu .logo-env .logo .logo-collapsed {
  display: none;
}
.sidebar-menu .logo-env .settings-icon {
  float: right;
  position: relative;
  top: 1px;
}
.sidebar-menu .logo-env .settings-icon a {
  color: #979898;
  line-height: 1;
}
.sidebar-menu .logo-env .settings-icon a i {
  line-height: 1;
  font-size: 18px;
}
.sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.sidebar-menu .main-menu {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  list-style: none;
}
@media screen and (max-width: 768px) {
  .sidebar-menu .main-menu {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.sidebar-menu .main-menu a {
  color: #979898;
  text-decoration: none;
  display: block;
  padding: 13px 5px;
  border-bottom: 1px solid #313437;
}
.sidebar-menu .main-menu a > i {
  display: inline-block;
  margin-right: 10px;
}
.sidebar-menu .main-menu a > i + span {
  padding-right: 10px;
}
.sidebar-menu .main-menu a > span {
  display: inline-block;
}
.sidebar-menu .main-menu a > .label {
  margin-top: 1px;
}
.sidebar-menu .main-menu a > .badge {
  font-size: 75%;
  line-height: 1.2;
}
.sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.sidebar-menu .main-menu li.has-sub > a:before {
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  content: '\f107';
  float: right;
  color: #6d747a;
  margin-left: 10px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.sidebar-menu .main-menu li.has-sub.opened > a:before,
.sidebar-menu .main-menu li.has-sub.expanded > a:before {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sidebar-menu .main-menu > li.opened > ul {
  display: block;
}
.sidebar-menu .main-menu > li.opened .opened > ul {
  display: block;
}
.sidebar-menu .main-menu ul {
  display: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}
.sidebar-menu .main-menu ul li {
  position: relative;
}
@media screen and (min-width: 768px) {
  .sidebar-menu .main-menu ul li.is-hidden {
    left: -20px;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
  }
}
.sidebar-menu .main-menu ul li.is-shown {
  left: 0;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown {
  -webkit-transition-delay: 80ms;
  -moz-transition-delay: 80ms;
  -o-transition-delay: 80ms;
  transition-delay: 80ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 120ms;
  -moz-transition-delay: 120ms;
  -o-transition-delay: 120ms;
  transition-delay: 120ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 160ms;
  -moz-transition-delay: 160ms;
  -o-transition-delay: 160ms;
  transition-delay: 160ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 240ms;
  -moz-transition-delay: 240ms;
  -o-transition-delay: 240ms;
  transition-delay: 240ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 280ms;
  -moz-transition-delay: 280ms;
  -o-transition-delay: 280ms;
  transition-delay: 280ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 320ms;
  -moz-transition-delay: 320ms;
  -o-transition-delay: 320ms;
  transition-delay: 320ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 360ms;
  -moz-transition-delay: 360ms;
  -o-transition-delay: 360ms;
  transition-delay: 360ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 400ms;
  -moz-transition-delay: 400ms;
  -o-transition-delay: 400ms;
  transition-delay: 400ms;
}
.sidebar-menu .main-menu ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
  -webkit-transition-delay: 440ms;
  -moz-transition-delay: 440ms;
  -o-transition-delay: 440ms;
  transition-delay: 440ms;
}
.sidebar-menu .main-menu ul li.hidden-item {
  visibility: hidden;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.sidebar-menu .main-menu ul li a {
  padding-left: 35px;
}
.sidebar-menu .main-menu ul li ul li a {
  padding-left: 60px;
}
.sidebar-menu .main-menu ul li ul li ul li a {
  padding-left: 85px;
}
.sidebar-menu .main-menu ul li ul li ul li ul li a {
  padding-left: 110px;
}
.sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: rgba(255, 255, 255, 0.6);
}
.sidebar-menu.fixed {
  height: 100%;
}
.sidebar-menu.fixed .sidebar-menu-inner {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: inherit;
  overflow: hidden;
}
.page-body.right-sidebar .sidebar-menu.fixed .sidebar-menu-inner {
  left: auto;
  right: 0;
}
@media screen and (min-width: 768px) {
  .sidebar-menu.collapsed {
    width: 80px;
    z-index: 10;
    overflow: visible;
  }
  .sidebar-menu.collapsed .hidden-collapsed {
    display: none !important;
  }
  .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .sidebar-menu.collapsed + .main-content .main-footer {
    left: 80px;
  }
  .sidebar-menu.collapsed .sidebar-menu-inner {
    overflow: visible;
    position: relative;
  }
  .sidebar-menu.collapsed .logo-env {
    padding: 18px 0;
  }
  .sidebar-menu.collapsed .logo-env .logo-collapsed {
    display: block;
    text-align: center;
  }
  .sidebar-menu.collapsed .logo-env .logo-collapsed img {
    display: inline-block;
  }
  .sidebar-menu.collapsed .logo-env .logo-expanded,
  .sidebar-menu.collapsed .logo-env .settings-icon {
    display: none;
  }
  .sidebar-menu.collapsed .logo-env .logo {
    float: none;
  }
  .sidebar-menu.collapsed .main-menu {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-menu.collapsed .main-menu > li {
    text-align: center;
    position: relative;
  }
  .sidebar-menu.collapsed .main-menu > li.active,
  .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #252627;
  }
  .sidebar-menu.collapsed .main-menu > li > a > i {
    margin-right: 0;
    font-size: 16px;
  }
  .sidebar-menu.collapsed .main-menu > li > a > span {
    display: none;
  }
  .sidebar-menu.collapsed .main-menu > li > a > span.label {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .sidebar-menu.collapsed .main-menu > li.has-sub > a:before {
    display: none;
  }
  .sidebar-menu.collapsed .main-menu > li.opened > ul {
    display: none;
  }
  .sidebar-menu.collapsed .main-menu > li > ul {
    position: absolute;
    background: #2c2e2f;
    width: 250px;
    top: 0;
    left: 100%;
    text-align: left;
  }
  .page-body.right-sidebar .sidebar-menu.collapsed .main-menu > li > ul {
    left: auto;
    right: 100%;
  }
  .sidebar-menu.collapsed .main-menu > li > ul > li > a {
    padding-left: 20px;
  }
  .sidebar-menu.collapsed .main-menu > li > ul > li > ul > li > a {
    padding-left: 35px;
  }
  .sidebar-menu.collapsed .main-menu > li > ul > li > ul > li > ul > li > a {
    padding-left: 50px;
  }
  .sidebar-menu.collapsed .main-menu > li > ul > li > ul > li > ul > li > ul > li > a {
    padding-left: 65px;
  }
  .sidebar-menu.collapsed .main-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > a {
    padding-left: 80px;
  }
  .sidebar-menu.collapsed .main-menu > li > ul li.has-sub > a:before {
    margin-right: 10px;
  }
  .sidebar-menu.collapsed .main-menu > li:hover > ul {
    display: block;
  }
}
.page-body.right-sidebar .page-container {
  direction: rtl;
}
.page-body.right-sidebar .page-container > * {
  direction: ltr;
}
.navbar.horizontal-menu {
  position: relative;
  height: 85px;
  background: #ffffff;
  margin: 0;
  padding: 0;
  z-index: 101;
  min-height: 0px;
  -webkit-box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
}
.navbar.horizontal-menu.navbar-fixed-top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.navbar.horizontal-menu.navbar-fixed-top + .page-container {
  margin-top: 85px;
}
.navbar.horizontal-menu.navbar-fixed-top + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
  top: 85px;
}
.settings-pane-open .navbar.horizontal-menu.navbar-fixed-top {
  position: relative;
}
.settings-pane-open .navbar.horizontal-menu.navbar-fixed-top + .page-container {
  margin-top: 0;
}
.settings-pane-open .navbar.horizontal-menu.navbar-fixed-top + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
  top: 0;
}
.settings-pane-open .navbar.horizontal-menu.navbar-minimal + .page-container {
  margin-top: 0 !important;
}
.navbar.horizontal-menu + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
  top: 85px;
}
.navbar.horizontal-menu .navbar-inner .navbar-brand {
  padding: 0 30px;
  margin: 0;
  float: left;
  height: 85px;
  display: table;
}
.navbar.horizontal-menu .navbar-inner .navbar-brand:hover a[data-toggle="settings-pane"] {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.settings-pane-open .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.settings-pane-open .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #2c2e2f;
}
.navbar.horizontal-menu .navbar-inner .navbar-brand a {
  display: table-cell;
  vertical-align: middle;
}
.navbar.horizontal-menu .navbar-inner .navbar-brand a.logo img {
  margin: 0;
}
.navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] {
  padding-left: 10px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
.navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  display: block;
  line-height: 1;
  font-size: 12px;
  color: #979898;
  margin-top: 1px;
}
.navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #2c2e2f;
}
@media screen and (min-width: 768px) {
  .navbar.horizontal-menu .navbar-inner .navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    position: relative;
    display: inline-block;
    line-height: 1;
    color: #7d7f7f;
    z-index: 1;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > i {
    display: inline-block;
    margin-right: 5px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > .label.pull-right {
    margin-top: 0;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > .title {
    display: inline-block;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > .title + .badge {
    margin-left: 7px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #313232;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    position: relative;
    z-index: 1;
    line-height: 85px;
    border: 1px solid transparent;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    z-index: 2;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: #f3f3f3;
    background-color: #fafafa;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active {
    margin-left: -1px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li > a {
    padding: 0 15px;
    line-height: 85px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    position: absolute;
    min-width: 270px;
    border: 1px solid #eee;
    visibility: hidden;
    display: block;
    left: -1px;
    -webkit-border-radius: 0 0 0px 0px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 0px 0px;
    -moz-background-clip: padding;
    border-radius: 0 0 0px 0px;
    background-clip: padding-box;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -webkit-transition: opacity 180ms linear, visibility 180ms linear;
    -moz-transition: opacity 180ms linear, visibility 180ms linear;
    -o-transition: opacity 180ms linear, visibility 180ms linear;
    transition: opacity 180ms linear, visibility 180ms linear;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li.hover > ul {
    visibility: visible;
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    position: relative;
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 100%;
    min-width: 100%;
    background: #ffffff;
    z-index: 2;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul ul {
    display: block;
    height: 0px;
    overflow: hidden;
    visibility: hidden;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-transition: opacity 350ms linear;
    -moz-transition: opacity 350ms linear;
    -o-transition: opacity 350ms linear;
    transition: opacity 350ms linear;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul ul.is-visible {
    height: auto;
    visibility: visible;
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li:last-child > a:after {
    display: none;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li {
    line-height: 1;
    margin: 0;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    position: relative;
    display: block;
    white-space: nowrap;
    padding: 15px 25px;
    color: #979898;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    content: '';
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: 0;
    background: #eee;
    height: 1px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #313232;
    background: #fafafa;
    border-top: 1px solid #eee;
    margin-top: -2px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a:after {
    left: 0;
    right: 0;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    content: '\f105';
    font-family: 'FontAwesome';
    display: block;
    float: right;
    color: #979898;
    position: relative;
    margin-left: 10px;
    line-height: 1.3;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -o-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub:hover > a:before,
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #f6f6f6;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-top: -1px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #f1f1f1;
    border-top-color: #e7e7e7;
    border-bottom-color: #e7e7e7;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #e7e7e7;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #ececec;
    border-top-color: #e2e2e2;
    border-bottom-color: #e2e2e2;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #e2e2e2;
  }
}
.navbar.horizontal-menu .navbar-inner .navbar-mobile-clear {
  display: block;
}
.navbar.horizontal-menu .navbar-inner > .nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
.navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile {
  display: none;
}
.navbar.horizontal-menu .navbar-inner > .nav > li {
  line-height: 85px;
  display: inline-block;
}
.navbar.horizontal-menu .navbar-inner > .nav > li i {
  font-size: 15px;
  color: #979898;
  line-height: 1;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a {
  position: relative;
  padding: 0 15px;
  margin: 0;
  border: 1px solid transparent;
  border-top: 0;
  border-bottom: 0;
  z-index: 2;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #fafafa;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #717272;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon {
  padding: 0 20px;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  content: '';
  position: absolute;
  height: 2px;
  top: 0px;
  left: 0;
  right: 0;
  background: #68b828;
  display: none;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:hover:before {
  display: block;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon i {
  margin-right: 2px;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon .badge {
  font-size: 9px;
  position: absolute;
  top: 50%;
  right: 10px;
  padding: 4px;
  min-width: 18px;
  font-weight: 400;
  line-height: 1;
  margin-top: -20px;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] {
  padding: 0 20px;
}
.chat-open .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #717272;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #fff;
  border-color: #f3f3f3;
  color: #717272;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #717272;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  z-index: 1;
  border-color: #f3f3f3;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form {
  padding: 0 20px;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a {
  border: 1px solid transparent;
  border-top: 0;
  border-bottom: 0;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #fff;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #fff;
  border-color: #f3f3f3;
}
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > .dropdown-menu {
  margin-left: -1px;
}
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  line-height: 1.42857143;
}
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu {
  z-index: 1;
  margin-top: -1px;
}
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu {
  right: 0 !important;
}
@media screen and (min-width: 767px) and (max-width: 992px) {
  .navbar.horizontal-menu .navbar-inner .navbar-brand {
    padding: 0 15px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    line-height: 84px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li > a {
    padding: 0 10px;
    font-size: 12px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li > a > .badge {
    display: none;
  }
  .navbar.horizontal-menu .navbar-inner > .nav > li i {
    font-size: 13px;
  }
  .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon {
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span {
    display: none !important;
  }
}
.navbar.horizontal-menu.click-to-expand .has-sub:hover > a:before {
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.navbar.horizontal-menu.click-to-expand .has-sub.expanded > a:before {
  -webkit-transform: rotate(90deg) !important;
  -moz-transform: rotate(90deg) !important;
  -o-transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}
@media screen and (min-width: 768px) {
  .navbar.horizontal-menu.navbar-minimal {
    height: 55px;
  }
}
.navbar.horizontal-menu.navbar-minimal.navbar-fixed-top + .page-container {
  margin-top: 55px;
}
@media screen and (max-width: 768px) {
  .navbar.horizontal-menu.navbar-minimal.navbar-fixed-top + .page-container {
    margin-top: 0;
  }
}
.navbar.horizontal-menu.navbar-minimal.navbar-fixed-top + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
  top: 55px;
}
.navbar.horizontal-menu.navbar-minimal + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
  top: 55px;
}
@media screen and (min-width: 768px) {
  .navbar.horizontal-menu.navbar-minimal .navbar-inner .navbar-brand {
    height: 55px;
  }
  .navbar.horizontal-menu.navbar-minimal .navbar-inner .navbar-nav > li {
    line-height: 55px;
  }
  .navbar.horizontal-menu.navbar-minimal .navbar-inner .navbar-nav > li > a {
    line-height: 55px;
  }
}
.navbar.horizontal-menu.navbar-minimal .navbar-inner > .nav > li {
  line-height: 55px;
}
@media screen and (min-width: 767px) and (max-width: 992px) {
  .navbar.horizontal-menu.navbar-minimal .navbar-inner .navbar-nav > li {
    line-height: 54px;
  }
}
@media screen and (max-width: 768px) {
  .navbar.horizontal-menu {
    background: #2c2e2f;
    color: #fff;
    height: auto;
  }
  .chat-open .navbar.horizontal-menu {
    left: -300px;
  }
  .navbar.horizontal-menu.navbar-fixed-top {
    position: relative;
  }
  .navbar.horizontal-menu.navbar-fixed-top + .page-container {
    margin-top: 0;
  }
  .navbar.horizontal-menu.navbar-fixed-top + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
    top: 0;
  }
  .settings-pane-open .navbar.horizontal-menu.navbar-fixed-top {
    position: relative;
  }
  .settings-pane-open .navbar.horizontal-menu.navbar-fixed-top + .page-container {
    margin-top: 0;
  }
  .settings-pane-open .navbar.horizontal-menu.navbar-fixed-top + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
    top: 0;
  }
  .navbar.horizontal-menu + .page-container > .sidebar-menu.fixed .sidebar-menu-inner {
    top: 85px;
  }
  .navbar.horizontal-menu .navbar-inner {
    padding: 20px 0;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-brand {
    height: auto;
    padding-left: 20px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] {
    display: none;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-mobile-clear {
    display: block;
    clear: both;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav {
    display: none;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    list-style: none;
    margin-bottom: 0;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #979898;
    text-decoration: none;
    display: block;
    padding: 13px 5px;
    border-bottom: 1px solid #313437;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > i {
    display: inline-block;
    margin-right: 10px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > i + span {
    padding-right: 10px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > span {
    display: inline-block;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > .label {
    margin-top: 1px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a > .badge {
    font-size: 75%;
    line-height: 1.2;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav a:hover {
    color: #ffffff;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav li.has-sub > a:before {
    position: relative;
    display: inline-block;
    font-family: 'FontAwesome';
    content: '\f107';
    float: right;
    color: #6d747a;
    margin-left: 10px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav li.has-sub.opened > a:before,
  .navbar.horizontal-menu .navbar-inner .navbar-nav li.has-sub.expanded > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li.opened > ul {
    display: block;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav > li.opened .opened > ul {
    display: block;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    display: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    list-style: none;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li {
    position: relative;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown {
    left: 0;
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown {
    -webkit-transition-delay: 80ms;
    -moz-transition-delay: 80ms;
    -o-transition-delay: 80ms;
    transition-delay: 80ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 120ms;
    -moz-transition-delay: 120ms;
    -o-transition-delay: 120ms;
    transition-delay: 120ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 160ms;
    -moz-transition-delay: 160ms;
    -o-transition-delay: 160ms;
    transition-delay: 160ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 200ms;
    -moz-transition-delay: 200ms;
    -o-transition-delay: 200ms;
    transition-delay: 200ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 240ms;
    -moz-transition-delay: 240ms;
    -o-transition-delay: 240ms;
    transition-delay: 240ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 280ms;
    -moz-transition-delay: 280ms;
    -o-transition-delay: 280ms;
    transition-delay: 280ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 320ms;
    -moz-transition-delay: 320ms;
    -o-transition-delay: 320ms;
    transition-delay: 320ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 360ms;
    -moz-transition-delay: 360ms;
    -o-transition-delay: 360ms;
    transition-delay: 360ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 400ms;
    -moz-transition-delay: 400ms;
    -o-transition-delay: 400ms;
    transition-delay: 400ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown + .is-shown {
    -webkit-transition-delay: 440ms;
    -moz-transition-delay: 440ms;
    -o-transition-delay: 440ms;
    transition-delay: 440ms;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.hidden-item {
    visibility: hidden;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li a {
    padding-left: 35px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li ul li a {
    padding-left: 60px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li ul li ul li a {
    padding-left: 85px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li ul li ul li ul li a {
    padding-left: 110px;
  }
  .navbar.horizontal-menu .navbar-inner .navbar-nav.mobile-is-visible {
    display: block;
  }
  .navbar.horizontal-menu .navbar-inner > .nav {
    display: none;
  }
  .navbar.horizontal-menu .navbar-inner > .nav > li {
    line-height: 65px;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.nav-userinfo {
    float: none;
    width: 100%;
    display: none;
    background: #fff;
    position: relative;
    top: 20px;
    text-align: right;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.nav-userinfo.mobile-is-visible {
    display: block;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.nav-userinfo > .xs-left {
    float: left;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.nav-userinfo > .xs-left.open > .dropdown-menu {
    left: 1px;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.nav-userinfo .notification-icon .badge {
    top: 50%;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.nav-userinfo .user-profile > a span {
    display: none;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.nav-userinfo .user-profile.open > a {
    background-color: #fafafa;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile {
    display: block;
    float: right;
    padding-right: 20px;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    position: relative;
    display: inline-block;
    color: #979898;
    line-height: 1;
    margin-left: 20px;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a i {
    line-height: 1;
    font-size: 18px;
    position: relative;
    top: 1px;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
  .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a .badge {
    position: absolute;
    right: -3px;
    top: -8px;
    font-size: 8px;
    padding: 1px 2px;
    min-width: 14px;
  }
}
@media screen and (max-width: 768px) and screen and (max-width: 768px) {
  .navbar.horizontal-menu .navbar-inner .navbar-nav {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) and screen and (min-width: 768px) {
  .navbar.horizontal-menu .navbar-inner .navbar-nav ul li.is-hidden {
    left: -20px;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
  }
}
footer.main-footer {
  padding: 20px 30px;
  border-top: 1px solid #dddddd;
  font-size: 12px;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 10px;
  background-color: #eeeeee;
}
footer.main-footer:before,
footer.main-footer:after {
  content: " ";
  display: table;
}
footer.main-footer:after {
  clear: both;
}
footer.main-footer:before,
footer.main-footer:after {
  content: " ";
  display: table;
}
footer.main-footer:after {
  clear: both;
}
.page-container + footer.main-footer {
  margin-left: 0;
  margin-right: 0;
}
.page-container + footer.main-footer .footer-inner {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
footer.main-footer .footer-text {
  float: left;
}
footer.main-footer .go-up {
  float: right;
  margin-bottom: 20px;
}
footer.main-footer .go-up a {
  display: inline-block;
  background-color: rgba(44, 46, 47, 0.05);
  padding: 2px 5px;
}
footer.main-footer .go-up a:hover {
  background-color: rgba(44, 46, 47, 0.1);
}
footer.main-footer.footer-type-2 {
  background-color: #252729;
  border-top: 0;
  color: #ffffff;
}
footer.main-footer.footer-type-2 a {
  color: #ffffff;
}
footer.main-footer.footer-type-2 .go-up a {
  background-color: rgba(255, 255, 255, 0.05);
}
footer.main-footer.footer-type-2 .go-up a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
footer.main-footer.fixed {
  position: fixed;
  left: 300px;
  right: 0;
  bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px !important;
}
body.chat-open .user-info-navbar a[data-toggle="chat"] {
  color: #606161;
}
.user-info-navbar {
  background-color: #ffffff;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: -30px;
  margin-bottom: 30px;
}
.user-info-navbar .user-info-menu {
  margin: 0;
}
.user-info-navbar .user-info-menu.left-links {
  float: left;
}
.user-info-navbar .user-info-menu.right-links {
  float: right;
}
.user-info-navbar .user-info-menu li {
  line-height: 1;
}
.user-info-navbar .user-info-menu li a {
  line-height: 1;
}
.user-info-navbar .user-info-menu li a img {
  line-height: 1;
}
.user-info-navbar .user-info-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
  border: 1px solid #ffffff;
  border-top: 0;
  border-bottom: 0;
  display: block;
  float: left;
}
.user-info-navbar .user-info-menu > li.hover-line:before {
  content: '';
  position: absolute;
  display: block;
  height: 2px;
  top: 0px;
  left: 0;
  right: 0;
  background: #68b828;
  display: none;
}
.user-info-navbar .user-info-menu > li.hover-line:hover:before,
.user-info-navbar .user-info-menu > li.hover-line.open:before {
  display: block;
}
.user-info-navbar .user-info-menu > li > a {
  position: relative;
  display: block;
  padding: 30px 20px;
  color: #979898;
  border-bottom: 1px solid #ffffff;
  top: 1px;
  z-index: 5;
  text-decoration: none;
}
.user-info-navbar .user-info-menu > li > a i {
  font-size: 15px;
  line-height: 1;
}
.user-info-navbar .user-info-menu > li > a:hover {
  color: #606161;
}
.user-info-navbar .user-info-menu > li > a .badge {
  font-size: 9px;
  position: absolute;
  top: 20px;
  right: 10px;
  padding: 4px;
  min-width: 18px;
  font-weight: 400;
  line-height: 1;
}
.user-info-navbar .user-info-menu > li > form {
  position: relative;
  display: block;
  padding: 30px 20px;
  padding-bottom: 26px;
  margin: 0;
  border-bottom: 1px solid #ffffff;
}
.user-info-navbar .user-info-menu > li a[data-toggle="sidebar"] {
  padding-left: 25px;
}
.user-info-navbar .user-info-menu > li.fullscreen {
  position: relative;
  z-index: 1;
}
.user-info-navbar .user-info-menu > li.fullscreen:hover a {
  background-color: #fafafa;
  color: #2c2e2f;
}
.user-info-navbar .user-info-menu > li.search-form,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form {
  position: relative;
  z-index: 5;
}
.user-info-navbar .user-info-menu > li.search-form .btn,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  position: relative;
  padding: 0;
  margin: 0;
  height: auto;
  color: #979898;
  z-index: 2;
}
.user-info-navbar .user-info-menu > li.search-form .btn:hover,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #606161;
}
.user-info-navbar .user-info-menu > li.search-form .btn:active,
.user-info-navbar .user-info-menu > li.search-form .btn:focus,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.user-info-navbar .user-info-menu > li.search-form .search-field,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -15.5px;
  line-height: 1;
  height: 31px;
  border: 1px solid #dddddd;
  border: 0;
  padding: 0 10px;
  width: 170px;
  padding-right: 30px;
  font-size: 12px;
  z-index: 1;
  visibility: hidden;
  background: #f3f3f3;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
.user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #606161;
}
.user-info-navbar .user-info-menu > li.search-form.focused .search-field,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form.focused .search-field {
  visibility: visible;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.user-info-navbar .user-info-menu > li.search-form.always-visible .search-field,
.navbar.horizontal-menu .navbar-inner > .nav > li.search-form.always-visible .search-field {
  visibility: visible;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.user-info-navbar .user-info-menu > li.user-profile > a,
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a,
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a {
  text-decoration: none;
}
.user-info-navbar .user-info-menu > li.user-profile > a > img,
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img,
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img {
  position: relative;
}
.user-info-navbar .user-info-menu > li.user-profile > a > img + span,
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span,
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > img + span {
  margin-left: 5px;
}
.user-info-navbar .user-info-menu > li.user-profile > a span,
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span,
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  display: inline-block;
}
.user-info-navbar .user-info-menu > li.user-profile .userpic-32,
.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32,
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile .userpic-32 {
  margin-top: -17px;
  top: 8px;
}
.user-info-navbar .user-info-menu > li.language-switcher a > img {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.user-info-navbar .user-info-menu > li.open {
  border-left-color: #dddddd;
  border-right-color: #dddddd;
  z-index: 15;
}
.user-info-navbar .user-info-menu > li.open > a {
  color: #606161;
}
.user-info-navbar .user-info-menu > li .dropdown-menu,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  margin: 0;
  padding: 0;
  margin-top: 0px;
  margin-left: -1px;
  z-index: 3;
  -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
  -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
  box-shadow: 0 2px 8px rgba(0,1,1,.05);
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list {
  position: relative;
  max-height: 300px;
  overflow: auto;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list.ps-scrollbar,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list.ps-scrollbar {
  overflow: hidden;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li {
  position: relative;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  position: relative;
  display: block;
  padding: 20px 30px;
  color: #979898;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a .line,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a .line {
  display: block;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a .line.desc,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a .line.desc {
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 10px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a .line .light,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a .line .light {
  zoom: 1;
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: alpha(opacity=60);
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a .line.small,
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a .line .small,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a .line.small,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a .line .small {
  font-size: 12px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a .line.time,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a .line.time {
  font-size: 11px;
  margin-top: 5px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  background: #ededed;
  bottom: 0;
  left: 30px;
  right: 30px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #606161;
}
.user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #606161;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .top,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top {
  padding: 20px 30px;
  padding-bottom: 0;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #979898;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #606161;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  margin: 0;
  color: #979898;
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .external,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external {
  position: relative;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  background-color: none;
  height: 1px;
  background-color: #ededed;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  display: block;
  padding: 20px 30px;
  color: #979898;
  background-color: #f5f5f5;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .external a span,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a span {
  float: left;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .external a i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a i {
  float: right;
}
.user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #6a6b6c;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.messages,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages {
  width: 370px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #cccccc;
  top: 50%;
  margin-top: -2.5px;
  right: 30px;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications {
  width: 370px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  float: left;
  background: #dddddd;
  color: #FFF;
  padding: 5px;
  line-height: 17px;
  width: 27px;
  text-align: center;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-primary > a > i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-primary > a > i {
  background-color: #2c2e2f;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-success > a > i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-success > a > i {
  background-color: #8dc63f;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-danger > a > i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-danger > a > i {
  background-color: #cc3f44;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-warning > a > i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-warning > a > i {
  background-color: #ffba00;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-info > a > i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-info > a > i {
  background-color: #40bbea;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu {
  right: -1px;
  left: auto;
  width: 250px;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  padding: 15px 20px;
  padding-bottom: 0;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  display: block;
  color: #979898;
  padding: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  background: none;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #606161;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #fafafa;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  background: #f0f0f0;
  top: -1px;
  left: 0;
  right: 0;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.languages,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.languages {
  width: 200px;
}
@media screen and (max-width: 991px) {
  .user-info-navbar .user-info-menu > li .dropdown-menu.languages,
  .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.languages {
    width: auto;
  }
}
.user-info-navbar .user-info-menu > li .dropdown-menu.languages li a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.languages li a {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #777879;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.languages li:hover a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.languages li:hover a {
  background-color: #fbfbfb;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.languages li.active a,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.languages li.active a {
  background-color: #f6f6f6;
  color: #2c2e2f;
}
.user-info-navbar .user-info-menu > li .dropdown-menu.languages li + li,
.navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.languages li + li {
  border-top: 1px solid #eeeeee;
}
.badge {
  position: relative;
  font-size: 9px;
  line-height: 12px;
}
.badge.pull-right {
  margin-top: 4px;
  margin-left: 5px;
}
.badge.pull-left {
  margin-top: 4px;
  margin-right: 5px;
}
.badge.badge-default {
  background-color: #777777;
}
.badge.badge-red,
.badge.badge-danger {
  background-color: #cc3f44;
}
.badge.badge-blue,
.badge.badge-info {
  background-color: #40bbea;
}
.badge.badge-yellow,
.badge.badge-warning {
  background-color: #ffba00;
}
.badge.badge-green,
.badge.badge-success {
  background-color: #8dc63f;
}
.badge.badge-primary {
  background-color: #2c2e2f;
}
.badge.badge-secondary {
  background-color: #68b828;
}
.badge.badge-purple {
  background-color: #7c38bc;
}
.badge.badge-pink {
  background-color: #ff6264;
}
.badge.badge-yellow {
  background-color: #fcd036;
}
.badge.badge-orange {
  background-color: #f7aa47;
}
.badge.badge-turquoise {
  background-color: #00b19d;
}
.badge.badge-red {
  background-color: #d5080f;
}
.badge.badge-blue {
  background-color: #0e62c7;
}
.badge.badge-black {
  background-color: #222222;
}
.badge.badge-white {
  background-color: #fff;
  color: #333;
  -webkit-box-shadow: 0px 0px 0px 1px #dddddd;
  -moz-box-shadow: 0px 0px 0px 1px #dddddd;
  box-shadow: 0px 0px 0px 1px #dddddd;
}
#chat {
  display: table-cell;
  position: relative;
  z-index: 100;
  width: 280px;
  background: #ffffff;
  vertical-align: top;
  display: none;
}
body.chat-open #chat {
  display: table-cell;
}
#chat.fixed .chat-inner {
  position: fixed;
  right: 0;
  width: inherit;
}
.page-body.right-sidebar #chat.fixed .chat-inner {
  right: auto;
  left: 0;
}
#chat .chat-inner {
  overflow: auto;
  height: 100%;
  border-left: 1px solid #dddddd;
}
.page-body.right-sidebar #chat .chat-inner {
  border-left: 0;
  border-right: 1px solid #dddddd;
}
#chat .chat-inner.ps-container {
  overflow: hidden;
}
#chat .chat-header {
  font-size: 16px;
  color: #979898;
  padding: 29px 35px;
  padding-bottom: 0;
  line-height: 1;
  margin: 0;
  position: relative;
}
#chat .chat-header .badge {
  position: relative;
  top: -1px;
  margin-left: 5px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
#chat .chat-header .badge.is-hidden {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
#chat .chat-header .chat-close {
  position: absolute;
  right: 0px;
  font-size: 15px;
  right: 25px;
  margin-top: 0px;
  color: #dddddd;
  font-weight: normal;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#chat .chat-header .chat-close:hover {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
#chat .chat-group {
  margin-top: 24px;
}
#chat .chat-group > strong,
#chat .chat-group > a {
  display: block;
  padding: 6px 35px;
  font-weight: normal;
  font-size: 12px;
}
#chat .chat-group > strong {
  text-transform: uppercase;
  color: #979898;
}
#chat .chat-group > a {
  position: relative;
  color: #979898;
  -webkit-transition: background 250ms ease-in-out;
  -moz-transition: background 250ms ease-in-out;
  -o-transition: background 250ms ease-in-out;
  transition: background 250ms ease-in-out;
}
#chat .chat-group > a:hover,
#chat .chat-group > a.active {
  color: #717272;
}
#chat .chat-group > a:before {
  content: '';
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #111111;
  left: 0;
  top: 50%;
  margin-top: -4px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
#chat .chat-group > a.active:before {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
#chat .chat-group > a .badge {
  font-size: 9px;
  margin-left: 5px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
#chat .chat-group > a .badge.is-hidden {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
#chat .chat-group > a em {
  font-style: normal;
}
#chat .user-status {
  display: inline-block;
  background: #b7b7b7;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 8px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 8px;
  -moz-background-clip: padding;
  border-radius: 8px;
  background-clip: padding-box;
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
#chat .user-status.is-online {
  background-color: #8dc63f;
}
#chat .user-status.is-offline {
  background-color: #b7b7b7;
}
#chat .user-status.is-idle {
  background-color: #ffba00;
}
#chat .user-status.is-busy {
  background-color: #cc3f44;
}
#chat .chat-conversation {
  position: absolute;
  right: 279px;
  width: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  top: 166px;
  display: none;
}
.page-body.right-sidebar #chat .chat-conversation {
  right: auto;
  left: 279px;
}
#chat .chat-conversation.is-open {
  display: block;
}
#chat .chat-conversation .conversation-header {
  position: relative;
  padding: 20px 24px;
  font-size: 14px;
  color: #979898;
  border-bottom: 1px solid #ededed;
}
#chat .chat-conversation .conversation-header small {
  color: rgba(151, 152, 152, 0.6);
  font-size: 12px;
  padding-left: 8px;
}
#chat .chat-conversation .conversation-header .conversation-close {
  float: right;
  color: #979898;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  position: relative;
  top: -2px;
}
#chat .chat-conversation .conversation-header:after {
  position: absolute;
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 9.1px;
  border-color: transparent transparent transparent #dedede;
  right: -9.1px;
  top: 50%;
  margin-top: -3.5px;
}
.page-body.right-sidebar #chat .chat-conversation .conversation-header:after {
  right: auto;
  left: -8.1px;
  border-width: 7px 7px 9.1px 0;
  border-color: transparent #dedede transparent transparent;
}
#chat .chat-conversation .conversation-body {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 245px;
}
#chat .chat-conversation .conversation-body::-webkit-scrollbar {
  width: 5px;
}
#chat .chat-conversation .conversation-body::-webkit-scrollbar-track {
  width: 5px;
  background-color: #fff;
}
#chat .chat-conversation .conversation-body::-webkit-scrollbar-thumb {
  background-color: #dedede;
}
#chat .chat-conversation .conversation-body > li {
  padding: 0 30px;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #979898;
}
#chat .chat-conversation .conversation-body > li.odd {
  background: rgba(250, 250, 250, 0.55);
}
#chat .chat-conversation .conversation-body > li .user {
  font-weight: bold;
  color: #7d7f7f;
}
#chat .chat-conversation .conversation-body > li .time {
  float: right;
  color: rgba(151, 152, 152, 0.8);
  font-size: 10px;
  position: relative;
  top: 3px;
}
#chat .chat-conversation .conversation-body > li p {
  padding-top: 5px;
}
#chat .chat-conversation .conversation-body > li:last-child {
  border-bottom: 0;
}
#chat .chat-conversation .chat-textarea {
  padding: 20px 24px;
  background: #fafafa;
  position: relative;
  border-top: 1px solid #ededed;
  padding: 0;
}
#chat .chat-conversation .chat-textarea:after {
  content: '\f075';
  font-family: 'fontawesome';
  color: #979898;
  right: 15px;
  top: 10px;
  font-size: 15px;
  position: absolute;
}
#chat .chat-conversation .chat-textarea textarea {
  background: #fafafa;
  border: 0;
  max-height: 100px !important;
  padding-right: 35px;
  padding-top: 10px;
  height: 32px;
  resize: none;
}
#chat .chat-conversation .chat-textarea textarea::-moz-placeholder {
  color: rgba(151, 152, 152, 0.85);
  opacity: 1;
}
#chat .chat-conversation .chat-textarea textarea:-ms-input-placeholder {
  color: rgba(151, 152, 152, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-webkit-input-placeholder {
  color: rgba(151, 152, 152, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-webkit-input-placeholder {
  color: rgba(151, 152, 152, 0.85);
}
#chat .chat-conversation .chat-textarea textarea:-moz-placeholder {
  color: rgba(151, 152, 152, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-moz-placeholder {
  color: rgba(151, 152, 152, 0.85);
}
#chat .chat-conversation .chat-textarea textarea:-ms-input-placeholder {
  color: rgba(151, 152, 152, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-webkit-scrollbar {
  width: 5px;
}
#chat .chat-conversation .chat-textarea textarea::-webkit-scrollbar-track {
  width: 5px;
  background-color: #ededed;
}
#chat .chat-conversation .chat-textarea textarea::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.chat-history {
  display: none;
}
/* Relatively-Small screen */
@media (max-width: 767px) {
  .chat-visible #chat,
  .chat-visible #chat.fixed {
    display: none;
  }
  .chat-visible.toggle-click #chat,
  .chat-visible.toggle-click #chat.fixed {
    display: block;
  }
  #chat,
  #chat.fixed {
    position: fixed;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 10px;
  }
  #chat .chat-conversation,
  #chat.fixed .chat-conversation {
    position: fixed;
    width: auto;
    left: 0;
    right: 0;
    top: 0 !important;
    bottom: 0;
    margin: 10px;
    overflow: auto;
  }
  #chat .chat-conversation .conversation-body,
  #chat.fixed .chat-conversation .conversation-body {
    height: auto;
    max-height: 450px;
  }
  #chat .chat-conversation::-webkit-scrollbar,
  #chat.fixed .chat-conversation::-webkit-scrollbar {
    width: 5px;
  }
  #chat .chat-conversation::-webkit-scrollbar-track,
  #chat.fixed .chat-conversation::-webkit-scrollbar-track {
    width: 5px;
    background-color: #343a45;
  }
  #chat .chat-conversation::-webkit-scrollbar-thumb,
  #chat.fixed .chat-conversation::-webkit-scrollbar-thumb {
    background-color: #4a5262;
  }
}
/* Chat 2, added in v1.3 */
.footer-sticked-chat {
  position: fixed;
  right: 30px;
  bottom: 0;
  z-index: 1000;
}
.chat-open .footer-sticked-chat {
  right: 310px;
}
@media screen and (max-width: 768px) {
  .chat-open .footer-sticked-chat {
    right: 30px;
  }
}
.right-sidebar .footer-sticked-chat {
  left: 30px;
  right: auto;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat.mobile-is-visible .chat-conversations {
    display: block !important;
  }
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat {
    left: 0;
    right: 0;
  }
}
.footer-sticked-chat .chat-conversations {
  display: block;
  margin: 0;
  padding: 0;
  z-index: 5;
  position: relative;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations {
    padding: 15px 30px;
    background: #ffffff;
    border-top: 1px solid #e4e4e4;
    display: none;
  }
}
.footer-sticked-chat .chat-conversations > li {
  display: block;
  position: relative;
  float: left;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li {
    float: none;
    width: 100%;
  }
}
.footer-sticked-chat .chat-conversations > li .chat-user {
  display: block;
  position: relative;
  z-index: 5;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  color: #888888;
  font-size: 12px;
  line-height: 1;
  padding: 8px 10px;
  width: 150px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li .chat-user {
    width: 100%;
  }
}
.footer-sticked-chat .chat-conversations > li .chat-user .user-status {
  display: inline-block;
  vertical-align: middle;
  background: #b7b7b7;
  margin-right: 5px;
  position: relative;
  top: -1px;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 8px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 8px;
  -moz-background-clip: padding;
  border-radius: 8px;
  background-clip: padding-box;
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
.footer-sticked-chat .chat-conversations > li .chat-user .user-status.is-online {
  background-color: #8dc63f;
}
.footer-sticked-chat .chat-conversations > li .chat-user .user-status.is-offline {
  background-color: #b7b7b7;
}
.footer-sticked-chat .chat-conversations > li .chat-user .user-status.is-idle {
  background-color: #ffba00;
}
.footer-sticked-chat .chat-conversations > li .chat-user .user-status.is-busy {
  background-color: #cc3f44;
}
.footer-sticked-chat .chat-conversations > li .chat-user:hover {
  color: #2c2e2f;
}
.footer-sticked-chat .chat-conversations > li .badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 15;
  -webkit-transform: translate(25%, -25%);
  -moz-transform: translate(25%, -25%);
  -o-transform: translate(25%, -25%);
  -ms-transform: translate(25%, -25%);
  transform: translate(25%, -25%);
}
.footer-sticked-chat .chat-conversations > li .conversation-window {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid #ffffff;
  z-index: 20;
  border: 1px solid #e4e4e4;
  visibility: hidden;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
.footer-sticked-chat .chat-conversations > li .conversation-window > .chat-user {
  width: 100%;
  border: 0;
  background: #f5f5f5;
}
.footer-sticked-chat .chat-conversations > li .conversation-window > .chat-user .close {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 15px;
  -webkit-transform: translate(-100%, -50%);
  -moz-transform: translate(-100%, -50%);
  -o-transform: translate(-100%, -50%);
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  max-height: 250px;
  overflow: hidden;
  position: relative;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages.full-height {
  overflow: visible;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li {
  display: table;
  width: 100%;
  table-layout: auto;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li .user-info,
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li .message-entry {
  display: table-cell;
  vertical-align: top;
  padding: 10px 0;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li .user-info {
  padding-left: 10px;
  padding-right: 10px;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li .user-info + .message-entry {
  padding-left: 0;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li .message-entry {
  width: 99%;
  color: #666666;
  font-size: 11px;
  padding-right: 10px;
  padding-left: 10px;
  word-break: break-all;
  white-space: normal;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li .message-entry > p {
  color: #666666;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li .message-entry > p:last-child {
  margin-bottom: 0;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li.me {
  background-color: #f4f4f4;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .conversation-messages li.time {
  text-align: center;
  display: block;
  font-size: 9px;
  background: #f0f0f0;
  text-transform: uppercase;
  color: #888888;
  padding: 4px 0 2px 0;
  line-height: 1;
  margin-bottom: 1px;
}
.footer-sticked-chat .chat-conversations > li .conversation-window .chat-form .form-control {
  border: 0;
  font-size: 12px;
}
.footer-sticked-chat .chat-conversations > li.open > a {
  width: 250px;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li.open > a {
    width: 100%;
  }
}
.footer-sticked-chat .chat-conversations > li.open .conversation-window {
  bottom: 0;
  visibility: visible;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition-delay: 120ms;
  -moz-transition-delay: 120ms;
  -o-transition-delay: 120ms;
  transition-delay: 120ms;
}
.footer-sticked-chat .chat-conversations > li.open .badge {
  display: none;
}
.right-sidebar .footer-sticked-chat .chat-conversations > li.browse-more {
  float: right;
  margin-right: 0;
  margin-left: 10px;
}
.footer-sticked-chat .chat-conversations > li.browse-more > a {
  width: auto;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li.browse-more > a {
    background-color: #f4f4f4;
  }
}
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  margin-bottom: -2px;
  z-index: 1;
  display: none;
}
.right-sidebar .footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list {
  right: auto;
  left: auto;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list {
    position: relative;
    width: 100%;
    border-top: 0;
  }
}
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li {
  display: block;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li {
    text-align: left;
  }
}
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li a {
  display: block;
  font-size: 11px;
  font-weight: bold;
  padding: 5px 10px;
  white-space: nowrap;
  color: #888888;
}
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li a span {
  position: relative;
  left: 2px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li a span {
    float: right;
    left: 0px;
  }
}
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li a:hover {
  color: #2c2e2f;
}
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li a:hover span {
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.footer-sticked-chat .chat-conversations > li.browse-more .other-conversations-list li + li {
  border-top: 1px solid rgba(228, 228, 228, 0.15);
}
@media screen and (min-width: 768px) {
  .footer-sticked-chat .chat-conversations > li.browse-more:hover > a {
    border-top: 0;
    top: 1px;
  }
}
.footer-sticked-chat .chat-conversations > li.browse-more:hover .other-conversations-list {
  display: block;
}
.footer-sticked-chat .chat-conversations > li + li {
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .chat-conversations > li + li {
    margin-left: 0;
    margin-top: 5px;
  }
}
.footer-sticked-chat .mobile-chat-toggle {
  position: absolute;
  right: 30px;
  bottom: 100%;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  font-size: 12px;
  line-height: 1;
  padding: 10px 12px;
  text-decoration: none;
  z-index: 1;
  border-bottom: 0;
  display: none;
}
.footer-sticked-chat .mobile-chat-toggle i {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .footer-sticked-chat .mobile-chat-toggle {
    display: block;
  }
}
.footer-sticked-chat .mobile-chat-toggle .badge {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
@media screen and (max-width: 768px) {
  .page-container {
    display: block;
  }
  .page-container .sidebar-menu,
  .page-container .main-content {
    display: block;
    width: 100%;
    height: auto;
  }
  .page-container .sidebar-menu {
    position: relative;
  }
  .page-container .sidebar-menu .sidebar-menu-inner {
    position: relative;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env,
  .page-container .sidebar-menu .sidebar-menu-inner .main-menu {
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env {
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle {
    float: right;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a {
    position: relative;
    display: inline-block;
    color: #979898;
    line-height: 1;
    margin-left: 20px;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a i {
    line-height: 1;
    font-size: 18px;
    position: relative;
    top: 1px;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:hover,
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:active,
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a:focus {
    color: #ffffff;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .logo-env .mobile-menu-toggle a .badge {
    position: absolute;
    right: -3px;
    top: -8px;
    font-size: 8px;
    padding: 1px 2px;
    min-width: 14px;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .main-menu {
    display: none;
    margin-bottom: 0;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .main-menu.mobile-is-visible {
    display: block;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .main-menu.mobile-is-visible.both-menus-visible {
    margin-top: 0;
    padding-top: 0;
  }
  .page-container .sidebar-menu .sidebar-menu-inner .main-menu > li:last-child > a {
    border-bottom: 0;
  }
  .page-container .main-content .navbar.user-info-navbar {
    display: none;
  }
  .page-container .main-content .navbar.user-info-navbar.mobile-is-visible {
    display: block;
  }
  .page-container .main-content .navbar.user-info-navbar .user-info-menu > li {
    min-height: 0 !important;
  }
  .page-container .main-content .navbar.user-info-navbar .user-info-menu > li > a,
  .page-container .main-content .navbar.user-info-navbar .user-info-menu > li > form {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .page-container .main-content .navbar.user-info-navbar .user-info-menu > li.user-profile > a > span,
  .page-container .main-content .navbar.navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span,
  .page-container .main-content .navbar.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a > span {
    display: none;
  }
  .page-container .main-content .navbar.user-info-navbar .user-info-menu > li a > .badge {
    top: 10px;
  }
  .page-container .main-content .main-footer {
    position: relative;
    left: 0;
    right: auto;
    margin-left: -30px;
    margin-right: -30px;
  }
  .page-container {
    position: relative;
  }
  .page-container #chat {
    left: auto;
    margin: 0;
    width: 340px;
  }
  .page-container #chat .chat-conversation {
    bottom: auto;
    position: absolute;
    left: 0;
    top: 0;
  }
  .chat-open .page-container {
    left: -340px;
  }
}
.panel {
  position: relative;
  background: #ffffff;
  padding: 20px 30px;
  border: 0;
  margin-bottom: 30px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.panel.panel-headerless .panel-body {
  padding-top: 0;
}
.panel .panel-heading {
  position: relative;
  padding: 0;
  margin: 0;
  background: none;
  font-size: 17px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f5f5f5;
}
.panel .panel-heading > .panel-title {
  float: left;
  font-size: 17px;
}
.panel .panel-heading > .panel-title > a {
  color: inherit;
}
.panel .panel-heading > .panel-options {
  float: right;
  font-size: 13px;
}
.panel .panel-heading > .panel-options a {
  display: inline-block;
  color: #979898;
  margin-left: 7px;
  line-height: 1;
}
.panel .panel-heading > .panel-options a i {
  line-height: 1;
}
.panel .panel-heading > .panel-options a[data-toggle="panel"] span {
  display: block;
  font-size: 16px;
  width: 10px;
  text-align: center;
}
.panel .panel-heading > .panel-options a[data-toggle="panel"] span.expand-icon {
  display: none;
  font-size: 18px;
}
.panel .panel-heading > .panel-options a[data-toggle="remove"] {
  font-size: 18px;
}
.panel .panel-heading > .panel-options a:first-child {
  margin-left: 0;
}
.panel .panel-heading > .panel-options .nav-tabs {
  border: 0;
}
.panel .panel-heading > .panel-options .nav-tabs > li {
  border: 0;
}
.panel .panel-heading > .panel-options .nav-tabs > li > a {
  padding: 0;
  border: 0;
  margin-left: 15px;
  color: #979898;
  background: none;
}
.panel .panel-heading > .panel-options .nav-tabs > li > a:hover {
  background: none;
}
.panel .panel-heading > .panel-options .nav-tabs > li.active > a {
  color: #4b4b4b;
}
.panel .panel-body {
  padding: 0;
  padding-top: 20px;
  color: #979898;
}
.panel .panel-body.no-padding {
  padding: 0;
  margin-top: -2px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -20px;
}
.panel .panel-body.panel-border {
  border-top: 1px solid #f5f5f5;
  padding-top: 30px;
}
.panel .panel-body p {
  margin-bottom: 0;
}
.panel .panel-body p + p {
  margin-top: 15px;
}
.panel .panel-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
}
.panel.collapsed .panel-heading .panel-options a[data-toggle="panel"] .collapse-icon {
  display: none;
}
.panel.collapsed .panel-heading .panel-options a[data-toggle="panel"] .expand-icon {
  display: block;
}
.panel.collapsed > .panel-body,
.panel.collapsed > .table {
  display: none;
}
.panel.panel-border {
  border: 1px solid #cccccc;
}
.panel.panel-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0,1,1,.08);
  -moz-box-shadow: 0 1px 1px rgba(0,1,1,.08);
  box-shadow: 0 1px 1px rgba(0,1,1,.08);
}
.panel.panel-inverted {
  background: #2c2e2f;
  color: #FFF;
}
.panel.panel-inverted > .panel-heading {
  border-bottom-color: #393c3e;
}
.panel.panel-inverted > .panel-disabled {
  background: rgba(44, 46, 47, 0.5);
}
.panel.panel-tabs {
  background: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 10px;
  padding-top: 7px;
}
.panel.panel-tabs > .panel-heading {
  border-bottom: 0;
}
.panel.panel-tabs > .panel-heading .nav-tabs {
  position: relative;
  margin-bottom: -21px;
  margin-right: -2px;
}
.panel.panel-tabs > .panel-heading .nav-tabs > li > a {
  position: relative;
  background-color: #f5f5f5;
  padding: 15px 20px;
  margin-left: 5px;
  top: -6px;
}
.panel.panel-tabs > .panel-heading .nav-tabs > li:hover > a,
.panel.panel-tabs > .panel-heading .nav-tabs > li.active > a {
  background-color: #FFF;
}
.panel.panel-tabs > .panel-body {
  background: #FFF;
  padding: 20px 30px;
}
.panel.panel-flat {
  background: none;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.panel.panel-flat .panel-heading {
  border-bottom-color: #dddddd;
}
.panel.panel-flat .panel-disabled {
  background: rgba(238, 238, 238, 0.6);
}
.panel.panel-success .panel-heading {
  background-color: #8dc63f;
  color: #ffffff;
}
.panel.panel-success .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-success .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-success .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-success .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-success .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-success > .panel-disabled {
  background-color: rgba(141, 198, 63, 0.15);
}
.panel.panel-info .panel-heading {
  background-color: #40bbea;
  color: #ffffff;
}
.panel.panel-info .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-info .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-info .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-info .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-info .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-info > .panel-disabled {
  background-color: rgba(64, 187, 234, 0.15);
}
.panel.panel-warning .panel-heading {
  background-color: #ffba00;
  color: #ffffff;
}
.panel.panel-warning .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-warning .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-warning .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-warning .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-warning .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-warning > .panel-disabled {
  background-color: rgba(255, 186, 0, 0.15);
}
.panel.panel-danger .panel-heading {
  background-color: #cc3f44;
  color: #ffffff;
}
.panel.panel-danger .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-danger .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-danger .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-danger .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-danger .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-danger > .panel-disabled {
  background-color: rgba(204, 63, 68, 0.15);
}
.panel.panel-red .panel-heading {
  background-color: #d5080f;
  color: #ffffff;
}
.panel.panel-red .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-red .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-red .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-red .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-red .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-red > .panel-disabled {
  background-color: rgba(213, 8, 15, 0.15);
}
.panel.panel-black .panel-heading {
  background-color: #222222;
  color: #ffffff;
}
.panel.panel-black .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-black .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-black .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-black .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-black .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-black > .panel-disabled {
  background-color: rgba(34, 34, 34, 0.15);
}
.panel.panel-purple .panel-heading {
  background-color: #7c38bc;
  color: #ffffff;
}
.panel.panel-purple .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-purple .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-purple .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-purple .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-purple .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-purple > .panel-disabled {
  background-color: rgba(124, 56, 188, 0.15);
}
.panel.panel-blue .panel-heading {
  background-color: #0e62c7;
  color: #ffffff;
}
.panel.panel-blue .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-blue .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-blue .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-blue .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-blue .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-blue > .panel-disabled {
  background-color: rgba(14, 98, 199, 0.15);
}
.panel.panel-light-blue .panel-heading {
  background-color: #4fcdfc;
  color: #ffffff;
}
.panel.panel-light-blue .panel-heading > .panel-title {
  color: #ffffff;
}
.panel.panel-light-blue .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-light-blue .panel-heading > .panel-options {
  color: #ffffff;
}
.panel.panel-light-blue .panel-heading > .panel-options a {
  color: rgba(255, 255, 255, 0.6);
}
.panel.panel-light-blue .panel-heading > .panel-options a:hover {
  color: #ffffff;
}
.panel.panel-light-blue > .panel-disabled {
  background-color: rgba(79, 205, 252, 0.15);
}
.panel.panel-gray .panel-heading {
  background-color: #e1e1e1;
  color: #717272;
}
.panel.panel-gray .panel-heading > .panel-title {
  color: #717272;
}
.panel.panel-gray .panel-heading > .panel-title > a {
  color: inherit;
}
.panel.panel-gray .panel-heading > .panel-options {
  color: #717272;
}
.panel.panel-gray .panel-heading > .panel-options a {
  color: rgba(113, 114, 114, 0.6);
}
.panel.panel-gray .panel-heading > .panel-options a:hover {
  color: #717272;
}
.panel.panel-gray > .panel-disabled {
  background-color: rgba(225, 225, 225, 0.15);
}
.panel.panel-color .panel-heading {
  margin-top: -20px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 20px 30px;
  border-bottom: 0;
}
.panel.panel-color.collapsed > .panel-heading {
  margin-bottom: -20px;
}
.breadcrumb {
  background: none;
}
.breadcrumb li i {
  margin-right: 4px;
}
.breadcrumb li a {
  color: #979898;
}
.breadcrumb li.active > * {
  color: #646565;
}
.breadcrumb.bc-2 {
  background-color: #eeeeee;
}
.breadcrumb.bc-3 {
  border: 1px solid #dddddd;
}
.breadcrumb.auto-hidden li {
  display: block;
  float: left;
}
.breadcrumb.auto-hidden li:before {
  display: block;
  float: left;
}
.breadcrumb.auto-hidden li a {
  display: block;
  float: left;
  white-space: nowrap;
  overflow: hidden;
}
.breadcrumb.auto-hidden li a.collapsed {
  width: 45px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  body.chat-open .settings-pane {
    left: -340px;
  }
}
.settings-pane {
  background: #27292a;
  color: #ffffff;
  display: none;
  position: relative;
  overflow: hidden;
  -webkit-perspective: 500px;
  -moz-perspective: 500px;
  perspective: 500px;
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
}
@media screen and (max-width: 768px) {
  .settings-pane {
    padding: 30px;
  }
}
.settings-pane .settings-pane-inner {
  position: relative;
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .settings-pane .settings-pane-inner {
    padding: 0;
  }
}
.settings-pane .settings-pane-inner.with-animation {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 350ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
  -moz-transition: all 350ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
  -o-transition: all 350ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
  transition: all 350ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
}
.settings-pane .settings-pane-inner.with-animation.visible {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
}
.settings-pane .settings-pane-inner.with-animation.closing {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  -o-transition-delay: 0ms;
  transition-delay: 0ms;
}
.settings-pane > a[data-toggle="settings-pane"] {
  display: block;
  color: #979898;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 12px;
  font-size: 18px;
  line-height: 1;
  z-index: 100;
}
.settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.settings-pane .user-info {
  display: table;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .settings-pane .user-info {
    width: auto;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .settings-pane .user-info {
    width: auto;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
.settings-pane .user-info .user-image,
.settings-pane .user-info .user-details {
  display: table-cell;
  vertical-align: middle;
}
.settings-pane .user-info .user-image {
  width: 130px;
}
.settings-pane .user-info .user-image a {
  display: block;
  position: relative;
}
.settings-pane .user-info .user-details {
  padding-left: 20px;
}
.settings-pane .user-info .user-details h3 {
  margin: 0;
}
.settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.settings-pane .user-info .user-details h3 .user-status {
  position: relative;
  display: inline-block;
  background: #FFF;
  top: -3px;
  margin-left: 5px;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.settings-pane .user-info .user-details h3 .user-status.is-online {
  background-color: #8dc63f;
}
.settings-pane .user-info .user-details h3 .user-status.is-idle {
  background-color: #ffba00;
}
.settings-pane .user-info .user-details h3 .user-status.is-busy {
  background-color: #d5080f;
}
.settings-pane .user-info .user-details h3 .user-status.is-offline {
  background-color: #CCC;
}
.settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
  margin: 0;
  margin-top: 5px;
}
.settings-pane .user-info .user-details .user-links {
  margin-top: 15px;
}
.settings-pane .user-info .user-details .user-links a {
  display: inline-block;
}
.settings-pane .user-info .user-details .user-links a + a {
  margin-left: 8px;
}
.settings-pane .links-block {
  display: inline-block;
  padding-right: 85px;
  text-align: left;
}
@media screen and (max-width: 991px) {
  .settings-pane .links-block {
    padding-right: 40px;
    margin-top: 10px;
  }
}
.settings-pane .links-block.left-sep {
  border-left: 1px solid #323435;
  padding-left: 75px;
}
@media screen and (max-width: 991px) {
  .settings-pane .links-block.left-sep {
    padding-left: 40px;
  }
  .settings-pane .links-block.left-sep:first-child {
    border-left: 0;
  }
}
@media screen and (max-width: 599px) {
  .settings-pane .links-block.left-sep {
    padding: 0 25px;
  }
}
.settings-pane .links-block h4 {
  color: #ffffff;
  margin: 0;
  margin-bottom: 15px;
}
.settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #323435;
  padding-bottom: 5px;
}
.settings-pane .links-block h4 a {
  color: #ffffff;
}
.settings-pane .links-block h4 a:hover {
  color: #979898;
}
.settings-pane .links-block ul li label {
  display: inline-block;
  padding-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.settings-pane .links-block ul li a {
  color: #979898;
  display: block;
}
.settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.settings-pane .links-block ul li + li a {
  margin-top: 5px;
}
.settings-pane .link-blocks-env {
  text-align: center;
}
.settings-pane-open .settings-pane {
  display: block;
}
.settings-pane-open .sidebar-menu .sidebar-menu-inner {
  position: relative !important;
}
.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #27292a;
  -webkit-box-shadow: 0px 0px 0px 10px #27292a, 0px -10px 0px 10px #27292a, 0px -20px 0px 10px #27292a, 0px -30px 0px 10px #27292a;
  -moz-box-shadow: 0px 0px 0px 10px #27292a, 0px -10px 0px 10px #27292a, 0px -20px 0px 10px #27292a, 0px -30px 0px 10px #27292a;
  box-shadow: 0px 0px 0px 10px #27292a, 0px -10px 0px 10px #27292a, 0px -20px 0px 10px #27292a, 0px -30px 0px 10px #27292a;
}
.cbr-replaced {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cbr-replaced .cbr-input {
  display: none;
}
.cbr-replaced .cbr-state {
  position: relative;
  display: block;
  background: #FFF;
  border: 1px solid #dddddd;
  line-height: 1;
  width: 15px;
  height: 15px;
}
.cbr-replaced .cbr-state span {
  display: block;
  background: url(../images/ok.png) no-repeat center center;
  background-image: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
}
.cbr-replaced.cbr-checked span {
  background-image: url(../images/ok.png);
}
.cbr-replaced.cbr-disabled {
  zoom: 1;
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
.cbr-replaced.cbr-radio .cbr-state {
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.cbr-replaced.cbr-radio .cbr-state span {
  background: #FFF;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.cbr-replaced.cbr-radio.cbr-checked .cbr-state span {
  background: #dddddd;
}
.cbr-replaced.cbr-primary .cbr-state {
  background: #2c2e2f;
  border-color: #323435;
}
.cbr-replaced.cbr-primary.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-primary.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-primary.cbr-radio .cbr-state span {
  background-color: #fff;
}
.cbr-replaced.cbr-primary.cbr-radio.cbr-checked .cbr-state span {
  background: #2c2e2f;
}
.cbr-replaced.cbr-secondary .cbr-state {
  background: #68b828;
  border-color: #68b828;
}
.cbr-replaced.cbr-secondary.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-secondary.cbr-checked.cbr-radio .cbr-state span {
  background: #68b828;
}
.cbr-replaced.cbr-secondary.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-success .cbr-state {
  background: #8dc63f;
  border-color: #8dc63f;
}
.cbr-replaced.cbr-success.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-success.cbr-checked.cbr-radio .cbr-state span {
  background: #8dc63f;
}
.cbr-replaced.cbr-success.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-info .cbr-state {
  background: #40bbea;
  border-color: #40bbea;
}
.cbr-replaced.cbr-info.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-info.cbr-checked.cbr-radio .cbr-state span {
  background: #40bbea;
}
.cbr-replaced.cbr-info.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-danger .cbr-state {
  background: #cc3f44;
  border-color: #cc3f44;
}
.cbr-replaced.cbr-danger.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-danger.cbr-checked.cbr-radio .cbr-state span {
  background: #cc3f44;
}
.cbr-replaced.cbr-danger.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-warning .cbr-state {
  background: #ffba00;
  border-color: #ffba00;
}
.cbr-replaced.cbr-warning.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-warning.cbr-checked.cbr-radio .cbr-state span {
  background: #ffba00;
}
.cbr-replaced.cbr-warning.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-red .cbr-state {
  background: #d5080f;
  border-color: #d5080f;
}
.cbr-replaced.cbr-red.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-red.cbr-checked.cbr-radio .cbr-state span {
  background: #d5080f;
}
.cbr-replaced.cbr-red.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-blue .cbr-state {
  background: #0e62c7;
  border-color: #0e62c7;
}
.cbr-replaced.cbr-blue.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-blue.cbr-checked.cbr-radio .cbr-state span {
  background: #0e62c7;
}
.cbr-replaced.cbr-blue.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-purple .cbr-state {
  background: #7c38bc;
  border-color: #7c38bc;
}
.cbr-replaced.cbr-purple.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-purple.cbr-checked.cbr-radio .cbr-state span {
  background: #7c38bc;
}
.cbr-replaced.cbr-purple.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-pink .cbr-state {
  background: #ff6264;
  border-color: #ff6264;
}
.cbr-replaced.cbr-pink.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-pink.cbr-checked.cbr-radio .cbr-state span {
  background: #ff6264;
}
.cbr-replaced.cbr-pink.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-orange .cbr-state {
  background: #f7aa47;
  border-color: #f7aa47;
}
.cbr-replaced.cbr-orange.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-orange.cbr-checked.cbr-radio .cbr-state span {
  background: #f7aa47;
}
.cbr-replaced.cbr-orange.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-turquoise .cbr-state {
  background: #00b19d;
  border-color: #00b19d;
}
.cbr-replaced.cbr-turquoise.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-turquoise.cbr-checked.cbr-radio .cbr-state span {
  background: #00b19d;
}
.cbr-replaced.cbr-turquoise.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-yellow .cbr-state {
  background: #fcd036;
  border-color: #fcd036;
}
.cbr-replaced.cbr-yellow.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-yellow.cbr-checked.cbr-radio .cbr-state span {
  background: #fcd036;
}
.cbr-replaced.cbr-yellow.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-gray .cbr-state {
  background: #c8c8c8;
  border-color: #c8c8c8;
}
.cbr-replaced.cbr-gray.cbr-checked span {
  background-image: url(../images/ok-white-full.png);
}
.cbr-replaced.cbr-gray.cbr-checked.cbr-radio .cbr-state span {
  background: #c8c8c8;
}
.cbr-replaced.cbr-gray.cbr-radio .cbr-state {
  background: #fff;
}
.cbr-replaced.cbr-gray.cbr-checked span {
  background-image: url(../images/ok.png);
}
.cbr-replaced + label {
  padding-left: 5px;
}
label .cbr-replaced {
  margin-right: 4px;
}
label.cbr-inline {
  padding-top: 7px;
}
label.cbr-inline + .cbr-inline {
  margin-left: 10px;
}
.btn {
  outline: none;
  border: 1px solid transparent;
  margin-bottom: 10px;
}
.btn.text-left {
  text-align: left;
}
.btn.text-right {
  text-align: right;
}
.btn:active,
.btn:focus {
  outline: none !important;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.08);
}
.btn + .btn {
  margin-left: 5px;
}
.btn + .btn.btn-block {
  margin-left: 0;
}
.btn.btn-single {
  margin-bottom: 0;
}
.btn.btn-icon {
  line-height: 1;
}
.btn.btn-icon i {
  vertical-align: middle;
  display: inline-block;
  padding: 5px 0;
}
.btn.btn-icon span {
  display: inline-block;
}
.btn.btn-icon-standalone {
  padding: 0;
  border: 0;
  line-height: 1;
}
.btn.btn-icon-standalone i,
.btn.btn-icon-standalone span {
  display: block;
  padding: 12px 12px;
}
.btn.btn-icon-standalone i {
  border-right: 1px solid transparent;
  float: left;
}
.btn.btn-icon-standalone span {
  padding-left: 55px;
  padding-right: 13px;
}
.btn.btn-icon-standalone.btn-icon-standalone-right i {
  float: right;
  border-left: 1px solid transparent;
  border-right: 0;
}
.btn.btn-icon-standalone.btn-icon-standalone-right span {
  padding-left: 13px;
  padding-right: 55px;
}
.btn.btn-icon-standalone.btn-lg i,
.btn.btn-icon-standalone.btn-lg span {
  padding: 15px 16px;
}
.btn.btn-icon-standalone.btn-lg span {
  padding-left: 75px;
}
.btn.btn-icon-standalone.btn-lg.btn-icon-standalone-right span {
  padding-left: 17px;
  padding-right: 75px;
}
.btn.btn-icon-standalone.btn-sm i,
.btn.btn-icon-standalone.btn-sm span {
  padding: 8px 10px;
}
.btn.btn-icon-standalone.btn-sm span {
  padding-left: 50px;
}
.btn.btn-icon-standalone.btn-sm.btn-icon-standalone-right span {
  padding-left: 12px;
  padding-right: 50px;
}
.btn.btn-icon-standalone.btn-xs i,
.btn.btn-icon-standalone.btn-xs span {
  padding: 5px 5px;
}
.btn.btn-icon-standalone.btn-xs span {
  padding-left: 34px;
}
.btn.btn-icon-standalone.btn-xs.btn-icon-standalone-right span {
  padding-left: 10px;
  padding-right: 34px;
}
.btn.btn-secondary {
  background-color: #68b828;
  color: #ffffff;
}
.btn.btn-secondary:hover {
  background-color: #5ca323;
  text-color: #ffffff;
}
.btn.btn-secondary:active,
.btn.btn-secondary:focus {
  background-color: #5ca323;
  border-color: #579b22;
}
.btn.btn-purple {
  background-color: #7c38bc;
  color: #ffffff;
}
.btn.btn-purple:hover {
  background-color: #6f32a8;
  text-color: #ffffff;
}
.btn.btn-purple:active,
.btn.btn-purple:focus {
  background-color: #6f32a8;
  border-color: #6a30a0;
}
.btn.btn-orange {
  background-color: #f7aa47;
  color: #ffffff;
}
.btn.btn-orange:hover {
  background-color: #f69f2f;
  text-color: #ffffff;
}
.btn.btn-orange:active,
.btn.btn-orange:focus {
  background-color: #f69f2f;
  border-color: #f69a25;
}
.btn.btn-pink {
  background-color: #ff6264;
  color: #ffffff;
}
.btn.btn-pink:hover {
  background-color: #ff484b;
  text-color: #ffffff;
}
.btn.btn-pink:active,
.btn.btn-pink:focus {
  background-color: #ff484b;
  border-color: #ff3e41;
}
.btn.btn-turquoise {
  background-color: #00b19d;
  color: #ffffff;
}
.btn.btn-turquoise:hover {
  background-color: #009886;
  text-color: #ffffff;
}
.btn.btn-turquoise:active,
.btn.btn-turquoise:focus {
  background-color: #009886;
  border-color: #008d7d;
}
.btn.btn-blue {
  background-color: #0e62c7;
  color: #ffffff;
}
.btn.btn-blue:hover {
  background-color: #0c56af;
  text-color: #ffffff;
}
.btn.btn-blue:active,
.btn.btn-blue:focus {
  background-color: #0c56af;
  border-color: #0c52a6;
}
.btn.btn-red {
  background-color: #d5080f;
  color: #ffffff;
}
.btn.btn-red:hover {
  background-color: #bc070d;
  text-color: #ffffff;
}
.btn.btn-red:active,
.btn.btn-red:focus {
  background-color: #bc070d;
  border-color: #b3070d;
}
.btn.btn-gray {
  background-color: #eeeeee;
  color: #333333;
}
.btn.btn-gray:hover {
  background-color: #e1e1e1;
  text-color: #333333;
}
.btn.btn-gray:active,
.btn.btn-gray:focus {
  background-color: #e1e1e1;
  border-color: #dcdcdc;
}
.btn.btn-black {
  background-color: #222222;
  color: #ffffff;
}
.btn.btn-black:hover {
  background-color: #151515;
  text-color: #ffffff;
}
.btn.btn-black:active,
.btn.btn-black:focus {
  background-color: #151515;
  border-color: #101010;
}
.btn.btn-black:hover {
  background-color: #3c3c3c;
}
.btn.btn-white {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #dddddd;
}
.btn.btn-white:hover {
  background-color: #f2f2f2;
  text-color: #333333;
}
.btn.btn-white:active,
.btn.btn-white:focus {
  background-color: #f2f2f2;
  border-color: #ededed;
}
.btn.btn-primary.btn-icon-standalone i {
  background-color: #383b3c;
  border-right-color: #454849;
}
.btn.btn-primary.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #454849;
}
.btn.btn-primary.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #1d1f1f;
}
.btn.btn-primary.btn-icon-standalone:hover i {
  border-right-color: #1d1f1f;
}
.btn.btn-secondary.btn-icon-standalone i {
  background-color: #63b026;
  border-right-color: #5fa724;
}
.btn.btn-secondary.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #5fa724;
}
.btn.btn-secondary.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #5a9f23;
}
.btn.btn-secondary.btn-icon-standalone:hover i {
  border-right-color: #5a9f23;
}
.btn.btn-info.btn-icon-standalone i {
  background-color: #2eb4e8;
  border-right-color: #1baee6;
}
.btn.btn-info.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #1baee6;
}
.btn.btn-info.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #24b1e7;
}
.btn.btn-info.btn-icon-standalone:hover i {
  border-right-color: #24b1e7;
}
.btn.btn-danger.btn-icon-standalone i {
  background-color: #c33439;
  border-right-color: #b32f34;
}
.btn.btn-danger.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #b32f34;
}
.btn.btn-danger.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #bb3236;
}
.btn.btn-danger.btn-icon-standalone:hover i {
  border-right-color: #bb3236;
}
.btn.btn-warning.btn-icon-standalone i {
  background-color: #f0af00;
  border-right-color: #e6a700;
}
.btn.btn-warning.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #e6a700;
}
.btn.btn-warning.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #e0a400;
}
.btn.btn-warning.btn-icon-standalone:hover i {
  border-right-color: #e0a400;
}
.btn.btn-red.btn-icon-standalone i {
  background-color: #c1070e;
  border-right-color: #b8070d;
}
.btn.btn-red.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #b8070d;
}
.btn.btn-red.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #b8070d;
}
.btn.btn-red.btn-icon-standalone:hover i {
  border-right-color: #b8070d;
}
.btn.btn-purple.btn-icon-standalone i {
  background-color: #7233ac;
  border-right-color: #NaNNaNNaN;
}
.btn.btn-purple.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #NaNNaNNaN;
}
.btn.btn-purple.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #6c31a4;
}
.btn.btn-purple.btn-icon-standalone:hover i {
  border-right-color: #6c31a4;
}
.btn.btn-blue.btn-icon-standalone i {
  background-color: #0d59b4;
  border-right-color: #0c54aa;
}
.btn.btn-blue.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #0c54aa;
}
.btn.btn-blue.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #0c54aa;
}
.btn.btn-blue.btn-icon-standalone:hover i {
  border-right-color: #0c54aa;
}
.btn.btn-white.btn-icon-standalone i {
  background-color: #f5f5f5;
  border-right-color: #e6e6e6;
}
.btn.btn-white.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #e6e6e6;
}
.btn.btn-white.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #e0e0e0;
}
.btn.btn-white.btn-icon-standalone:hover i {
  border-right-color: #e0e0e0;
}
.btn.btn-gray.btn-icon-standalone i {
  background-color: #e4e4e4;
  border-right-color: #d5d5d5;
}
.btn.btn-gray.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #d5d5d5;
}
.btn.btn-gray.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #cfcfcf;
}
.btn.btn-gray.btn-icon-standalone:hover i {
  border-right-color: #cfcfcf;
}
.btn.btn-black.btn-icon-standalone i {
  background-color: #2f2f2f;
  border-right-color: #3c3c3c;
}
.btn.btn-black.btn-icon-standalone.btn-icon-standalone-right i {
  border-left-color: #3c3c3c;
}
.btn.btn-black.btn-icon-standalone.btn-icon-standalone-right:hover i {
  border-left-color: #414141;
}
.btn.btn-black.btn-icon-standalone:hover i {
  border-right-color: #414141;
}
.btn-group .btn.btn-purple:hover {
  border-color: #552681;
}
.btn-group .btn.btn-red:hover {
  border-color: #8b050a;
}
.btn-group .btn.btn-blue:hover {
  border-color: #093f80;
}
.btn-group .btn.btn-white:hover {
  border-color: #d9d9d9;
}
.btn-group .btn.btn-gray:hover {
  border-color: #c8c8c8;
}
.btn-group .btn.btn-black:hover {
  border-color: #484848;
}
.btn-unstyled {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  height: auto;
  line-height: 1;
}
.btn-unstyled:active,
.btn-unstyled:focus {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dropdown-menu.no-spacing {
  margin-top: -10px;
}
.dropdown-menu.dropdown-primary {
  background: #2c2e2f;
  border-color: #2c2e2f;
}
.dropdown-menu.dropdown-primary .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #454849;
}
.dropdown-menu.dropdown-primary > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-primary > li > a:hover {
  background-color: #454849;
}
.dropdown-menu.dropdown-secondary {
  background: #68b828;
  border-color: #68b828;
}
.dropdown-menu.dropdown-secondary .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #5ca323;
}
.dropdown-menu.dropdown-secondary > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-secondary > li > a:hover {
  background-color: #5ca323;
}
.dropdown-menu.dropdown-info {
  background: #40bbea;
  border-color: #40bbea;
}
.dropdown-menu.dropdown-info .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #1baee6;
}
.dropdown-menu.dropdown-info > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-info > li > a:hover {
  background-color: #1baee6;
}
.dropdown-menu.dropdown-success {
  background: #8dc63f;
  border-color: #8dc63f;
}
.dropdown-menu.dropdown-success .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #80b636;
}
.dropdown-menu.dropdown-success > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-success > li > a:hover {
  background-color: #80b636;
}
.dropdown-menu.dropdown-warning {
  background: #ffba00;
  border-color: #ffba00;
}
.dropdown-menu.dropdown-warning .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #e6a700;
}
.dropdown-menu.dropdown-warning > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-warning > li > a:hover {
  background-color: #e6a700;
}
.dropdown-menu.dropdown-danger {
  background: #cc3f44;
  border-color: #cc3f44;
}
.dropdown-menu.dropdown-danger .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #b32f34;
}
.dropdown-menu.dropdown-danger > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-danger > li > a:hover {
  background-color: #b32f34;
}
.dropdown-menu.dropdown-red {
  background: #d5080f;
  border-color: #d5080f;
}
.dropdown-menu.dropdown-red .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #bc070d;
}
.dropdown-menu.dropdown-red > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-red > li > a:hover {
  background-color: #bc070d;
}
.dropdown-menu.dropdown-purple {
  background: #7c38bc;
  border-color: #7c38bc;
}
.dropdown-menu.dropdown-purple .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #672f9d;
}
.dropdown-menu.dropdown-purple > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-purple > li > a:hover {
  background-color: #672f9d;
}
.dropdown-menu.dropdown-blue {
  background: #0e62c7;
  border-color: #0e62c7;
}
.dropdown-menu.dropdown-blue .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #0b4fa1;
}
.dropdown-menu.dropdown-blue > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-blue > li > a:hover {
  background-color: #0b4fa1;
}
.dropdown-menu.dropdown-black {
  background: #222222;
  border-color: #222222;
}
.dropdown-menu.dropdown-black .divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #363636;
}
.dropdown-menu.dropdown-black > li > a {
  color: #ffffff;
}
.dropdown-menu.dropdown-black > li > a:hover {
  background-color: #363636;
}
.dropdown-menu.dropdown-white.no-spacing {
  margin-top: -11px;
}
.nav.nav-tabs {
  border-bottom: 0;
}
.nav.nav-tabs > li {
  display: inline-block;
  float: none;
  margin-bottom: 0;
}
.nav.nav-tabs > li > a {
  border: none;
  background-color: #f4f4f4;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}
.nav.nav-tabs > li > a:hover {
  border: none;
  background-color: #ffffff;
}
.nav.nav-tabs > li.active > a {
  border: 0;
  background-color: #fff;
}
.nav.nav-tabs.right-aligned,
.right-aligned .nav.nav-tabs {
  text-align: right;
}
.nav.nav-tabs.right-aligned > li,
.right-aligned .nav.nav-tabs > li {
  float: none;
  display: inline-block;
}
.nav.nav-tabs.right-aligned > li > a,
.right-aligned .nav.nav-tabs > li > a {
  margin-right: 0;
  margin-left: 5px;
}
.nav.nav-tabs.nav-tabs-justified,
.nav-tabs-justified .nav.nav-tabs {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.nav.nav-tabs.nav-tabs-justified:before,
.nav-tabs-justified .nav.nav-tabs:before,
.nav.nav-tabs.nav-tabs-justified:after,
.nav-tabs-justified .nav.nav-tabs:after {
  display: none;
}
.nav.nav-tabs.nav-tabs-justified > li,
.nav-tabs-justified .nav.nav-tabs > li {
  display: table-cell;
  vertical-align: top;
  width: 1%;
  text-align: center;
}
.nav.nav-tabs.nav-tabs-justified > li:last-child > a,
.nav-tabs-justified .nav.nav-tabs > li:last-child > a {
  margin-right: 0;
}
.nav.nav-tabs + .tab-content {
  background: #FFF;
  padding: 30px;
  margin-bottom: 30px;
}
.nav.nav-tabs + .tab-content > p:last-child,
.nav.nav-tabs + .tab-content * > p:last-child {
  margin-bottom: 0;
}
.panel-group .panel .panel-heading {
  padding-bottom: 0;
  border-bottom: 0;
}
.panel-group .panel .panel-heading .panel-title {
  float: none;
}
.panel-group .panel .panel-heading a[data-toggle="collapse"],
.panel-group .panel .panel-heading .accordion-toggle {
  display: block;
}
.panel-group .panel .panel-heading a[data-toggle="collapse"]:before,
.panel-group .panel .panel-heading .accordion-toggle:before {
  float: right;
  display: block;
  content: '–';
  font-size: 18px;
  width: 25px;
  text-align: right;
}
.panel-group .panel .panel-heading a[data-toggle="collapse"].collapsed:before,
.panel-group .panel .panel-heading .accordion-toggle.collapsed:before {
  content: '+';
}
.panel-group .panel .panel-heading .accordion-toggle {
  cursor: pointer;
}
.panel-group .panel .panel-heading .accordion-toggle:before {
  content: '+';
}
.panel-group .panel .panel-heading + .panel-collapse .panel-body {
  border-top: 2px solid #f5f5f5;
  margin-top: 15px;
}
.panel-group.panel-group-joined,
.panel-group-joined .panel-group {
  border: 1px solid #dddddd;
}
.panel-group.panel-group-joined .panel .panel-collapse .panel-body,
.panel-group-joined .panel-group .panel .panel-collapse .panel-body {
  border-top: 0;
  padding-top: 0;
}
.panel-group.panel-group-joined .panel + .panel,
.panel-group-joined .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #dddddd;
}
.panel.expanded .accordion-toggle:before {
  content: '–' !important;
}
.tabs-vertical-env {
  display: table;
  margin-bottom: 30px;
  width: 100%;
}
.tabs-vertical-env .nav.tabs-vertical,
.tabs-vertical-env .tab-content {
  display: table-cell;
  vertical-align: top;
}
.tabs-vertical-env .nav.tabs-vertical {
  min-width: 120px;
  width: 150px;
}
.tabs-vertical-env .nav.tabs-vertical li > a {
  background-color: #f4f4f4;
  margin-bottom: 5px;
  text-align: center;
  white-space: nowrap;
}
.tabs-vertical-env .nav.tabs-vertical li.active > a {
  background-color: #fff;
}
.tabs-vertical-env .nav.tabs-vertical li:last-child {
  padding-bottom: 20px;
}
.tabs-vertical-env .tab-content {
  padding: 30px;
  background: #FFF;
}
.tabs-vertical-env .tab-content * > p:last-child {
  margin-bottom: 0;
}
.tabs-vertical-env.tabs-vertical-bordered .tab-content,
.tabs-vertical-env.tabs-vertical-bordered .nav.tabs-vertical > li > a {
  border: 1px solid #dddddd;
}
.tabs-vertical-env.tabs-vertical-bordered .nav.tabs-vertical {
  position: relative;
  right: -1px;
}
.tabs-vertical-env.tabs-vertical-bordered .nav.tabs-vertical > li.active > a {
  border-right-color: #fff;
}
.tabs-vertical-env.tabs-vertical-bordered.right-aligned .nav.tabs-vertical {
  right: 1px;
}
.tabs-vertical-env.tabs-vertical-bordered.right-aligned .nav.tabs-vertical > li.active > a {
  border-left-color: #fff;
  border-right-color: #dddddd;
}
.label {
  position: relative;
}
.label.pull-right {
  margin-top: 4px;
}
.label.pull-left {
  margin-top: 4px;
  margin-right: 5px;
}
.label.label-primary {
  background-color: #2c2e2f;
  color: #fff;
}
.label.label-secondary {
  background-color: #68b828;
  color: #fff;
}
.label.label-success {
  background-color: #8dc63f;
  color: #fff;
}
.label.label-danger {
  background-color: #cc3f44;
  color: #fff;
}
.label.label-info {
  background-color: #40bbea;
  color: #fff;
}
.label.label-warning {
  background-color: #ffba00;
  color: #fff;
}
.label.label-red {
  background-color: #d5080f;
  color: #fff;
}
.label.label-purple {
  background-color: #7c38bc;
  color: #fff;
}
.label.label-pink {
  background-color: #ff6264;
  color: #fff;
}
.label.label-yellow {
  background-color: #fcd036;
  color: #fff;
}
.label.label-orange {
  background-color: #f7aa47;
  color: #fff;
}
.label.label-turquoise {
  background-color: #00b19d;
  color: #fff;
}
.label.label-blue {
  background-color: #0e62c7;
  color: #fff;
}
.label.label-black {
  background-color: #222222;
  color: #fff;
}
.label.label-white {
  color: #333;
  -webkit-box-shadow: 0px 0px 0px 1px #dddddd;
  -moz-box-shadow: 0px 0px 0px 1px #dddddd;
  box-shadow: 0px 0px 0px 1px #dddddd;
}
.line-height-default li {
  line-height: 1.85;
}
.popover + .btn,
.tooltip + .btn {
  margin-left: 5px;
}
.popover {
  padding: 0;
  border: 1px solid #dddddd;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.popover.popover-primary .popover-title {
  background-color: #2c2e2f;
  color: #FFF;
  position: relative;
}
.popover.popover-primary.top .popover-title,
.popover.popover-primary.bottom .popover-title {
  margin: -1px;
}
.popover.popover-primary.bottom .arrow:after {
  border-bottom-color: #2c2e2f;
}
.popover.popover-secondary .popover-title {
  background-color: #68b828;
  color: #FFF;
  position: relative;
}
.popover.popover-secondary.top .popover-title,
.popover.popover-secondary.bottom .popover-title {
  margin: -1px;
}
.popover.popover-secondary.bottom .arrow:after {
  border-bottom-color: #68b828;
}
.popover.popover-info .popover-title {
  background-color: #40bbea;
  color: #FFF;
  position: relative;
}
.popover.popover-info.top .popover-title,
.popover.popover-info.bottom .popover-title {
  margin: -1px;
}
.popover.popover-info.bottom .arrow:after {
  border-bottom-color: #40bbea;
}
.popover.popover-warning .popover-title {
  background-color: #ffba00;
  color: #FFF;
  position: relative;
}
.popover.popover-warning.top .popover-title,
.popover.popover-warning.bottom .popover-title {
  margin: -1px;
}
.popover.popover-warning.bottom .arrow:after {
  border-bottom-color: #ffba00;
}
.popover.popover-danger .popover-title {
  background-color: #cc3f44;
  color: #FFF;
  position: relative;
}
.popover.popover-danger.top .popover-title,
.popover.popover-danger.bottom .popover-title {
  margin: -1px;
}
.popover.popover-danger.bottom .arrow:after {
  border-bottom-color: #cc3f44;
}
.popover.popover-success .popover-title {
  background-color: #8dc63f;
  color: #FFF;
  position: relative;
}
.popover.popover-success.top .popover-title,
.popover.popover-success.bottom .popover-title {
  margin: -1px;
}
.popover.popover-success.bottom .arrow:after {
  border-bottom-color: #8dc63f;
}
.popover.popover-purple .popover-title {
  background-color: #7c38bc;
  color: #FFF;
  position: relative;
}
.popover.popover-purple.top .popover-title,
.popover.popover-purple.bottom .popover-title {
  margin: -1px;
}
.popover.popover-purple.bottom .arrow:after {
  border-bottom-color: #7c38bc;
}
.popover.popover-blue .popover-title {
  background-color: #0e62c7;
  color: #FFF;
  position: relative;
}
.popover.popover-blue.top .popover-title,
.popover.popover-blue.bottom .popover-title {
  margin: -1px;
}
.popover.popover-blue.bottom .arrow:after {
  border-bottom-color: #0e62c7;
}
.popover.popover-red .popover-title {
  background-color: #d5080f;
  color: #FFF;
  position: relative;
}
.popover.popover-red.top .popover-title,
.popover.popover-red.bottom .popover-title {
  margin: -1px;
}
.popover.popover-red.bottom .arrow:after {
  border-bottom-color: #d5080f;
}
.popover.popover-black .popover-title {
  background-color: #0e62c7;
  color: #FFF;
  position: relative;
}
.popover.popover-black.top .popover-title,
.popover.popover-black.bottom .popover-title {
  margin: -1px;
}
.popover.popover-black.bottom .arrow:after {
  border-bottom-color: #0e62c7;
}
.tooltip .tooltip-inner {
  padding: 10px 15px;
}
.tooltip.tooltip-primary .tooltip-inner {
  background-color: #2c2e2f;
}
.tooltip.tooltip-primary.top .tooltip-arrow {
  border-top-color: #2c2e2f;
}
.tooltip.tooltip-primary.bottom .tooltip-arrow {
  border-bottom-color: #2c2e2f;
}
.tooltip.tooltip-primary.left .tooltip-arrow {
  border-left-color: #2c2e2f;
}
.tooltip.tooltip-primary.right .tooltip-arrow {
  border-right-color: #2c2e2f;
}
.tooltip.tooltip-secondary .tooltip-inner {
  background-color: #68b828;
}
.tooltip.tooltip-secondary.top .tooltip-arrow {
  border-top-color: #68b828;
}
.tooltip.tooltip-secondary.bottom .tooltip-arrow {
  border-bottom-color: #68b828;
}
.tooltip.tooltip-secondary.left .tooltip-arrow {
  border-left-color: #68b828;
}
.tooltip.tooltip-secondary.right .tooltip-arrow {
  border-right-color: #68b828;
}
.tooltip.tooltip-info .tooltip-inner {
  background-color: #40bbea;
}
.tooltip.tooltip-info.top .tooltip-arrow {
  border-top-color: #40bbea;
}
.tooltip.tooltip-info.bottom .tooltip-arrow {
  border-bottom-color: #40bbea;
}
.tooltip.tooltip-info.left .tooltip-arrow {
  border-left-color: #40bbea;
}
.tooltip.tooltip-info.right .tooltip-arrow {
  border-right-color: #40bbea;
}
.tooltip.tooltip-warning .tooltip-inner {
  background-color: #ffba00;
}
.tooltip.tooltip-warning.top .tooltip-arrow {
  border-top-color: #ffba00;
}
.tooltip.tooltip-warning.bottom .tooltip-arrow {
  border-bottom-color: #ffba00;
}
.tooltip.tooltip-warning.left .tooltip-arrow {
  border-left-color: #ffba00;
}
.tooltip.tooltip-warning.right .tooltip-arrow {
  border-right-color: #ffba00;
}
.tooltip.tooltip-danger .tooltip-inner {
  background-color: #cc3f44;
}
.tooltip.tooltip-danger.top .tooltip-arrow {
  border-top-color: #cc3f44;
}
.tooltip.tooltip-danger.bottom .tooltip-arrow {
  border-bottom-color: #cc3f44;
}
.tooltip.tooltip-danger.left .tooltip-arrow {
  border-left-color: #cc3f44;
}
.tooltip.tooltip-danger.right .tooltip-arrow {
  border-right-color: #cc3f44;
}
.tooltip.tooltip-success .tooltip-inner {
  background-color: #8dc63f;
}
.tooltip.tooltip-success.top .tooltip-arrow {
  border-top-color: #8dc63f;
}
.tooltip.tooltip-success.bottom .tooltip-arrow {
  border-bottom-color: #8dc63f;
}
.tooltip.tooltip-success.left .tooltip-arrow {
  border-left-color: #8dc63f;
}
.tooltip.tooltip-success.right .tooltip-arrow {
  border-right-color: #8dc63f;
}
.tooltip.tooltip-purple .tooltip-inner {
  background-color: #7c38bc;
}
.tooltip.tooltip-purple.top .tooltip-arrow {
  border-top-color: #7c38bc;
}
.tooltip.tooltip-purple.bottom .tooltip-arrow {
  border-bottom-color: #7c38bc;
}
.tooltip.tooltip-purple.left .tooltip-arrow {
  border-left-color: #7c38bc;
}
.tooltip.tooltip-purple.right .tooltip-arrow {
  border-right-color: #7c38bc;
}
.tooltip.tooltip-blue .tooltip-inner {
  background-color: #0e62c7;
}
.tooltip.tooltip-blue.top .tooltip-arrow {
  border-top-color: #0e62c7;
}
.tooltip.tooltip-blue.bottom .tooltip-arrow {
  border-bottom-color: #0e62c7;
}
.tooltip.tooltip-blue.left .tooltip-arrow {
  border-left-color: #0e62c7;
}
.tooltip.tooltip-blue.right .tooltip-arrow {
  border-right-color: #0e62c7;
}
.tooltip.tooltip-red .tooltip-inner {
  background-color: #d5080f;
}
.tooltip.tooltip-red.top .tooltip-arrow {
  border-top-color: #d5080f;
}
.tooltip.tooltip-red.bottom .tooltip-arrow {
  border-bottom-color: #d5080f;
}
.tooltip.tooltip-red.left .tooltip-arrow {
  border-left-color: #d5080f;
}
.tooltip.tooltip-red.right .tooltip-arrow {
  border-right-color: #d5080f;
}
.tooltip.tooltip-black .tooltip-inner {
  background-color: #0e62c7;
}
.tooltip.tooltip-black.top .tooltip-arrow {
  border-top-color: #0e62c7;
}
.tooltip.tooltip-black.bottom .tooltip-arrow {
  border-bottom-color: #0e62c7;
}
.tooltip.tooltip-black.left .tooltip-arrow {
  border-left-color: #0e62c7;
}
.tooltip.tooltip-black.right .tooltip-arrow {
  border-right-color: #0e62c7;
}
blockquote small {
  margin-top: 10px;
  display: block;
}
.blockquote {
  position: relative;
  padding: 20px 30px;
  background: #fff;
  border: 1px solid #dddddd;
}
.blockquote:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background: #f7f7f7;
}
.blockquote.blockquote-blue {
  border: 1px solid #0e62c7;
}
.blockquote.blockquote-blue:before {
  background: rgba(14, 98, 199, 0.1);
}
.blockquote.blockquote-blue strong {
  color: #0e62c7;
}
.blockquote.blockquote-red {
  border: 1px solid #d5080f;
}
.blockquote.blockquote-red:before {
  background: rgba(213, 8, 15, 0.1);
}
.blockquote.blockquote-red strong {
  color: #d5080f;
}
.blockquote.blockquote-success {
  border: 1px solid #8dc63f;
}
.blockquote.blockquote-success:before {
  background: rgba(141, 198, 63, 0.1);
}
.blockquote.blockquote-success strong {
  color: #8dc63f;
}
.blockquote.blockquote-warning {
  border: 1px solid #ffba00;
}
.blockquote.blockquote-warning:before {
  background: rgba(255, 186, 0, 0.1);
}
.blockquote.blockquote-warning strong {
  color: #ffba00;
}
.blockquote.blockquote-info {
  border: 1px solid #40bbea;
}
.blockquote.blockquote-info:before {
  background: rgba(64, 187, 234, 0.1);
}
.blockquote.blockquote-info strong {
  color: #40bbea;
}
.blockquote.blockquote-danger {
  border: 1px solid #cc3f44;
}
.blockquote.blockquote-danger:before {
  background: rgba(204, 63, 68, 0.1);
}
.blockquote.blockquote-danger strong {
  color: #cc3f44;
}
.blockquote.blockquote-purple {
  border: 1px solid #7c38bc;
}
.blockquote.blockquote-purple:before {
  background: rgba(124, 56, 188, 0.1);
}
.blockquote.blockquote-purple strong {
  color: #7c38bc;
}
.blockquote.blockquote-black {
  border: 1px solid #222222;
}
.blockquote.blockquote-black:before {
  background: rgba(34, 34, 34, 0.1);
}
.blockquote.blockquote-black strong {
  color: #222222;
}
.modal .modal-dialog .modal-content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #f5f5f5;
  padding: 30px;
}
.modal .modal-dialog .modal-content .modal-header {
  margin: 0;
  padding: 0;
  border-bottom-width: 2px;
  padding-bottom: 15px;
}
.modal .modal-dialog .modal-content .modal-header .close {
  outline: none;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 20px 0;
}
.modal .modal-dialog .modal-content .modal-footer {
  padding: 0;
  padding-top: 15px;
}
.modal .modal-dialog .modal-content .modal-footer .btn {
  margin-bottom: 0;
}
.modal.scale .modal-dialog {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 1000ms ease-in-out;
  -moz-transition: all 1000ms ease-in-out;
  -o-transition: all 1000ms ease-in-out;
  transition: all 1000ms ease-in-out;
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
}
.modal.scale.in .modal-dialog {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.table.table-bordered {
  border-color: #eeeeee;
}
.table.table-bordered > thead > tr > td,
.table.table-bordered > tbody > tr > td,
.table.table-bordered > tfood > tr > td,
.table.table-bordered > thead > tr > th,
.table.table-bordered > tbody > tr > th,
.table.table-bordered > tfood > tr > th {
  border-color: #eeeeee;
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfood > tr > td,
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfood > tr > th {
  padding: 12px 15px;
}
.text-center.table > thead > tr > td,
.text-center.table > tbody > tr > td,
.text-center.table > tfood > tr > td,
.text-center.table > thead > tr > th,
.text-center.table > tbody > tr > th,
.text-center.table > tfood > tr > th {
  text-align: center !important;
}
.middle-align.table > thead > tr > td,
.middle-align.table > tbody > tr > td,
.middle-align.table > tfood > tr > td,
.middle-align.table > thead > tr > th,
.middle-align.table > tbody > tr > th,
.middle-align.table > tfood > tr > th {
  vertical-align: middle;
}
.table > thead > tr > td.middle-align,
.table > tbody > tr > td.middle-align,
.table > tfood > tr > td.middle-align,
.table > thead > tr > th.middle-align,
.table > tbody > tr > th.middle-align,
.table > tfood > tr > th.middle-align {
  vertical-align: middle;
}
.table > thead > tr.no-borders > td,
.table > tbody > tr.no-borders > td,
.table > tfood > tr.no-borders > td,
.table > thead > tr.no-borders > th,
.table > tbody > tr.no-borders > th,
.table > tfood > tr.no-borders > th {
  border-left: 0;
  border-right: 0;
}
.table > thead > tr > td,
.table > thead > tr > th {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #2c2e2f;
}
.table > thead > tr > td:after,
.table > thead > tr > th:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  height: 1px;
  background-color: #eeeeee;
}
.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
}
.table > tbody > tr > td .progress,
.table > tfoot > tr > td .progress,
.table > tbody > tr > th .progress,
.table > tfoot > tr > th .progress,
.table > tbody > tr > td .btn,
.table > tfoot > tr > td .btn,
.table > tbody > tr > th .btn,
.table > tfoot > tr > th .btn {
  margin-bottom: 0;
}
.table > tfoot > tr > td,
.table > tfoot > tr > th {
  border-left: 0;
  border-bottom: 0;
  border-top: 1px solid #eeeeee;
  padding: 12px 15px;
  position: relative;
  color: #2c2e2f;
}
.table > tfoot > tr > td:after,
.table > tfoot > tr > th:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 1px;
  height: 1px;
  background-color: #eeeeee;
}
.table.table-condensed > thead > tr > td,
.table.table-condensed > tbody > tr > td,
.table.table-condensed > tfoot > tr > td,
.table.table-condensed > thead > tr > th,
.table.table-condensed > tbody > tr > th,
.table.table-condensed > tfoot > tr > th {
  padding: 5px 8px;
}
.table.table-model-2 > thead > tr {
  background: #f6f6f6;
}
.table.table-model-2 > thead > tr > td:after,
.table.table-model-2 > thead > tr > th:after {
  display: none;
}
.table.table-model-2 > thead > tr > td,
.table.table-model-2 > tbody > tr > td,
.table.table-model-2 > tfood > tr > td,
.table.table-model-2 > thead > tr > th,
.table.table-model-2 > tbody > tr > th,
.table.table-model-2 > tfood > tr > th {
  border-color: #dddddd;
}
.table.table-model-2.table-bordered {
  border-color: #dddddd;
}
.table tbody.middle-align > tr > th,
.table tbody.middle-align > tr > td {
  vertical-align: middle;
}
.panel > .table > thead > tr > td:after,
.panel > .table > thead > tr > th:after {
  display: none;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  padding-bottom: 5px;
}
.dataTables_wrapper .dataTables_filter .form-control {
  margin-left: 10px;
}
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  padding-top: 10px;
}
.dataTables_wrapper .DTTT_container {
  float: right;
  border: 1px solid #eeeeee;
  position: relative;
  margin-top: -2px;
  margin-left: 20px;
}
.dataTables_wrapper .DTTT_container + .dataTables_filter {
  float: right;
}
.dataTables_wrapper .DTTT_container .DTTT_button {
  position: relative;
  display: block;
  float: left;
  padding: 7px 10px;
}
.dataTables_wrapper .DTTT_container .DTTT_button span {
  position: relative;
  z-index: 100;
  cursor: pointer;
}
.dataTables_wrapper .DTTT_container .DTTT_button:hover {
  background: #f5f5f5;
}
.dataTables_wrapper .DTTT_container .DTTT_button:hover div {
  background: #f5f5f5;
}
.dataTables_wrapper .DTTT_container .DTTT_button + .DTTT_button {
  border-left: 1px solid #eeeeee;
}
.dataTables_wrapper table.dataTable thead > tr > th {
  padding-left: 15px;
  padding-right: 15px;
}
.dataTables_wrapper tr.replace-inputs > th,
.dataTables_wrapper tr.replace-inputs > td {
  vertical-align: top;
}
.dataTables_wrapper tr .dataTables_empty {
  border-bottom: 1px solid #eeeeee;
}
.dataTables_wrapper tr .yadcf-filter-wrapper {
  position: relative;
  margin-top: 10px;
  border-top: 1px solid #eeeeee;
  margin-left: -15px;
  margin-right: -15px;
  display: block;
  padding: 10px 15px;
  padding-bottom: 0;
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter-reset-button {
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
  padding-top: 0;
  background: #f5f5f5;
  color: #979898;
  line-height: 1;
  font-weight: normal;
  font-size: 11px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter-reset-button:active,
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter-reset-button:focus {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dataTables_wrapper tr .yadcf-filter-wrapper:hover .yadcf-filter-reset-button {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter {
  display: block;
  width: 100%;
  margin: 0;
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter[type="text"] {
  background: #fff;
  border: 1px solid #e4e4e4;
  font-weight: normal;
  outline: none;
  padding: 0 10px;
  height: 30px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter-wrapper-inner {
  display: table;
  width: 100%;
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter-wrapper-inner .yadcf-filter-range {
  display: table-cell;
  width: 100%;
  background: #fff;
  border: 1px solid #e4e4e4;
  font-weight: normal;
  outline: none;
  padding: 0 5px;
  height: 32px;
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter-wrapper-inner .yadcf-filter-range-number-seperator {
  padding: 0 5px;
  display: table-cell;
  width: 10%;
}
.dataTables_wrapper tr .yadcf-filter-wrapper .yadcf-filter-wrapper-inner .yadcf-filter-range-number-seperator:after {
  content: '–';
  color: #979898;
  font-size: 11px;
  font-weight: normal;
}
.dataTables_wrapper .table thead > tr .sorting,
.dataTables_wrapper .table thead > tr .sorting_asc,
.dataTables_wrapper .table thead > tr .sorting_desc {
  background: none;
}
.dataTables_wrapper .table thead > tr .sorting:before,
.dataTables_wrapper .table thead > tr .sorting_asc:before,
.dataTables_wrapper .table thead > tr .sorting_desc:before {
  font-family: 'fontawesome';
  content: '\f0dc';
  float: right;
  display: block;
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.dataTables_wrapper .table thead > tr .sorting_asc:before {
  content: '\f0de';
}
.dataTables_wrapper .table thead > tr .sorting_desc:before {
  content: '\f0dd';
}
.dataTables_wrapper .table thead > tr .no-sorting:before {
  display: none;
}
.form-horizontal .control-label {
  text-align: right;
}
.form-horizontal > .form-group:last-child {
  margin-bottom: 0;
}
input.form-control.form-focus-primary:focus {
  border-color: rgba(44, 46, 47, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(44, 46, 47, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(44, 46, 47, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(44, 46, 47, 0.6);
}
input.form-control.form-focus-primary:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-warning:focus {
  border-color: rgba(255, 186, 0, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 186, 0, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 186, 0, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 186, 0, 0.6);
}
input.form-control.form-focus-warning:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-danger:focus {
  border-color: rgba(204, 63, 68, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(204, 63, 68, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(204, 63, 68, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(204, 63, 68, 0.6);
}
input.form-control.form-focus-danger:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-success:focus {
  border-color: rgba(141, 198, 63, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(141, 198, 63, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(141, 198, 63, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(141, 198, 63, 0.6);
}
input.form-control.form-focus-success:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-info:focus {
  border-color: rgba(64, 187, 234, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(64, 187, 234, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(64, 187, 234, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(64, 187, 234, 0.6);
}
input.form-control.form-focus-info:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-purple:focus {
  border-color: rgba(124, 56, 188, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(124, 56, 188, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(124, 56, 188, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(124, 56, 188, 0.6);
}
input.form-control.form-focus-purple:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-red:focus {
  border-color: rgba(213, 8, 15, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(213, 8, 15, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(213, 8, 15, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(213, 8, 15, 0.6);
}
input.form-control.form-focus-red:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-blue:focus {
  border-color: rgba(14, 98, 199, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(14, 98, 199, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(14, 98, 199, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(14, 98, 199, 0.6);
}
input.form-control.form-focus-blue:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input.form-control.form-focus-gray:focus {
  border-color: rgba(200, 200, 200, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(200, 200, 200, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(200, 200, 200, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(200, 200, 200, 0.6);
}
input.form-control.form-focus-gray:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form .help-block {
  color: #979898;
}
form .form-block {
  padding-top: 7px;
}
form .form-group.has-success .help-block,
form .form-group.has-success .control-label,
form .form-group.has-success .radio,
form .form-group.has-success .checkbox,
form .form-group.has-success .radio-inline,
form .form-group.has-success .checkbox-inline {
  color: #8dc63f;
}
form .form-group.has-success .form-control {
  border-color: rgba(141, 198, 63, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-success .form-control:focus {
  border-color: #8dc63f;
}
form .form-group.has-success .input-group-addon {
  color: #8dc63f;
  border-color: rgba(141, 198, 63, 0.4);
}
form .form-group.has-success .form-control-feedback {
  color: #8dc63f;
}
form .form-group.has-warning .help-block,
form .form-group.has-warning .control-label,
form .form-group.has-warning .radio,
form .form-group.has-warning .checkbox,
form .form-group.has-warning .radio-inline,
form .form-group.has-warning .checkbox-inline {
  color: #ffba00;
}
form .form-group.has-warning .form-control {
  border-color: rgba(255, 186, 0, 0.6);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-warning .form-control:focus {
  border-color: #ffba00;
}
form .form-group.has-warning .input-group-addon {
  color: #ffba00;
  border-color: rgba(255, 186, 0, 0.6);
}
form .form-group.has-warning .form-control-feedback {
  color: #ffba00;
}
form .form-group.has-error .help-block,
form .form-group.has-error .control-label,
form .form-group.has-error .radio,
form .form-group.has-error .checkbox,
form .form-group.has-error .radio-inline,
form .form-group.has-error .checkbox-inline {
  color: #cc3f44;
}
form .form-group.has-error .form-control {
  border-color: rgba(204, 63, 68, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-error .form-control:focus {
  border-color: #cc3f44;
}
form .form-group.has-error .input-group-addon {
  color: #cc3f44;
  border-color: rgba(204, 63, 68, 0.4);
}
form .form-group.has-error .form-control-feedback {
  color: #cc3f44;
}
form .form-group.has-info .help-block,
form .form-group.has-info .control-label,
form .form-group.has-info .radio,
form .form-group.has-info .checkbox,
form .form-group.has-info .radio-inline,
form .form-group.has-info .checkbox-inline {
  color: #40bbea;
}
form .form-group.has-info .form-control {
  border-color: rgba(64, 187, 234, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form .form-group.has-info .form-control:focus {
  border-color: #40bbea;
}
form .form-group.has-info .input-group-addon {
  color: #40bbea;
  border-color: rgba(64, 187, 234, 0.4);
}
form .form-group.has-info .form-control-feedback {
  color: #40bbea;
}
form .form-group.validate-has-error .form-control {
  border-color: #cc3f44;
  color: #cc3f44;
}
form .form-group.validate-has-error .form-control::-moz-placeholder {
  color: #cc3f44;
  opacity: 1;
}
form .form-group.validate-has-error .form-control:-ms-input-placeholder {
  color: #cc3f44;
}
form .form-group.validate-has-error .form-control::-webkit-input-placeholder {
  color: #cc3f44;
}
form .form-group.validate-has-error .form-control::-webkit-input-placeholder {
  color: #cc3f44;
}
form .form-group.validate-has-error .form-control:-moz-placeholder {
  color: #cc3f44;
}
form .form-group.validate-has-error .form-control::-moz-placeholder {
  color: #cc3f44;
}
form .form-group.validate-has-error .form-control:-ms-input-placeholder {
  color: #cc3f44;
}
form .form-group.validate-has-error .form-control + span {
  display: block;
  padding-top: 5px;
  font-size: 12px;
  color: #cc3f44;
}
form.form-inline .form-group + .form-group {
  margin-left: 5px;
}
form .form-group-separator {
  height: 1px;
  background: #eeeeee;
  margin-bottom: 15px;
}
label[for] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
label.checkbox-inline input[type="checkbox"] {
  margin-top: 2px;
}
label.radio-inline input[type="radio"] {
  margin-top: 2px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  margin-top: 2px;
}
.input-group .btn {
  margin-bottom: 0;
}
.input-group.input-group-minimal > .form-control:first-child {
  border-right: 0;
  padding-right: 0;
}
.input-group.input-group-minimal .input-group-addon {
  background: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -moz-transition: border-color ease-in-out .15s, -moz-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.input-group.input-group-minimal .input-group-addon + .form-control {
  border-left: 0;
  padding-left: 0;
}
.input-group.input-group-minimal .input-group-addon + .form-control.no-right-border {
  padding-right: 0;
}
.input-group.input-group-minimal.focused .input-group-addon {
  border-color: rgba(104, 184, 40, 0.5);
}
nav.navbar {
  border: 0;
}
nav.navbar form > .btn {
  margin-bottom: 0;
}
nav.navbar .navbar-header .navbar-brand {
  padding-left: 30px;
  padding-right: 30px;
}
nav.navbar .navbar-nav li a {
  padding-left: 20px;
  padding-right: 20px;
}
nav.navbar.navbar-inverse .navbar-toggle i {
  color: #fff;
}
@media print {
  .sidebar-menu {
    display: none;
  }
  .main-content {
    padding: 0 !important;
  }
  .main-content .user-info-navbar,
  .main-content .page-title,
  .main-content footer.main-footer {
    display: none;
  }
  .main-content > .panel {
    margin-bottom: 0;
  }
  .main-content > .panel + .panel {
    margin-top: 30px;
  }
}
.overflow-hidden {
  overflow: hidden !important;
}
.vertical-top > * {
  vertical-align: top;
}
strong + table {
  margin-top: 10px;
}
.text-small {
  font-size: 65%;
}
.text-bold {
  font-weight: bold;
}
address {
  padding-left: 20px;
  border-left: 5px solid #f0f0f0;
}
.scrollable .scrollable {
  margin-right: 20px;
}
.super-large {
  font-size: 66px;
}
.inline {
  display: inline-block !important;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.list-margin {
  margin: 10px 0;
}
.list-margin li {
  padding: 5px 0;
}
.rotate-45deg {
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.text-medium {
  font-size: 12px;
}
.text-bold {
  font-weight: bold;
}
.text-bold.h1,
.text-bold.h2,
.text-bold.h3,
.text-bold.h4,
.text-bold.h5,
.text-bold.h6 {
  font-weight: bold;
}
.text-upper {
  text-transform: uppercase;
}
.chart-item-bg {
  position: relative;
  background: #fff;
  width: 100%;
  margin-bottom: 30px;
}
.chart-item-bg .chart-label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 30px;
  font-size: 15px;
}
.chart-item-bg .chart-label.chart-label-small {
  padding: 20px;
}
.chart-item-bg .chart-label .h1,
.chart-item-bg .chart-label .h2,
.chart-item-bg .chart-label .h3,
.chart-item-bg .chart-label .h4 {
  margin: 0;
  padding: 0;
}
.chart-item-bg .chart-right-legend {
  position: absolute;
  left: 0;
  top: 0;
  padding: 30px;
  font-size: 15px;
  left: auto;
  right: 0;
}
.chart-item-bg .chart-right-legend.chart-label-small {
  padding: 20px;
}
.chart-item-bg .chart-right-legend .h1,
.chart-item-bg .chart-right-legend .h2,
.chart-item-bg .chart-right-legend .h3,
.chart-item-bg .chart-right-legend .h4 {
  margin: 0;
  padding: 0;
}
.chart-item-bg .chart-inner {
  padding: 20px;
}
.chart-item-bg .chart-inner-wide {
  padding: 20px 40px;
}
.chart-item-bg .chart-entry-view {
  border-top: 1px solid #eeeeee;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.chart-item-bg .chart-entry-view > div {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 30px;
  color: #979898;
  font-size: 14px;
}
.chart-item-bg .chart-entry-view:hover {
  background: #fafafa;
}
.chart-item-bg-2 {
  background: #fff;
  display: table;
  width: 100%;
  margin-bottom: 30px;
}
.chart-item-bg-2 > div {
  display: table-cell;
  padding: 15px;
  vertical-align: middle;
}
.chart-item-bg-2 .chart-item-num {
  padding-left: 40px;
  font-size: 90px;
  color: #434444;
  padding-right: 30px;
  white-space: nowrap;
}
.chart-item-bg-2 .chart-item-num.num-sm {
  font-size: 70px;
}
.chart-item-bg-2 .chart-item-env > div {
  margin: 10px 0;
}
.skin-color-palette-entry {
  margin-top: 10px;
}
.skin-color-palette {
  display: block;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
}
.skin-color-palette > span {
  float: left;
  width: 16.66666667%;
  height: 20px;
}
.skin-color-palette > span + span {
  border-left: 0px solid #fff;
}
.skin-color-palette.skin-color-palette-four > span {
  width: 25%;
}
.skin-name-link {
  font-weight: bold;
  color: #2c2e2f;
}
.skin-name-link:hover {
  color: #68b828;
}
.bs-example {
  line-height: 1.85;
}
.bs-example .label {
  margin-left: 2px;
}
.same-margin > * {
  margin-top: 0;
  margin-bottom: 15px;
}
.same-margin > *:last-child {
  margin-bottom: 0;
}
.vspacer {
  height: 80px;
}
.vspacer.v2 {
  height: 15px;
}
.vspacer.v3 {
  height: 30px;
  display: block !important;
}
.vspacer.v4 {
  height: 10px;
  display: block !important;
}
@media screen and (max-width: 768px) {
  .vspacer {
    display: none;
  }
  .vspacer.v2 {
    display: block;
  }
}
.img-container {
  margin-bottom: 20px;
}
.img-shade {
  display: block;
  background: #f5f5f5;
  text-align: center;
  line-height: 0;
  margin-bottom: 20px;
}
.img-preview {
  display: inline-block;
  overflow: hidden;
  background: #f7f7f7;
  line-height: 1;
}
.img-preview img {
  display: block;
}
.no-left-border {
  border-left: 0 !important;
}
.no-right-border {
  border-right: 0 !important;
}
.no-top-margin {
  margin-top: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
img.img-cirlce {
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.col-margin.row > * {
  margin-bottom: 20px;
}
.col-margin.row > *:last-child {
  margin-bottom: 0;
}
.ckeditor + .cke {
  margin-top: 20px;
  margin-bottom: 30px;
}
*[class*="fa-"] {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div.scrollable {
  max-height: 200px;
  overflow: hidden;
  position: relative;
}
.icon-collection .page-header {
  margin: 0;
  margin-bottom: 10px;
}
.icon-collection section .page-header {
  margin-top: 40px;
}
.icon-collection .fontawesome-icon-list {
  margin-left: -15px;
  margin-right: -15px;
}
.icon-collection .fontawesome-icon-list .fa-hover {
  float: left;
  width: 25%;
  margin-bottom: 10px;
}
.icon-collection .fontawesome-icon-list .fa-hover .icon-str {
  display: block;
  background: #fff;
  border: 1px solid #dddddd;
  margin: 0 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.icon-collection .fontawesome-icon-list .fa-hover .icon-str > i {
  display: block;
  float: left;
  position: relative;
  background: #fafafa;
  border-right: 1px solid #e9e9e9;
  padding: 10px;
  text-align: center;
  width: 25%;
}
.icon-collection .fontawesome-icon-list .fa-hover .icon-str > i:hover {
  width: 60%;
  background: #68b828;
  color: #fff;
}
.icon-collection .fontawesome-icon-list .fa-hover .icon-str > i:hover + span {
  width: 40%;
}
.icon-collection .fontawesome-icon-list .fa-hover .icon-str > span {
  display: block;
  float: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px;
  padding-bottom: 8px;
  width: 75%;
  color: #7d7f7f;
  font-family: "Arimo", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.icon-collection .fontawesome-icon-list .fa-hover .icon-str > span:hover {
  color: #2c2e2f;
}
@media (max-width: 991px) {
  .icon-collection .fontawesome-icon-list .fa-hover {
    width: 33%;
  }
}
@media (max-width: 767px) {
  .icon-collection .fontawesome-icon-list .fa-hover {
    width: 50%;
  }
}
.layout-variants {
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
}
.layout-variants .row-clearer {
  clear: both;
  height: 15px;
}
.layout-variants .layout-variant {
  display: block;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid transparent;
}
.layout-variants .layout-variant:hover,
.layout-variants .layout-variant.layout-current {
  background: rgba(240, 240, 240, 0.2);
  border-color: #f0f0f0;
}
.layout-variants .layout-variant:hover .layout-name a,
.layout-variants .layout-variant.layout-current .layout-name a {
  color: #2c2e2f;
}
.layout-variants .layout-variant .layout-img {
  margin-bottom: 15px;
}
.layout-variants .layout-variant .layout-img img {
  display: block;
  width: 100%;
}
.layout-variants .layout-variant .layout-name {
  font-weight: bold;
}
.layout-variants .layout-variant .layout-name a {
  color: #6a6e71;
  max-width: 218px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.layout-variants .layout-variant .layout-name:after {
  display: block;
  content: '';
  width: 20px;
  height: 1px;
  background: #ccc;
  margin: 5.88235294px 0;
}
.layout-variants .layout-variant .layout-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
.layout-variants .layout-variant .layout-links li {
  margin: 2px 0;
}
.layout-variants .layout-variant .layout-links li a {
  color: #979898;
  font-size: 10px;
  text-transform: uppercase;
}
.layout-variants .layout-variant .layout-links li a:hover,
.layout-variants .layout-variant .layout-links li a.layout-mode-current {
  color: #4b4b4b;
}
.layout-variants .layout-variant .layout-links li a.layout-mode-current {
  font-weight: bold;
}
.layout-variants .layout-variant .layout-links li a.disabled {
  cursor: not-allowed;
  color: #979898;
}
.dx-warning {
  background: #FFF;
  border: 5px solid #ffba00;
  padding: 20px;
  margin-bottom: 30px;
}
.dx-warning h2 {
  margin: 0;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f0f0f0;
}
.dx-warning ol {
  margin-top: 20px;
}
.dx-warning li {
  margin: 5px 0;
}
.loader-1 {
  -webkit-animation: loaderAnimate 1000ms linear infinite;
  -moz-animation: loaderAnimate 1000ms linear infinite;
  -o-animation: loaderAnimate 1000ms linear infinite;
  animation: loaderAnimate 1000ms linear infinite;
  clip: rect(0, 30px, 30px, 15px);
  height: 30px;
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes loaderAnimate{ 0%{ -webkit-transform: rotate(0deg); } 100%{ -webkit-transform: rotate(220deg); }}
@-moz-keyframes loaderAnimate{ 0%{ -moz-transform: rotate(0deg); } 100%{ -moz-transform: rotate(220deg); }}
@-o-keyframes loaderAnimate{ 0%{ -o-transform: rotate(0deg); } 100%{ -o-transform: rotate(220deg); }}
@keyframes loaderAnimate{ 0%{-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg); } 100%{-webkit-transform: rotate(220deg);-moz-transform: rotate(220deg);-ms-transform: rotate(220deg);transform: rotate(220deg); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes loaderAnimate2{ 0%{ box-shadow: inset #555555 0 0 0 8px; -webkit-transform: rotate(-140deg); } 50%{ box-shadow: inset #555555 0 0 0 2px; } 100%{ box-shadow: inset #555555 0 0 0 8px; -webkit-transform: rotate(140deg); }}
@-moz-keyframes loaderAnimate2{ 0%{ box-shadow: inset #555555 0 0 0 8px; -moz-transform: rotate(-140deg); } 50%{ box-shadow: inset #555555 0 0 0 2px; } 100%{ box-shadow: inset #555555 0 0 0 8px; -moz-transform: rotate(140deg); }}
@-o-keyframes loaderAnimate2{ 0%{ box-shadow: inset #555555 0 0 0 8px; -o-transform: rotate(-140deg); } 50%{ box-shadow: inset #555555 0 0 0 2px; } 100%{ box-shadow: inset #555555 0 0 0 8px; -o-transform: rotate(140deg); }}
@keyframes loaderAnimate2{ 0%{ box-shadow: inset #555555 0 0 0 8px;-webkit-transform: rotate(-140deg);-moz-transform: rotate(-140deg);-ms-transform: rotate(-140deg);transform: rotate(-140deg); } 50%{ box-shadow: inset #555555 0 0 0 2px; } 100%{ box-shadow: inset #555555 0 0 0 8px;-webkit-transform: rotate(140deg);-moz-transform: rotate(140deg);-ms-transform: rotate(140deg);transform: rotate(140deg); };
}
.loader-1:after {
  -webkit-animation: loaderAnimate2 1000ms ease-in-out infinite;
  -moz-animation: loaderAnimate2 1000ms ease-in-out infinite;
  -o-animation: loaderAnimate2 1000ms ease-in-out infinite;
  animation: loaderAnimate2 1000ms ease-in-out infinite;
  clip: rect(0, 30px, 30px, 15px);
  content: '';
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
}
@keyframes loaderAnimate2 {
  0% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(-140deg);
  }
  50% {
    box-shadow: inset #fff 0 0 0 2px;
  }
  100% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(140deg);
  }
}
.loader-2 {
  -webkit-animation: loaderAnimate2 1000ms linear infinite;
  -moz-animation: loaderAnimate2 1000ms linear infinite;
  -o-animation: loaderAnimate2 1000ms linear infinite;
  animation: loaderAnimate2 1000ms linear infinite;
  clip: rect(0, 30px, 30px, 15px);
  height: 30px;
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes loaderAnimate2{ 0%{ -webkit-transform: rotate(0deg); } 100%{ -webkit-transform: rotate(220deg); }}
@-moz-keyframes loaderAnimate2{ 0%{ -moz-transform: rotate(0deg); } 100%{ -moz-transform: rotate(220deg); }}
@-o-keyframes loaderAnimate2{ 0%{ -o-transform: rotate(0deg); } 100%{ -o-transform: rotate(220deg); }}
@keyframes loaderAnimate2{ 0%{-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg); } 100%{-webkit-transform: rotate(220deg);-moz-transform: rotate(220deg);-ms-transform: rotate(220deg);transform: rotate(220deg); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes loaderAnimate22{ 0%{ box-shadow: inset #ffffff 0 0 0 8px; -webkit-transform: rotate(-140deg); } 50%{ box-shadow: inset #ffffff 0 0 0 2px; } 100%{ box-shadow: inset #ffffff 0 0 0 8px; -webkit-transform: rotate(140deg); }}
@-moz-keyframes loaderAnimate22{ 0%{ box-shadow: inset #ffffff 0 0 0 8px; -moz-transform: rotate(-140deg); } 50%{ box-shadow: inset #ffffff 0 0 0 2px; } 100%{ box-shadow: inset #ffffff 0 0 0 8px; -moz-transform: rotate(140deg); }}
@-o-keyframes loaderAnimate22{ 0%{ box-shadow: inset #ffffff 0 0 0 8px; -o-transform: rotate(-140deg); } 50%{ box-shadow: inset #ffffff 0 0 0 2px; } 100%{ box-shadow: inset #ffffff 0 0 0 8px; -o-transform: rotate(140deg); }}
@keyframes loaderAnimate22{ 0%{ box-shadow: inset #ffffff 0 0 0 8px;-webkit-transform: rotate(-140deg);-moz-transform: rotate(-140deg);-ms-transform: rotate(-140deg);transform: rotate(-140deg); } 50%{ box-shadow: inset #ffffff 0 0 0 2px; } 100%{ box-shadow: inset #ffffff 0 0 0 8px;-webkit-transform: rotate(140deg);-moz-transform: rotate(140deg);-ms-transform: rotate(140deg);transform: rotate(140deg); };
}
.loader-2:after {
  -webkit-animation: loaderAnimate22 1000ms ease-in-out infinite;
  -moz-animation: loaderAnimate22 1000ms ease-in-out infinite;
  -o-animation: loaderAnimate22 1000ms ease-in-out infinite;
  animation: loaderAnimate22 1000ms ease-in-out infinite;
  clip: rect(0, 30px, 30px, 15px);
  content: '';
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
}
@keyframes loaderAnimate22 {
  0% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(-140deg);
  }
  50% {
    box-shadow: inset #fff 0 0 0 2px;
  }
  100% {
    box-shadow: inset #fff 0 0 0 17px;
    transform: rotate(140deg);
  }
}
.page-loading-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0px;
  overflow: hidden;
  background: #2c2e2f;
  z-index: 10000;
  -webkit-perspective: 10000;
  -moz-perspective: 10000;
  perspective: 10000;
  -webkit-perspective: 10000px;
  -moz-perspective: 10000px;
  perspective: 10000px;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 800ms ease-in-out;
  -moz-transition: all 800ms ease-in-out;
  -o-transition: all 800ms ease-in-out;
  transition: all 800ms ease-in-out;
}
.page-loading-overlay.loaded {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  visibility: hidden;
}
.tocify.full-width {
  width: 100% !important;
}
img.img-corona {
  -webkit-box-shadow: 0 0 0 4px rgba(221, 221, 221, 0.3);
  -moz-box-shadow: 0 0 0 4px rgba(221, 221, 221, 0.3);
  box-shadow: 0 0 0 4px rgba(221, 221, 221, 0.3);
}
.highlights {
  margin-bottom: 20px;
}
.highlights li {
  display: block;
  margin: 0;
}
.highlights li.version + li {
  margin-top: 20px;
}
.highlights li h4 {
  margin-bottom: 0;
}
.highlights li + li {
  margin-top: 50px;
}
.highlights .entry-img {
  display: block;
  float: left;
  width: 30%;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.highlights .entry-img img {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  max-width: 100%;
}
.highlights .entry-description {
  float: left;
  width: 70%;
}
.highlights .entry-description h3 {
  margin-top: 10px;
  color: #2c2e2f;
}
@media screen and (max-width: 768px) {
  .highlights .entry-description h3 {
    font-size: 19px;
    margin-bottom: 15px;
  }
}
.highlights .entry-description h3 a {
  color: #2c2e2f;
}
@media screen and (min-width: 1500px) {
  .highlights .entry-img {
    width: 21%;
  }
  .highlights .entry-description {
    width: 79%;
  }
}
@media screen and (max-width: 992px) {
  .highlights .entry-img {
    width: 35%;
  }
  .highlights .entry-description {
    width: 65%;
  }
}
@media screen and (max-width: 768px) {
  .highlights .entry-img,
  .highlights .entry-description {
    float: none;
    width: 100%;
    text-align: center;
  }
  .highlights .entry-img {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 0;
  }
  .highlights .entry-img img {
    display: inline-block;
  }
  .highlights .entry-description p {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .text-left-sm {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .text-center-sm {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .text-right-sm {
    text-align: right;
  }
}
@media screen and (min-width: 992px) {
  .pull-right-md {
    float: right;
  }
}
@media screen and (min-width: 768px) {
  .pull-right-sm {
    float: right;
  }
}
pre.pre-line {
  white-space: pre-line;
}
a:hover {
  text-decoration: none;
}
label {
  font-weight: normal;
}
.label {
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
}
.dropdown-menu,
span.twitter-typeahead .tt-dropdown-menu {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dropdown-menu > li > a,
span.twitter-typeahead .tt-suggestion > p {
  padding: 5px 20px;
}
.dropdown-menu > li > a > i {
  display: inline-block;
  margin-right: 5px;
}
.left-dropdown .btn {
  float: right;
}
.left-dropdown .btn.dropdown-toggle {
  float: left;
}
.form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-control:focus,
.form-control:active {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.alert.alert-dismissable {
  padding-right: 20px;
}
.alert.alert-dismissable .close {
  right: -10px;
  top: 0px;
}
.alert .close {
  position: relative;
  margin-top: -2px;
  outline: 0;
  color: #000;
}
.alert .close span {
  text-shadow: none;
}
.alert.alert-default {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #333333;
}
.alert.alert-default hr {
  border-top-color: #e1e1e1;
}
.alert.alert-default .alert-link {
  color: #1a1a1a;
}
.alert.alert-white {
  background-color: #ffffff;
  border-color: #eeeeee;
  color: #333333;
}
.alert.alert-white hr {
  border-top-color: #e1e1e1;
}
.alert.alert-white .alert-link {
  color: #1a1a1a;
}
.alert.alert-black {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}
.alert.alert-black hr {
  border-top-color: #000000;
}
.alert.alert-black .alert-link {
  color: #e6e6e6;
}
.alert.alert-black .close {
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.alert.alert-black .close span {
  color: #FFF;
}
.alert.alert-black .close:hover {
  zoom: 1;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);
}
.progress {
  height: 6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.progress.progress-bar-purple > .progress-bar {
  background-color: #7c38bc;
}
.progress-striped .progress.progress-bar-purple > .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress.progress-bar-blue > .progress-bar {
  background-color: #0e62c7;
}
.progress-striped .progress.progress-bar-blue > .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress.progress-bar-red > .progress-bar {
  background-color: #d5080f;
}
.progress-striped .progress.progress-bar-red > .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress.progress-bar-black > .progress-bar {
  background-color: #222222;
}
.progress-striped .progress.progress-bar-black > .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress.progress-bar-gray > .progress-bar {
  background-color: #cccccc;
}
.progress-striped .progress.progress-bar-gray > .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress .progress-bar {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.progress .progress-bar.progress-bar-purple {
  background-color: #7c38bc;
}
.progress-striped .progress .progress-bar.progress-bar-purple {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress .progress-bar.progress-bar-blue {
  background-color: #0e62c7;
}
.progress-striped .progress .progress-bar.progress-bar-blue {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress .progress-bar.progress-bar-red {
  background-color: #d5080f;
}
.progress-striped .progress .progress-bar.progress-bar-red {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress .progress-bar.progress-bar-black {
  background-color: #222222;
}
.progress-striped .progress .progress-bar.progress-bar-black {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress .progress-bar.progress-bar-gray {
  background-color: #cccccc;
}
.progress-striped .progress .progress-bar.progress-bar-gray {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list-group .list-group-item {
  padding: 15px 20px;
}
.list-group .list-group-item .list-group-item-text {
  margin-top: 10px;
}
.list-group.list-group-minimal .list-group-item {
  padding: 10px 15px;
}
.badge-roundless {
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
}
mark,
.mark {
  background-color: rgba(255, 186, 0, 0.5);
  color: #666;
  padding-left: 5px;
  padding-right: 5px;
}
.text-muted {
  color: #9d9d9d;
}
.text-primary {
  color: #2c2e2f;
}
a.text-primary:hover {
  color: #131415;
}
.text-secondary {
  color: #68b828;
}
a.text-secondary:hover {
  color: #508e1f;
}
.text-purple {
  color: #7c38bc;
}
a.text-purple:hover {
  color: #622c95;
}
.text-turquoise {
  color: #00b19d;
}
a.text-turquoise:hover {
  color: #007e70;
}
.text-red {
  color: #d5080f;
}
a.text-red:hover {
  color: #a4060c;
}
.text-blue {
  color: #0e62c7;
}
a.text-blue:hover {
  color: #0b4b97;
}
.text-pink {
  color: #ff6264;
}
a.text-pink:hover {
  color: #ff2f32;
}
.text-yellow {
  color: #fcd036;
}
a.text-yellow:hover {
  color: #fbc404;
}
.text-gray {
  color: #555555;
}
a.text-gray:hover {
  color: #3b3b3b;
}
.text-success {
  color: #8dc63f;
}
a.text-success:hover {
  color: #72a230;
}
.text-info {
  color: #40bbea;
}
a.text-info:hover {
  color: #18a8df;
}
.text-warning {
  color: #ffba00;
}
a.text-warning:hover {
  color: #cc9500;
}
.text-danger {
  color: #cc3f44;
}
a.text-danger:hover {
  color: #ab2d32;
}
.text-white {
  color: #ffffff;
}
a.text-white:hover {
  color: #e6e6e6;
}
.bg-muted,
.bg-gray,
.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger {
  padding: 10px 15px;
  color: #fff;
}
.bg-muted {
  background-color: #d0d0d0;
}
.bg-gray {
  background-color: #eeeeee;
}
pre {
  white-space: pre-wrap;
  font-size: 12px;
}
.page-body .jumbotron {
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 48px;
  margin-bottom: 20px;
}
.page-body .jumbotron > h1 {
  margin-top: 0;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .page-body .jumbotron {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .page-body .jumbotron {
    padding-top: 15px;
    padding-bottom: 48px;
  }
}
div.datepicker {
  padding: 4px;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
  direction: ltr;
  /*.dow {
		border-top: 1px solid #ddd !important;
	}*/
}
div.datepicker-inline {
  width: 206px;
}
div.datepickerdiv.datepicker-rtl {
  direction: rtl;
}
div.datepickerdiv.datepicker-rtl table tr td span {
  float: right;
}
div.datepicker-dropdown {
  top: 0;
  left: 0;
}
div.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #e4e4e4;
  border-top: 0;
  border-bottom-color: #e4e4e4;
  position: absolute;
}
div.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-top: 0;
  position: absolute;
}
div.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
div.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
div.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
div.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
div.datepicker-dropdown.datepicker-orient-top:before {
  top: -7px;
}
div.datepicker-dropdown.datepicker-orient-top:after {
  top: -6px;
}
div.datepicker-dropdown.datepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #e4e4e4;
}
div.datepicker-dropdown.datepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #ffffff;
}
div.datepicker > div {
  display: none;
}
div.datepicker.days div.datepicker-days {
  display: block;
}
div.datepicker.months div.datepicker-months {
  display: block;
}
div.datepicker.years div.datepicker-years {
  display: block;
}
div.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.datepicker td,
div.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
  border: none;
}
.table-striped div.datepicker table tr td,
.table-striped div.datepicker table tr th {
  background-color: transparent;
}
div.datepicker table tr td.day {
  color: #646565;
}
div.datepicker table tr td.day:hover {
  background: #f5f5f5;
  cursor: pointer;
  color: #2c2e2f;
}
div.datepicker table tr td.old,
div.datepicker table tr td.new {
  color: #b1b1b1;
}
div.datepicker table tr td.disabled,
div.datepicker table tr td.disabled:hover {
  background: none;
  color: #777777;
  color: #979898;
  cursor: default;
}
div.datepicker table tr td.today,
div.datepicker table tr td.today:hover,
div.datepicker table tr td.today.disabled,
div.datepicker table tr td.today.disabled:hover {
  *background-color: #76797e;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  color: #000;
}
div.datepicker table tr td.today.switch-left,
div.datepicker table tr td.today:hover.switch-left,
div.datepicker table tr td.today.disabled.switch-left,
div.datepicker table tr td.today.disabled:hover.switch-left {
  background-color: #76797e;
  color: #ffffff;
}
div.datepicker table tr td.today.switch-right,
div.datepicker table tr td.today:hover.switch-right,
div.datepicker table tr td.today.disabled.switch-right,
div.datepicker table tr td.today.disabled:hover.switch-right {
  background-color: #ffffff;
  color: #979898;
}
div.datepicker table tr td.today.switch-right:hover,
div.datepicker table tr td.today:hover.switch-right:hover,
div.datepicker table tr td.today.disabled.switch-right:hover,
div.datepicker table tr td.today.disabled:hover.switch-right:hover {
  background-color: #ffffff;
  color: #979898;
}
div.datepicker table tr td.today:hover,
div.datepicker table tr td.today:hover:hover,
div.datepicker table tr td.today.disabled:hover,
div.datepicker table tr td.today.disabled:hover:hover,
div.datepicker table tr td.today:focus,
div.datepicker table tr td.today:hover:focus,
div.datepicker table tr td.today.disabled:focus,
div.datepicker table tr td.today.disabled:hover:focus,
div.datepicker table tr td.today:active,
div.datepicker table tr td.today:hover:active,
div.datepicker table tr td.today.disabled:active,
div.datepicker table tr td.today.disabled:hover:active,
div.datepicker table tr td.today.active,
div.datepicker table tr td.today:hover.active,
div.datepicker table tr td.today.disabled.active,
div.datepicker table tr td.today.disabled:hover.active,
div.datepicker table tr td.today.disabled,
div.datepicker table tr td.today:hover.disabled,
div.datepicker table tr td.today.disabled.disabled,
div.datepicker table tr td.today.disabled:hover.disabled,
div.datepicker table tr td.today[disabled],
div.datepicker table tr td.today:hover[disabled],
div.datepicker table tr td.today.disabled[disabled],
div.datepicker table tr td.today.disabled:hover[disabled] {
  color: #ffffff;
  *background-color: #6a6c71;
}
div.datepicker table tr td.today:active,
div.datepicker table tr td.today:hover:active,
div.datepicker table tr td.today.disabled:active,
div.datepicker table tr td.today.disabled:hover:active,
div.datepicker table tr td.today.active,
div.datepicker table tr td.today:hover.active,
div.datepicker table tr td.today.disabled.active,
div.datepicker table tr td.today.disabled:hover.active {
  background-color: #5d5f64 \9;
}
div.datepicker table tr td.today:hover:hover {
  color: #000;
}
div.datepicker table tr td.today.active:hover {
  color: #fff;
}
div.datepicker table tr td.range,
div.datepicker table tr td.range:hover,
div.datepicker table tr td.range.disabled,
div.datepicker table tr td.range.disabled:hover {
  background: #eeeeee;
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
}
div.datepicker table tr td.range.today,
div.datepicker table tr td.range.today:hover,
div.datepicker table tr td.range.today.disabled,
div.datepicker table tr td.range.today.disabled:hover {
  *background-color: #eff777;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
}
div.datepicker table tr td.range.today.switch-left,
div.datepicker table tr td.range.today:hover.switch-left,
div.datepicker table tr td.range.today.disabled.switch-left,
div.datepicker table tr td.range.today.disabled:hover.switch-left {
  background-color: #eff777;
  color: #ffffff;
}
div.datepicker table tr td.range.today.switch-right,
div.datepicker table tr td.range.today:hover.switch-right,
div.datepicker table tr td.range.today.disabled.switch-right,
div.datepicker table tr td.range.today.disabled:hover.switch-right {
  background-color: #ffffff;
  color: #979898;
}
div.datepicker table tr td.range.today.switch-right:hover,
div.datepicker table tr td.range.today:hover.switch-right:hover,
div.datepicker table tr td.range.today.disabled.switch-right:hover,
div.datepicker table tr td.range.today.disabled:hover.switch-right:hover {
  background-color: #ffffff;
  color: #979898;
}
div.datepicker table tr td.range.today:hover,
div.datepicker table tr td.range.today:hover:hover,
div.datepicker table tr td.range.today.disabled:hover,
div.datepicker table tr td.range.today.disabled:hover:hover,
div.datepicker table tr td.range.today:focus,
div.datepicker table tr td.range.today:hover:focus,
div.datepicker table tr td.range.today.disabled:focus,
div.datepicker table tr td.range.today.disabled:hover:focus,
div.datepicker table tr td.range.today:active,
div.datepicker table tr td.range.today:hover:active,
div.datepicker table tr td.range.today.disabled:active,
div.datepicker table tr td.range.today.disabled:hover:active,
div.datepicker table tr td.range.today.active,
div.datepicker table tr td.range.today:hover.active,
div.datepicker table tr td.range.today.disabled.active,
div.datepicker table tr td.range.today.disabled:hover.active,
div.datepicker table tr td.range.today.disabled,
div.datepicker table tr td.range.today:hover.disabled,
div.datepicker table tr td.range.today.disabled.disabled,
div.datepicker table tr td.range.today.disabled:hover.disabled,
div.datepicker table tr td.range.today[disabled],
div.datepicker table tr td.range.today:hover[disabled],
div.datepicker table tr td.range.today.disabled[disabled],
div.datepicker table tr td.range.today.disabled:hover[disabled] {
  color: #ffffff;
  *background-color: #ecf55f;
}
div.datepicker table tr td.range.today:active,
div.datepicker table tr td.range.today:hover:active,
div.datepicker table tr td.range.today.disabled:active,
div.datepicker table tr td.range.today.disabled:hover:active,
div.datepicker table tr td.range.today.active,
div.datepicker table tr td.range.today:hover.active,
div.datepicker table tr td.range.today.disabled.active,
div.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #e9f447 \9;
}
div.datepicker table tr td.selected,
div.datepicker table tr td.selected:hover,
div.datepicker table tr td.selected.disabled,
div.datepicker table tr td.selected.disabled:hover {
  *background-color: #5e5e5e;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
div.datepicker table tr td.selected.switch-left,
div.datepicker table tr td.selected:hover.switch-left,
div.datepicker table tr td.selected.disabled.switch-left,
div.datepicker table tr td.selected.disabled:hover.switch-left {
  background-color: #5e5e5e;
  color: #ffffff;
}
div.datepicker table tr td.selected.switch-right,
div.datepicker table tr td.selected:hover.switch-right,
div.datepicker table tr td.selected.disabled.switch-right,
div.datepicker table tr td.selected.disabled:hover.switch-right {
  background-color: #ffffff;
  color: #979898;
}
div.datepicker table tr td.selected.switch-right:hover,
div.datepicker table tr td.selected:hover.switch-right:hover,
div.datepicker table tr td.selected.disabled.switch-right:hover,
div.datepicker table tr td.selected.disabled:hover.switch-right:hover {
  background-color: #ffffff;
  color: #979898;
}
div.datepicker table tr td.selected:hover,
div.datepicker table tr td.selected:hover:hover,
div.datepicker table tr td.selected.disabled:hover,
div.datepicker table tr td.selected.disabled:hover:hover,
div.datepicker table tr td.selected:focus,
div.datepicker table tr td.selected:hover:focus,
div.datepicker table tr td.selected.disabled:focus,
div.datepicker table tr td.selected.disabled:hover:focus,
div.datepicker table tr td.selected:active,
div.datepicker table tr td.selected:hover:active,
div.datepicker table tr td.selected.disabled:active,
div.datepicker table tr td.selected.disabled:hover:active,
div.datepicker table tr td.selected.active,
div.datepicker table tr td.selected:hover.active,
div.datepicker table tr td.selected.disabled.active,
div.datepicker table tr td.selected.disabled:hover.active,
div.datepicker table tr td.selected.disabled,
div.datepicker table tr td.selected:hover.disabled,
div.datepicker table tr td.selected.disabled.disabled,
div.datepicker table tr td.selected.disabled:hover.disabled,
div.datepicker table tr td.selected[disabled],
div.datepicker table tr td.selected:hover[disabled],
div.datepicker table tr td.selected.disabled[disabled],
div.datepicker table tr td.selected.disabled:hover[disabled] {
  color: #ffffff;
  *background-color: #515151;
}
div.datepicker table tr td.selected:active,
div.datepicker table tr td.selected:hover:active,
div.datepicker table tr td.selected.disabled:active,
div.datepicker table tr td.selected.disabled:hover:active,
div.datepicker table tr td.selected.active,
div.datepicker table tr td.selected:hover.active,
div.datepicker table tr td.selected.disabled.active,
div.datepicker table tr td.selected.disabled:hover.active {
  background-color: #444444 \9;
}
div.datepicker table tr td.active,
div.datepicker table tr td.active:hover,
div.datepicker table tr td.active.disabled,
div.datepicker table tr td.active.disabled:hover {
  background: #68b828;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
div.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
div.datepicker table tr td span:hover {
  background: #f5f5f5;
}
div.datepicker table tr td span.disabled,
div.datepicker table tr td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}
div.datepicker table tr td span.active,
div.datepicker table tr td span.active:hover,
div.datepicker table tr td span.active.disabled,
div.datepicker table tr td span.active.disabled:hover {
  background: #68b828;
  color: #fff;
}
div.datepicker table tr td span.old,
div.datepicker table tr td span.new {
  color: #b1b1b1;
}
div.datepicker th.datepicker-switch {
  width: 145px;
}
div.datepicker thead tr:first-child th,
div.datepicker tfoot tr th {
  cursor: pointer;
}
div.datepicker thead tr:first-child th.datepicker-switch,
div.datepicker tfoot tr th.datepicker-switch {
  color: #2c2e2f;
}
div.datepicker thead tr:first-child th:hover,
div.datepicker tfoot tr th:hover {
  background: #f5f5f5;
}
div.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
div.datepicker thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  -webkit-border-radius: 0px 0 0 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px 0 0 0px;
  -moz-background-clip: padding;
  border-radius: 0px 0 0 0px;
  background-clip: padding-box;
}
.input-daterange input:last-child {
  -webkit-border-radius: 0 0px 0px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0px 0px 0;
  -moz-background-clip: padding;
  border-radius: 0 0px 0px 0;
  background-clip: padding-box;
}
.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 1.42857143;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  vertical-align: middle;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}
.datepicker-inline {
  border: 1px solid #e4e4e4;
}
.datepicker-inline.no-border {
  border-color: transparent;
}
.datepicker-inline table {
  width: 195px;
}
.no-border .datepicker-inline {
  border: 0;
  padding: 0;
}
.input-group-addon .color-preview {
  width: 16px;
  height: 16px;
  background: #FFF;
  display: inline-block;
  border: 1px solid #e4e4e4;
}
.dropdown-menu.daterangepicker {
  background: #fafafa;
  border-color: #e4e4e4;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.dropdown-menu.daterangepicker:has(.ranges) .calendar {
  display: none;
}
.dropdown-menu.daterangepicker.opensright:before {
  border-right: 7px solid transparent;
  border-bottom: 7px solid #e4e4e4;
  border-left: 7px solid transparent;
  border-bottom-color: #e4e4e4;
}
.dropdown-menu.daterangepicker.opensright:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fafafa;
  border-left: 6px solid transparent;
}
.dropdown-menu.daterangepicker .calendar-time {
  background: #fff;
  border: 1px solid #e4e4e4;
  border-color: #e4e4e4;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.dropdown-menu.daterangepicker .no-border-radius {
  border-color: #e4e4e4;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.dropdown-menu.daterangepicker .range_inputs input {
  color: #979898;
  outline: none;
}
.dropdown-menu.daterangepicker .calendar-date,
.dropdown-menu.daterangepicker .range_inputs input,
.dropdown-menu.daterangepicker table > thead > tr > th,
.dropdown-menu.daterangepicker table > thead > tr > td,
.dropdown-menu.daterangepicker table > tbody > tr > th,
.dropdown-menu.daterangepicker table > tbody > tr > td,
.dropdown-menu.daterangepicker table > tbody > tr > td.start-date.end-date {
  border-color: #e4e4e4;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.dropdown-menu.daterangepicker .calendar-date table > thead th {
  color: #979898;
}
.dropdown-menu.daterangepicker .calendar-date table > thead .month {
  color: #2c2e2f;
}
.dropdown-menu.daterangepicker .calendar-date table > thead .next i:before,
.dropdown-menu.daterangepicker .calendar-date table > thead .prev i:before {
  font-family: 'fontawesome';
  content: '\f105';
  font-size: 11px;
}
.dropdown-menu.daterangepicker .calendar-date table > thead .next.prev i:before,
.dropdown-menu.daterangepicker .calendar-date table > thead .prev.prev i:before {
  content: '\f104';
}
.dropdown-menu.daterangepicker .calendar-date table > thead .next:hover,
.dropdown-menu.daterangepicker .calendar-date table > thead .prev:hover {
  background-color: #f5f5f5;
}
.dropdown-menu.daterangepicker .calendar-date table > tbody > tr > td {
  color: #979898;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dropdown-menu.daterangepicker .calendar-date table > tbody > tr > td:hover {
  background-color: #f5f5f5;
}
.dropdown-menu.daterangepicker .calendar-date table > tbody > tr > td.available {
  color: #7d7f7f;
}
.dropdown-menu.daterangepicker .calendar-date table > tbody > tr > td.off {
  color: #bdbebe;
}
.dropdown-menu.daterangepicker .calendar-date table > tbody > tr > td.disabled {
  background-color: #fafafa;
  color: #bdbebe;
  cursor: not-allowed;
}
.dropdown-menu.daterangepicker .calendar-date table > tbody > tr > td.in-range {
  background-color: rgba(104, 184, 40, 0.1);
}
.dropdown-menu.daterangepicker .calendar-date table > tbody > tr > td.active {
  background-color: #68b828;
  color: #fff;
}
.dropdown-menu.daterangepicker .btn-default {
  border-color: #e4e4e4;
}
.dropdown-menu.daterangepicker .ranges ul li {
  background: #fff;
  border-color: #e4e4e4;
  color: #979898;
  padding: 4px 8px;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.dropdown-menu.daterangepicker .ranges ul li:hover {
  background: #f5f5f5;
}
.dropdown-menu.daterangepicker .ranges ul li.active {
  color: #fff;
  background: #68b828;
  border-color: #68b828;
}
.daterange-inline {
  background: #f5f5f5;
  border: 1px solid #e4e4e4;
  padding: 10px;
  cursor: pointer;
}
.daterange-inline i {
  color: #2c2e2f;
  margin-right: 5px;
}
.daterange-inline:hover {
  color: #2c2e2f;
}
/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
  position: relative;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}
.bootstrap-timepicker .add-on {
  cursor: pointer;
}
.bootstrap-timepicker .add-on i {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.bootstrap-timepicker-widget.dropdown-menu {
  padding: 0px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid #e4e4e4;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #FFFFFF;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #e4e4e4;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #ffffff;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
  border-radius: 0px;
}
.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
  width: 100%;
}
.bootstrap-timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 0;
  outline: 0;
  color: #979898;
}
.bootstrap-timepicker-widget table td a .icon-chevron-up:before {
  font-family: 'fontawesome';
  content: '\f0d8';
  font-style: normal;
  color: #575858;
}
.bootstrap-timepicker-widget table td a .icon-chevron-down:before {
  font-family: 'fontawesome';
  content: '\f0d7';
  font-style: normal;
  color: #575858;
}
.bootstrap-timepicker-widget table td a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
  border: 0;
  background: none;
  padding: 0;
  height: auto;
}
.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}
@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}
@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
  }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}
.date-and-time .form-control {
  float: left;
}
.date-and-time .form-control + .form-control {
  border-left: 0;
}
.date-and-time .form-control.datepicker {
  width: 65%;
}
.date-and-time .form-control.timepicker {
  width: 35%;
}
/*!
 * Bootstrap Colorpicker
 * http://mjolnic.github.io/bootstrap-colorpicker/
 *
 * Originally written by (c) 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0.txt
 *
 */
.colorpicker-saturation {
  width: 100px;
  height: 100px;
  background-image: url("../js/colorpicker/images/saturation.png");
  cursor: crosshair;
  float: left;
}
.colorpicker-saturation i {
  display: block;
  height: 5px;
  width: 5px;
  border: 1px solid #000;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  position: absolute;
  top: 0;
  left: 0;
  margin: -4px 0 0 -4px;
}
.colorpicker-saturation i b {
  display: block;
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.colorpicker-hue,
.colorpicker-alpha {
  width: 15px;
  height: 100px;
  float: left;
  cursor: row-resize;
  margin-left: 4px;
  margin-bottom: 4px;
}
.colorpicker-hue i,
.colorpicker-alpha i {
  display: block;
  height: 1px;
  background: #000;
  border-top: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: -1px;
}
.colorpicker-hue {
  background-image: url("../js/colorpicker/images/hue.png");
}
.colorpicker-alpha {
  background-image: url("../js/colorpicker/images/alpha.png");
  display: none;
}
div.colorpicker {
  *zoom: 1;
  top: 0;
  left: 0;
  padding: 4px;
  min-width: 130px;
  margin-top: 1px;
  -moz-border-radius: -1px;
  -webkit-border-radius: -1px;
  border-radius: -1px;
  z-index: 2500;
}
.colorpicker:before,
.colorpicker:after {
  display: table;
  content: "";
  line-height: 0;
}
.colorpicker:after {
  clear: both;
}
.colorpicker:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #e4e4e4;
  border-bottom-color: #dfdfdf;
  position: absolute;
  top: -7px;
  left: 6px;
}
.colorpicker:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 7px;
}
.colorpicker div {
  position: relative;
}
.colorpicker.colorpicker-with-alpha {
  min-width: 140px;
}
.colorpicker.colorpicker-with-alpha .colorpicker-alpha {
  display: block;
}
.colorpicker-color {
  height: 10px;
  margin-top: 5px;
  clear: both;
  background-image: url("../js/colorpicker/images/alpha.png");
  background-position: 0 100%;
}
.colorpicker-color div {
  height: 10px;
}
.colorpicker-element .input-group-addon i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.colorpicker.colorpicker-inline {
  position: relative;
  display: inline-block;
  float: none;
}
.colorpicker.colorpicker-horizontal {
  width: 110px;
  min-width: 110px;
  height: auto;
}
.colorpicker.colorpicker-horizontal .colorpicker-saturation {
  margin-bottom: 4px;
}
.colorpicker.colorpicker-horizontal .colorpicker-color {
  width: 100px;
}
.colorpicker.colorpicker-horizontal .colorpicker-hue,
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  width: 100px;
  height: 15px;
  float: left;
  cursor: col-resize;
  margin-left: 0px;
  margin-bottom: 4px;
}
.colorpicker.colorpicker-horizontal .colorpicker-hue i,
.colorpicker.colorpicker-horizontal .colorpicker-alpha i {
  display: block;
  height: 15px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  border: none;
  margin-top: 0px;
}
.colorpicker.colorpicker-horizontal .colorpicker-hue {
  background-image: url("../js/colorpicker/images/hue-horizontal.png");
}
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  background-image: url("../js/colorpicker/images/alpha-horizontal.png");
}
.colorpicker.colorpicker-hidden {
  display: none;
}
.colorpicker.colorpicker-visible {
  display: block;
}
.colorpicker-inline.colorpicker-visible {
  display: inline-block;
}
html .select2-container {
  width: 100%;
}
html .select2-container .select2-choice {
  background: #fff  !important;
  border-color: #e4e4e4 !important;
  padding: 0;
  height: 35px;
  -webkit-border-radius: 0px !important;
  -webkit-background-clip: padding-box !important;
  -moz-border-radius: 0px !important;
  -moz-background-clip: padding !important;
  border-radius: 0px !important;
  background-clip: padding-box !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
html .select2-container .select2-choice .select2-chosen,
html .select2-container .select2-choice .select2-search-choice-close,
html .select2-container .select2-choice .select2-arrow {
  padding: 0;
  line-height: 35px;
}
html .select2-container .select2-choice .select2-chosen {
  margin: 0;
  padding-left: 15px;
  padding-right: 10px;
  margin-right: 35px;
}
html .select2-container .select2-choice .select2-search-choice-close {
  margin-right: 20px;
  top: 11px;
}
html .select2-container .select2-choice .select2-arrow {
  background: #f5f5f5;
  border-left-color: #e4e4e4;
  text-align: center;
  color: #717272;
  width: 35px;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
html .select2-container .select2-choice .select2-arrow b {
  background: none !important;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
html .select2-container .select2-choice .select2-arrow b:before {
  position: relative;
  display: block;
  font-family: 'FontAwesome';
  content: '\f107';
}
html .select2-container.select2-dropdown-open {
  border-color: #e4e4e4 !important;
  -webkit-border-radius: 0px !important;
  -webkit-background-clip: padding-box !important;
  -moz-border-radius: 0px !important;
  -moz-background-clip: padding !important;
  border-radius: 0px !important;
  background-clip: padding-box !important;
}
html .select2-container.select2-dropdown-open .select2-arrow {
  border-left: 1px solid #e4e4e4 !important;
}
html .select2-container.select2-dropdown-open .select2-arrow b {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
html .select2-container.select2-container-multi .select2-choices {
  border: 1px solid #e4e4e4;
  background: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
html .select2-container.select2-container-multi .select2-choices .select2-search-choice {
  background: #f5f5f5;
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
html .select2-container.select2-container-multi .select2-choices .select2-search-choice div {
  color: #7d7f7f;
}
html .select2-drop {
  border-top: 1px solid #ffffff;
  -webkit-border-radius: 0px !important;
  -webkit-background-clip: padding-box !important;
  -moz-border-radius: 0px !important;
  -moz-background-clip: padding !important;
  border-radius: 0px !important;
  background-clip: padding-box !important;
}
html .select2-drop.select2-drop-above {
  border: 1px solid #e4e4e4 !important;
  border-top: 1px solid #e4e4e4 !important;
}
html .select2-drop.select2-drop-above .select2-search input {
  margin-top: 0;
}
html .select2-drop.select2-drop-active {
  border-color: #e4e4e4;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
html .select2-drop .select2-search {
  padding: 0;
  position: relative;
}
html .select2-drop .select2-search:after {
  position: absolute;
  content: '\e801';
  font-family: 'linecons';
  color: #979898;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px 15px;
  z-index: 2;
}
html .select2-drop .select2-input {
  border: 0;
  background: none;
  padding: 0;
  height: auto;
  padding: 10px 15px;
  padding-right: 45px;
  background-image: none !important;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  z-index: 1;
  background: #f5f5f5;
}
html .select2-drop .select2-results {
  padding: 0;
  margin: 0;
  max-height: 250px;
}
html .select2-drop .select2-results.overflow-hidden {
  overflow: hidden;
  position: relative;
}
html .select2-drop .select2-results li.select2-result-selectable .select2-result-label {
  color: #7d7f7f;
  padding-left: 15px;
}
html .select2-drop .select2-results li.select2-result-unselectable .select2-result-label {
  padding: 8px 15px;
  color: #7d7f7f;
}
html .select2-drop .select2-results li.select2-no-results,
html .select2-drop .select2-results li.select2-searching {
  padding: 10px 15px;
  color: #979898;
  background: #fff;
}
html .select2-drop .select2-results li ul .select2-result-label {
  padding-left: 30px !important;
}
html .select2-drop .select2-results li .select2-result-unselectable.select2-disabled {
  border: 0 !important;
  background: #f9f9f9;
}
html .select2-drop .select2-results li .select2-result-unselectable.select2-disabled .select2-result-label {
  cursor: not-allowed;
  color: #a4a5a5;
}
html .select2-drop .select2-results li .select2-result-selectable .select2-result-label {
  padding: 5px;
}
html .select2-drop .select2-results li .select2-result-selectable.select2-highlighted .select2-result-label {
  color: #fff;
}
html .select2-drop .select2-results li.select2-highlighted {
  background-color: #68b828;
}
html .select2-drop .select2-results li.select2-highlighted .select2-result-label {
  color: #fff;
}
/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */
/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/
/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: block;
  vertical-align: top;
}
.selectboxit-container .selectboxit-open .selectboxit-arrow-container {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  font: 14px Helvetica, Arial;
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap;
}
/* Button */
.selectboxit-container .selectboxit {
  width: 100% !important;
  /* Width of the dropdown button */
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 0px;
  overflow: hidden;
  display: block;
  position: relative;
}
/* Height and Vertical Alignment of Text */
.selectboxit-container span,
.selectboxit-container .selectboxit-options a {
  height: 35px;
  /* Height of the drop down */
  line-height: 35px;
  /* Vertically positions the drop down text */
  display: block;
}
.selectboxit-container .selectboxit-options a {
  height: 25px;
  line-height: 25px;
}
/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0;
}
/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled,
.selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}
/* Button Text */
.selectboxit-text {
  text-indent: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  max-width: 100% !important;
  color: #7d7f7f;
  font-size: 13px;
}
.selectboxit .selectboxit-option-icon-container {
  margin-left: 5px;
}
/* Options List */
.selectboxit-container .selectboxit-options {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 100%;
  /* Minimum Width of the dropdown list box options */
  *width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 9999999999999;
  border-radius: 0px;
  text-align: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
/* Individual options */
.selectboxit-option .selectboxit-option-anchor {
  padding: 0 10px;
}
/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none;
}
/* Individual Option Optgroup Header */
.selectboxit-option,
.selectboxit-optgroup-header {
  text-indent: 5px;
  /* Horizontal Positioning of the select box option text */
  margin: 0;
  list-style-type: none;
}
/* The first Drop Down option */
.selectboxit-option-first {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
/* The last Drop Down option */
.selectboxit-option-last {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold;
}
/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
  cursor: default;
}
/* Drop Down down arrow container */
.selectboxit-arrow-container {
  /* Positions the down arrow */
  width: 30px;
  position: absolute;
  right: 0;
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  /* Horizontally centers the down arrow */
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  left: 0;
}
/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
  top: 30%;
}
/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
  float: left;
}
.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left;
}
.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit;
}
/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px;
}
/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-btn {
  background-color: #fff;
  background-repeat: repeat-x;
  border: 1px solid #e4e4e4;
}
.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:active {
  color: #2c2e2f;
}
.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus {
  color: #2c2e2f;
  text-decoration: none;
  background-position: 0 -15px;
}
.selectboxit-default-arrow {
  width: 0;
  height: 0;
}
.selectboxit-default-arrow:before {
  display: block;
  content: '\f107';
  font-family: 'FontAwesome';
}
.selectboxit-list {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-height: 250px;
}
.selectboxit-list.ps-container {
  overflow: hidden;
}
.selectboxit-list .selectboxit-option-anchor {
  color: #717272;
  font-size: 13px;
}
.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: #ffffff;
  background-color: #68b828;
  background-repeat: repeat-x;
}
.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
}
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  display: block;
  padding: 4px 6px;
  color: #7d7f7f;
  vertical-align: middle;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: auto !important;
  max-width: inherit;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: white;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
span.twitter-typeahead {
  width: 100%;
}
span.twitter-typeahead .tt-dropdown-menu {
  background: #fff;
  border: 1px solid #e4e4e4;
  width: 100%;
  margin-top: -1px;
  max-height: 250px;
  overflow: auto;
}
span.twitter-typeahead .tt-suggestion.tt-cursor {
  background-color: #fafafa;
}
span.twitter-typeahead .tt-hint {
  color: #cacbcb;
}
span.twitter-typeahead .empty-message {
  padding: 5px 13px;
  color: #bdbebe;
}
span.twitter-typeahead .tt-suggestion > p {
  padding: 5px 13px;
}
span.twitter-typeahead .tt-suggestion > p .tt-highlight {
  color: #7d7f7f;
}
span.twitter-typeahead .tt-suggestion > p:hover,
span.twitter-typeahead .tt-suggestion > p:focus {
  background: #fafafa;
  cursor: pointer;
}
.input-group span.twitter-typeahead {
  display: block !important;
}
.input-group span.twitter-typeahead .tt-dropdown-menu {
  top: 32px !important;
}
.input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
  top: 44px !important;
}
.input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
  top: 28px !important;
}
.ms-container {
  -webkit-background-size: 21px !important;
  -moz-background-size: 21px !important;
  background-size: 21px !important;
}
@media screen and (max-width: 768px) {
  .ms-container {
    width: 100% !important;
  }
}
.ms-container > div > .ms-list {
  border-color: #e4e4e4 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  /* If you dont use perfect scrollbar then remove this line */
}
.ms-container > div > .ms-list:active {
  border-color: #cbcbcb !important;
}
.ms-container > div > .ms-list li {
  display: block;
  width: 100%;
}
.ms-container > div > .ms-list li span {
  font-size: 13px;
}
.ms-container > div > .ms-list li.ms-hover {
  background-color: #68b828;
}
.icheck-list {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.icheck-list > li {
  display: block;
  margin-bottom: 10px;
}
.icheck-list > li:last-child {
  margin-bottom: 0;
}
.icheck-list > li div + label {
  position: relative;
  top: 1px;
  margin-left: 5px;
}
.icheck-skins {
  display: block;
  margin-top: 10px;
}
.icheck-skins a {
  display: inline-block;
  border: 1px solid transparent;
  background: #333;
  -webkit-transition: all 450ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -moz-transition: all 450ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -o-transition: all 450ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition: all 450ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.icheck-skins a[data-color-class="red"] {
  background-color: #d54e21;
}
.icheck-skins a[data-color-class="green"] {
  background-color: #1b7e5a;
}
.icheck-skins a[data-color-class="blue"] {
  background-color: #2489c5;
}
.icheck-skins a[data-color-class="aero"] {
  background-color: #9cc2cb;
}
.icheck-skins a[data-color-class="grey"] {
  background-color: #73716e;
}
.icheck-skins a[data-color-class="orange"] {
  background-color: #f70;
}
.icheck-skins a[data-color-class="yellow"] {
  background-color: #fc3;
}
.icheck-skins a[data-color-class="pink"] {
  background-color: #a77a94;
}
.icheck-skins a[data-color-class="purple"] {
  background-color: #6a5a8c;
}
.icheck-skins a.current {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -o-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
}
.form-wizard > .tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: table;
  position: relative;
  width: 100%;
}
.form-wizard > .tabs > li {
  display: table-cell;
  width: 1%;
  text-align: center;
  background: #fcfcfc;
}
.form-wizard > .tabs > li a {
  position: relative;
  display: block;
  padding: 10px 5px;
  color: #979898;
}
.form-wizard > .tabs > li a > span {
  background: #68b828;
  font-size: 12px;
  color: #fff;
  background: #e9e9e9;
  color: #979898;
  padding: 2px 0;
  text-align: center;
  display: block;
  position: relative;
  margin: -10px -5px;
  margin-top: 10px;
}
.form-wizard > .tabs > li a:after {
  content: '';
  position: absolute;
  display: block;
  height: 10px;
  background: transparent;
  top: 100%;
  left: 0;
  right: 0;
}
.form-wizard > .tabs > li a:hover {
  background: #f7f7f7;
}
.form-wizard > .tabs > li + li {
  border-left: 1px solid #eeeeee;
}
.form-wizard > .tabs > li.active a {
  color: #646565;
}
.form-wizard > .tabs > li.active a:after {
  background-color: rgba(0, 0, 0, 0.1);
}
.form-wizard > .tabs > li.completed {
  background-color: #f7f7f7;
}
.form-wizard > .progress-indicator {
  height: 10px;
  position: relative;
  background: transparent;
  width: 0%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: height 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000), width 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  -moz-transition: height 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000), width 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  -o-transition: height 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000), width 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  transition: height 250ms cubic-bezier(0.190, 1.000, 0.220, 1.000), width 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
.form-wizard > .progress-indicator > span {
  display: block;
  background: #8dc63f;
  height: 10px;
  line-height: 1;
  text-indent: -9999px;
}
.form-wizard .tab-content {
  margin-top: 20px;
}
.form-wizard .tab-content strong {
  color: #7d7f7f;
}
.form-wizard .tab-content label {
  color: #979898;
}
.form-wizard .tab-content .tab-pane > .form-group {
  margin-bottom: 25px;
}
.form-wizard .tab-content .tab-pane > .form-group:last-child {
  margin-bottom: 0;
}
.form-wizard .tab-content.no-margin {
  margin-top: 0;
}
.form-wizard .tab-content.no-margin .tab-pane.with-bg {
  padding-top: 15px;
}
.form-wizard .tab-content .tab-pane.with-bg {
  background: #fff;
  padding: 20px;
}
.form-wizard .pager {
  margin-top: 20px;
}
.form-wizard .pager .previous + .previous a {
  margin-left: 5px;
}
.form-wizard .pager .next + .next a {
  margin-right: 5px;
}
.form-wizard .pager .disabled a {
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
html body .ui-rangeSlider .ui-rangeSlider-container {
  height: 10px;
  background: #ebebeb;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
html body .ui-rangeSlider .ui-rangeSlider-container .ui-rangeSlider-bar {
  background: #68b828;
  height: 10px;
}
html body .ui-rangeSlider .ui-rangeSlider-container .ui-rangeSlider-handle {
  position: relative;
  background: #68b828;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
  border: 0px solid #FFF;
  padding: 8px;
  margin-top: -7px;
}
html body .ui-rangeSlider .ui-rangeSlider-container .ui-rangeSlider-handle.ui-rangeSlider-leftHandle {
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -o-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
}
html body .ui-rangeSlider .ui-rangeSlider-label {
  visibility: hidden;
}
html body .slider {
  height: 8px;
  margin-bottom: 30px;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
  background: #ebebeb;
}
html body .slider .ui-slider-handle {
  background: #68b828;
  top: -3px;
  border: 0;
  width: 14px;
  height: 14px;
  margin-top: 0;
  outline: none;
  cursor: pointer !important;
}
html body .slider .ui-slider-handle .ui-label {
  position: absolute;
  left: -50%;
  top: 18px;
  color: #7d7f7f;
  margin-left: 4px;
  white-space: nowrap;
}
html body .slider .ui-slider-range {
  background: #68b828;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
html body .slider.ui-slider-horizontal .ui-slider-handle .ui-label {
  margin-top: 0px;
}
html body .slider.ui-slider-vertical {
  height: 250px;
  width: 8px;
}
html body .slider.ui-slider-vertical .ui-slider-handle {
  top: auto;
  margin-left: 0.5px;
}
html body .slider.ui-slider-vertical .ui-slider-handle .ui-label {
  top: -1px;
  left: 18px;
}
html body .slider .ui-slider-handle {
  background: #508e1f;
  border: 0;
}
html body .slider .ui-slider-range,
html body .slider .ui-fill {
  background: #68b828;
}
html body .slider.slider-primary {
  background: #ebebeb;
}
html body .slider.slider-primary .ui-slider-handle {
  background: #131415;
  border: 0;
}
html body .slider.slider-primary .ui-slider-range,
html body .slider.slider-primary .ui-fill {
  background: #2c2e2f;
}
html body .slider.slider-secondary {
  background: #ebebeb;
}
html body .slider.slider-secondary .ui-slider-handle {
  background: #508e1f;
  border: 0;
}
html body .slider.slider-secondary .ui-slider-range,
html body .slider.slider-secondary .ui-fill {
  background: #68b828;
}
html body .slider.slider-success {
  background: #ebebeb;
}
html body .slider.slider-success .ui-slider-handle {
  background: #72a230;
  border: 0;
}
html body .slider.slider-success .ui-slider-range,
html body .slider.slider-success .ui-fill {
  background: #8dc63f;
}
html body .slider.slider-info {
  background: #ebebeb;
}
html body .slider.slider-info .ui-slider-handle {
  background: #18a8df;
  border: 0;
}
html body .slider.slider-info .ui-slider-range,
html body .slider.slider-info .ui-fill {
  background: #40bbea;
}
html body .slider.slider-danger {
  background: #ebebeb;
}
html body .slider.slider-danger .ui-slider-handle {
  background: #ab2d32;
  border: 0;
}
html body .slider.slider-danger .ui-slider-range,
html body .slider.slider-danger .ui-fill {
  background: #cc3f44;
}
html body .slider.slider-warning {
  background: #ebebeb;
}
html body .slider.slider-warning .ui-slider-handle {
  background: #f7d227;
  border: 0;
}
html body .slider.slider-warning .ui-slider-range,
html body .slider.slider-warning .ui-fill {
  background: #f9dc58;
}
html body .slider.slider-purple {
  background: #ebebeb;
}
html body .slider.slider-purple .ui-slider-handle {
  background: #622c95;
  border: 0;
}
html body .slider.slider-purple .ui-slider-range,
html body .slider.slider-purple .ui-fill {
  background: #7c38bc;
}
html body .slider.slider-blue {
  background: #ebebeb;
}
html body .slider.slider-blue .ui-slider-handle {
  background: #0b4b97;
  border: 0;
}
html body .slider.slider-blue .ui-slider-range,
html body .slider.slider-blue .ui-fill {
  background: #0e62c7;
}
html body .slider.slider-red {
  background: #ebebeb;
}
html body .slider.slider-red .ui-slider-handle {
  background: #a4060c;
  border: 0;
}
html body .slider.slider-red .ui-slider-range,
html body .slider.slider-red .ui-fill {
  background: #d5080f;
}
html body .slider.slider-gray {
  background: #ebebeb;
}
html body .slider.slider-gray .ui-slider-handle {
  background: #959595;
  border: 0;
}
html body .slider.slider-gray .ui-slider-range,
html body .slider.slider-gray .ui-fill {
  background: #aeaeae;
}
.wysihtml5 {
  min-height: 250px;
}
.wysihtml5-toolbar .btn {
  margin-bottom: 0;
}
.wysihtml5-toolbar .btn.btn-white:active,
.wysihtml5-toolbar .btn.btn-white:focus,
.wysihtml5-toolbar .btn.btn-white.active {
  border-color: #d8d8d8 !important;
}
.wysihtml5-toolbar .btn.btn-white.wysihtml5-command-active {
  background: #eeeeee;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.wysihtml5-toolbar .btn.btn-white.dropdown-toggle i {
  position: relative;
  top: 1px;
}
.wysihtml5-toolbar li.html-code-icon {
  float: right;
}
.wysihtml5-toolbar .dropdown-menu {
  border-color: #e4e4e4;
}
.wysihtml5-toolbar a.wysihtml5-colors-title {
  padding-top: 3px !important;
  padding-left: 35px !important;
}
.wysihtml5-toolbar div.wysihtml5-colors {
  width: 25px !important;
}
.wysihtml5-sandbox {
  min-height: 250px;
  padding: 10px 15px !important;
}
.wysihtml5-sandbox::-webkit-scrollbar {
  width: 5px;
}
.wysihtml5-sandbox::-webkit-scrollbar-track {
  width: 5px;
  background-color: #e0e0e0;
}
.wysihtml5-sandbox::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
}
.modal .bootstrap-wysihtml5-insert-link-url {
  margin-bottom: 12px !important;
}
.modal .bootstrap-wysihtml5-insert-link-url + label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal .bootstrap-wysihtml5-insert-link-url + label input {
  top: 4px;
  margin-right: 10px !important;
}
html .dropzone {
  border: 1px solid #e9e9e9;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.droppable-area {
  display: inline-block;
  background: #ededed;
  border: 2px dashed #cbcbcb;
  width: 150px;
  min-height: 150px !important;
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 15px;
  color: #646565;
  margin-bottom: 30px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.droppable-area.dz-clickable {
  cursor: pointer;
}
.droppable-area.dz-drag-hover {
  background: #68b828;
  color: #fff;
  border-style: solid;
  border-color: #508e1f;
  border-width: 5px;
  line-height: 143px;
}
/*///////////////////////////////////////
// Code By Jordan Robert Dobson
//
// JordanDobson.com
// @jordandobson
// jordandobson@gmail.com
////////////////////////////////////////*/
input[type="checkbox"].iswitch {
  font-size: 10px;
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  margin: 0;
  padding: 0;
  box-shadow: inset 0 0 0 2px #eeeeee;
  outline: 1px solid transparent !important;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 4px;
  margin-bottom: 7px;
  transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
  transition-delay: .1s, 0s;
  /* Animations if supported */
}
input[type="checkbox"].iswitch:checked {
  transition-delay: 0s, 0s;
  box-shadow: inset 0 0 0 12.57142857px #eeeeee !important;
  padding-left: 16px;
}
input[type="checkbox"].iswitch.iswitch-primary:checked {
  box-shadow: inset 0 0 0 12.57142857px #2c2e2f !important;
}
input[type="checkbox"].iswitch.iswitch-secondary:checked {
  box-shadow: inset 0 0 0 12.57142857px #68b828 !important;
}
input[type="checkbox"].iswitch.iswitch-info:checked {
  box-shadow: inset 0 0 0 12.57142857px #40bbea !important;
}
input[type="checkbox"].iswitch.iswitch-warning:checked {
  box-shadow: inset 0 0 0 12.57142857px #ffba00 !important;
}
input[type="checkbox"].iswitch.iswitch-danger:checked {
  box-shadow: inset 0 0 0 12.57142857px #cc3f44 !important;
}
input[type="checkbox"].iswitch.iswitch-success:checked {
  box-shadow: inset 0 0 0 12.57142857px #8dc63f !important;
}
input[type="checkbox"].iswitch.iswitch-red:checked {
  box-shadow: inset 0 0 0 12.57142857px #d5080f !important;
}
input[type="checkbox"].iswitch.iswitch-blue:checked {
  box-shadow: inset 0 0 0 12.57142857px #0e62c7 !important;
}
input[type="checkbox"].iswitch.iswitch-purple:checked {
  box-shadow: inset 0 0 0 12.57142857px #7c38bc !important;
}
input[type="checkbox"].iswitch.iswitch-pink:checked {
  box-shadow: inset 0 0 0 12.57142857px #ff6264 !important;
}
input[type="checkbox"].iswitch.iswitch-turquoise:checked {
  box-shadow: inset 0 0 0 12.57142857px #00b19d !important;
}
input[type="checkbox"].iswitch.iswitch-yellow:checked {
  box-shadow: inset 0 0 0 12.57142857px #fcd036 !important;
}
input[type="checkbox"].iswitch.iswitch-orange:checked {
  box-shadow: inset 0 0 0 12.57142857px #f7aa47 !important;
}
input[type="checkbox"].iswitch.iswitch-gray:checked {
  box-shadow: inset 0 0 0 12.57142857px #d5d5d5 !important;
}
input[type="checkbox"].iswitch.hit {
  -webkit-animation: slide-off .35s ease both;
  -moz-animation: slide-off .35s ease both;
  -o-animation: slide-off .35s ease both;
  animation: slide-off .35s ease both;
}
input[type="checkbox"].iswitch.hit:checked {
  -webkit-animation: slide-on  .35s ease both .05s;
  -moz-animation: slide-on  .35s ease both .05s;
  -o-animation: slide-on  .35s ease both .05s;
  animation: slide-on  .35s ease both .05s;
}
input[type="checkbox"].iswitch:before,
input[type="checkbox"].iswitch:after {
  content: "";
}
input[type="checkbox"].iswitch:after {
  /* Increases hit area */
  position: absolute;
  top: -6px;
  left: -6px;
  bottom: -6px;
  right: -6px;
}
input[type="checkbox"].iswitch:before {
  display: inline-block;
  height: 18px;
  width: 18px;
  margin: 2px 0 0 2px;
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: all .1s ease .1s;
  -moz-transition: all .1s ease .1s;
  -o-transition: all .1s ease .1s;
  transition: all .1s ease .1s;
}
input[type="checkbox"].iswitch:active:before {
  transition: all .1s ease .05s;
}
input[type="checkbox"].iswitch.touch:hover:before {
  transition: all .0s ease .00s;
}
input[type="checkbox"].iswitch:active:before,
input[type="checkbox"].iswitch.touch:hover:before {
  width: 20px;
}
input[type="checkbox"].iswitch:active:checked:before,
input[type="checkbox"].iswitch.touch:hover:checked:before {
  margin-left: 0px;
}
@keyframes slide-on {
  0% {
    padding-left: 0px;
  }
  60% {
    padding-left: 16px;
  }
  100% {
    padding-left: 16px;
  }
}
@keyframes slide-off {
  0% {
    padding-left: 16px;
    text-indent: 0;
  }
  60% {
    padding-left: 0px;
    text-indent: 0px;
  }
  100% {
    padding-left: 0px;
    text-indent: 0;
  }
}
input[type="checkbox"].iswitch-lg {
  font-size: 10px;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  line-height: 28px;
  border-radius: 14px;
  margin: 0;
  padding: 0;
  box-shadow: inset 0 0 0 2px #eeeeee;
  outline: 1px solid transparent !important;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 4px;
  margin-bottom: 7px;
  transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
  transition-delay: .1s, 0s;
  /* Animations if supported */
}
input[type="checkbox"].iswitch-lg:checked {
  transition-delay: 0s, 0s;
  box-shadow: inset 0 0 0 16px #eeeeee !important;
  padding-left: 22px;
}
input[type="checkbox"].iswitch-lg.iswitch-primary:checked {
  box-shadow: inset 0 0 0 16px #2c2e2f !important;
}
input[type="checkbox"].iswitch-lg.iswitch-secondary:checked {
  box-shadow: inset 0 0 0 16px #68b828 !important;
}
input[type="checkbox"].iswitch-lg.iswitch-info:checked {
  box-shadow: inset 0 0 0 16px #40bbea !important;
}
input[type="checkbox"].iswitch-lg.iswitch-warning:checked {
  box-shadow: inset 0 0 0 16px #ffba00 !important;
}
input[type="checkbox"].iswitch-lg.iswitch-danger:checked {
  box-shadow: inset 0 0 0 16px #cc3f44 !important;
}
input[type="checkbox"].iswitch-lg.iswitch-success:checked {
  box-shadow: inset 0 0 0 16px #8dc63f !important;
}
input[type="checkbox"].iswitch-lg.iswitch-red:checked {
  box-shadow: inset 0 0 0 16px #d5080f !important;
}
input[type="checkbox"].iswitch-lg.iswitch-blue:checked {
  box-shadow: inset 0 0 0 16px #0e62c7 !important;
}
input[type="checkbox"].iswitch-lg.iswitch-purple:checked {
  box-shadow: inset 0 0 0 16px #7c38bc !important;
}
input[type="checkbox"].iswitch-lg.iswitch-pink:checked {
  box-shadow: inset 0 0 0 16px #ff6264 !important;
}
input[type="checkbox"].iswitch-lg.iswitch-turquoise:checked {
  box-shadow: inset 0 0 0 16px #00b19d !important;
}
input[type="checkbox"].iswitch-lg.iswitch-yellow:checked {
  box-shadow: inset 0 0 0 16px #fcd036 !important;
}
input[type="checkbox"].iswitch-lg.iswitch-orange:checked {
  box-shadow: inset 0 0 0 16px #f7aa47 !important;
}
input[type="checkbox"].iswitch-lg.iswitch-gray:checked {
  box-shadow: inset 0 0 0 16px #d5d5d5 !important;
}
input[type="checkbox"].iswitch-lg.hit {
  -webkit-animation: slide-off .35s ease both;
  -moz-animation: slide-off .35s ease both;
  -o-animation: slide-off .35s ease both;
  animation: slide-off .35s ease both;
}
input[type="checkbox"].iswitch-lg.hit:checked {
  -webkit-animation: slide-on  .35s ease both .05s;
  -moz-animation: slide-on  .35s ease both .05s;
  -o-animation: slide-on  .35s ease both .05s;
  animation: slide-on  .35s ease both .05s;
}
input[type="checkbox"].iswitch-lg:before,
input[type="checkbox"].iswitch-lg:after {
  content: "";
}
input[type="checkbox"].iswitch-lg:after {
  /* Increases hit area */
  position: absolute;
  top: -8px;
  left: -8px;
  bottom: -8px;
  right: -8px;
}
input[type="checkbox"].iswitch-lg:before {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin: 2px 0 0 2px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: all .1s ease .1s;
  -moz-transition: all .1s ease .1s;
  -o-transition: all .1s ease .1s;
  transition: all .1s ease .1s;
}
input[type="checkbox"].iswitch-lg:active:before {
  transition: all .1s ease .05s;
}
input[type="checkbox"].iswitch-lg.touch:hover:before {
  transition: all .0s ease .00s;
}
input[type="checkbox"].iswitch-lg:active:before,
input[type="checkbox"].iswitch-lg.touch:hover:before {
  width: 26px;
}
input[type="checkbox"].iswitch-lg:active:checked:before,
input[type="checkbox"].iswitch-lg.touch:hover:checked:before {
  margin-left: 0px;
}
@keyframes slide-on {
  0% {
    padding-left: 0px;
  }
  60% {
    padding-left: 22px;
  }
  100% {
    padding-left: 22px;
  }
}
@keyframes slide-off {
  0% {
    padding-left: 22px;
    text-indent: 0;
  }
  60% {
    padding-left: 0px;
    text-indent: 0px;
  }
  100% {
    padding-left: 0px;
    text-indent: 0;
  }
}
input[type="checkbox"].iswitch-xlg {
  font-size: 10px;
  position: relative;
  display: inline-block;
  width: 85px;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  margin: 0;
  padding: 0;
  box-shadow: inset 0 0 0 2px #eeeeee;
  outline: 1px solid transparent !important;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 4px;
  margin-bottom: 7px;
  transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
  transition-delay: .1s, 0s;
  /* Animations if supported */
}
input[type="checkbox"].iswitch-xlg:checked {
  transition-delay: 0s, 0s;
  box-shadow: inset 0 0 0 21.71428571px #eeeeee !important;
  padding-left: 47px;
}
input[type="checkbox"].iswitch-xlg.iswitch-primary:checked {
  box-shadow: inset 0 0 0 21.71428571px #2c2e2f !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-secondary:checked {
  box-shadow: inset 0 0 0 21.71428571px #68b828 !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-info:checked {
  box-shadow: inset 0 0 0 21.71428571px #40bbea !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-warning:checked {
  box-shadow: inset 0 0 0 21.71428571px #ffba00 !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-danger:checked {
  box-shadow: inset 0 0 0 21.71428571px #cc3f44 !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-success:checked {
  box-shadow: inset 0 0 0 21.71428571px #8dc63f !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-red:checked {
  box-shadow: inset 0 0 0 21.71428571px #d5080f !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-blue:checked {
  box-shadow: inset 0 0 0 21.71428571px #0e62c7 !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-purple:checked {
  box-shadow: inset 0 0 0 21.71428571px #7c38bc !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-pink:checked {
  box-shadow: inset 0 0 0 21.71428571px #ff6264 !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-turquoise:checked {
  box-shadow: inset 0 0 0 21.71428571px #00b19d !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-yellow:checked {
  box-shadow: inset 0 0 0 21.71428571px #fcd036 !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-orange:checked {
  box-shadow: inset 0 0 0 21.71428571px #f7aa47 !important;
}
input[type="checkbox"].iswitch-xlg.iswitch-gray:checked {
  box-shadow: inset 0 0 0 21.71428571px #d5d5d5 !important;
}
input[type="checkbox"].iswitch-xlg.hit {
  -webkit-animation: slide-off .35s ease both;
  -moz-animation: slide-off .35s ease both;
  -o-animation: slide-off .35s ease both;
  animation: slide-off .35s ease both;
}
input[type="checkbox"].iswitch-xlg.hit:checked {
  -webkit-animation: slide-on  .35s ease both .05s;
  -moz-animation: slide-on  .35s ease both .05s;
  -o-animation: slide-on  .35s ease both .05s;
  animation: slide-on  .35s ease both .05s;
}
input[type="checkbox"].iswitch-xlg:before,
input[type="checkbox"].iswitch-xlg:after {
  content: "";
}
input[type="checkbox"].iswitch-xlg:after {
  /* Increases hit area */
  position: absolute;
  top: -11.33333333px;
  left: -11.33333333px;
  bottom: -11.33333333px;
  right: -11.33333333px;
}
input[type="checkbox"].iswitch-xlg:before {
  display: inline-block;
  height: 34px;
  width: 34px;
  margin: 2px 0 0 2px;
  background-color: #ffffff;
  border-radius: 17px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: all .1s ease .1s;
  -moz-transition: all .1s ease .1s;
  -o-transition: all .1s ease .1s;
  transition: all .1s ease .1s;
}
input[type="checkbox"].iswitch-xlg:active:before {
  transition: all .1s ease .05s;
}
input[type="checkbox"].iswitch-xlg.touch:hover:before {
  transition: all .0s ease .00s;
}
input[type="checkbox"].iswitch-xlg:active:before,
input[type="checkbox"].iswitch-xlg.touch:hover:before {
  width: 36px;
}
input[type="checkbox"].iswitch-xlg:active:checked:before,
input[type="checkbox"].iswitch-xlg.touch:hover:checked:before {
  margin-left: 0px;
}
@keyframes slide-on {
  0% {
    padding-left: 0px;
  }
  60% {
    padding-left: 47px;
  }
  100% {
    padding-left: 47px;
  }
}
@keyframes slide-off {
  0% {
    padding-left: 47px;
    text-indent: 0;
  }
  60% {
    padding-left: 0px;
    text-indent: 0px;
  }
  100% {
    padding-left: 0px;
    text-indent: 0;
  }
}
.mailbox-env .mailbox-sidebar .mailbox-list {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .mailbox-env .mailbox-sidebar .mailbox-list {
    margin-top: 10px;
  }
}
.mailbox-env .mailbox-sidebar .mailbox-list li {
  border-bottom: 1px solid #dddddd;
}
.mailbox-env .mailbox-sidebar .mailbox-list li a {
  display: block;
  color: #979898;
  padding: 10px 10px;
}
.mailbox-env .mailbox-sidebar .mailbox-list li a:hover {
  color: #717272;
}
.mailbox-env .mailbox-sidebar .mailbox-list li a .badge {
  margin-top: 0px;
}
.mailbox-env .mailbox-sidebar .mailbox-list li.active > a {
  color: #2c2e2f;
  font-weight: bold;
}
.mailbox-env .mailbox-sidebar .mailbox-list li:last-child {
  border-bottom: 0;
}
.mailbox-env .mailbox-sidebar .mailbox-list li.list-header {
  font-size: 10px;
  padding: 10px 10px;
  font-weight: bold;
  color: #979898;
  text-transform: uppercase;
}
.mailbox-env .mail-env {
  background: #FFF;
  padding: 15px 0;
  margin-bottom: 30px;
}
.mailbox-env .mail-env .mail-table {
  color: #979898;
  margin-bottom: 0;
}
.mailbox-env .mail-env .mail-table > thead > tr > td,
.mailbox-env .mail-env .mail-table > tfoot > tr > td,
.mailbox-env .mail-env .mail-table > thead > tr > th,
.mailbox-env .mail-env .mail-table > tfoot > tr > th {
  color: #717272;
  font-weight: normal;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 15px;
}
.mailbox-env .mail-env .mail-table > thead > tr > td:after,
.mailbox-env .mail-env .mail-table > tfoot > tr > td:after,
.mailbox-env .mail-env .mail-table > thead > tr > th:after,
.mailbox-env .mail-env .mail-table > tfoot > tr > th:after {
  display: none;
}
.mailbox-env .mail-env .mail-table > tfoot > tr > td,
.mailbox-env .mail-env .mail-table > tfoot > tr > th {
  border-bottom: 0;
  padding-top: 15px;
  padding-bottom: 0;
}
.mailbox-env .mail-env .mail-table > tbody > tr > td,
.mailbox-env .mail-env .mail-table > tbody > tr > th {
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}
.mailbox-env .mail-env .mail-table > tbody > tr a:hover {
  color: #646565;
}
.mailbox-env .mail-env .mail-table > tbody > tr.unread .col-name {
  font-weight: bold;
}
.mailbox-env .mail-env .mail-table > tbody > tr.unread .col-name a {
  color: #646565;
}
.mailbox-env .mail-env .mail-table > tbody > tr.highlighted {
  background-color: rgba(255, 255, 204, 0.35);
}
.mailbox-env .mail-env .mail-table .col-cb,
.mailbox-env .mail-env .mail-table .col-options {
  width: 2%;
}
.mailbox-env .mail-env .mail-table .col-cb {
  padding-left: 30px;
}
@media screen and (max-width: 550px) {
  .mailbox-env .mail-env .mail-table .col-cb {
    padding-left: 15px;
  }
}
.mailbox-env .mail-env .mail-table .col-subject a {
  display: block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .mailbox-env .mail-env .mail-table .col-subject a {
    max-width: 150px;
  }
}
@media screen and (max-width: 550px) {
  .mailbox-env .mail-env .mail-table .col-subject {
    padding-right: 15px;
  }
}
.mailbox-env .mail-env .mail-table .col-options {
  text-align: right;
}
.mailbox-env .mail-env .mail-table .col-time {
  width: 12%;
  text-align: right;
  padding-right: 30px;
  white-space: nowrap;
}
@media screen and (max-width: 550px) {
  .mailbox-env .mail-env .mail-table .col-time {
    display: none;
    padding-right: 15px;
  }
}
.mailbox-env .mail-env .mail-table .col-header-options {
  padding-right: 30px;
}
@media screen and (max-width: 550px) {
  .mailbox-env .mail-env .mail-table .col-header-options {
    padding-right: 15px;
  }
}
.mailbox-env .mail-env .mail-table a {
  color: #979898;
}
.mailbox-env .mail-env .mail-table .btn {
  margin-bottom: 0;
}
.mailbox-env .mail-env .mail-table .checkbox {
  margin: 0;
}
.mailbox-env .mail-env .mail-table .mail-select-options {
  float: left;
  position: relative;
  top: 2px;
}
@media screen and (max-width: 450px) {
  .mailbox-env .mail-env .mail-table .mail-select-options {
    display: none;
  }
}
.mailbox-env .mail-env .mail-table .mail-pagination {
  float: right;
  margin-right: -10px;
}
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev {
  position: relative;
  display: inline-block;
  margin-bottom: -5px;
  margin-left: 10px;
}
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev a {
  position: relative;
  display: block;
  float: left;
  color: #575858;
  font-size: 14px;
}
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev a i {
  margin: 0 8px;
}
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev a:hover {
  background: rgba(221, 221, 221, 0.25);
}
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev a:first-child {
  margin-right: 9px;
}
.mailbox-env .mail-env .mail-table .mail-pagination .next-prev a:first-child:after {
  display: block;
  height: 10px;
  width: 1px;
  background: #dddddd;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: -5px;
  content: '';
}
.mailbox-env .mail-env .mail-table .star {
  position: relative;
  display: inline-block;
  font-size: 15px;
  margin-top: -3px;
}
.mailbox-env .mail-env .mail-table .star.starred {
  color: #ffba00 !important;
}
.mailbox-env .mail-compose {
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
}
.mailbox-env .mail-compose .compose-message-editor {
  margin-bottom: 20px;
}
.mailbox-env .mail-compose .compose-message-editor textarea {
  min-height: 150px;
}
.mailbox-env .mail-compose .form-group {
  position: relative;
}
.mailbox-env .mail-compose .form-group label {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  line-height: 48px;
  padding: 0 15px;
  font-weight: bold;
  color: #717272;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mailbox-env .mail-compose .form-group input,
.mailbox-env .mail-compose .form-group textarea {
  position: relative;
  z-index: 1;
}
.mailbox-env .mail-compose .form-group input.form-control {
  height: 48px;
  padding-left: 80px;
}
.mailbox-env .mail-compose .form-group .field-options {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  padding: 14px 15px;
}
.mailbox-env .mail-compose .form-group .field-options a {
  display: block;
  background: #cccccc;
  color: #fff;
  font-size: 10px;
  float: left;
  margin-left: 5px;
  padding: 3px 5px;
}
.mailbox-env .mail-compose .form-group .field-options a:hover {
  background-color: #999;
}
.mailbox-env .mail-header {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.mailbox-env .mail-header h3 {
  margin: 0;
}
.mailbox-env .mail-header h3 i {
  font-size: 17px;
}
@media screen and (max-width: 768px) {
  .mailbox-env .mail-header h3 {
    margin-bottom: 20px;
  }
}
.mailbox-env .mail-single {
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}
.mailbox-env .mail-single .mail-single-header {
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.mailbox-env .mail-single .mail-single-header > h2 {
  float: left;
  margin: 0;
  font-size: 23px;
}
@media screen and (max-width: 768px) {
  .mailbox-env .mail-single .mail-single-header > h2 {
    float: none;
    margin-bottom: 20px;
    font-size: 19px;
  }
  .mailbox-env .mail-single .mail-single-header > h2 .go-back {
    font-size: 11px;
  }
}
.mailbox-env .mail-single .mail-single-header > h2 .go-back {
  display: block;
  color: #bdbebe;
  font-size: 13px;
  margin-top: 10px;
  margin-left: -5px;
}
.mailbox-env .mail-single .mail-single-header > h2 .go-back i {
  color: #b1b1b1;
}
.mailbox-env .mail-single .mail-single-header > h2 .label,
.mailbox-env .mail-single .mail-single-header > h2 .badge {
  margin-left: 10px;
}
.mailbox-env .mail-single .mail-single-header .mail-single-header-options {
  float: right;
}
@media screen and (max-width: 768px) {
  .mailbox-env .mail-single .mail-single-header .mail-single-header-options {
    float: none;
  }
}
.mailbox-env .mail-single .mail-single-info {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user {
  float: left;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user > a {
  display: block;
  color: #979898;
  text-decoration: none;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user > a strong,
.mailbox-env .mail-single .mail-single-info .mail-single-info-user > a span {
  color: #717272;
  font-weight: bold;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user > a img {
  float: left;
  margin-right: 10px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user > a em {
  font-style: normal;
  display: block;
  color: #CCC;
  padding-top: 2px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user.open > a {
  text-decoration: none;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user .dropdown-menu {
  width: 100%;
  margin-top: 10px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user .dropdown-menu:before {
  content: '';
  position: absolute;
  display: block;
  background: inherit;
  width: 10px;
  height: 10px;
  top: -5px;
  left: 4%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user .dropdown-menu li {
  position: relative;
  z-index: 2;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-user .dropdown-menu li i {
  display: inline-block;
  margin-right: 2px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-options {
  float: right;
  padding-top: 10px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-options a {
  color: #979898;
  display: inline-block;
  margin-left: 5px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-options a i {
  font-size: 16px;
}
.mailbox-env .mail-single .mail-single-info .mail-single-info-options a.starred {
  color: #ffba00;
}
.mailbox-env .mail-single .mail-single-body {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.mailbox-env .mail-single .mail-single-body p {
  color: #7d7f7f;
}
.mailbox-env .mail-single .mail-single-attachments h3 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 22px;
}
.mailbox-env .mail-single .mail-single-attachments h3 i {
  font-size: 17px;
  position: relative;
  top: -2px;
  display: inline-block;
}
.mailbox-env .mail-single .mail-single-attachments ul li {
  margin-bottom: 20px;
}
.mailbox-env .mail-single .mail-single-attachments ul li .thumb {
  position: relative;
  display: block;
}
.mailbox-env .mail-single .mail-single-attachments ul li .thumb:before {
  content: '';
  display: block;
  position: absolute;
  background: linear-gradient(to bottom, #ffffff 0%, #7c38bc 100%);
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  left: 8px;
  right: 8px;
  top: 8px;
  bottom: 8px;
  z-index: 1;
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.mailbox-env .mail-single .mail-single-attachments ul li .thumb:after {
  display: block;
  position: absolute;
  color: #fff;
  content: '\f06e';
  font-family: 'fontawesome';
  left: 50%;
  top: 50%;
  font-size: 22px;
  z-index: 2;
  margin-left: -12px;
  margin-top: -13px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.mailbox-env .mail-single .mail-single-attachments ul li .thumb:hover:before {
  zoom: 1;
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
.mailbox-env .mail-single .mail-single-attachments ul li .thumb:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.mailbox-env .mail-single .mail-single-attachments ul li .img-thumbnail {
  border-width: 2px;
}
.mailbox-env .mail-single .mail-single-attachments ul li .name {
  display: block;
  margin-top: 12px;
  color: #717272;
  font-weight: bold;
}
.mailbox-env .mail-single .mail-single-attachments ul li .name span {
  float: right;
  font-size: 11px;
  color: #ddd;
  font-weight: normal;
}
.mailbox-env .mail-single .mail-single-attachments ul li .links {
  display: block;
  margin-top: 5px;
  color: #a4a5a5;
  font-size: 11px;
}
.mailbox-env .mail-single .mail-single-attachments ul li .links a {
  color: #979898;
}
.mailbox-env .mail-single .mail-single-attachments ul li .links a:hover {
  color: #646565;
}
.mailbox-env .mail-single .mail-single-attachments ul.list-inline li {
  margin-right: 20px;
}
.mailbox-env .mail-single .mail-single-reply {
  border: 1px solid #e4e4e4;
  padding: 15px 20px;
  min-height: 70px;
  color: #979898;
}
.mailbox-env .mail-single .mail-single-reply a {
  font-weight: bold;
  color: #717272;
  text-decoration: none;
}
.mailbox-env .mail-single .mail-single-reply a:hover {
  color: #575858;
}
.mailbox-env .mailbox-right {
  float: right !important;
}
@media screen and (max-width: 768px) {
  .mailbox-env .mailbox-right {
    float: none !important;
  }
}
.mailbox-env .mailbox-left {
  float: left !important;
}
@media screen and (max-width: 768px) {
  .mailbox-env .mailbox-left {
    float: none !important;
  }
}
.invoice-env {
  padding: 15px 0;
}
@media print {
  .invoice-env {
    padding: 0;
  }
}
.invoice-env .invoice-header {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
}
.invoice-env .invoice-header .invoice-logo {
  float: left;
}
.invoice-env .invoice-header .invoice-logo .logo {
  display: block;
  margin-bottom: 20px;
}
.invoice-env .invoice-header .invoice-logo ul li {
  color: #979898;
  font-size: 14px;
}
.invoice-env .invoice-header .invoice-logo ul li strong {
  color: #717272;
}
.invoice-env .invoice-header .invoice-logo ul li + li {
  margin-top: 2px;
}
.invoice-env .invoice-header .invoice-options {
  float: right;
  width: 180px;
}
@media screen and (max-width: 480px) {
  .invoice-env .invoice-header .invoice-logo,
  .invoice-env .invoice-header .invoice-options {
    float: none;
  }
  .invoice-env .invoice-header .invoice-options {
    width: auto;
    margin-bottom: 30px;
  }
}
.invoice-env .invoice-details {
  padding-top: 30px;
  padding-bottom: 10px;
}
.invoice-env .invoice-details .invoice-client-info,
.invoice-env .invoice-details .invoice-payment-info {
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.invoice-env .invoice-details .invoice-client-info > strong,
.invoice-env .invoice-details .invoice-payment-info > strong {
  display: block;
  margin-bottom: 5px;
  color: #646565;
}
.invoice-env .invoice-details .invoice-client-info strong,
.invoice-env .invoice-details .invoice-payment-info strong {
  color: #717272;
}
.invoice-env .invoice-details .invoice-client-info {
  float: left;
  width: 60%;
}
.invoice-env .invoice-details .invoice-client-info ul {
  float: left;
  margin-right: 20px;
}
@media print {
  .invoice-env .invoice-details .invoice-client-info {
    width: 50%;
  }
}
.invoice-env .invoice-details .invoice-payment-info {
  float: right;
  width: 30%;
}
@media print {
  .invoice-env .invoice-details .invoice-payment-info {
    width: 50%;
    text-align: right;
  }
}
@media screen and (max-width: 480px) {
  .invoice-env .invoice-details .invoice-client-info,
  .invoice-env .invoice-details .invoice-payment-info {
    float: none;
    width: 100%;
  }
  .invoice-env .invoice-details .invoice-payment-info {
    margin-top: 15px;
  }
}
.invoice-env .table {
  margin-top: 15px;
  margin-bottom: 15px;
}
.invoice-env .invoice-totals {
  margin-top: 30px;
}
.invoice-env .invoice-totals .invoice-bill-info {
  float: left;
  padding-left: 15px;
}
.invoice-env .invoice-totals .invoice-subtotals-totals {
  float: right;
  text-align: right;
  padding-right: 15px;
}
.invoice-env .invoice-totals .invoice-subtotals-totals span {
  display: block;
}
.invoice-env .invoice-totals .invoice-subtotals-totals span strong {
  color: #717272;
  display: inline-block;
  min-width: 50px;
}
.invoice-env .invoice-totals .invoice-subtotals-totals hr {
  margin: 8px 0;
}
@media screen and (max-width: 480px) {
  .invoice-env .invoice-totals .invoice-bill-info,
  .invoice-env .invoice-totals .invoice-subtotals-totals {
    float: none;
    padding: 0;
  }
  .invoice-env .invoice-totals .invoice-subtotals-totals {
    margin-bottom: 30px;
  }
}
@media print {
  .invoice-env .invoice-totals .invoice-bill-info,
  .invoice-env .invoice-totals .invoice-subtotals-totals {
    padding: 0;
  }
}
.search-env form {
  position: relative;
  margin-bottom: 30px;
}
.search-env form .form-control {
  padding-right: 50px;
}
.search-env form button[type="submit"] {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 45px;
  padding: 0 15px;
  font-size: 16px;
  color: #979898;
  outline: none;
}
.search-env .nav.tabs-vertical {
  width: 200px;
}
@media screen and (max-width: 768px) {
  .search-env .nav.tabs-vertical {
    width: 50px;
    min-width: 50px;
  }
}
.search-env .nav.tabs-vertical > li > a {
  background: none;
  font-size: 14px;
  text-align: left;
}
.search-env .nav.tabs-vertical > li > a:after {
  display: block;
  content: '';
  height: 1px;
  margin-top: 5px;
  background-color: #dddddd;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
}
.search-env .nav.tabs-vertical > li.active > a {
  font-weight: bold;
  color: #2c2e2f;
}
.search-env .nav.tabs-vertical > li.active > a:after {
  display: none;
}
.search-env .nav.tabs-vertical > li:last-child > a:after {
  display: none;
}
.search-env .tab-content .tab-pane h2 {
  margin: 0;
  font-size: 24px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 30px;
  margin-top: -10px;
  margin-bottom: 30px;
}
.search-env .tab-content .tab-pane h2 small {
  display: block;
  padding-top: 10px;
  color: #999;
  font-size: 13px;
}
.search-env .tab-content .tab-pane .results li {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.search-env .tab-content .tab-pane .results li h3 {
  margin: 0;
  margin-bottom: 15px;
}
.search-env .tab-content .tab-pane .results li p {
  color: #b1b1b1;
}
.search-env .tab-content .tab-pane .results li .link {
  color: #68b828;
}
.search-env .tab-content .tab-pane .results li .link:hover {
  color: #508e1f;
}
.search-env .tab-content .tab-pane .results li + li {
  margin-bottom: 30px;
}
.search-env .tab-content .tab-pane .pagination {
  margin: 0;
}
.gallery-env {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .gallery-env .gallery-sidebar {
    margin-top: 30px;
  }
}
.gallery-env .gallery-sidebar ul {
  margin: 20px 0;
}
.gallery-env .gallery-sidebar ul li a {
  display: block;
  padding: 10px 5px;
  border-bottom: 1px solid #dddddd;
  color: #7d7f7f;
}
.gallery-env .gallery-sidebar ul li a i {
  display: inline-block;
  margin-right: 2px;
}
.gallery-env .gallery-sidebar ul li a:hover {
  color: #575858;
}
.gallery-env .gallery-sidebar ul li.active > a {
  color: #2c2e2f;
  font-weight: bold;
}
.gallery-env .gallery-sidebar ul li:last-child > a {
  border-bottom: 0;
}
.gallery-env .album-header {
  padding: 25px 30px;
  background: #fff;
  margin-bottom: 20px;
}
.gallery-env .album-header h2 {
  margin: 0;
  padding: 0;
  float: left;
  color: #2c2e2f;
  font-size: 24px;
}
.gallery-env .album-header .album-options {
  float: right;
  padding: 0;
  margin: 0;
  margin-top: 5px;
}
.gallery-env .album-header .album-options .cbr-replaced {
  margin-bottom: 0;
  margin-right: 5px;
}
.gallery-env .album-header .album-options li {
  color: #646565;
}
.gallery-env .album-header .album-options li a {
  color: #646565;
}
.gallery-env .album-header .album-options li a:hover {
  color: #2c2e2f;
}
.gallery-env .album-header .album-options li label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gallery-env .album-header .album-options li + li {
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .gallery-env .album-header {
    padding: 15px 20px;
  }
  .gallery-env .album-header h2,
  .gallery-env .album-header .album-options {
    float: none;
  }
  .gallery-env .album-header h2 {
    text-align: center;
    padding: 10px 0;
  }
  .gallery-env .album-header .album-options {
    margin-top: 10px;
  }
}
.gallery-env .album-sorting-info {
  display: none;
}
.gallery-env .album-sorting-info .album-sorting-info-inner {
  border: 2px dashed #b7b7b7;
  padding: 15px 10px;
  font-size: 14px;
  margin-bottom: 20px;
}
.gallery-env .album-images .album-image {
  position: relative;
  padding: 10px;
  background: #fff;
  margin-bottom: 20px;
}
.gallery-env .album-images .album-image .thumb {
  position: relative;
  display: block;
  margin-bottom: 10px;
  z-index: 2;
}
.gallery-env .album-images .album-image .thumb img {
  display: block;
  margin: 0;
  line-height: 1;
  width: 100%;
}
.gallery-env .album-images .album-image .thumb:hover ~ .image-checkbox .cbr-replaced {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.gallery-env .album-images .album-image .name {
  float: left;
  width: 75%;
}
.gallery-env .album-images .album-image .name span {
  color: #2c2e2f;
  font-weight: bold;
}
.gallery-env .album-images .album-image .name em {
  font-style: normal;
  color: #979898;
  font-size: 11px;
  display: block;
}
@media screen and (min-width: 768px) {
  .gallery-env .album-images .album-image .name span,
  .gallery-env .album-images .album-image .name em {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
}
.gallery-env .album-images .album-image .image-options {
  float: right;
  width: 25%;
  text-align: right;
  white-space: nowrap;
}
.gallery-env .album-images .album-image .image-options a {
  font-size: 10px;
  color: #5d6264;
}
.gallery-env .album-images .album-image .image-options a:hover {
  color: #2c2e2f;
}
.gallery-env .album-images .album-image .image-checkbox {
  position: absolute;
  right: 17px;
  top: 15px;
  z-index: 5;
}
.gallery-env .album-images .album-image .image-checkbox .cbr-replaced {
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.gallery-env .album-images .album-image .image-checkbox .cbr-replaced:hover,
.gallery-env .album-images .album-image .image-checkbox .cbr-replaced.cbr-checked {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.gallery-env .album-images .ui-sortable-handle .album-image {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(104, 184, 40, 0.2);
  -moz-box-shadow: 0px 0px 0px 1px rgba(104, 184, 40, 0.2);
  box-shadow: 0px 0px 0px 1px rgba(104, 184, 40, 0.2);
  -webkit-animation: glowAnimation 1s ease-in-out alternate infinite;
  -moz-animation: glowAnimation 1s ease-in-out alternate infinite;
  -o-animation: glowAnimation 1s ease-in-out alternate infinite;
  animation: glowAnimation 1s ease-in-out alternate infinite;
  margin-bottom: 0;
}
.gallery-env .album-images .ui-sortable-handle {
  margin-bottom: 20px;
}
.gallery-env .album-images .ui-sortable-placeholder {
  visibility: visible !important;
  background: rgba(204, 204, 204, 0.1);
  border: 1px dashed #c4c4c4;
  margin: 0 !important;
}
.gallery-env .gallery-right {
  float: right !important;
}
@media screen and (max-width: 768px) {
  .gallery-env .gallery-right {
    float: none !important;
  }
}
.gallery-env .gallery-left {
  float: left !important;
}
@media screen and (max-width: 768px) {
  .gallery-env .gallery-left {
    float: none !important;
  }
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes glowAnimation{ 0%{ box-shadow: rgba(104, 184, 40, 0.2) 0 0 0 1px; } 100%{ box-shadow: rgba(104, 184, 40, 0.8) 0 0 3px 1px; }}
@-moz-keyframes glowAnimation{ 0%{ box-shadow: rgba(104, 184, 40, 0.2) 0 0 0 1px; } 100%{ box-shadow: rgba(104, 184, 40, 0.8) 0 0 3px 1px; }}
@-o-keyframes glowAnimation{ 0%{ box-shadow: rgba(104, 184, 40, 0.2) 0 0 0 1px; } 100%{ box-shadow: rgba(104, 184, 40, 0.8) 0 0 3px 1px; }}
@keyframes glowAnimation{ 0%{ box-shadow: rgba(104, 184, 40, 0.2) 0 0 0 1px; } 100%{ box-shadow: rgba(104, 184, 40, 0.8) 0 0 3px 1px; };
}
.modal-gallery-image {
  margin: -31px;
  margin-bottom: 0;
  border-bottom: 5px solid #f5f5f5;
  position: relative;
}
.modal-gallery-image img {
  display: block;
  width: 100%;
}
.modal-gallery-top-controls {
  position: absolute;
  right: 15px;
  top: 0;
  border: 0;
}
.page-error {
  background: #fff;
  width: 700px;
  padding: 50px;
  margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .page-error {
    width: 90%;
  }
  .main-content .page-error {
    width: 100%;
  }
}
.page-error.centered {
  margin-left: auto;
  margin-right: auto;
}
.page-error .error-symbol {
  font-size: 48px;
  line-height: 1;
}
.page-error .error-symbol i {
  line-height: 1;
}
.page-error h2 {
  font-size: 35px;
  margin-bottom: 20px;
}
.page-error h2 small {
  display: block;
  padding-top: 10px;
  color: #bcbcbc;
  font-size: 50%;
}
.page-error-env > .page-error {
  margin-top: 50px;
}
.page-error-search {
  width: 700px;
}
@media screen and (max-width: 991px) {
  .page-error-search {
    width: 90%;
  }
  .main-content .page-error-search {
    width: 100%;
  }
}
.page-error-search.centered {
  margin-left: auto;
  margin-right: auto;
}
.page-error-search form {
  position: relative;
}
.page-error-search form button[type="submit"] {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 32px;
  padding: 0 10px;
  font-size: 12px;
  color: #a4a5a5;
}
.page-error-search form.form-half {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
}
.page-error-search form .input-lg + button[type="submit"] {
  line-height: 45px;
  padding: 0 15px;
}
.page-error-search .go-back {
  display: block;
  text-align: center;
  color: #abacac;
  margin: 15px 0;
}
.page-error-search .go-back:hover {
  color: #979898;
}
.calendar-env {
  position: relative;
  margin-bottom: 30px;
}
.calendar-env .calendar-sidebar form {
  margin-bottom: 20px;
}
.calendar-env .calendar-sidebar form .form-control {
  padding: 20px 15px;
  height: 37px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .calendar-env .calendar-sidebar {
    margin-top: 30px;
  }
  .calendar-env .calendar-sidebar form {
    margin-bottom: 0;
  }
}
.calendar-env .calendar-sidebar .calendar-list {
  margin: 0;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .calendar-env .calendar-sidebar .calendar-list {
    margin-top: 10px;
  }
}
.calendar-env .calendar-sidebar .calendar-list li a {
  display: block;
  color: #979898;
  padding-bottom: 7px;
}
.calendar-env .calendar-sidebar .calendar-list li a:hover {
  color: #717272;
}
.calendar-env .calendar-sidebar .calendar-list li a .badge {
  margin-top: 0px;
  display: block;
  text-align: left;
  font-size: 12px;
  padding: 7px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.calendar-env .calendar-sidebar .calendar-list li.list-header {
  font-size: 10px;
  padding: 10px 0;
  font-weight: bold;
  color: #979898;
  border-bottom: 0;
  text-transform: uppercase;
}
.calendar-env .calendar-sidebar .calendar-list li.ui-draggable-dragging .badge {
  zoom: 1;
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: alpha(opacity=60);
}
.calendar-env .calendar-sidebar .calendar-list li.ui-draggable-handle .badge {
  cursor: move;
}
.calendar-env .calendar-main {
  position: relative;
  background: #fff;
  padding: 30px;
  z-index: 5;
}
.calendar-env .calendar-main .fc .fc-popover,
.calendar-env .calendar-main .fc .fc-row,
.calendar-env .calendar-main .fc hr,
.calendar-env .calendar-main .fc tbody,
.calendar-env .calendar-main .fc td,
.calendar-env .calendar-main .fc th,
.calendar-env .calendar-main .fc thead {
  border-color: #eeeeee;
}
.calendar-env .calendar-main .fc .fc-toolbar {
  padding-bottom: 15px;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-left h2 {
  font-size: 24px;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-left h2:before {
  content: '\f133';
  font-family: 'fontawesome';
  display: inline-block;
  margin-right: 10px;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-right {
  margin-top: 5px;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-right > .fc-button-group:last-child {
  margin-right: -15px;
}
@media screen and (max-width: 768px) {
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right {
    float: none;
    width: 100%;
  }
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left:before,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right:before,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left:after,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right:after {
    content: " ";
    display: table;
  }
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left:after,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right:after {
    clear: both;
  }
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left:before,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right:before,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left:after,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right:after {
    content: " ";
    display: table;
  }
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left:after,
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right:after {
    clear: both;
  }
  .calendar-env .calendar-main .fc .fc-toolbar .fc-left h2 {
    text-align: center;
    width: 100%;
    float: none;
  }
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right {
    position: relative;
    margin-top: 10px;
    text-align: center;
    top: 12px;
  }
  .calendar-env .calendar-main .fc .fc-toolbar .fc-right .fc-button-group {
    display: inline-block;
    float: none;
  }
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group {
  background: none;
  border: 0;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-month-button.fc-state-active,
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-basicWeek-button.fc-state-active,
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-agendaWeek-button.fc-state-active,
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-agendaDay-button.fc-state-active,
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-basicDay-button.fc-state-active {
  color: #2c2e2f;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-month-button:after {
  content: '';
  display: block;
  position: absolute;
  right: -1px;
  top: 25%;
  bottom: 25%;
  width: 1px;
  background: #eeeeee;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-month-button + .fc-agendaWeek-button:after {
  content: '';
  display: block;
  position: absolute;
  right: -1px;
  top: 25%;
  bottom: 25%;
  width: 1px;
  background: #eeeeee;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button-group .fc-month-button + .fc-basicWeek-button:after {
  content: '';
  display: block;
  position: absolute;
  right: -1px;
  top: 25%;
  bottom: 25%;
  width: 1px;
  background: #eeeeee;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button {
  border: 0;
  background: none;
  padding: 0 10px;
  margin: 0;
  height: auto;
  position: relative;
  outline: none;
  color: #979898;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button .fc-icon {
  font-size: 13px;
  font-family: 'fontawesome';
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button.fc-next-button:hover,
.calendar-env .calendar-main .fc .fc-toolbar .fc-button.fc-prev-button:hover {
  color: #2c2e2f;
}
.calendar-env .calendar-main .fc .fc-toolbar .fc-button.fc-prev-button:after {
  content: '';
  display: block;
  position: absolute;
  right: -1px;
  top: 25%;
  bottom: 25%;
  width: 1px;
  background: #eeeeee;
}
.calendar-env .calendar-main .fc .fc-view-container > .fc-basic-view > table > thead > tr > td.fc-widget-header {
  border: 0;
  border-bottom: 1px solid #eeeeee;
}
.calendar-env .calendar-main .fc .fc-view-container > .fc-basic-view > table > thead > tr > td .fc-day-header {
  border: 0;
  padding-bottom: 10px;
  color: #717272;
  font-weight: normal;
}
.calendar-env .calendar-main .fc .fc-view-container > .fc-basic-view > table > tbody > tr > .fc-widget-content {
  border: 0;
  border-bottom: 1px solid #eeeeee;
}
.calendar-env .calendar-main .fc .fc-view-container > .fc-basic-view > table > tbody > tr > .fc-widget-content .fc-day-number {
  padding-top: 10px;
  padding-right: 10px;
  color: #949494;
}
.calendar-env .calendar-main .fc .fc-popover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.calendar-env .calendar-main .fc .fc-popover .fc-header {
  padding: 5px;
}
.calendar-env .calendar-main .fc .fc-popover .fc-body .fc-event-container a {
  margin-left: 2px;
  margin-right: 2px;
}
.calendar-env .calendar-main .fc .fc-today {
  background-color: #fafafa;
  color: #2c2e2f;
}
.calendar-env .calendar-main .fc .fc-event {
  background: #68b828;
  border: 0;
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
  padding: 1px 3px;
}
.calendar-env .calendar-main .fc .fc-event.event-color-primary {
  background-color: #2c2e2f;
}
.calendar-env .calendar-main .fc .fc-event.event-color-success {
  background-color: #8dc63f;
}
.calendar-env .calendar-main .fc .fc-event.event-color-danger {
  background-color: #cc3f44;
}
.calendar-env .calendar-main .fc .fc-event.event-color-warning {
  background-color: #ffba00;
}
.calendar-env .calendar-main .fc .fc-event.event-color-info {
  background-color: #40bbea;
}
.calendar-env .calendar-main .fc .fc-event.event-color-red {
  background-color: #d5080f;
}
.calendar-env .calendar-main .fc .fc-event.event-color-blue {
  background-color: #0e62c7;
}
.calendar-env .calendar-main .fc .fc-event.event-color-purple {
  background-color: #7c38bc;
}
.calendar-env .calendar-main .fc .fc-event.event-color-gray {
  background-color: #959595;
}
.calendar-env .calendar-main .fc .fc-event.event-color-black {
  background-color: #222222;
}
.calendar-env .calendar-right {
  float: right !important;
}
@media screen and (max-width: 768px) {
  .calendar-env .calendar-right {
    float: none !important;
  }
}
.calendar-env .calendar-left {
  float: left !important;
}
@media screen and (max-width: 768px) {
  .calendar-env .calendar-left {
    float: none !important;
  }
}
.profile-env {
  margin-bottom: 30px;
}
.profile-env .user-info-sidebar hr {
  border-top-color: #e1e1e1;
  margin: 10px 0;
}
.profile-env .user-info-sidebar .user-img {
  display: block;
  margin-bottom: 10px;
  text-align: center;
}
.profile-env .user-info-sidebar .user-img img {
  display: inline-block;
}
.profile-env .user-info-sidebar .user-img img.img-thumbnail {
  padding: 4px;
  border: 0;
}
.profile-env .user-info-sidebar .user-name {
  display: block;
  font-size: 19px;
  text-align: center;
}
.profile-env .user-info-sidebar .user-title {
  display: block;
  color: #999999;
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
}
.profile-env .user-info-sidebar .user-title strong,
.profile-env .user-info-sidebar .user-title a {
  color: #7d7f7f;
}
.profile-env .user-info-sidebar .user-status {
  position: relative;
  display: inline-block;
  background: #FFF;
  top: -2px;
  margin-left: 5px;
  width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.profile-env .user-info-sidebar .user-status.is-online {
  background-color: #8dc63f;
}
.profile-env .user-info-sidebar .user-status.is-idle {
  background-color: #ffba00;
}
.profile-env .user-info-sidebar .user-status.is-busy {
  background-color: #d5080f;
}
.profile-env .user-info-sidebar .user-status.is-offline {
  background-color: #CCC;
}
.profile-env .user-info-sidebar .user-info-list {
  display: table;
  margin: 0 auto;
}
.profile-env .user-info-sidebar .user-info-list li {
  margin: 10px 0;
  color: #979898;
}
.profile-env .user-info-sidebar .user-info-list li i {
  color: #7d7f7f;
  display: inline-block;
  margin-right: 5px;
}
.profile-env .user-info-sidebar .user-info-list li a,
.profile-env .user-info-sidebar .user-info-list li strong {
  color: #717272;
}
.profile-env .user-info-sidebar .user-info-list li a:hover {
  color: #4b4b4b;
}
.profile-env .user-info-sidebar .user-friends-count {
  display: table;
  margin: 20px auto;
}
.profile-env .user-info-sidebar .user-friends-count li {
  position: relative;
  display: table-cell;
  color: #b1b1b1;
  font-size: 12px;
  padding: 0 20px;
}
.profile-env .user-info-sidebar .user-friends-count li span {
  display: block;
  font-size: 23px;
  color: #646565;
}
.profile-env .user-info-sidebar .user-friends-count li:first-child {
  padding-left: 0;
}
.profile-env .user-info-sidebar .user-friends-count li:last-child {
  padding-right: 0;
}
.profile-env .user-info-sidebar .user-friends-count li + li:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 15%;
  bottom: 15%;
  width: 1px;
  background: #dddddd;
}
.profile-env .profile-post-form {
  position: relative;
  background: #fff;
  border: 1px solid #e4e4e4;
  padding: 20px;
  margin-bottom: 30px;
}
.profile-env .profile-post-form:after {
  content: '';
  display: block;
  position: absolute;
  left: -7.4px;
  top: 25px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 8.4px 7px 0;
  border-color: transparent #fff transparent transparent;
}
@media screen and (max-width: 767px) {
  .profile-env .profile-post-form {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .profile-env .profile-post-form:after {
    display: none;
  }
}
.profile-env .profile-post-form textarea {
  position: relative;
  resize: none;
  z-index: 1;
  border-bottom: 1px solid #eeeeee;
}
.profile-env .profile-post-form textarea::-moz-placeholder {
  color: #bdbebe;
  opacity: 1;
}
.profile-env .profile-post-form textarea:-ms-input-placeholder {
  color: #bdbebe;
}
.profile-env .profile-post-form textarea::-webkit-input-placeholder {
  color: #bdbebe;
}
.profile-env .profile-post-form textarea::-webkit-input-placeholder {
  color: #bdbebe;
}
.profile-env .profile-post-form textarea:-moz-placeholder {
  color: #bdbebe;
}
.profile-env .profile-post-form textarea::-moz-placeholder {
  color: #bdbebe;
}
.profile-env .profile-post-form textarea:-ms-input-placeholder {
  color: #bdbebe;
}
.profile-env .profile-post-form textarea:focus ~ .post-story-button {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.profile-env .profile-post-form .form-action-buttons {
  margin: 0;
  margin-top: 15px;
}
.profile-env .profile-post-form .form-action-buttons li button {
  color: #979898;
  font-size: 15px;
}
.profile-env .profile-post-form .form-action-buttons li button:hover {
  color: #515557;
}
.profile-env .profile-post-form .form-action-buttons li button:active {
  color: #68b828;
}
.profile-env .profile-post-form .post-story-button {
  float: right;
  margin-top: -17px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: opacity 150ms linear;
  -moz-transition: opacity 150ms linear;
  -o-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
.profile-env .profile-post-form .post-story-button:active,
.profile-env .profile-post-form .post-story-button:focus {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.profile-env .block-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #cacbcb;
}
.profile-env .user-timeline-stories .timeline-story {
  position: relative;
  background: #fff;
  padding: 20px;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .profile-env .user-timeline-stories .timeline-story {
    margin-bottom: 20px;
  }
}
.profile-env .user-timeline-stories .timeline-story header {
  color: #979898;
  font-size: 14px;
  margin-bottom: 10px;
}
.profile-env .user-timeline-stories .timeline-story header .user-img {
  margin-right: 20px;
  float: left;
}
.profile-env .user-timeline-stories .timeline-story header .user-img img {
  width: 48px;
}
.profile-env .user-timeline-stories .timeline-story header .user-details {
  display: block;
  padding-top: 5px;
}
.profile-env .user-timeline-stories .timeline-story header a {
  color: #646565;
}
.profile-env .user-timeline-stories .timeline-story header a:hover {
  color: #4b4b4b;
}
.profile-env .user-timeline-stories .timeline-story header time {
  display: block;
  font-size: 12px;
  color: #b1b1b1;
}
.profile-env .user-timeline-stories .timeline-story .story-content {
  display: block;
  padding-left: 70px;
}
@media screen and (max-width: 767px) {
  .profile-env .user-timeline-stories .timeline-story .story-content {
    padding-left: 0;
  }
}
.profile-env .user-timeline-stories .timeline-story .story-content p {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .profile-env .user-timeline-stories .timeline-story > .story-content {
    margin-top: 20px;
  }
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item {
  background: #f8f8f8;
  padding: 30px;
  margin: 10px -20px;
  margin-top: 20px;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .story-content {
  margin-left: -13px;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info {
  padding-bottom: 15px;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info .artist-info,
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info .track-length {
  color: #979898;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info .artist-info {
  float: left;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-info .track-length {
  float: right;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline {
  width: 100%;
  display: table;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline .play-pause,
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline .track-timeline,
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline .track-volume {
  display: table-cell;
  vertical-align: middle;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline .play-pause {
  width: 75px;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline .track-volume {
  width: 30px;
  text-align: right;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline .track-timeline .track-timeline-empty {
  display: block;
  height: 2px;
  background: #dedede;
  position: relative;
}
.profile-env .user-timeline-stories .timeline-story .story-audio-item .audio-track-timeline .track-timeline .track-timeline-empty .track-fill {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #2c2e2f;
}
.profile-env .user-timeline-stories .timeline-story .story-options-links {
  padding-top: 10px;
}
.profile-env .user-timeline-stories .timeline-story .story-options-links a {
  display: inline-block;
  color: #979898;
  margin-right: 30px;
}
.profile-env .user-timeline-stories .timeline-story .story-options-links a span {
  display: inline-block;
  zoom: 1;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);
}
.profile-env .user-timeline-stories .timeline-story .story-options-links a:hover {
  color: #575858;
}
.profile-env .user-timeline-stories .timeline-story .story-options-links a.liked {
  color: #ed3434;
}
.profile-env .user-timeline-stories .timeline-story .story-comments {
  border-top: 1px solid #eeeeee;
  margin-top: 20px;
  padding-top: 10px;
}
.profile-env .user-timeline-stories .timeline-story .story-comments .story-comment {
  margin-top: 10px;
}
.profile-env .user-timeline-stories .timeline-story .story-comments .story-comment .comment-user-img {
  display: block;
  float: left;
}
.profile-env .user-timeline-stories .timeline-story .story-comments .story-comment .comment-user-img img {
  width: 32px;
}
.profile-env .user-timeline-stories .timeline-story .story-comments .story-comment .story-comment-content {
  margin-left: 50px;
  padding-bottom: 5px;
  padding-left: 2px;
  border-bottom: 1px solid #eeeeee;
}
.profile-env .user-timeline-stories .timeline-story .story-comments .story-comment .story-comment-content .story-comment-user-name {
  display: block;
  font-size: 14px;
  color: #575858;
  margin-bottom: 5px;
}
.profile-env .user-timeline-stories .timeline-story .story-comments .story-comment .story-comment-content .story-comment-user-name time {
  display: inline-block;
  margin-left: 5px;
  font-size: 11px;
  color: #ddd;
}
.profile-env .user-timeline-stories .timeline-story .story-comments .story-comment .story-comment-content p {
  font-size: 13px;
}
.profile-env .user-timeline-stories .timeline-story .story-comments li:last-child .story-comment .story-comment-content {
  border-bottom: 0;
}
.profile-env .user-timeline-stories .timeline-story .story-comment-form {
  margin-top: 20px;
}
.profile-env .user-timeline-stories .timeline-story .story-comment-form textarea {
  border: 1px solid #eee;
  padding: 10px;
  max-height: 100px;
  margin-bottom: 10px;
}
.profile-env .user-timeline-stories .timeline-story .story-checkin {
  margin-bottom: 10px;
  border: 1px solid #eeeeee;
  padding: 10px;
}
.profile-env .user-timeline-stories .timeline-story .story-album {
  border: 1px solid #eeeeee;
  padding: 10px;
  margin-bottom: 20px;
}
.profile-env .user-timeline-stories .timeline-story .story-album .col-1,
.profile-env .user-timeline-stories .timeline-story .story-album .col-2 {
  display: block;
  float: left;
  width: 50%;
}
.profile-env .user-timeline-stories .timeline-story .story-album .col-1 {
  padding-right: 6px;
}
.profile-env .user-timeline-stories .timeline-story .story-album .col-2 {
  padding-left: 6px;
}
@media screen and (max-width: 767px) {
  .profile-env .user-timeline-stories .timeline-story .story-album {
    padding-bottom: 0;
  }
  .profile-env .user-timeline-stories .timeline-story .story-album .col-1,
  .profile-env .user-timeline-stories .timeline-story .story-album .col-2 {
    padding: 0;
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }
}
.profile-env .user-timeline-stories .timeline-story:last-child {
  margin-bottom: 0;
}
.page-body .main-content .cbp_tmtimeline:before {
  background: #ffffff;
  width: 5px;
  margin-left: -6px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span {
  color: #575858;
  font-size: 15px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span:first-child {
  font-weight: bold;
  margin-bottom: 2px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span:last-child {
  color: #979898;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  font-size: 12px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span.large {
  font-size: 17px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmtime > span.hidden + span {
  margin-top: 8px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon {
  background: #ffffff;
  color: #979898;
  -webkit-box-shadow: 0px 0px 0px 3px #ffffff;
  -moz-box-shadow: 0px 0px 0px 3px #ffffff;
  box-shadow: 0px 0px 0px 3px #ffffff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-primary {
  background-color: #2c2e2f;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-success {
  background-color: #8dc63f;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-info {
  background-color: #40bbea;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-danger {
  background-color: #cc3f44;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-warning {
  background-color: #ffba00;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-red {
  background-color: #d5080f;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-purple {
  background-color: #7c38bc;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-secondary {
  background-color: #68b828;
  color: #fff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmicon.timeline-bg-gray {
  background-color: #ddd;
  color: #777;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel {
  background: #ffffff;
  color: #737881;
  margin-bottom: 30px;
  padding: 20px;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2,
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel p {
  color: #737881;
  font-size: 13px;
  margin: 0;
  line-height: 1.42857143;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel p + p {
  margin-top: 10px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2 {
  font-size: 15px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2 a {
  color: #303641;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel h2 span {
  zoom: 1;
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: alpha(opacity=60);
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel blockquote {
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel:after {
  border-right-color: #ffffff;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty {
  background: 0;
  padding: 9px 0;
  margin-bottom: 70px;
}
.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty:after {
  visibility: hidden;
}
@media screen and (max-width: 47.2em) {
  .page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #fff;
    left: 10px;
  }
  .page-body .main-content .cbp_tmtimeline > li .large {
    font-weight: bold;
    font-size: 16px !important;
  }
  .page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty {
    background: #ffffff;
    padding: 1.7em;
  }
  .page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty:after {
    visibility: visible;
  }
}
.timeline-centered {
  position: relative;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .timeline-centered {
    margin-top: 60px !important;
  }
}
.timeline-centered:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  background: #ffffff;
  left: 50%;
  top: 20px;
  bottom: 20px;
  margin-left: -4px;
}
.timeline-centered .timeline-entry {
  position: relative;
  width: 50%;
  float: right;
  margin-bottom: 70px;
  clear: both;
}
.timeline-centered .timeline-entry:before,
.timeline-centered .timeline-entry:after {
  content: " ";
  display: table;
}
.timeline-centered .timeline-entry:after {
  clear: both;
}
.timeline-centered .timeline-entry:before,
.timeline-centered .timeline-entry:after {
  content: " ";
  display: table;
}
.timeline-centered .timeline-entry:after {
  clear: both;
}
.timeline-centered .timeline-entry.begin {
  margin-bottom: 0;
}
.timeline-centered .timeline-entry.left-aligned {
  float: left;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
  margin-left: 0;
  margin-right: -18px;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
  left: auto;
  right: -120px;
  text-align: left;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
  float: right;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
  margin-left: 0;
  margin-right: 70px;
}
.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -9px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.timeline-centered .timeline-entry .timeline-entry-inner {
  position: relative;
  margin-left: -22px;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
  position: absolute;
  left: -100px;
  text-align: right;
  padding: 10px;
  padding-top: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
  display: block;
  color: #979898;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:first-child {
  font-size: 15px;
  font-weight: bold;
  color: #717272;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:last-child {
  font-size: 12px;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
  background: #fff;
  color: #737881;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 20px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 20px;
  -moz-background-clip: padding;
  border-radius: 20px;
  background-clip: padding-box;
  text-align: center;
  -webkit-box-shadow: 0px 0px 0px 5px #ffffff;
  -moz-box-shadow: 0px 0px 0px 5px #ffffff;
  box-shadow: 0px 0px 0px 5px #ffffff;
  line-height: 40px;
  font-size: 16px;
  float: left;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-primary {
  background-color: #2c2e2f;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-success {
  background-color: #8dc63f;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-info {
  background-color: #40bbea;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-danger {
  background-color: #cc3f44;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-warning {
  background-color: #ffba00;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-red {
  background-color: #d5080f;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-purple {
  background-color: #7c38bc;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.timeline-bg-secondary {
  background-color: #68b828;
  color: #fff;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
  position: relative;
  background: #ffffff;
  padding: 1.7em;
  margin-left: 70px;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
  content: '';
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 9px 9px 9px 0;
  border-color: transparent #ffffff transparent transparent;
  left: 0;
  top: 10px;
  margin-left: -9px;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
  color: #737881;
  font-size: 13px;
  margin: 0;
  line-height: 1.42857143;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
  margin-top: 10px;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
  font-size: 15px;
  margin-bottom: 10px;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
  color: #303641;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 span {
  zoom: 1;
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: alpha(opacity=60);
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label blockquote {
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #979898;
}
@media screen and (max-width: 47.2em) {
  .timeline-centered {
    margin-top: 20px;
  }
  .timeline-centered:before {
    left: 22px;
  }
  .timeline-centered .timeline-entry,
  .timeline-centered .timeline-entry.left-aligned {
    width: 100%;
    float: none;
  }
  .timeline-centered .timeline-entry .timeline-entry-inner,
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
    margin-left: 0;
    margin-right: 0;
  }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time,
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
    left: 60px;
    right: auto;
    top: -30px;
    width: auto;
  }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time span,
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time span {
    display: inline-block;
  }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time span + span,
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time span + span {
    margin-left: 10px;
  }
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
    float: left;
  }
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
    margin-left: 70px;
    margin-right: 0;
  }
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
    left: 0;
    right: auto;
    margin-left: -9px;
    margin-right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.draggable-portlets .sorted {
  min-height: 100px;
}
.draggable-portlets .sorted .ui-sortable-placeholder {
  background: rgba(255, 255, 204, 0.7);
  border: 1px dashed #dddddd;
  visibility: visible !important;
}
.draggable-portlets .sorted > .panel {
  -webkit-transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  -webkit-transition: opacity 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  -moz-transition: opacity 200ms ease-in-out, -moz-transform 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out, -o-transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out,-webkit-transform 200ms ease-in-out,-moz-transform 200ms ease-in-out,-o-transform 200ms ease-in-out,transform 200ms ease-in-out;
}
.draggable-portlets .sorted > .panel .panel-heading {
  cursor: pointer;
}
.draggable-portlets.dragging .sorted > .panel {
  -webkit-transform: scale(.97);
  -moz-transform: scale(.97);
  -o-transform: scale(.97);
  -ms-transform: scale(.97);
  transform: scale(.97);
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.draggable-portlets.dragging .sorted > .panel.ui-sortable-helper {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -o-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.notes-env {
  position: relative;
  margin-bottom: 30px;
}
.notes-env .notes-header {
  margin-bottom: 10px;
}
.notes-env .notes-list {
  position: relative;
}
.notes-env .notes-list .write-pad,
.notes-env .notes-list .list-of-notes {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.notes-env .notes-list .list-of-notes {
  float: left;
  width: 26%;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
}
.notes-env .notes-list .list-of-notes li {
  position: relative;
  padding-right: 10px;
  padding-bottom: 10px;
}
.notes-env .notes-list .list-of-notes li a {
  display: block;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  padding: 10px 15px;
}
.notes-env .notes-list .list-of-notes li a strong,
.notes-env .notes-list .list-of-notes li a span {
  display: block;
}
.notes-env .notes-list .list-of-notes li a strong + strong,
.notes-env .notes-list .list-of-notes li a span + strong,
.notes-env .notes-list .list-of-notes li a strong + span,
.notes-env .notes-list .list-of-notes li a span + span {
  padding-top: 8px;
}
.notes-env .notes-list .list-of-notes li a strong {
  word-break: break-all;
  background: #f5f5f5;
  margin: -15px;
  margin-top: -10px;
  margin-bottom: 0;
  padding: 8px 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .notes-env .notes-list .list-of-notes li a strong {
    margin-bottom: -10px;
    border-bottom: 0;
  }
}
.notes-env .notes-list .list-of-notes li a span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: 12px;
}
.notes-env .notes-list .list-of-notes li a em {
  float: right;
}
.notes-env .notes-list .list-of-notes li a:hover {
  background: #f5f5f5 !important;
  border-color: #d3d3d3;
}
.notes-env .notes-list .list-of-notes li a:hover span,
.notes-env .notes-list .list-of-notes li a:hover strong {
  color: #737881;
}
.notes-env .notes-list .list-of-notes li .note-close {
  position: absolute;
  right: 11px;
  top: 1px;
  border: none;
  background: none;
  outline: none;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-top: 0;
  border-right: 0;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
.notes-env .notes-list .list-of-notes li:hover .note-close {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.notes-env .notes-list .list-of-notes li .content {
  display: none;
}
.notes-env .notes-list .list-of-notes li.current a {
  background: #fff;
}
.notes-env .notes-list .list-of-notes li + .no-notes {
  display: none;
}
.notes-env .notes-list .write-pad {
  float: right;
  width: 74%;
  background: #ffffff;
  position: relative;
}
.notes-env .notes-list .write-pad:after {
  display: block;
  content: '';
  position: absolute;
  left: 95px;
  top: 0;
  bottom: 0;
  background: #f9d4d1;
  width: 1px;
}
.notes-env .notes-list .write-pad textarea {
  background: transparent;
  border: none;
  background: url(../images/notes-lines.png) left top;
  background-attachment: local;
  min-height: 780px;
  font: 14px/52px "Arimo", "Helvetica Neue", Helvetica, Arial, sans-serif, serif;
  border: 1px solid #dddddd;
  max-height: 1500px;
  padding-left: 125px;
  padding-right: 50px;
}
.notes-env .notes-list .write-pad textarea::-webkit-scrollbar {
  width: 5px;
}
.notes-env .notes-list .write-pad textarea::-webkit-scrollbar-track {
  width: 5px;
  background-color: #e0e0e0;
}
.notes-env .notes-list .write-pad textarea::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
}
.notes-env + footer.main {
  border-top: 0;
  padding-top: 10px;
}
@media (max-width: 992px) {
  .notes-env .notes-list .list-of-notes {
    width: 35%;
  }
  .notes-env .notes-list .write-pad {
    width: 65%;
  }
  .notes-env .notes-list .write-pad textarea {
    padding-left: 50px;
  }
  .notes-env .notes-list .write-pad:after {
    left: 35px;
  }
}
@media (max-width: 768px) {
  body .notes-env .notes-list .list-of-notes,
  body .notes-env .notes-list .write-pad {
    width: 100%;
    float: none;
  }
  body .notes-env .notes-list .list-of-notes {
    padding-bottom: 10px;
  }
  body .notes-env .notes-list .list-of-notes li {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 0;
  }
  body .notes-env .notes-list .list-of-notes li a span {
    display: none;
  }
  body .notes-env .notes-list .list-of-notes li .note-close {
    right: 1px;
    top: 11px;
  }
  body .notes-env .notes-list .write-pad textarea {
    min-height: 400px;
    max-height: 600px;
  }
}
.tocify {
  border: 1px solid #dddddd;
  background: #fff;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.tocify.fixed {
  position: fixed;
  top: 15px;
}
.navbar.horizontal-menu + .page-container .tocify.fixed {
  top: 110px;
}
@media (max-width: 991px) {
  .tocify {
    top: 0px;
    position: relative !important;
    margin-bottom: 30px;
  }
  .tocify .tocify-item.active > a:before,
  .tocify .tocify-item.active > a:after {
    display: none !important;
  }
}
.tocify li {
  border-top: 1px solid #f0f0f0;
}
.tocify li a {
  color: #979898;
}
.tocify li a:hover {
  background: #ffffff;
  color: #2c2e2f;
}
.tocify > ul:first-child > li {
  border-top: 0;
}
.tocify > ul ul li a {
  padding-left: 30px;
}
.tocify > ul ul {
  background: #ffffff;
}
.tocify > ul ul ul li a {
  padding-left: 40px;
}
.tocify > ul:last-child {
  border-bottom: 0;
}
.tocify .tocify-item.active > a {
  position: relative;
  background: #f6f6f6;
  color: #2c2e2f;
}
.tocify .tocify-item.active > a:after {
  content: '';
  display: block;
  position: absolute;
  top: 14px;
  right: -1px;
  width: 0;
  height: 0;
  border-color: transparent #eeeeee transparent transparent;
  border-style: solid;
  border-width: 6px;
}
.tocify .tocify-item.active > a:before {
  content: '';
  display: block;
  position: absolute;
  top: 13px;
  right: -1px;
  margin-left: -2px;
  width: 0;
  height: 0;
  border-color: transparent #dddddd transparent transparent;
  border-style: solid;
  border-width: 7px;
}
.tocify-extend-page {
  display: none !important;
}
.google-map-env {
  background: #e5e5e5;
  margin-bottom: 30px;
}
.google-map-env ~ .main-footer {
  display: none !important;
}
.google-map-env.fill {
  margin: -30px;
}
.google-map-env.fill .map-toolbar > .row {
  margin-left: 0;
  margin-right: 0;
}
.google-map-env .map-toolbar {
  padding: 20px 15px;
  background: #fff;
}
.google-map-env .map-toolbar + .gmap {
  border-top: 1px solid #ccc;
}
.google-map-env .gmap + .map-toolbar {
  border-top: 1px solid 0px;
}
.google-map-env .full-page-google-map {
  border-top: 1px solid #dddddd;
}
.google-map-env .full-page-google-map > div {
  width: 100%;
  height: 500px;
}
.jvectormap-label {
  position: absolute;
  display: none;
  background: #2c2e2f;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px 7px;
  z-index: 1000 !important;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  left: 10px;
  border: 1px solid #e7e7e7;
  background: #fafafa;
  padding: 3px;
  color: #979898;
  width: 28px;
  height: 28px;
  cursor: pointer;
  line-height: 19px;
  text-align: center;
  -webkit-border-radius: 0px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0px;
  -moz-background-clip: padding;
  border-radius: 0px;
  background-clip: padding-box;
}
.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  background-color: #f2f2f2;
}
.jvectormap-zoomin:active,
.jvectormap-zoomout:active {
  -webkit-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.jvectormap-zoomin {
  top: 10px;
}
.jvectormap-zoomout {
  top: 37px;
}
.xenon-loading-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(44, 46, 47, 0.3);
  height: 3px;
  z-index: 10000;
  top: 0px;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 220ms ease-in-out;
  -moz-transition: all 220ms ease-in-out;
  -o-transition: all 220ms ease-in-out;
  transition: all 220ms ease-in-out;
}
.xenon-loading-bar span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0%;
  background: #68b828;
}
.xenon-loading-bar.progress-is-hidden {
  top: -3px;
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
  visibility: hidden;
}
.toast-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.toast-close-button:hover,
.toast-close-button:focus {
  text-decoration: none;
  cursor: pointer;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
  margin-top: 15px;
}
.toast-top-full-width .toast {
  margin-bottom: 15px !important;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  margin: 0 0 6px;
  padding: 12px 15px;
  width: 300px;
  margin-bottom: 10px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-position: 15px center;
  background-repeat: no-repeat;
  color: #ffffff;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > div i {
  margin-right: 6px;
}
#toast-container > :hover {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #8dc63f;
}
.toast-error {
  background-color: #cc3f44;
}
.toast-info {
  background-color: #40bbea;
}
.toast-warning {
  background-color: #ffba00;
}
.toast-default > div {
  background-color: rgba(44, 46, 47, 0.8);
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.uk-nestable-item .uk-nestable-handle {
  padding: 5px 8px;
  background: #eeeeee;
}
.uk-nestable-item .uk-nestable-handle:hover,
.uk-nestable-item .uk-nestable-handle:active {
  background: #e1e1e1;
}
.uk-nestable-item [data-nestable-action="toggle"] {
  padding: 0 5px;
  padding-right: 0;
  display: none;
}
.uk-nestable-item .list-label {
  display: inline-block;
  line-height: 1;
  padding: 5px 8px;
}
.uk-parent > .uk-nestable-item [data-nestable-action='toggle'] {
  display: inline-block !important;
}
.login-page {
  background: #2c2e2f;
  color: #fff;
  padding-top: 150px;
}
.login-page .errors-container {
  margin-bottom: 20px;
  padding: 0 30px;
}
.login-page .login-form {
  position: relative;
  padding: 30px;
  margin-bottom: 20px;
}
.login-page .login-form.fade-in-effect {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 800ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -o-transition: all 800ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  transition: all 800ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
}
@media screen and (max-width: 768px) {
  .login-page .login-form.fade-in-effect {
    left: 0;
  }
}
.login-page .login-form.fade-in-effect .form-group {
  position: relative;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 500ms ease-out;
  -o-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
  -webkit-transition-delay: 150ms;
  -moz-transition-delay: 150ms;
  -o-transition-delay: 150ms;
  transition-delay: 150ms;
}
.login-page .login-form.fade-in-effect + .external-login {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -moz-transition: all 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -o-transition: all 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  transition: all 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
}
.login-page .login-form.fade-in-effect.in {
  left: 0;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.login-page .login-form.fade-in-effect.in .form-group {
  top: 0;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.login-page .login-form.fade-in-effect.in + .external-login {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.login-page .login-form .login-header {
  margin-bottom: 40px;
}
.login-page .login-form .login-header .logo {
  display: block;
}
.login-page .login-form .login-header .logo img {
  vertical-align: baseline;
}
.login-page .login-form .login-header .logo span {
  padding-left: 3px;
  color: rgba(151, 152, 152, 0.6);
  font-size: 15px;
  vertical-align: baseline;
}
.login-page .login-form .login-header p {
  color: #979898;
  font-size: 13px;
  margin: 0;
  margin-top: 10px;
}
.login-page .login-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.login-page .login-form .form-group .control-label {
  line-height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 13px;
  color: #979898;
  cursor: text;
}
.login-page .login-form .form-group .form-control {
  height: 40px;
}
.login-page .login-form .form-group .form-control::-moz-placeholder {
  color: rgba(151, 152, 152, 0.5);
  opacity: 1;
}
.login-page .login-form .form-group .form-control:-ms-input-placeholder {
  color: rgba(151, 152, 152, 0.5);
}
.login-page .login-form .form-group .form-control::-webkit-input-placeholder {
  color: rgba(151, 152, 152, 0.5);
}
.login-page .login-form .form-group .form-control::-webkit-input-placeholder {
  color: rgba(151, 152, 152, 0.5);
}
.login-page .login-form .form-group .form-control:-moz-placeholder {
  color: rgba(151, 152, 152, 0.5);
}
.login-page .login-form .form-group .form-control::-moz-placeholder {
  color: rgba(151, 152, 152, 0.5);
}
.login-page .login-form .form-group .form-control:-ms-input-placeholder {
  color: rgba(151, 152, 152, 0.5);
}
.login-page .login-form .form-group .form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  -webkit-text-fill-color: #979898;
  border: 1px solid #e4e4e4;
}
.login-page .login-form .form-group .form-control.input-dark {
  background: #313334;
  border: 1px solid transparent;
  color: #fff;
}
.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.login-page .login-form .form-group .form-control.input-dark:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #313334 inset;
  -webkit-text-fill-color: #fff;
  border: 0;
}
.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.login-page .login-form .form-group label.error {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-right: 12px;
  font-size: 12px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.7);
}
.login-page .login-form .form-group.validate-has-error .form-control {
  border-color: rgba(204, 63, 68, 0.5);
}
.login-page .login-form .form-group.is-focused .control-label {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  visibility: hidden;
}
.login-page .login-form .form-group .btn {
  padding-top: 11.11111111px;
  padding-bottom: 11.11111111px;
}
.login-page .login-form .form-group .btn i {
  margin-right: 5px;
}
.login-page .login-form .form-group .btn.btn-dark {
  border-color: rgba(55, 57, 58, 0.9);
  background-color: #2c2e2f;
  color: #fff;
}
.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(55, 57, 58, 0.3);
}
.login-page .login-form .login-footer {
  margin-top: 45px;
}
.login-page .login-form .login-footer a {
  color: #979898;
}
.login-page .login-form .login-footer a:hover {
  color: #bdbebe;
}
.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: rgba(151, 152, 152, 0.7);
}
.login-page .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.login-page .login-form .login-footer .info-links a:hover {
  color: #979898;
}
.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.login-page .external-login a i {
  margin-right: 5px;
}
.login-page .external-login a:hover {
  background: #454849;
}
.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.login-page .external-login a.twitter {
  background-color: #55acee;
}
.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.login-page.login-light {
  background: #eeeeee;
}
.login-page.login-light .errors-container,
.login-page.login-light .external-login {
  padding: 0;
}
.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .login-page {
    padding-top: 0;
  }
  .login-page .login-form,
  .login-page .errors-container,
  .login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .login-page .errors-container {
    margin-top: 20px;
  }
  .login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .login-page.login-light .errors-container,
  .login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .login-page .row {
    margin: 0;
  }
}
.login-container {
  max-width: 960px;
  margin: 0 auto;
}
.lockscreen-page {
  background: #2c2e2f;
  color: #fff;
  padding-top: 250px;
}
@media screen and (max-width: 768px) {
  .lockscreen-page {
    padding-top: 30px;
  }
  .lockscreen-page .row {
    margin: 0 auto;
  }
}
.lockscreen-page .lockcreen-form .user-thumb {
  float: left;
  width: 25%;
}
.lockscreen-page .lockcreen-form .user-thumb img {
  -webkit-box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.05);
  -moz-box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.05);
}
.lockscreen-page .lockcreen-form .form-group {
  position: relative;
  float: left;
  width: 75%;
  padding-left: 30px;
}
.lockscreen-page .lockcreen-form .form-group h3 {
  margin: 0;
}
.lockscreen-page .lockcreen-form .form-group p {
  color: #979898;
  font-size: 13px;
  margin: 0;
  margin-top: 10px;
}
.lockscreen-page .lockcreen-form .form-group .input-group {
  position: relative;
  margin-top: 20px;
}
.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  background: transparent;
  border: 1px solid #3e4142;
  color: #fff;
  height: 40px;
}
.lockscreen-page .lockcreen-form .form-group .input-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.lockscreen-page .lockcreen-form .form-group .input-group label.error {
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 10px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}
.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn {
  padding-left: 10px;
}
.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  border: 1px solid #3e4142;
  padding: 10px 20px;
}
.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(62, 65, 66, 0.3);
}
.lockscreen-page .lockcreen-form.fade-in-effect .user-thumb {
  position: relative;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 800ms ease-in-out;
  -moz-transition: all 800ms ease-in-out;
  -o-transition: all 800ms ease-in-out;
  transition: all 800ms ease-in-out;
}
.lockscreen-page .lockcreen-form.fade-in-effect .form-group {
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.lockscreen-page .lockcreen-form.fade-in-effect .form-group .input-group {
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
  -webkit-transition: all 800ms ease-in-out;
  -moz-transition: all 800ms ease-in-out;
  -o-transition: all 800ms ease-in-out;
  transition: all 800ms ease-in-out;
}
.lockscreen-page .lockcreen-form.fade-in-effect.in .user-thumb {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  left: 0;
}
.lockscreen-page .lockcreen-form.fade-in-effect.in .form-group {
  top: 0px;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.lockscreen-page .lockcreen-form.fade-in-effect.in .form-group .input-group {
  top: 0px;
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
@media screen and (max-width: 768px) {
  .lockscreen-page .lockcreen-form .user-thumb,
  .lockscreen-page .lockcreen-form .form-group {
    float: none;
    width: 100%;
  }
  .lockscreen-page .lockcreen-form .user-thumb {
    text-align: center;
    margin-bottom: 30px;
  }
  .lockscreen-page .lockcreen-form .user-thumb a {
    display: inline-block;
  }
  .lockscreen-page .lockcreen-form .form-group {
    padding-left: 0;
    text-align: center;
  }
}
.xe-widget {
  position: relative;
}
.xe-widget.xe-counter,
.xe-widget.xe-counter-block .xe-upper,
.xe-widget.xe-progress-counter .xe-upper {
  background: #fff;
  padding: 28px;
  line-height: 1;
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.xe-widget.xe-counter .xe-icon,
.xe-widget.xe-counter .xe-label,
.xe-widget.xe-counter-block .xe-upper .xe-icon,
.xe-widget.xe-counter-block .xe-upper .xe-label,
.xe-widget.xe-progress-counter .xe-upper .xe-icon,
.xe-widget.xe-progress-counter .xe-upper .xe-label {
  display: table-cell;
  vertical-align: middle;
  padding: 18px;
}
.xe-widget.xe-counter .xe-icon,
.xe-widget.xe-counter-block .xe-upper .xe-icon,
.xe-widget.xe-progress-counter .xe-upper .xe-icon {
  width: 1%;
}
.xe-widget.xe-counter .xe-icon i,
.xe-widget.xe-counter-block .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper .xe-icon i {
  display: block;
  background: #68b828;
  color: #fff;
  text-align: center;
  font-size: 25px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.xe-widget.xe-counter .xe-icon + .xe-label,
.xe-widget.xe-counter-block .xe-upper .xe-icon + .xe-label,
.xe-widget.xe-progress-counter .xe-upper .xe-icon + .xe-label {
  padding-left: 0;
}
.xe-widget.xe-counter .xe-label .num,
.xe-widget.xe-counter-block .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter .xe-upper .xe-label .num {
  display: block;
  font-size: 28px;
  font-weight: normal;
  color: #3e3f3f;
}
.xe-widget.xe-counter .xe-label span,
.xe-widget.xe-counter-block .xe-upper .xe-label span,
.xe-widget.xe-progress-counter .xe-upper .xe-label span {
  display: block;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: #979898;
  margin-top: 5px;
}
.xe-widget.xe-counter .xe-label + .xe-icon,
.xe-widget.xe-counter-block .xe-upper .xe-label + .xe-icon,
.xe-widget.xe-progress-counter .xe-upper .xe-label + .xe-icon {
  padding-left: 0;
}
.xe-widget.xe-counter.xe-counter-primary .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-primary .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-primary .xe-icon i {
  background-color: #2c2e2f;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-success .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-success .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-success .xe-icon i {
  background-color: #8dc63f;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-warning .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-warning .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-warning .xe-icon i {
  background-color: #ffba00;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-info .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-info .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-info .xe-icon i {
  background-color: #40bbea;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-danger .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-danger .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-danger .xe-icon i {
  background-color: #cc3f44;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-blue .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-blue .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-blue .xe-icon i {
  background-color: #0e62c7;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-red .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-red .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-red .xe-icon i {
  background-color: #d5080f;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-purple .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-purple .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-purple .xe-icon i {
  background-color: #7c38bc;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-orange .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-orange .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-orange .xe-icon i {
  background-color: #f7aa47;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-yellow .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-yellow .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-yellow .xe-icon i {
  background-color: #fcd036;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-turquoise .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-turquoise .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-turquoise .xe-icon i {
  background-color: #00b19d;
  color: #ffffff;
}
.xe-widget.xe-counter.xe-counter-white .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-white .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-white .xe-icon i {
  background-color: #ffffff;
  color: #4b4b4b;
}
.xe-widget.xe-counter.xe-counter-white .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-white .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-white .xe-icon i {
  border: 1px solid #dddddd;
}
.xe-widget.xe-counter.xe-counter-gray .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-gray .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-gray .xe-icon i {
  background-color: #eeeeee;
  color: #555555;
}
.xe-widget.xe-counter.xe-counter-pink .xe-icon i,
.xe-widget.xe-counter-block .xe-upper.xe-counter-pink .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper.xe-counter-pink .xe-icon i {
  background-color: #ff6264;
  color: #ffffff;
}
.xe-widget.xe-counter-block,
.xe-widget.xe-progress-counter {
  color: #fff;
  background: #68b828;
  margin-bottom: 20px;
}
.xe-widget.xe-counter-block .xe-upper,
.xe-widget.xe-progress-counter .xe-upper {
  margin: 0;
  border-spacing: 0;
  border: 0;
  background: #68b828;
}
.xe-widget.xe-counter-block .xe-upper .xe-icon,
.xe-widget.xe-counter-block .xe-upper .xe-label,
.xe-widget.xe-progress-counter .xe-upper .xe-icon,
.xe-widget.xe-progress-counter .xe-upper .xe-label {
  padding-bottom: 0;
}
.xe-widget.xe-counter-block .xe-upper .xe-icon,
.xe-widget.xe-progress-counter .xe-upper .xe-icon {
  text-align: center;
}
.xe-widget.xe-counter-block .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter .xe-upper .xe-icon i {
  background: #68b828;
  font-size: 32px;
}
.xe-widget.xe-counter-block .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter .xe-upper .xe-label .num {
  color: #fff;
}
.xe-widget.xe-counter-block .xe-upper .xe-label span,
.xe-widget.xe-progress-counter .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block .xe-lower,
.xe-widget.xe-progress-counter .xe-lower {
  padding: 15px 30px;
}
.xe-widget.xe-counter-block .xe-lower .border,
.xe-widget.xe-progress-counter .xe-lower .border {
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}
.xe-widget.xe-counter-block .xe-lower span,
.xe-widget.xe-counter-block .xe-lower strong,
.xe-widget.xe-progress-counter .xe-lower span,
.xe-widget.xe-progress-counter .xe-lower strong {
  font-size: 10px;
  text-transform: uppercase;
  display: block;
}
.xe-widget.xe-counter-block .xe-lower span,
.xe-widget.xe-progress-counter .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block .xe-lower strong,
.xe-widget.xe-progress-counter .xe-lower strong {
  font-weight: normal;
}
.xe-widget.xe-counter-block.xe-counter-block-primary,
.xe-widget.xe-progress-counter.xe-counter-block-primary {
  background: #2c2e2f;
}
.xe-widget.xe-counter-block.xe-counter-block-primary .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-primary .xe-upper {
  background: #2c2e2f;
}
.xe-widget.xe-counter-block.xe-counter-block-primary .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-primary .xe-upper .xe-icon i {
  background-color: #2c2e2f;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-primary .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-primary .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-primary .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-primary .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-primary .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-primary .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-primary .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-primary .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-primary .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-primary .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-success,
.xe-widget.xe-progress-counter.xe-counter-block-success {
  background: #8dc63f;
}
.xe-widget.xe-counter-block.xe-counter-block-success .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-success .xe-upper {
  background: #8dc63f;
}
.xe-widget.xe-counter-block.xe-counter-block-success .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-success .xe-upper .xe-icon i {
  background-color: #8dc63f;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-success .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-success .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-success .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-success .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-success .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-success .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-success .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-success .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-success .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-success .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-warning,
.xe-widget.xe-progress-counter.xe-counter-block-warning {
  background: #ffba00;
}
.xe-widget.xe-counter-block.xe-counter-block-warning .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-warning .xe-upper {
  background: #ffba00;
}
.xe-widget.xe-counter-block.xe-counter-block-warning .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-warning .xe-upper .xe-icon i {
  background-color: #ffba00;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-warning .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-warning .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-warning .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-warning .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-warning .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-warning .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-warning .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-warning .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-warning .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-warning .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-danger,
.xe-widget.xe-progress-counter.xe-counter-block-danger {
  background: #cc3f44;
}
.xe-widget.xe-counter-block.xe-counter-block-danger .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-danger .xe-upper {
  background: #cc3f44;
}
.xe-widget.xe-counter-block.xe-counter-block-danger .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-danger .xe-upper .xe-icon i {
  background-color: #cc3f44;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-danger .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-danger .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-danger .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-danger .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-danger .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-danger .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-danger .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-danger .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-danger .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-danger .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-info,
.xe-widget.xe-progress-counter.xe-counter-block-info {
  background: #40bbea;
}
.xe-widget.xe-counter-block.xe-counter-block-info .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-info .xe-upper {
  background: #40bbea;
}
.xe-widget.xe-counter-block.xe-counter-block-info .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-info .xe-upper .xe-icon i {
  background-color: #40bbea;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-info .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-info .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-info .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-info .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-info .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-info .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-info .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-info .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-info .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-info .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-purple,
.xe-widget.xe-progress-counter.xe-counter-block-purple {
  background: #7c38bc;
}
.xe-widget.xe-counter-block.xe-counter-block-purple .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-purple .xe-upper {
  background: #7c38bc;
}
.xe-widget.xe-counter-block.xe-counter-block-purple .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-purple .xe-upper .xe-icon i {
  background-color: #7c38bc;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-purple .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-purple .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-purple .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-purple .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-purple .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-purple .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-purple .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-purple .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-purple .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-purple .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-blue,
.xe-widget.xe-progress-counter.xe-counter-block-blue {
  background: #0e62c7;
}
.xe-widget.xe-counter-block.xe-counter-block-blue .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-blue .xe-upper {
  background: #0e62c7;
}
.xe-widget.xe-counter-block.xe-counter-block-blue .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-blue .xe-upper .xe-icon i {
  background-color: #0e62c7;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-blue .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-blue .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-blue .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-blue .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-blue .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-blue .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-blue .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-blue .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-blue .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-blue .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-red,
.xe-widget.xe-progress-counter.xe-counter-block-red {
  background: #d5080f;
}
.xe-widget.xe-counter-block.xe-counter-block-red .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-red .xe-upper {
  background: #d5080f;
}
.xe-widget.xe-counter-block.xe-counter-block-red .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-red .xe-upper .xe-icon i {
  background-color: #d5080f;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-red .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-red .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-red .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-red .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-red .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-red .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-red .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-red .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-red .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-red .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-orange,
.xe-widget.xe-progress-counter.xe-counter-block-orange {
  background: #f7aa47;
}
.xe-widget.xe-counter-block.xe-counter-block-orange .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-orange .xe-upper {
  background: #f7aa47;
}
.xe-widget.xe-counter-block.xe-counter-block-orange .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-orange .xe-upper .xe-icon i {
  background-color: #f7aa47;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-orange .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-orange .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-orange .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-orange .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-orange .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-orange .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-orange .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-orange .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-orange .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-orange .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise {
  background: #00b19d;
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise .xe-upper {
  background: #00b19d;
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise .xe-upper .xe-icon i {
  background-color: #00b19d;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-turquoise .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-turquoise .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-pink,
.xe-widget.xe-progress-counter.xe-counter-block-pink {
  background: #ff6264;
}
.xe-widget.xe-counter-block.xe-counter-block-pink .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-pink .xe-upper {
  background: #ff6264;
}
.xe-widget.xe-counter-block.xe-counter-block-pink .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-pink .xe-upper .xe-icon i {
  background-color: #ff6264;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-pink .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-pink .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-pink .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-pink .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-pink .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-pink .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-pink .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-pink .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-pink .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-pink .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-yellow,
.xe-widget.xe-progress-counter.xe-counter-block-yellow {
  background: #fcd036;
}
.xe-widget.xe-counter-block.xe-counter-block-yellow .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-yellow .xe-upper {
  background: #fcd036;
}
.xe-widget.xe-counter-block.xe-counter-block-yellow .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-yellow .xe-upper .xe-icon i {
  background-color: #fcd036;
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-yellow .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-yellow .xe-upper .xe-label .num {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-yellow .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-yellow .xe-upper .xe-label span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-yellow .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-yellow .xe-lower .border {
  background: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-yellow .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-yellow .xe-lower span {
  color: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-yellow .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-yellow .xe-lower strong {
  color: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-gray,
.xe-widget.xe-progress-counter.xe-counter-block-gray {
  background: #d5d5d5;
}
.xe-widget.xe-counter-block.xe-counter-block-gray .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-gray .xe-upper {
  background: #d5d5d5;
}
.xe-widget.xe-counter-block.xe-counter-block-gray .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-gray .xe-upper .xe-icon i {
  background-color: #d5d5d5;
  color: #666666;
}
.xe-widget.xe-counter-block.xe-counter-block-gray .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-gray .xe-upper .xe-label .num {
  color: #666666;
}
.xe-widget.xe-counter-block.xe-counter-block-gray .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-gray .xe-upper .xe-label span {
  color: rgba(102, 102, 102, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-gray .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-gray .xe-lower .border {
  background: rgba(102, 102, 102, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-gray .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-gray .xe-lower span {
  color: rgba(102, 102, 102, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-gray .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-gray .xe-lower strong {
  color: #666666;
}
.xe-widget.xe-counter-block.xe-counter-block-white,
.xe-widget.xe-progress-counter.xe-counter-block-white {
  background: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-white .xe-upper,
.xe-widget.xe-progress-counter.xe-counter-block-white .xe-upper {
  background: #ffffff;
}
.xe-widget.xe-counter-block.xe-counter-block-white .xe-upper .xe-icon i,
.xe-widget.xe-progress-counter.xe-counter-block-white .xe-upper .xe-icon i {
  background-color: #ffffff;
  color: #4b4b4b;
}
.xe-widget.xe-counter-block.xe-counter-block-white .xe-upper .xe-label .num,
.xe-widget.xe-progress-counter.xe-counter-block-white .xe-upper .xe-label .num {
  color: #4b4b4b;
}
.xe-widget.xe-counter-block.xe-counter-block-white .xe-upper .xe-label span,
.xe-widget.xe-progress-counter.xe-counter-block-white .xe-upper .xe-label span {
  color: rgba(75, 75, 75, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-white .xe-lower .border,
.xe-widget.xe-progress-counter.xe-counter-block-white .xe-lower .border {
  background: rgba(75, 75, 75, 0.5);
}
.xe-widget.xe-counter-block.xe-counter-block-white .xe-lower span,
.xe-widget.xe-progress-counter.xe-counter-block-white .xe-lower span {
  color: rgba(75, 75, 75, 0.7);
}
.xe-widget.xe-counter-block.xe-counter-block-white .xe-lower strong,
.xe-widget.xe-progress-counter.xe-counter-block-white .xe-lower strong {
  color: #4b4b4b;
}
.xe-widget.xe-progress-counter {
  position: relative;
  color: #fff;
  background: #68b828;
  margin-bottom: 20px;
  overflow: hidden;
}
.xe-widget.xe-progress-counter .xe-background {
  position: absolute;
  left: -40%;
  bottom: -10%;
  color: #fff;
  font-size: 150px;
  zoom: 1;
  -webkit-opacity: 0.1;
  -moz-opacity: 0.1;
  opacity: 0.1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  filter: alpha(opacity=10);
}
.xe-widget.xe-progress-counter .xe-background i {
  line-height: 1;
  display: block;
}
.xe-widget.xe-progress-counter .xe-upper .xe-icon i {
  font-size: 28px;
  background: none;
}
.xe-widget.xe-progress-counter .xe-upper .xe-label span {
  padding: 0;
  padding-bottom: 5px;
}
.xe-widget.xe-progress-counter .xe-upper .xe-label .num {
  font-size: 20px;
}
.xe-widget.xe-progress-counter .xe-progress {
  height: 2px;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 30px;
  margin-top: 10px;
}
.xe-widget.xe-progress-counter .xe-progress .xe-progress-fill {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-primary {
  background-color: #2c2e2f;
}
.xe-widget.xe-progress-counter.xe-progress-counter-primary .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-primary .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-success {
  background-color: #8dc63f;
}
.xe-widget.xe-progress-counter.xe-progress-counter-success .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-success .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-info {
  background-color: #40bbea;
}
.xe-widget.xe-progress-counter.xe-progress-counter-info .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-info .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-danger {
  background-color: #cc3f44;
}
.xe-widget.xe-progress-counter.xe-progress-counter-danger .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-danger .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-warning {
  background-color: #ffba00;
}
.xe-widget.xe-progress-counter.xe-progress-counter-warning .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-warning .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-pink {
  background-color: #ff6264;
}
.xe-widget.xe-progress-counter.xe-progress-counter-pink .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-pink .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-turquoise {
  background-color: #00b19d;
}
.xe-widget.xe-progress-counter.xe-progress-counter-turquoise .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-turquoise .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-red {
  background-color: #d5080f;
}
.xe-widget.xe-progress-counter.xe-progress-counter-red .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-red .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-orange {
  background-color: #f7aa47;
}
.xe-widget.xe-progress-counter.xe-progress-counter-orange .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-orange .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-yellow {
  background-color: #fcd036;
}
.xe-widget.xe-progress-counter.xe-progress-counter-yellow .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-yellow .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-progress-counter.xe-progress-counter-purple {
  background-color: #7c38bc;
}
.xe-widget.xe-progress-counter.xe-progress-counter-purple .xe-background {
  color: #ffffff;
}
.xe-widget.xe-progress-counter.xe-progress-counter-purple .xe-upper {
  background-color: transparent;
}
.xe-widget.xe-vertical-counter {
  background: #68b828;
  padding: 20px;
  padding-top: 40px;
  margin-bottom: 20px;
  color: #fff;
}
.xe-widget.xe-vertical-counter .xe-icon {
  font-size: 75px;
  text-align: center;
  padding-bottom: 15px;
}
.xe-widget.xe-vertical-counter .xe-icon i {
  display: block;
  line-height: 1.4;
}
.xe-widget.xe-vertical-counter .xe-label {
  padding-top: 15px;
  text-align: center;
}
.xe-widget.xe-vertical-counter .xe-label .num {
  display: block;
  font-weight: normal;
  font-size: 23px;
}
.xe-widget.xe-vertical-counter .xe-label span {
  display: block;
  padding-top: 5px;
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-primary {
  background: #2c2e2f;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-primary .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-success {
  background: #8dc63f;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-success .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-danger {
  background: #cc3f44;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-danger .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-warning {
  background: #ffba00;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-warning .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-info {
  background: #40bbea;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-info .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-purple {
  background: #7c38bc;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-purple .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-orange {
  background: #f7aa47;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-orange .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-red {
  background: #d5080f;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-red .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-blue {
  background: #0e62c7;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-blue .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-turquoise {
  background: #00b19d;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-turquoise .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-pink {
  background: #ff6264;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-pink .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-yellow {
  background: #fcd036;
  color: #ffffff;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-yellow .xe-label span {
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-gray {
  background: #d5d5d5;
  color: #555555;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-gray .xe-label span {
  color: rgba(85, 85, 85, 0.95);
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-white {
  background: #ffffff;
  color: #4b4b4b;
}
.xe-widget.xe-vertical-counter.xe-vertical-counter-white .xe-label span {
  color: rgba(75, 75, 75, 0.95);
}
.xe-widget.xe-todo-list {
  background: #fff;
  padding: 25px 30px;
  margin-bottom: 20px;
}
.xe-widget.xe-todo-list .xe-header {
  display: table;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}
.xe-widget.xe-todo-list .xe-header .xe-icon,
.xe-widget.xe-todo-list .xe-header .xe-label {
  display: table-cell;
  vertical-align: top;
  padding-bottom: 20px;
}
.xe-widget.xe-todo-list .xe-header .xe-icon {
  width: 1%;
}
.xe-widget.xe-todo-list .xe-header .xe-icon i {
  display: block;
  line-height: 1;
  font-size: 28px;
}
.xe-widget.xe-todo-list .xe-header .xe-label {
  padding-left: 15px;
}
.xe-widget.xe-todo-list .xe-header .xe-label span,
.xe-widget.xe-todo-list .xe-header .xe-label strong {
  display: block;
  line-height: 1.1;
}
.xe-widget.xe-todo-list .xe-header .xe-label span {
  font-size: 12px;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list .xe-header .xe-label strong {
  font-size: 15px;
}
.xe-widget.xe-todo-list .xe-body {
  padding-top: 5px;
  padding-bottom: 10px;
}
.xe-widget.xe-todo-list .xe-body label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.xe-widget.xe-todo-list .xe-body label > span {
  position: relative;
  display: inline-block;
  color: #7d7f7f;
}
.xe-widget.xe-todo-list .xe-body li {
  margin: 2px 0;
}
.xe-widget.xe-todo-list .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.xe-widget.xe-todo-list .xe-body li.done label > span:after {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 9px;
  height: 1px;
  background: #8a8b8b;
  content: '';
}
.xe-widget.xe-todo-list.xe-todo-list-primary {
  background: #2c2e2f;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-primary .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-success {
  background: #8dc63f;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-success .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-warning {
  background: #ffba00;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-warning .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-danger {
  background: #cc3f44;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-danger .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-info {
  background: #40bbea;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-info .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary {
  background: #68b828;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-secondary .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise {
  background: #00b19d;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-turquoise .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-pink {
  background: #ff6264;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-pink .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-red {
  background: #d5080f;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-red .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-blue {
  background: #0e62c7;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-blue .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-purple {
  background: #7c38bc;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-purple .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow {
  background: #fcd036;
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-body .cbr-replaced .cbr-state {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-body label > span {
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-body li.done label > span {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-body li.done label > span:after {
  background: rgba(255, 255, 255, 0.7);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-todo-list.xe-todo-list-yellow .xe-footer .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}
.xe-widget.xe-status-update {
  background: #55acee;
  color: #fff;
  margin-bottom: 20px;
  padding: 30px;
  padding-bottom: 20px;
}
.xe-widget.xe-status-update .xe-header {
  display: table;
  width: 100%;
}
.xe-widget.xe-status-update .xe-header .xe-icon,
.xe-widget.xe-status-update .xe-header .xe-nav {
  display: table-cell;
  vertical-align: top;
  color: #fff;
  padding-bottom: 20px;
}
.xe-widget.xe-status-update .xe-header .xe-icon i {
  font-size: 48px;
}
.xe-widget.xe-status-update .xe-header .xe-nav {
  text-align: right;
}
.xe-widget.xe-status-update .xe-header .xe-nav a {
  position: relative;
  margin-right: -10px;
  margin-top: -10px;
  color: #fff;
  padding: 0 8px;
  display: inline-block;
  line-height: 32px;
}
.xe-widget.xe-status-update .xe-header .xe-nav a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.xe-widget.xe-status-update .xe-header .xe-nav a + a {
  margin-left: 10px;
}
.xe-widget.xe-status-update .xe-body li {
  display: none !important;
}
.xe-widget.xe-status-update .xe-body li .status-date {
  display: block;
  font-size: 16px;
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
  padding-bottom: 10px;
}
.xe-widget.xe-status-update .xe-body li p {
  font-size: 14px;
  color: #fff;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-status-update .xe-body li.active {
  display: block !important;
}
.xe-widget.xe-status-update .xe-footer {
  text-transform: uppercase;
  font-size: 11px;
  padding-top: 15px;
}
.xe-widget.xe-status-update .xe-footer a {
  color: #fff;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-status-update .xe-footer a:hover {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.xe-widget.xe-status-update.xe-status-update-facebook {
  background-color: #3b5998;
}
.xe-widget.xe-status-update.xe-status-update-google-plus {
  background-color: #dd4b39;
}
.xe-widget.xe-single-news {
  margin-bottom: 20px;
  position: relative;
}
.xe-widget.xe-single-news .xe-image img {
  display: block;
  width: 100%;
}
.xe-widget.xe-single-news .xe-image .xe-gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0icmdiKDAsIDAsIDApIiBzdG9wLW9wYWNpdHk9IjAuMDUiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjbGVzc2hhdC1nZW5lcmF0ZWQpIiAvPjwvc3ZnPg==);
  background-image: -webkit-linear-gradient(bottom, #000000 0%, rgba(0, 0, 0, 0.05) 100%);
  background-image: -moz-linear-gradient(bottom, #000000 0%, rgba(0, 0, 0, 0.05) 100%);
  background-image: -o-linear-gradient(bottom, #000000 0%, rgba(0, 0, 0, 0.05) 100%);
  background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0.05) 100%);
}
.xe-widget.xe-single-news .xe-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}
.xe-widget.xe-single-news .xe-details .category {
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.xe-widget.xe-single-news .xe-details .category a {
  color: #fff;
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.xe-widget.xe-single-news .xe-details .category a:hover {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.xe-widget.xe-single-news .xe-details h3 {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-size: 19px;
}
.xe-widget.xe-single-news .xe-details h3 a {
  color: #fff;
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.xe-widget.xe-single-news .xe-details h3 a:hover {
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}
.xe-widget.xe-single-news .xe-details time {
  position: relative;
  display: block;
  color: #fff;
  font-size: 13px;
  margin-bottom: -20px;
  -webkit-transition: all 350ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -moz-transition: all 350ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -o-transition: all 350ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition: all 350ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
}
.xe-widget.xe-single-news:hover .xe-details time {
  margin-bottom: 0;
  zoom: 1;
  -webkit-opacity: 0.7;
  -moz-opacity: 0.7;
  opacity: 0.7;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  filter: alpha(opacity=70);
}
.xe-widget.xe-conversations {
  position: relative;
  background: #fff;
  margin-bottom: 20px;
  padding: 30px;
}
.xe-widget.xe-conversations .xe-bg-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}
.xe-widget.xe-conversations .xe-bg-icon i {
  font-size: 80px;
  display: block;
  line-height: 1;
  zoom: 1;
  -webkit-opacity: 0.1;
  -moz-opacity: 0.1;
  opacity: 0.1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  filter: alpha(opacity=10);
}
.xe-widget.xe-conversations .xe-header {
  display: table;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.xe-widget.xe-conversations .xe-header .xe-icon,
.xe-widget.xe-conversations .xe-header .xe-label {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 20px;
}
.xe-widget.xe-conversations .xe-header .xe-icon {
  width: 1%;
}
.xe-widget.xe-conversations .xe-header .xe-icon i {
  display: block;
  line-height: 1;
  font-size: 37px;
}
.xe-widget.xe-conversations .xe-header .xe-label {
  padding-left: 10px;
}
.xe-widget.xe-conversations .xe-header .xe-label h3 {
  display: block;
  margin: 0;
  padding: 0;
}
.xe-widget.xe-conversations .xe-header .xe-label h3 small {
  padding-top: 5px;
  font-size: 13px;
  display: block;
  zoom: 1;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
}
.xe-widget.xe-conversations .xe-body {
  border-bottom: 1px solid #eee;
}
.xe-widget.xe-conversations .xe-body .xe-user-img {
  float: left;
  display: block;
  padding-right: 10px;
}
.xe-widget.xe-conversations .xe-body .xe-comment-entry {
  display: table;
  width: 100%;
}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-user-img,
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment {
  display: table-cell;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-user-img img {
  display: block;
}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment {
  width: 99%;
  padding-left: 10px;
}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment .xe-user-name {
  display: block;
  margin-bottom: 5px;
}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment .xe-user-name:hover strong {
  color: #7d7f7f;
}
.xe-widget.xe-conversations .xe-body .xe-comment-entry .xe-comment p {
  font-size: 12px;
}
.xe-widget.xe-conversations .xe-body ul > li {
  margin: 10px 0;
  border-bottom: 1px solid #eee;
}
.xe-widget.xe-conversations .xe-body ul > li:last-child {
  border-bottom: 0;
}
.xe-widget.xe-conversations .xe-footer a {
  margin-top: 20px;
  display: block;
  font-size: 11px;
  color: #7d7f7f;
  text-align: center;
  text-transform: uppercase;
}
.xe-widget.xe-conversations .xe-footer a:hover {
  color: #646565;
}
.xe-widget.xe-weather {
  position: relative;
  background: #459ec4;
  color: #fff;
  overflow: hidden;
  margin-bottom: 20px;
}
.xe-widget.xe-weather .xe-background {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
}
.xe-widget.xe-weather .xe-background img {
  display: block;
  line-height: 1;
  width: 100%;
  position: relative;
}
.xe-widget.xe-weather .xe-background.xe-background-animated {
  left: -10%;
  top: -20%;
}
.xe-widget.xe-weather .xe-background.xe-background-animated img {
  width: 120%;
  -webkit-animation: weatherBgMove 10s infinite linear;
  -moz-animation: weatherBgMove 10s infinite linear;
  -o-animation: weatherBgMove 10s infinite linear;
  animation: weatherBgMove 10s infinite linear;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  animation-direction: alternate;
}
.xe-widget.xe-weather .xe-background.xe-background-animated lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes weatherBgMove{ 0% { left: 0%; } 50% { left: 10%; } 100% { left: 0%; }}
@-moz-keyframes weatherBgMove{ 0% { left: 0%; } 50% { left: 10%; } 100% { left: 0%; }}
@-o-keyframes weatherBgMove{ 0% { left: 0%; } 50% { left: 10%; } 100% { left: 0%; }}
@keyframes weatherBgMove{ 0% { left: 0%; } 50% { left: 10%; } 100% { left: 0%; };
}
@media screen and (max-width: 768px) {
  .xe-widget.xe-weather .xe-background {
    top: auto;
    bottom: 0;
  }
  .xe-widget.xe-weather .xe-background.xe-background-animated {
    top: auto;
    bottom: 0;
  }
}
.xe-widget.xe-weather .xe-current-day {
  position: absolute;
  z-index: 2;
  right: 35%;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 30px;
}
.xe-widget.xe-weather .xe-current-day .xe-now {
  margin-bottom: 20px;
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-temperature {
  display: table;
  float: left;
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-temperature .xe-icon,
.xe-widget.xe-weather .xe-current-day .xe-now .xe-temperature .xe-label {
  display: table-cell;
  vertical-align: middle;
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-temperature .xe-icon i {
  display: block;
  line-height: 1;
  font-size: 35px;
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-temperature .xe-label {
  padding-left: 10px;
  line-height: 1;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-temperature .xe-label strong {
  display: block;
  padding-top: 2px;
  font-size: 23px;
  line-height: 1;
  color: #fff;
  font-weight: normal;
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-location {
  float: right;
  text-align: right;
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-location h4 {
  margin: 0;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.95);
}
.xe-widget.xe-weather .xe-current-day .xe-now .xe-location time {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 30px;
  padding-bottom: 30px;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: table;
  width: 100%;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast ul > li {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 1%;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast ul > li:after {
  content: '';
  display: block;
  width: 1px;
  top: 32px;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast ul > li:first-child:after {
  display: none;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast .xe-forecast-entry {
  text-align: center;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast .xe-forecast-entry time {
  display: inline-block;
  background: rgba(0, 0, 0, 0.15);
  padding: 5px 5px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.7);
  font-size: 11px;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast .xe-forecast-entry .xe-icon i {
  display: block;
  line-height: 42px;
  font-size: 20px;
}
.xe-widget.xe-weather .xe-current-day .xe-forecast .xe-forecast-entry .xe-temp {
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
}
.xe-widget.xe-weather .xe-current-day .xe-forecast .xe-forecast-entry:hover time,
.xe-widget.xe-weather .xe-current-day .xe-forecast .xe-forecast-entry:hover .xe-temp {
  color: #fff;
}
@media screen and (max-width: 768px) {
  .xe-widget.xe-weather .xe-current-day {
    position: relative;
    width: 100%;
  }
  .xe-widget.xe-weather .xe-current-day .xe-forecast {
    position: relative;
    margin-top: 90px;
    padding: 0;
  }
}
.xe-widget.xe-weather .xe-weekdays {
  float: right;
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.05);
  padding: 15px 30px;
  width: 35%;
}
.xe-widget.xe-weather .xe-weekdays ul > li {
  position: relative;
}
.xe-widget.xe-weather .xe-weekdays ul > li:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.25);
}
.xe-widget.xe-weather .xe-weekdays ul > li:last-child:after {
  display: none;
}
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast {
  display: table;
  width: 100%;
  cursor: default;
}
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-temp,
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-day,
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-icon {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 0;
}
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-temp {
  width: 20%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-icon {
  text-align: right;
  font-size: 20px;
}
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-icon i {
  display: block;
  line-height: 1;
}
.xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast:hover .xe-temp {
  color: rgba(255, 255, 255, 0.95);
}
@media screen and (max-width: 768px) {
  .xe-widget.xe-weather .xe-weekdays {
    float: none;
    width: 100%;
  }
  .xe-widget.xe-weather .xe-weekdays .xe-weekday-forecast .xe-temp {
    width: 10%;
  }
}
.xe-widget.xe-map-stats {
  background: #385868;
  color: #fff;
  margin-bottom: 20px;
  display: table;
  width: 100%;
}
.xe-widget.xe-map-stats .xe-map,
.xe-widget.xe-map-stats .xe-details {
  display: table-cell;
  vertical-align: top;
}
.xe-widget.xe-map-stats .xe-map {
  width: 65%;
  position: relative;
}
.xe-widget.xe-map-stats .xe-map > div {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.xe-widget.xe-map-stats .xe-map > div .jvectormap-zoomin,
.xe-widget.xe-map-stats .xe-map > div .jvectormap-zoomout {
  width: 22px;
  height: 22px;
  line-height: 20px;
  padding: 0;
  border: 0;
}
.xe-widget.xe-map-stats .xe-map > div .jvectormap-zoomin.jvectormap-zoomout,
.xe-widget.xe-map-stats .xe-map > div .jvectormap-zoomout.jvectormap-zoomout {
  top: 32px;
  border-top: 1px solid rgba(204, 204, 204, 0.8);
}
.xe-widget.xe-map-stats .xe-details {
  width: 35%;
  background: rgba(0, 0, 0, 0.3);
  padding: 30px;
}
.xe-widget.xe-map-stats .xe-details > .xe-label h3 {
  margin: 0;
}
.xe-widget.xe-map-stats .xe-details > .xe-label p {
  margin: 0;
  padding-top: 5px;
  color: rgba(255, 255, 255, 0.8);
}
.xe-widget.xe-map-stats .xe-details ul {
  margin-top: 20px;
}
.xe-widget.xe-map-stats .xe-details ul li {
  position: relative;
  padding: 10px 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.xe-widget.xe-map-stats .xe-details .xe-map-data {
  display: block;
}
.xe-widget.xe-map-stats .xe-details .xe-map-data .label {
  display: inline-block;
  text-align: center;
  min-width: 30px;
  margin-right: 5px;
  vertical-align: middle;
}
.xe-widget.xe-map-stats .xe-details .xe-map-data .xe-label {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .xe-widget.xe-map-stats {
    display: block;
  }
  .xe-widget.xe-map-stats .xe-map {
    min-height: 300px;
  }
  .xe-widget.xe-map-stats .xe-map,
  .xe-widget.xe-map-stats .xe-details {
    width: 100%;
    display: block;
  }
}
.sidebar-user-info {
  background-color: #27292a;
  border-bottom: 1px solid #323435;
}
@media screen and (max-width: 768px) {
  .sidebar-user-info {
    display: none;
  }
  .sidebar-user-info.mobile-is-visible {
    display: block;
  }
}
.sidebar-user-info .user-profile {
  display: block;
  padding: 30px 45px;
}
@media screen and (max-width: 768px) {
  .sidebar-user-info .user-profile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.sidebar-user-info .user-profile img {
  margin-right: 20px;
}
.sidebar-user-info .user-profile span {
  display: inline-block;
  color: #979898;
  vertical-align: middle;
}
.sidebar-user-info .user-profile span strong {
  display: block;
  font-size: 21px;
  color: #ffffff;
  font-weight: 300;
}
.sidebar-user-info .user-links {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-top: 1px solid #323435;
  margin: 0;
}
.sidebar-user-info .user-links li {
  display: table-cell;
}
.sidebar-user-info .user-links li.logout-link {
  width: 20%;
}
.sidebar-user-info .user-links li a {
  display: block;
  text-align: center;
  color: #979898;
  padding: 10px;
}
.sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(50, 52, 53, 0.25);
}
.sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(50, 52, 53, 0.25);
}
.sidebar-user-info .user-links li + li a {
  border-left: 1px solid #323435;
}
.sidebar-user-info + .main-menu {
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .sidebar-menu.collapsed .sidebar-user-info + .main-menu {
    margin-top: 0;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner {
    position: relative;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-profile {
    padding: 15px 0;
    text-align: center;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-profile img {
    max-width: 32px;
    height: auto !important;
    margin: 0;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    text-align: left;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #27292a;
    display: block;
    width: 280px;
    height: 62px;
    padding: 10px 20px;
  }
  .right-sidebar .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    left: auto;
    right: 100%;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-profile span strong {
    font-size: 17px;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-links {
    position: absolute;
    left: 100%;
    width: 280px;
    background-color: #27292a;
    top: 62px;
  }
  .right-sidebar .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-links {
    left: auto;
    right: 100%;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-profile span,
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner .user-links {
    display: none;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner:hover .user-profile span {
    display: block;
  }
  .sidebar-menu.collapsed .sidebar-user-info .sidebar-user-info-inner:hover .user-links {
    display: table;
  }
}
.members-table thead tr th {
  text-transform: uppercase;
  color: #a4a5a5;
  font-weight: normal;
  background-color: #f6f6f6;
}
.members-table thead tr th:after {
  display: none;
}
.table-hover.members-table tbody tr:hover {
  background-color: #f8f8f8;
}
.members-table tbody tr td {
  padding: 10px;
}
.members-table tbody tr td .name {
  display: block;
  font-size: 14px;
  color: #2c2e2f;
  font-weight: bold;
}
.members-table tbody tr td .name + span {
  font-size: 12px;
  color: #9d9d9d;
}
.members-table tbody tr td .email {
  color: #9d9d9d;
  cursor: default;
}
.members-table tbody tr td .email:hover {
  color: #848484;
}
.members-table tbody tr td.user-cb {
  width: 1%;
}
.members-table tbody tr td.user-image {
  width: 1%;
}
@media screen and (max-width: 768px) {
  .members-table tbody tr td.user-image {
    display: none;
  }
}
.members-table tbody tr td.user-image img {
  width: 100%;
  min-width: 42px;
  height: auto !important;
}
.members-table tbody tr td.user-id {
  color: #9d9d9d;
  white-space: nowrap;
  width: 15%;
}
.members-table tbody tr td.action-links {
  width: 20%;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .members-table tbody tr td.action-links {
    width: 25%;
  }
}
.members-table tbody tr td.action-links a {
  display: block;
  font-size: 11px;
}
.members-table tbody tr td.action-links a:hover {
  zoom: 1;
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.members-table tbody tr td.action-links a.edit {
  color: #8dc63f;
}
.members-table tbody tr td.action-links a.delete {
  color: #cc3f44;
}
.members-table tbody tr td.action-links a + a {
  margin-top: 5px;
}
.members-table-actions {
  position: relative;
  margin-top: 5px;
}
@media screen and (max-width: 768px) {
  .members-table-actions {
    text-align: center;
    margin-bottom: 20px;
  }
}
.members-table-actions .selected-items,
.members-table-actions .selected-actions {
  display: inline-block;
}
.members-table-actions .selected-items span {
  font-weight: bold;
  color: #2c2e2f;
}
.members-table-actions .selected-items + .selected-actions {
  margin-left: 30px;
}
.members-table-actions .selected-actions .edit {
  color: #8dc63f;
}
.members-table-actions .selected-actions .delete {
  color: #cc3f44;
}
.member-form-add-header .user-img,
.member-form-add-header .user-name {
  display: inline-block;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .member-form-add-header .user-img {
    margin-top: 20px;
  }
}
.member-form-add-header .user-name {
  padding-left: 20px;
}
.member-form-add-header .user-name a {
  display: block;
  font-size: 23px;
}
.member-form-add-header .user-name span {
  display: block;
  color: #9d9d9d;
}
.member-form-add-header + .member-form-inputs {
  margin-top: 20px;
}
.member-form-inputs .row:before {
  display: block;
  margin: 0 15px;
  height: 1px;
  background: #eaeaea;
  margin-bottom: 20px;
}
.member-form-inputs .row + .row {
  margin-top: 20px;
}
.member-form-inputs .control-label {
  font-size: 14px;
  line-height: 30px;
}
.page-body.skin-aero .btn-primary {
  background: #558c89;
}
.page-body.skin-aero .btn-primary:hover {
  background: #4b7c79;
  border-color: #4b7c79;
}
.page-body.skin-aero .btn-primary:active,
.page-body.skin-aero .btn-primary:focus {
  background: #385c5a;
  border-color: #385c5a;
}
.page-body.skin-aero .btn.btn-secondary {
  background-color: #255e5b;
  color: #ffffff;
}
.page-body.skin-aero .btn.btn-secondary:hover {
  background-color: #1e4c49;
  text-color: #ffffff;
}
.page-body.skin-aero .btn.btn-secondary:active,
.page-body.skin-aero .btn.btn-secondary:focus {
  background-color: #1e4c49;
  border-color: #1b4442;
}
.page-body.skin-aero .settings-pane {
  background: #5f9a97;
  color: #ececea;
}
.page-body.skin-aero .settings-pane > a[data-toggle="settings-pane"] {
  color: #ececea;
}
.page-body.skin-aero .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-aero .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-aero .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-aero .settings-pane .links-block.left-sep {
  border-left: 1px solid #558c89;
}
.page-body.skin-aero .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-aero .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #5f9a97;
}
.page-body.skin-aero .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-aero .settings-pane .links-block h4 a:hover {
  color: #ececea;
}
.page-body.skin-aero .settings-pane .links-block ul li a {
  color: #ececea;
}
.page-body.skin-aero .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-aero .sidebar-menu {
  background: #558c89;
}
.page-body.skin-aero .sidebar-menu .logo-env {
  border-bottom: 1px solid #5f9a97;
}
.page-body.skin-aero .sidebar-menu .logo-env .settings-icon a {
  color: #ececea;
}
.page-body.skin-aero .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-aero .sidebar-menu .main-menu a {
  color: #ececea;
  border-bottom: 1px solid #5f9a97;
}
.page-body.skin-aero .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-aero .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-aero .sidebar-menu .main-menu li.has-sub > a:before {
  color: #acccca;
}
.page-body.skin-aero .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-aero .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-aero .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-aero .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-aero .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #4f8280;
  }
  .page-body.skin-aero .sidebar-menu.collapsed .main-menu > li > ul {
    background: #558c89;
  }
}
.page-body.skin-aero .page-loading-overlay {
  background-color: #558c89;
}
.page-body.skin-aero.login-page {
  background: #558c89;
  color: #fff;
}
.page-body.skin-aero.login-page .login-form .login-header .logo span {
  color: #ececea;
}
.page-body.skin-aero.login-page .login-form .login-header p {
  color: #ececea;
}
.page-body.skin-aero.login-page .login-form .form-group .control-label {
  color: #ececea;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark {
  background: #4b7c79;
  border: 1px solid transparent;
  color: #ececea;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-aero.login-page .login-form .form-group .btn.btn-dark {
  border-color: #5f9a97;
  background-color: #558c89;
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(95, 154, 151, 0.3);
}
.page-body.skin-aero.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-aero.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .login-footer a:hover {
  color: #ffffff;
}
.page-body.skin-aero.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #ececea;
}
.page-body.skin-aero.login-page .login-form .login-footer .info-links a {
  color: #ececea;
}
.page-body.skin-aero.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-aero.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-aero.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-aero.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-aero.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-aero.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-aero.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-aero.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-aero.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-aero.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-aero.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-aero.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-aero.login-page.login-light .errors-container,
.page-body.skin-aero.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-aero.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-aero.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-aero.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-aero.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-aero.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-aero.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-aero.login-page {
    padding-top: 0;
  }
  .page-body.skin-aero.login-page .login-form,
  .page-body.skin-aero.login-page .errors-container,
  .page-body.skin-aero.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-aero.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-aero.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-aero.login-page.login-light .errors-container,
  .page-body.skin-aero.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-aero.login-page .row {
    margin: 0;
  }
}
.page-body.skin-aero.lockscreen-page {
  background: #558c89;
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group p {
  color: #ececea;
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group h3 {
  color: #ececea;
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #5f9a97;
  color: #ffffff;
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #5f9a97;
}
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-aero.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(95, 154, 151, 0.3);
}
.page-body.skin-aero.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #5f9a97;
  -webkit-box-shadow: 0px 0px 0px 10px #5f9a97, 0px -10px 0px 10px #5f9a97, 0px -20px 0px 10px #5f9a97, 0px -30px 0px 10px #5f9a97;
  -moz-box-shadow: 0px 0px 0px 10px #5f9a97, 0px -10px 0px 10px #5f9a97, 0px -20px 0px 10px #5f9a97, 0px -30px 0px 10px #5f9a97;
  box-shadow: 0px 0px 0px 10px #5f9a97, 0px -10px 0px 10px #5f9a97, 0px -20px 0px 10px #5f9a97, 0px -30px 0px 10px #5f9a97;
}
.page-body.skin-aero .sidebar-user-info {
  background-color: #4b7c79;
  border-bottom-color: #5f9a97;
}
.page-body.skin-aero .sidebar-user-info .user-profile span {
  color: #ececea;
}
.page-body.skin-aero .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-aero .sidebar-user-info .user-links {
  border-top-color: #5f9a97;
}
.page-body.skin-aero .sidebar-user-info .user-links li a {
  color: #ececea;
}
.page-body.skin-aero .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(95, 154, 151, 0.25);
}
.page-body.skin-aero .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(95, 154, 151, 0.25);
}
.page-body.skin-aero .sidebar-user-info .user-links li + li a {
  border-left-color: #5f9a97;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-aero .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-aero .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-navy .btn-primary {
  background: #2c3e50;
}
.page-body.skin-navy .btn-primary:hover {
  background: #233140;
  border-color: #233140;
}
.page-body.skin-navy .btn-primary:active,
.page-body.skin-navy .btn-primary:focus {
  background: #11181f;
  border-color: #11181f;
}
.page-body.skin-navy .btn.btn-secondary {
  background-color: #ff4e50;
  color: #ffffff;
}
.page-body.skin-navy .btn.btn-secondary:hover {
  background-color: #ff3437;
  text-color: #ffffff;
}
.page-body.skin-navy .btn.btn-secondary:active,
.page-body.skin-navy .btn.btn-secondary:focus {
  background-color: #ff3437;
  border-color: #ff2a2d;
}
.page-body.skin-navy .settings-pane {
  background: #34495e;
  color: #a7bfd6;
}
.page-body.skin-navy .settings-pane > a[data-toggle="settings-pane"] {
  color: #a7bfd6;
}
.page-body.skin-navy .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-navy .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-navy .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-navy .settings-pane .links-block.left-sep {
  border-left: 1px solid #2c3e50;
}
.page-body.skin-navy .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-navy .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #34495e;
}
.page-body.skin-navy .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-navy .settings-pane .links-block h4 a:hover {
  color: #a7bfd6;
}
.page-body.skin-navy .settings-pane .links-block ul li a {
  color: #a7bfd6;
}
.page-body.skin-navy .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-navy .sidebar-menu {
  background: #2c3e50;
}
.page-body.skin-navy .sidebar-menu .logo-env {
  border-bottom: 1px solid #34495e;
}
.page-body.skin-navy .sidebar-menu .logo-env .settings-icon a {
  color: #a7bfd6;
}
.page-body.skin-navy .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-navy .sidebar-menu .main-menu a {
  color: #a7bfd6;
  border-bottom: 1px solid #34495e;
}
.page-body.skin-navy .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-navy .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-navy .sidebar-menu .main-menu li.has-sub > a:before {
  color: #6789ab;
}
.page-body.skin-navy .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-navy .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-navy .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-navy .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-navy .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #273646;
  }
  .page-body.skin-navy .sidebar-menu.collapsed .main-menu > li > ul {
    background: #2c3e50;
  }
}
.page-body.skin-navy .page-loading-overlay {
  background-color: #2c3e50;
}
.page-body.skin-navy.login-page {
  background: #2c3e50;
  color: #fff;
}
.page-body.skin-navy.login-page .login-form .login-header .logo span {
  color: #a7bfd6;
}
.page-body.skin-navy.login-page .login-form .login-header p {
  color: #a7bfd6;
}
.page-body.skin-navy.login-page .login-form .form-group .control-label {
  color: #a7bfd6;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark {
  background: #233140;
  border: 1px solid transparent;
  color: #a7bfd6;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-navy.login-page .login-form .form-group .btn.btn-dark {
  border-color: #34495e;
  background-color: #2c3e50;
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(52, 73, 94, 0.3);
}
.page-body.skin-navy.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-navy.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-navy.login-page .login-form .login-footer a:hover {
  color: #dbe5ee;
}
.page-body.skin-navy.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #a7bfd6;
}
.page-body.skin-navy.login-page .login-form .login-footer .info-links a {
  color: #a7bfd6;
}
.page-body.skin-navy.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-navy.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-navy.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-navy.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-navy.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-navy.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-navy.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-navy.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-navy.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-navy.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-navy.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-navy.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-navy.login-page.login-light .errors-container,
.page-body.skin-navy.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-navy.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-navy.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-navy.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-navy.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-navy.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-navy.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-navy.login-page {
    padding-top: 0;
  }
  .page-body.skin-navy.login-page .login-form,
  .page-body.skin-navy.login-page .errors-container,
  .page-body.skin-navy.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-navy.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-navy.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-navy.login-page.login-light .errors-container,
  .page-body.skin-navy.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-navy.login-page .row {
    margin: 0;
  }
}
.page-body.skin-navy.lockscreen-page {
  background: #2c3e50;
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group p {
  color: #a7bfd6;
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group h3 {
  color: #a7bfd6;
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #34495e;
  color: #ffffff;
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #34495e;
}
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-navy.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(52, 73, 94, 0.3);
}
.page-body.skin-navy.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #34495e;
  -webkit-box-shadow: 0px 0px 0px 10px #34495e, 0px -10px 0px 10px #34495e, 0px -20px 0px 10px #34495e, 0px -30px 0px 10px #34495e;
  -moz-box-shadow: 0px 0px 0px 10px #34495e, 0px -10px 0px 10px #34495e, 0px -20px 0px 10px #34495e, 0px -30px 0px 10px #34495e;
  box-shadow: 0px 0px 0px 10px #34495e, 0px -10px 0px 10px #34495e, 0px -20px 0px 10px #34495e, 0px -30px 0px 10px #34495e;
}
.page-body.skin-navy .sidebar-user-info {
  background-color: #233140;
  border-bottom-color: #34495e;
}
.page-body.skin-navy .sidebar-user-info .user-profile span {
  color: #a7bfd6;
}
.page-body.skin-navy .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-navy .sidebar-user-info .user-links {
  border-top-color: #34495e;
}
.page-body.skin-navy .sidebar-user-info .user-links li a {
  color: #a7bfd6;
}
.page-body.skin-navy .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(52, 73, 94, 0.25);
}
.page-body.skin-navy .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(52, 73, 94, 0.25);
}
.page-body.skin-navy .sidebar-user-info .user-links li + li a {
  border-left-color: #34495e;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-navy .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-navy .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-facebook .btn-primary {
  background: #3b5998;
}
.page-body.skin-facebook .btn-primary:hover {
  background: #344e86;
  border-color: #344e86;
}
.page-body.skin-facebook .btn-primary:active,
.page-body.skin-facebook .btn-primary:focus {
  background: #263961;
  border-color: #263961;
}
.page-body.skin-facebook .btn.btn-secondary {
  background-color: #8b9dc3;
  color: #ffffff;
}
.page-body.skin-facebook .btn.btn-secondary:hover {
  background-color: #7a8fba;
  text-color: #ffffff;
}
.page-body.skin-facebook .btn.btn-secondary:active,
.page-body.skin-facebook .btn.btn-secondary:focus {
  background-color: #7a8fba;
  border-color: #7389b7;
}
.page-body.skin-facebook .settings-pane {
  background: #4160a0;
  color: #8b9dc3;
}
.page-body.skin-facebook .settings-pane > a[data-toggle="settings-pane"] {
  color: #8b9dc3;
}
.page-body.skin-facebook .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-facebook .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-facebook .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-facebook .settings-pane .links-block.left-sep {
  border-left: 1px solid #3b5998;
}
.page-body.skin-facebook .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-facebook .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #4160a0;
}
.page-body.skin-facebook .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-facebook .settings-pane .links-block h4 a:hover {
  color: #8b9dc3;
}
.page-body.skin-facebook .settings-pane .links-block ul li a {
  color: #8b9dc3;
}
.page-body.skin-facebook .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-facebook .sidebar-menu {
  background: #3b5998;
}
.page-body.skin-facebook .sidebar-menu .logo-env {
  border-bottom: 1px solid #4160a0;
}
.page-body.skin-facebook .sidebar-menu .logo-env .settings-icon a {
  color: #8b9dc3;
}
.page-body.skin-facebook .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-facebook .sidebar-menu .main-menu a {
  color: #8b9dc3;
  border-bottom: 1px solid #4160a0;
}
.page-body.skin-facebook .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-facebook .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-facebook .sidebar-menu .main-menu li.has-sub > a:before {
  color: #8fa5d2;
}
.page-body.skin-facebook .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-facebook .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-facebook .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-facebook .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-facebook .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #37538d;
  }
  .page-body.skin-facebook .sidebar-menu.collapsed .main-menu > li > ul {
    background: #3b5998;
  }
}
.page-body.skin-facebook .page-loading-overlay {
  background-color: #3b5998;
}
.page-body.skin-facebook.login-page {
  background: #3b5998;
  color: #fff;
}
.page-body.skin-facebook.login-page .login-form .login-header .logo span {
  color: #8b9dc3;
}
.page-body.skin-facebook.login-page .login-form .login-header p {
  color: #8b9dc3;
}
.page-body.skin-facebook.login-page .login-form .form-group .control-label {
  color: #8b9dc3;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark {
  background: #344e86;
  border: 1px solid transparent;
  color: #8b9dc3;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-facebook.login-page .login-form .form-group .btn.btn-dark {
  border-color: #4160a0;
  background-color: #3b5998;
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(65, 96, 160, 0.3);
}
.page-body.skin-facebook.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-facebook.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .login-form .login-footer a:hover {
  color: #bdc8dd;
}
.page-body.skin-facebook.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #8b9dc3;
}
.page-body.skin-facebook.login-page .login-form .login-footer .info-links a {
  color: #8b9dc3;
}
.page-body.skin-facebook.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-facebook.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-facebook.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-facebook.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-facebook.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-facebook.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-facebook.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-facebook.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-facebook.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-facebook.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-facebook.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-facebook.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-facebook.login-page.login-light .errors-container,
.page-body.skin-facebook.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-facebook.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-facebook.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-facebook.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-facebook.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-facebook.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-facebook.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-facebook.login-page {
    padding-top: 0;
  }
  .page-body.skin-facebook.login-page .login-form,
  .page-body.skin-facebook.login-page .errors-container,
  .page-body.skin-facebook.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-facebook.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-facebook.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-facebook.login-page.login-light .errors-container,
  .page-body.skin-facebook.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-facebook.login-page .row {
    margin: 0;
  }
}
.page-body.skin-facebook.lockscreen-page {
  background: #3b5998;
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group p {
  color: #8b9dc3;
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group h3 {
  color: #8b9dc3;
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #4160a0;
  color: #ffffff;
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #4160a0;
}
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-facebook.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(65, 96, 160, 0.3);
}
.page-body.skin-facebook.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #4160a0;
  -webkit-box-shadow: 0px 0px 0px 10px #4160a0, 0px -10px 0px 10px #4160a0, 0px -20px 0px 10px #4160a0, 0px -30px 0px 10px #4160a0;
  -moz-box-shadow: 0px 0px 0px 10px #4160a0, 0px -10px 0px 10px #4160a0, 0px -20px 0px 10px #4160a0, 0px -30px 0px 10px #4160a0;
  box-shadow: 0px 0px 0px 10px #4160a0, 0px -10px 0px 10px #4160a0, 0px -20px 0px 10px #4160a0, 0px -30px 0px 10px #4160a0;
}
.page-body.skin-facebook .sidebar-user-info {
  background-color: #344e86;
  border-bottom-color: #4160a0;
}
.page-body.skin-facebook .sidebar-user-info .user-profile span {
  color: #8b9dc3;
}
.page-body.skin-facebook .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-facebook .sidebar-user-info .user-links {
  border-top-color: #4160a0;
}
.page-body.skin-facebook .sidebar-user-info .user-links li a {
  color: #8b9dc3;
}
.page-body.skin-facebook .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(65, 96, 160, 0.25);
}
.page-body.skin-facebook .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(65, 96, 160, 0.25);
}
.page-body.skin-facebook .sidebar-user-info .user-links li + li a {
  border-left-color: #4160a0;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-facebook .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-facebook .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-turquoise .btn-primary {
  background: #16a085;
}
.page-body.skin-turquoise .btn-primary:hover {
  background: #138a72;
  border-color: #138a72;
}
.page-body.skin-turquoise .btn-primary:active,
.page-body.skin-turquoise .btn-primary:focus {
  background: #0d5d4d;
  border-color: #0d5d4d;
}
.page-body.skin-turquoise .btn.btn-secondary {
  background-color: #0f7e68;
  color: #ffffff;
}
.page-body.skin-turquoise .btn.btn-secondary:hover {
  background-color: #0c6755;
  text-color: #ffffff;
}
.page-body.skin-turquoise .btn.btn-secondary:active,
.page-body.skin-turquoise .btn.btn-secondary:focus {
  background-color: #0c6755;
  border-color: #0b5e4e;
}
.page-body.skin-turquoise .settings-pane {
  background: #1daf92;
  color: #96ead9;
}
.page-body.skin-turquoise .settings-pane > a[data-toggle="settings-pane"] {
  color: #96ead9;
}
.page-body.skin-turquoise .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-turquoise .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-turquoise .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-turquoise .settings-pane .links-block.left-sep {
  border-left: 1px solid #16a085;
}
.page-body.skin-turquoise .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-turquoise .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #1daf92;
}
.page-body.skin-turquoise .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-turquoise .settings-pane .links-block h4 a:hover {
  color: #96ead9;
}
.page-body.skin-turquoise .settings-pane .links-block ul li a {
  color: #96ead9;
}
.page-body.skin-turquoise .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-turquoise .sidebar-menu {
  background: #16a085;
}
.page-body.skin-turquoise .sidebar-menu .logo-env {
  border-bottom: 1px solid #1daf92;
}
.page-body.skin-turquoise .sidebar-menu .logo-env .settings-icon a {
  color: #96ead9;
}
.page-body.skin-turquoise .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-turquoise .sidebar-menu .main-menu a {
  color: #96ead9;
  border-bottom: 1px solid #1daf92;
}
.page-body.skin-turquoise .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-turquoise .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-turquoise .sidebar-menu .main-menu li.has-sub > a:before {
  color: #66e6cc;
}
.page-body.skin-turquoise .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-turquoise .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-turquoise .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-turquoise .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-turquoise .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #14937a;
  }
  .page-body.skin-turquoise .sidebar-menu.collapsed .main-menu > li > ul {
    background: #16a085;
  }
}
.page-body.skin-turquoise .page-loading-overlay {
  background-color: #16a085;
}
.page-body.skin-turquoise.login-page {
  background: #16a085;
  color: #fff;
}
.page-body.skin-turquoise.login-page .login-form .login-header .logo span {
  color: #96ead9;
}
.page-body.skin-turquoise.login-page .login-form .login-header p {
  color: #96ead9;
}
.page-body.skin-turquoise.login-page .login-form .form-group .control-label {
  color: #96ead9;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark {
  background: #138a72;
  border: 1px solid transparent;
  color: #96ead9;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-turquoise.login-page .login-form .form-group .btn.btn-dark {
  border-color: #1daf92;
  background-color: #16a085;
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(29, 175, 146, 0.3);
}
.page-body.skin-turquoise.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-turquoise.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .login-form .login-footer a:hover {
  color: #d6f7f0;
}
.page-body.skin-turquoise.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #96ead9;
}
.page-body.skin-turquoise.login-page .login-form .login-footer .info-links a {
  color: #96ead9;
}
.page-body.skin-turquoise.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-turquoise.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-turquoise.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-turquoise.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-turquoise.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-turquoise.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-turquoise.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-turquoise.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-turquoise.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-turquoise.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-turquoise.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-turquoise.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-turquoise.login-page.login-light .errors-container,
.page-body.skin-turquoise.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-turquoise.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-turquoise.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-turquoise.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-turquoise.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-turquoise.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-turquoise.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-turquoise.login-page {
    padding-top: 0;
  }
  .page-body.skin-turquoise.login-page .login-form,
  .page-body.skin-turquoise.login-page .errors-container,
  .page-body.skin-turquoise.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-turquoise.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-turquoise.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-turquoise.login-page.login-light .errors-container,
  .page-body.skin-turquoise.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-turquoise.login-page .row {
    margin: 0;
  }
}
.page-body.skin-turquoise.lockscreen-page {
  background: #16a085;
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group p {
  color: #96ead9;
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group h3 {
  color: #96ead9;
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #1daf92;
  color: #ffffff;
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #1daf92;
}
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-turquoise.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(29, 175, 146, 0.3);
}
.page-body.skin-turquoise.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #1daf92;
  -webkit-box-shadow: 0px 0px 0px 10px #1daf92, 0px -10px 0px 10px #1daf92, 0px -20px 0px 10px #1daf92, 0px -30px 0px 10px #1daf92;
  -moz-box-shadow: 0px 0px 0px 10px #1daf92, 0px -10px 0px 10px #1daf92, 0px -20px 0px 10px #1daf92, 0px -30px 0px 10px #1daf92;
  box-shadow: 0px 0px 0px 10px #1daf92, 0px -10px 0px 10px #1daf92, 0px -20px 0px 10px #1daf92, 0px -30px 0px 10px #1daf92;
}
.page-body.skin-turquoise .sidebar-user-info {
  background-color: #138a72;
  border-bottom-color: #1daf92;
}
.page-body.skin-turquoise .sidebar-user-info .user-profile span {
  color: #96ead9;
}
.page-body.skin-turquoise .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-turquoise .sidebar-user-info .user-links {
  border-top-color: #1daf92;
}
.page-body.skin-turquoise .sidebar-user-info .user-links li a {
  color: #96ead9;
}
.page-body.skin-turquoise .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(29, 175, 146, 0.25);
}
.page-body.skin-turquoise .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(29, 175, 146, 0.25);
}
.page-body.skin-turquoise .sidebar-user-info .user-links li + li a {
  border-left-color: #1daf92;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-turquoise .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-turquoise .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-lime .btn-primary {
  background: #8cc657;
}
.page-body.skin-lime .btn-primary:hover {
  background: #7fc044;
  border-color: #7fc044;
}
.page-body.skin-lime .btn-primary:active,
.page-body.skin-lime .btn-primary:focus {
  background: #669c35;
  border-color: #669c35;
}
.page-body.skin-lime .btn.btn-secondary {
  background-color: #70a93c;
  color: #ffffff;
}
.page-body.skin-lime .btn.btn-secondary:hover {
  background-color: #649635;
  text-color: #ffffff;
}
.page-body.skin-lime .btn.btn-secondary:active,
.page-body.skin-lime .btn.btn-secondary:focus {
  background-color: #649635;
  border-color: #5f8f33;
}
.page-body.skin-lime .settings-pane {
  background: #95cd62;
  color: #ffffff;
}
.page-body.skin-lime .settings-pane > a[data-toggle="settings-pane"] {
  color: #ffffff;
}
.page-body.skin-lime .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-lime .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-lime .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-lime .settings-pane .links-block.left-sep {
  border-left: 1px solid #8cc657;
}
.page-body.skin-lime .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-lime .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #95cd62;
}
.page-body.skin-lime .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-lime .settings-pane .links-block h4 a:hover {
  color: #ffffff;
}
.page-body.skin-lime .settings-pane .links-block ul li a {
  color: #ffffff;
}
.page-body.skin-lime .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-menu {
  background: #8cc657;
}
.page-body.skin-lime .sidebar-menu .logo-env {
  border-bottom: 1px solid #95cd62;
}
.page-body.skin-lime .sidebar-menu .logo-env .settings-icon a {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-menu .main-menu a {
  color: #ffffff;
  border-bottom: 1px solid #95cd62;
}
.page-body.skin-lime .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-menu .main-menu li.has-sub > a:before {
  color: #d6ecc3;
}
.page-body.skin-lime .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-lime .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-lime .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-lime .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-lime .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #84c24c;
  }
  .page-body.skin-lime .sidebar-menu.collapsed .main-menu > li > ul {
    background: #8cc657;
  }
}
.page-body.skin-lime .page-loading-overlay {
  background-color: #8cc657;
}
.page-body.skin-lime.login-page {
  background: #8cc657;
  color: #fff;
}
.page-body.skin-lime.login-page .login-form .login-header .logo span {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .login-header p {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .control-label {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark {
  background: #7fc044;
  border: 1px solid transparent;
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-lime.login-page .login-form .form-group .btn.btn-dark {
  border-color: #95cd62;
  background-color: #8cc657;
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(149, 205, 98, 0.3);
}
.page-body.skin-lime.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-lime.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .login-footer a:hover {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .login-footer .info-links a {
  color: #ffffff;
}
.page-body.skin-lime.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-lime.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-lime.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-lime.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-lime.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-lime.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-lime.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-lime.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-lime.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-lime.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-lime.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-lime.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-lime.login-page.login-light .errors-container,
.page-body.skin-lime.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-lime.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-lime.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-lime.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-lime.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-lime.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-lime.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-lime.login-page {
    padding-top: 0;
  }
  .page-body.skin-lime.login-page .login-form,
  .page-body.skin-lime.login-page .errors-container,
  .page-body.skin-lime.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-lime.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-lime.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-lime.login-page.login-light .errors-container,
  .page-body.skin-lime.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-lime.login-page .row {
    margin: 0;
  }
}
.page-body.skin-lime.lockscreen-page {
  background: #8cc657;
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group p {
  color: #ffffff;
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group h3 {
  color: #ffffff;
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #95cd62;
  color: #ffffff;
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #95cd62;
}
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-lime.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(149, 205, 98, 0.3);
}
.page-body.skin-lime.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #95cd62;
  -webkit-box-shadow: 0px 0px 0px 10px #95cd62, 0px -10px 0px 10px #95cd62, 0px -20px 0px 10px #95cd62, 0px -30px 0px 10px #95cd62;
  -moz-box-shadow: 0px 0px 0px 10px #95cd62, 0px -10px 0px 10px #95cd62, 0px -20px 0px 10px #95cd62, 0px -30px 0px 10px #95cd62;
  box-shadow: 0px 0px 0px 10px #95cd62, 0px -10px 0px 10px #95cd62, 0px -20px 0px 10px #95cd62, 0px -30px 0px 10px #95cd62;
}
.page-body.skin-lime .sidebar-user-info {
  background-color: #7fc044;
  border-bottom-color: #95cd62;
}
.page-body.skin-lime .sidebar-user-info .user-profile span {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-user-info .user-links {
  border-top-color: #95cd62;
}
.page-body.skin-lime .sidebar-user-info .user-links li a {
  color: #ffffff;
}
.page-body.skin-lime .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(149, 205, 98, 0.25);
}
.page-body.skin-lime .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(149, 205, 98, 0.25);
}
.page-body.skin-lime .sidebar-user-info .user-links li + li a {
  border-left-color: #95cd62;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-lime .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-lime .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-green .btn-primary {
  background: #27ae60;
}
.page-body.skin-green .btn-primary:hover {
  background: #229955;
  border-color: #229955;
}
.page-body.skin-green .btn-primary:active,
.page-body.skin-green .btn-primary:focus {
  background: #19703e;
  border-color: #19703e;
}
.page-body.skin-green .btn.btn-secondary {
  background-color: #1c954f;
  color: #ffffff;
}
.page-body.skin-green .btn.btn-secondary:hover {
  background-color: #188044;
  text-color: #ffffff;
}
.page-body.skin-green .btn.btn-secondary:active,
.page-body.skin-green .btn.btn-secondary:focus {
  background-color: #188044;
  border-color: #16773f;
}
.page-body.skin-green .settings-pane {
  background: #2fbd6b;
  color: #a2f9c7;
}
.page-body.skin-green .settings-pane > a[data-toggle="settings-pane"] {
  color: #a2f9c7;
}
.page-body.skin-green .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-green .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-green .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-green .settings-pane .links-block.left-sep {
  border-left: 1px solid #27ae60;
}
.page-body.skin-green .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-green .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #2fbd6b;
}
.page-body.skin-green .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-green .settings-pane .links-block h4 a:hover {
  color: #a2f9c7;
}
.page-body.skin-green .settings-pane .links-block ul li a {
  color: #a2f9c7;
}
.page-body.skin-green .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-green .sidebar-menu {
  background: #27ae60;
}
.page-body.skin-green .sidebar-menu .logo-env {
  border-bottom: 1px solid #2fbd6b;
}
.page-body.skin-green .sidebar-menu .logo-env .settings-icon a {
  color: #a2f9c7;
}
.page-body.skin-green .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-green .sidebar-menu .main-menu a {
  color: #a2f9c7;
  border-bottom: 1px solid #2fbd6b;
}
.page-body.skin-green .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-green .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-green .sidebar-menu .main-menu li.has-sub > a:before {
  color: #8ae2af;
}
.page-body.skin-green .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-green .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-green .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-green .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-green .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #24a259;
  }
  .page-body.skin-green .sidebar-menu.collapsed .main-menu > li > ul {
    background: #27ae60;
  }
}
.page-body.skin-green .page-loading-overlay {
  background-color: #27ae60;
}
.page-body.skin-green.login-page {
  background: #27ae60;
  color: #fff;
}
.page-body.skin-green.login-page .login-form .login-header .logo span {
  color: #a2f9c7;
}
.page-body.skin-green.login-page .login-form .login-header p {
  color: #a2f9c7;
}
.page-body.skin-green.login-page .login-form .form-group .control-label {
  color: #a2f9c7;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark {
  background: #229955;
  border: 1px solid transparent;
  color: #a2f9c7;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-green.login-page .login-form .form-group .btn.btn-dark {
  border-color: #2fbd6b;
  background-color: #27ae60;
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(47, 189, 107, 0.3);
}
.page-body.skin-green.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-green.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-green.login-page .login-form .login-footer a:hover {
  color: #eafef2;
}
.page-body.skin-green.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #a2f9c7;
}
.page-body.skin-green.login-page .login-form .login-footer .info-links a {
  color: #a2f9c7;
}
.page-body.skin-green.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-green.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-green.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-green.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-green.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-green.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-green.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-green.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-green.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-green.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-green.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-green.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-green.login-page.login-light .errors-container,
.page-body.skin-green.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-green.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-green.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-green.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-green.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-green.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-green.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-green.login-page {
    padding-top: 0;
  }
  .page-body.skin-green.login-page .login-form,
  .page-body.skin-green.login-page .errors-container,
  .page-body.skin-green.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-green.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-green.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-green.login-page.login-light .errors-container,
  .page-body.skin-green.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-green.login-page .row {
    margin: 0;
  }
}
.page-body.skin-green.lockscreen-page {
  background: #27ae60;
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group p {
  color: #a2f9c7;
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group h3 {
  color: #a2f9c7;
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #2fbd6b;
  color: #ffffff;
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #2fbd6b;
}
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-green.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(47, 189, 107, 0.3);
}
.page-body.skin-green.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #2fbd6b;
  -webkit-box-shadow: 0px 0px 0px 10px #2fbd6b, 0px -10px 0px 10px #2fbd6b, 0px -20px 0px 10px #2fbd6b, 0px -30px 0px 10px #2fbd6b;
  -moz-box-shadow: 0px 0px 0px 10px #2fbd6b, 0px -10px 0px 10px #2fbd6b, 0px -20px 0px 10px #2fbd6b, 0px -30px 0px 10px #2fbd6b;
  box-shadow: 0px 0px 0px 10px #2fbd6b, 0px -10px 0px 10px #2fbd6b, 0px -20px 0px 10px #2fbd6b, 0px -30px 0px 10px #2fbd6b;
}
.page-body.skin-green .sidebar-user-info {
  background-color: #229955;
  border-bottom-color: #2fbd6b;
}
.page-body.skin-green .sidebar-user-info .user-profile span {
  color: #a2f9c7;
}
.page-body.skin-green .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-green .sidebar-user-info .user-links {
  border-top-color: #2fbd6b;
}
.page-body.skin-green .sidebar-user-info .user-links li a {
  color: #a2f9c7;
}
.page-body.skin-green .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(47, 189, 107, 0.25);
}
.page-body.skin-green .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(47, 189, 107, 0.25);
}
.page-body.skin-green .sidebar-user-info .user-links li + li a {
  border-left-color: #2fbd6b;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-green .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-green .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-purple .btn-primary {
  background: #795b95;
}
.page-body.skin-purple .btn-primary:hover {
  background: #6c5185;
  border-color: #6c5185;
}
.page-body.skin-purple .btn-primary:active,
.page-body.skin-purple .btn-primary:focus {
  background: #523e66;
  border-color: #523e66;
}
.page-body.skin-purple .btn.btn-secondary {
  background-color: #5f3d7e;
  color: #ffffff;
}
.page-body.skin-purple .btn.btn-secondary:hover {
  background-color: #52356d;
  text-color: #ffffff;
}
.page-body.skin-purple .btn.btn-secondary:active,
.page-body.skin-purple .btn.btn-secondary:focus {
  background-color: #52356d;
  border-color: #4d3166;
}
.page-body.skin-purple .settings-pane {
  background: #81629f;
  color: #c2afd4;
}
.page-body.skin-purple .settings-pane > a[data-toggle="settings-pane"] {
  color: #c2afd4;
}
.page-body.skin-purple .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-purple .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-purple .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-purple .settings-pane .links-block.left-sep {
  border-left: 1px solid #795b95;
}
.page-body.skin-purple .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-purple .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #81629f;
}
.page-body.skin-purple .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-purple .settings-pane .links-block h4 a:hover {
  color: #c2afd4;
}
.page-body.skin-purple .settings-pane .links-block ul li a {
  color: #c2afd4;
}
.page-body.skin-purple .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-purple .sidebar-menu {
  background: #795b95;
}
.page-body.skin-purple .sidebar-menu .logo-env {
  border-bottom: 1px solid #81629f;
}
.page-body.skin-purple .sidebar-menu .logo-env .settings-icon a {
  color: #c2afd4;
}
.page-body.skin-purple .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-purple .sidebar-menu .main-menu a {
  color: #c2afd4;
  border-bottom: 1px solid #81629f;
}
.page-body.skin-purple .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-purple .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-purple .sidebar-menu .main-menu li.has-sub > a:before {
  color: #c0b1cf;
}
.page-body.skin-purple .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-purple .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-purple .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-purple .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-purple .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #71558c;
  }
  .page-body.skin-purple .sidebar-menu.collapsed .main-menu > li > ul {
    background: #795b95;
  }
}
.page-body.skin-purple .page-loading-overlay {
  background-color: #795b95;
}
.page-body.skin-purple.login-page {
  background: #795b95;
  color: #fff;
}
.page-body.skin-purple.login-page .login-form .login-header .logo span {
  color: #c2afd4;
}
.page-body.skin-purple.login-page .login-form .login-header p {
  color: #c2afd4;
}
.page-body.skin-purple.login-page .login-form .form-group .control-label {
  color: #c2afd4;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark {
  background: #6c5185;
  border: 1px solid transparent;
  color: #c2afd4;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-purple.login-page .login-form .form-group .btn.btn-dark {
  border-color: #81629f;
  background-color: #795b95;
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(129, 98, 159, 0.3);
}
.page-body.skin-purple.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-purple.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-purple.login-page .login-form .login-footer a:hover {
  color: #e8e1ef;
}
.page-body.skin-purple.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #c2afd4;
}
.page-body.skin-purple.login-page .login-form .login-footer .info-links a {
  color: #c2afd4;
}
.page-body.skin-purple.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-purple.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-purple.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-purple.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-purple.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-purple.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-purple.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-purple.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-purple.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-purple.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-purple.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-purple.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-purple.login-page.login-light .errors-container,
.page-body.skin-purple.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-purple.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-purple.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-purple.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-purple.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-purple.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-purple.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-purple.login-page {
    padding-top: 0;
  }
  .page-body.skin-purple.login-page .login-form,
  .page-body.skin-purple.login-page .errors-container,
  .page-body.skin-purple.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-purple.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-purple.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-purple.login-page.login-light .errors-container,
  .page-body.skin-purple.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-purple.login-page .row {
    margin: 0;
  }
}
.page-body.skin-purple.lockscreen-page {
  background: #795b95;
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group p {
  color: #c2afd4;
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group h3 {
  color: #c2afd4;
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #81629f;
  color: #ffffff;
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #81629f;
}
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-purple.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(129, 98, 159, 0.3);
}
.page-body.skin-purple.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #81629f;
  -webkit-box-shadow: 0px 0px 0px 10px #81629f, 0px -10px 0px 10px #81629f, 0px -20px 0px 10px #81629f, 0px -30px 0px 10px #81629f;
  -moz-box-shadow: 0px 0px 0px 10px #81629f, 0px -10px 0px 10px #81629f, 0px -20px 0px 10px #81629f, 0px -30px 0px 10px #81629f;
  box-shadow: 0px 0px 0px 10px #81629f, 0px -10px 0px 10px #81629f, 0px -20px 0px 10px #81629f, 0px -30px 0px 10px #81629f;
}
.page-body.skin-purple .sidebar-user-info {
  background-color: #6c5185;
  border-bottom-color: #81629f;
}
.page-body.skin-purple .sidebar-user-info .user-profile span {
  color: #c2afd4;
}
.page-body.skin-purple .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-purple .sidebar-user-info .user-links {
  border-top-color: #81629f;
}
.page-body.skin-purple .sidebar-user-info .user-links li a {
  color: #c2afd4;
}
.page-body.skin-purple .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(129, 98, 159, 0.25);
}
.page-body.skin-purple .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(129, 98, 159, 0.25);
}
.page-body.skin-purple .sidebar-user-info .user-links li + li a {
  border-left-color: #81629f;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-purple .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-purple .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-white .btn-primary {
  background: #95cd62;
}
.page-body.skin-white .btn-primary:hover {
  background: #88c74f;
  border-color: #88c74f;
}
.page-body.skin-white .btn-primary:active,
.page-body.skin-white .btn-primary:focus {
  background: #6fac37;
  border-color: #6fac37;
}
.page-body.skin-white .btn.btn-secondary {
  background-color: #555555;
  color: #ffffff;
}
.page-body.skin-white .btn.btn-secondary:hover {
  background-color: #484848;
  text-color: #ffffff;
}
.page-body.skin-white .btn.btn-secondary:active,
.page-body.skin-white .btn.btn-secondary:focus {
  background-color: #484848;
  border-color: #434343;
}
.page-body.skin-white .settings-pane {
  background: #eeeeee;
  color: #666666;
}
.page-body.skin-white .settings-pane > a[data-toggle="settings-pane"] {
  color: #666666;
}
.page-body.skin-white .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-white .settings-pane .user-info .user-details h3 a {
  color: #95cd62;
}
.page-body.skin-white .settings-pane .user-info .user-details .user-title {
  color: rgba(149, 205, 98, 0.65);
}
.page-body.skin-white .settings-pane .links-block.left-sep {
  border-left: 1px solid #ffffff;
}
.page-body.skin-white .settings-pane .links-block h4 {
  color: #95cd62;
}
.page-body.skin-white .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #eeeeee;
}
.page-body.skin-white .settings-pane .links-block h4 a {
  color: #95cd62;
}
.page-body.skin-white .settings-pane .links-block h4 a:hover {
  color: #666666;
}
.page-body.skin-white .settings-pane .links-block ul li a {
  color: #666666;
}
.page-body.skin-white .settings-pane .links-block ul li a:hover {
  color: #95cd62;
}
.page-body.skin-white .sidebar-menu {
  background: #ffffff;
}
.page-body.skin-white .sidebar-menu .logo-env {
  border-bottom: 1px solid #eeeeee;
}
.page-body.skin-white .sidebar-menu .logo-env .settings-icon a {
  color: #666666;
}
.page-body.skin-white .sidebar-menu .logo-env .settings-icon a:hover {
  color: #95cd62;
}
.page-body.skin-white .sidebar-menu .main-menu a {
  color: #666666;
  border-bottom: 1px solid #eeeeee;
}
.page-body.skin-white .sidebar-menu .main-menu a:hover {
  color: #95cd62;
}
.page-body.skin-white .sidebar-menu .main-menu li.active > a {
  color: #95cd62;
}
.page-body.skin-white .sidebar-menu .main-menu li.has-sub > a:before {
  color: #ffffff;
}
.page-body.skin-white .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-white .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #eeeeee;
}
@media screen and (min-width: 768px) {
  .page-body.skin-white .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-white .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-white .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #f7f7f7;
  }
  .page-body.skin-white .sidebar-menu.collapsed .main-menu > li > ul {
    background: #ffffff;
  }
}
.page-body.skin-white .page-loading-overlay {
  background-color: #ffffff;
}
.page-body.skin-white.login-page {
  background: #ffffff;
  color: #fff;
}
.page-body.skin-white.login-page .login-form .login-header .logo span {
  color: #666666;
}
.page-body.skin-white.login-page .login-form .login-header p {
  color: #666666;
}
.page-body.skin-white.login-page .login-form .form-group .control-label {
  color: #666666;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark {
  background: #f2f2f2;
  border: 1px solid transparent;
  color: #666666;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #95cd62;
  opacity: 1;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-white.login-page .login-form .form-group .btn.btn-dark {
  border-color: #eeeeee;
  background-color: #ffffff;
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(238, 238, 238, 0.3);
}
.page-body.skin-white.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-white.login-page .login-form .login-footer a {
  color: #95cd62;
}
.page-body.skin-white.login-page .login-form .login-footer a:hover {
  color: #8c8c8c;
}
.page-body.skin-white.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #666666;
}
.page-body.skin-white.login-page .login-form .login-footer .info-links a {
  color: #666666;
}
.page-body.skin-white.login-page .login-form .login-footer .info-links a:hover {
  color: #95cd62;
}
.page-body.skin-white.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-white.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-white.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-white.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-white.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-white.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-white.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-white.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-white.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-white.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-white.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-white.login-page.login-light .errors-container,
.page-body.skin-white.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-white.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-white.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-white.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-white.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-white.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-white.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-white.login-page {
    padding-top: 0;
  }
  .page-body.skin-white.login-page .login-form,
  .page-body.skin-white.login-page .errors-container,
  .page-body.skin-white.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-white.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-white.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-white.login-page.login-light .errors-container,
  .page-body.skin-white.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-white.login-page .row {
    margin: 0;
  }
}
.page-body.skin-white.lockscreen-page {
  background: #ffffff;
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group p {
  color: #666666;
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group h3 {
  color: #666666;
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #eeeeee;
  color: #95cd62;
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(149, 205, 98, 0.5);
  opacity: 1;
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(149, 205, 98, 0.5);
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(149, 205, 98, 0.5);
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(149, 205, 98, 0.5);
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(149, 205, 98, 0.5);
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(149, 205, 98, 0.5);
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(149, 205, 98, 0.5);
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #95cd62;
  border: 1px solid #eeeeee;
}
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-white.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(238, 238, 238, 0.3);
}
.page-body.skin-white.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #eeeeee;
  -webkit-box-shadow: 0px 0px 0px 10px #eeeeee, 0px -10px 0px 10px #eeeeee, 0px -20px 0px 10px #eeeeee, 0px -30px 0px 10px #eeeeee;
  -moz-box-shadow: 0px 0px 0px 10px #eeeeee, 0px -10px 0px 10px #eeeeee, 0px -20px 0px 10px #eeeeee, 0px -30px 0px 10px #eeeeee;
  box-shadow: 0px 0px 0px 10px #eeeeee, 0px -10px 0px 10px #eeeeee, 0px -20px 0px 10px #eeeeee, 0px -30px 0px 10px #eeeeee;
}
.page-body.skin-white .sidebar-user-info {
  background-color: #f2f2f2;
  border-bottom-color: #eeeeee;
}
.page-body.skin-white .sidebar-user-info .user-profile span {
  color: #666666;
}
.page-body.skin-white .sidebar-user-info .user-profile span strong {
  color: #95cd62;
}
.page-body.skin-white .sidebar-user-info .user-links {
  border-top-color: #eeeeee;
}
.page-body.skin-white .sidebar-user-info .user-links li a {
  color: #666666;
}
.page-body.skin-white .sidebar-user-info .user-links li a:hover {
  color: #95cd62;
  background-color: rgba(238, 238, 238, 0.25);
}
.page-body.skin-white .sidebar-user-info .user-links li.active a {
  color: #95cd62;
  background-color: rgba(238, 238, 238, 0.25);
}
.page-body.skin-white .sidebar-user-info .user-links li + li a {
  border-left-color: #eeeeee;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-white .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-white .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-concrete .btn-primary {
  background: #a40f37;
}
.page-body.skin-concrete .btn-primary:hover {
  background: #8d0d2f;
  border-color: #8d0d2f;
}
.page-body.skin-concrete .btn-primary:active,
.page-body.skin-concrete .btn-primary:focus {
  background: #5e091f;
  border-color: #5e091f;
}
.page-body.skin-concrete .btn.btn-secondary {
  background-color: #323232;
  color: #ffffff;
}
.page-body.skin-concrete .btn.btn-secondary:hover {
  background-color: #252525;
  text-color: #ffffff;
}
.page-body.skin-concrete .btn.btn-secondary:active,
.page-body.skin-concrete .btn.btn-secondary:focus {
  background-color: #252525;
  border-color: #202020;
}
.page-body.skin-concrete .settings-pane {
  background: #b8bbb3;
  color: #666666;
}
.page-body.skin-concrete .settings-pane > a[data-toggle="settings-pane"] {
  color: #666666;
}
.page-body.skin-concrete .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-concrete .settings-pane .user-info .user-details h3 a {
  color: #a40f37;
}
.page-body.skin-concrete .settings-pane .user-info .user-details .user-title {
  color: rgba(164, 15, 55, 0.65);
}
.page-body.skin-concrete .settings-pane .links-block.left-sep {
  border-left: 1px solid #a8aba2;
}
.page-body.skin-concrete .settings-pane .links-block h4 {
  color: #a40f37;
}
.page-body.skin-concrete .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #b8bbb3;
}
.page-body.skin-concrete .settings-pane .links-block h4 a {
  color: #a40f37;
}
.page-body.skin-concrete .settings-pane .links-block h4 a:hover {
  color: #666666;
}
.page-body.skin-concrete .settings-pane .links-block ul li a {
  color: #666666;
}
.page-body.skin-concrete .settings-pane .links-block ul li a:hover {
  color: #a40f37;
}
.page-body.skin-concrete .sidebar-menu {
  background: #a8aba2;
}
.page-body.skin-concrete .sidebar-menu .logo-env {
  border-bottom: 1px solid #b8bbb3;
}
.page-body.skin-concrete .sidebar-menu .logo-env .settings-icon a {
  color: #666666;
}
.page-body.skin-concrete .sidebar-menu .logo-env .settings-icon a:hover {
  color: #a40f37;
}
.page-body.skin-concrete .sidebar-menu .main-menu a {
  color: #666666;
  border-bottom: 1px solid #b8bbb3;
}
.page-body.skin-concrete .sidebar-menu .main-menu a:hover {
  color: #a40f37;
}
.page-body.skin-concrete .sidebar-menu .main-menu li.active > a {
  color: #a40f37;
}
.page-body.skin-concrete .sidebar-menu .main-menu li.has-sub > a:before {
  color: #f7f7f6;
}
.page-body.skin-concrete .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-concrete .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-concrete .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-concrete .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-concrete .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #a0a49a;
  }
  .page-body.skin-concrete .sidebar-menu.collapsed .main-menu > li > ul {
    background: #a8aba2;
  }
}
.page-body.skin-concrete .page-loading-overlay {
  background-color: #a8aba2;
}
.page-body.skin-concrete.login-page {
  background: #a8aba2;
  color: #fff;
}
.page-body.skin-concrete.login-page .login-form .login-header .logo span {
  color: #666666;
}
.page-body.skin-concrete.login-page .login-form .login-header p {
  color: #666666;
}
.page-body.skin-concrete.login-page .login-form .form-group .control-label {
  color: #666666;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark {
  background: #9b9f95;
  border: 1px solid transparent;
  color: #666666;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #a40f37;
  opacity: 1;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-concrete.login-page .login-form .form-group .btn.btn-dark {
  border-color: #b8bbb3;
  background-color: #a8aba2;
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(184, 187, 179, 0.3);
}
.page-body.skin-concrete.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-concrete.login-page .login-form .login-footer a {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .login-form .login-footer a:hover {
  color: #8c8c8c;
}
.page-body.skin-concrete.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #666666;
}
.page-body.skin-concrete.login-page .login-form .login-footer .info-links a {
  color: #666666;
}
.page-body.skin-concrete.login-page .login-form .login-footer .info-links a:hover {
  color: #a40f37;
}
.page-body.skin-concrete.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-concrete.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-concrete.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-concrete.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-concrete.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-concrete.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-concrete.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-concrete.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-concrete.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-concrete.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-concrete.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-concrete.login-page.login-light .errors-container,
.page-body.skin-concrete.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-concrete.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-concrete.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-concrete.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-concrete.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-concrete.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-concrete.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-concrete.login-page {
    padding-top: 0;
  }
  .page-body.skin-concrete.login-page .login-form,
  .page-body.skin-concrete.login-page .errors-container,
  .page-body.skin-concrete.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-concrete.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-concrete.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-concrete.login-page.login-light .errors-container,
  .page-body.skin-concrete.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-concrete.login-page .row {
    margin: 0;
  }
}
.page-body.skin-concrete.lockscreen-page {
  background: #a8aba2;
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group p {
  color: #666666;
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group h3 {
  color: #666666;
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #b8bbb3;
  color: #a40f37;
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(164, 15, 55, 0.5);
  opacity: 1;
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(164, 15, 55, 0.5);
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(164, 15, 55, 0.5);
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(164, 15, 55, 0.5);
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(164, 15, 55, 0.5);
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(164, 15, 55, 0.5);
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(164, 15, 55, 0.5);
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #a40f37;
  border: 1px solid #b8bbb3;
}
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-concrete.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(184, 187, 179, 0.3);
}
.page-body.skin-concrete.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #b8bbb3;
  -webkit-box-shadow: 0px 0px 0px 10px #b8bbb3, 0px -10px 0px 10px #b8bbb3, 0px -20px 0px 10px #b8bbb3, 0px -30px 0px 10px #b8bbb3;
  -moz-box-shadow: 0px 0px 0px 10px #b8bbb3, 0px -10px 0px 10px #b8bbb3, 0px -20px 0px 10px #b8bbb3, 0px -30px 0px 10px #b8bbb3;
  box-shadow: 0px 0px 0px 10px #b8bbb3, 0px -10px 0px 10px #b8bbb3, 0px -20px 0px 10px #b8bbb3, 0px -30px 0px 10px #b8bbb3;
}
.page-body.skin-concrete .sidebar-user-info {
  background-color: #9b9f95;
  border-bottom-color: #b8bbb3;
}
.page-body.skin-concrete .sidebar-user-info .user-profile span {
  color: #666666;
}
.page-body.skin-concrete .sidebar-user-info .user-profile span strong {
  color: #a40f37;
}
.page-body.skin-concrete .sidebar-user-info .user-links {
  border-top-color: #b8bbb3;
}
.page-body.skin-concrete .sidebar-user-info .user-links li a {
  color: #666666;
}
.page-body.skin-concrete .sidebar-user-info .user-links li a:hover {
  color: #a40f37;
  background-color: rgba(184, 187, 179, 0.25);
}
.page-body.skin-concrete .sidebar-user-info .user-links li.active a {
  color: #a40f37;
  background-color: rgba(184, 187, 179, 0.25);
}
.page-body.skin-concrete .sidebar-user-info .user-links li + li a {
  border-left-color: #b8bbb3;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-concrete .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-concrete .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-watermelon .btn-primary {
  background: #b63131;
}
.page-body.skin-watermelon .btn-primary:hover {
  background: #a22c2c;
  border-color: #a22c2c;
}
.page-body.skin-watermelon .btn-primary:active,
.page-body.skin-watermelon .btn-primary:focus {
  background: #7a2121;
  border-color: #7a2121;
}
.page-body.skin-watermelon .btn.btn-secondary {
  background-color: #32932e;
  color: #ffffff;
}
.page-body.skin-watermelon .btn.btn-secondary:hover {
  background-color: #2b8028;
  text-color: #ffffff;
}
.page-body.skin-watermelon .btn.btn-secondary:active,
.page-body.skin-watermelon .btn.btn-secondary:focus {
  background-color: #2b8028;
  border-color: #297825;
}
.page-body.skin-watermelon .settings-pane {
  background: #c03737;
  color: #f7b2b2;
}
.page-body.skin-watermelon .settings-pane > a[data-toggle="settings-pane"] {
  color: #f7b2b2;
}
.page-body.skin-watermelon .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-watermelon .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-watermelon .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-watermelon .settings-pane .links-block.left-sep {
  border-left: 1px solid #b63131;
}
.page-body.skin-watermelon .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-watermelon .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #c03737;
}
.page-body.skin-watermelon .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-watermelon .settings-pane .links-block h4 a:hover {
  color: #f7b2b2;
}
.page-body.skin-watermelon .settings-pane .links-block ul li a {
  color: #f7b2b2;
}
.page-body.skin-watermelon .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-watermelon .sidebar-menu {
  background: #b63131;
}
.page-body.skin-watermelon .sidebar-menu .logo-env {
  border-bottom: 1px solid #c03737;
}
.page-body.skin-watermelon .sidebar-menu .logo-env .settings-icon a {
  color: #f7b2b2;
}
.page-body.skin-watermelon .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-watermelon .sidebar-menu .main-menu a {
  color: #f7b2b2;
  border-bottom: 1px solid #c03737;
}
.page-body.skin-watermelon .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-watermelon .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-watermelon .sidebar-menu .main-menu li.has-sub > a:before {
  color: #e19696;
}
.page-body.skin-watermelon .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-watermelon .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-watermelon .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-watermelon .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-watermelon .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #aa2e2e;
  }
  .page-body.skin-watermelon .sidebar-menu.collapsed .main-menu > li > ul {
    background: #b63131;
  }
}
.page-body.skin-watermelon .page-loading-overlay {
  background-color: #b63131;
}
.page-body.skin-watermelon.login-page {
  background: #b63131;
  color: #fff;
}
.page-body.skin-watermelon.login-page .login-form .login-header .logo span {
  color: #f7b2b2;
}
.page-body.skin-watermelon.login-page .login-form .login-header p {
  color: #f7b2b2;
}
.page-body.skin-watermelon.login-page .login-form .form-group .control-label {
  color: #f7b2b2;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark {
  background: #a22c2c;
  border: 1px solid transparent;
  color: #f7b2b2;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-watermelon.login-page .login-form .form-group .btn.btn-dark {
  border-color: #c03737;
  background-color: #b63131;
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(192, 55, 55, 0.3);
}
.page-body.skin-watermelon.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-watermelon.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .login-form .login-footer a:hover {
  color: #fef7f7;
}
.page-body.skin-watermelon.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #f7b2b2;
}
.page-body.skin-watermelon.login-page .login-form .login-footer .info-links a {
  color: #f7b2b2;
}
.page-body.skin-watermelon.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-watermelon.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-watermelon.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-watermelon.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-watermelon.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-watermelon.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-watermelon.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-watermelon.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-watermelon.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-watermelon.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-watermelon.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-watermelon.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-watermelon.login-page.login-light .errors-container,
.page-body.skin-watermelon.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-watermelon.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-watermelon.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-watermelon.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-watermelon.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-watermelon.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-watermelon.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-watermelon.login-page {
    padding-top: 0;
  }
  .page-body.skin-watermelon.login-page .login-form,
  .page-body.skin-watermelon.login-page .errors-container,
  .page-body.skin-watermelon.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-watermelon.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-watermelon.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-watermelon.login-page.login-light .errors-container,
  .page-body.skin-watermelon.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-watermelon.login-page .row {
    margin: 0;
  }
}
.page-body.skin-watermelon.lockscreen-page {
  background: #b63131;
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group p {
  color: #f7b2b2;
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group h3 {
  color: #f7b2b2;
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #c03737;
  color: #ffffff;
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #c03737;
}
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-watermelon.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(192, 55, 55, 0.3);
}
.page-body.skin-watermelon.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #c03737;
  -webkit-box-shadow: 0px 0px 0px 10px #c03737, 0px -10px 0px 10px #c03737, 0px -20px 0px 10px #c03737, 0px -30px 0px 10px #c03737;
  -moz-box-shadow: 0px 0px 0px 10px #c03737, 0px -10px 0px 10px #c03737, 0px -20px 0px 10px #c03737, 0px -30px 0px 10px #c03737;
  box-shadow: 0px 0px 0px 10px #c03737, 0px -10px 0px 10px #c03737, 0px -20px 0px 10px #c03737, 0px -30px 0px 10px #c03737;
}
.page-body.skin-watermelon .sidebar-user-info {
  background-color: #a22c2c;
  border-bottom-color: #c03737;
}
.page-body.skin-watermelon .sidebar-user-info .user-profile span {
  color: #f7b2b2;
}
.page-body.skin-watermelon .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-watermelon .sidebar-user-info .user-links {
  border-top-color: #c03737;
}
.page-body.skin-watermelon .sidebar-user-info .user-links li a {
  color: #f7b2b2;
}
.page-body.skin-watermelon .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(192, 55, 55, 0.25);
}
.page-body.skin-watermelon .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(192, 55, 55, 0.25);
}
.page-body.skin-watermelon .sidebar-user-info .user-links li + li a {
  border-left-color: #c03737;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-watermelon .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-watermelon .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.skin-lemonade .btn-primary {
  background: #f5c150;
}
.page-body.skin-lemonade .btn-primary:hover {
  background: #f4b838;
  border-color: #f4b838;
}
.page-body.skin-lemonade .btn-primary:active,
.page-body.skin-lemonade .btn-primary:focus {
  background: #eba50d;
  border-color: #eba50d;
}
.page-body.skin-lemonade .btn.btn-secondary {
  background-color: #d9a940;
  color: #ffffff;
}
.page-body.skin-lemonade .btn.btn-secondary:hover {
  background-color: #d59f2b;
  text-color: #ffffff;
}
.page-body.skin-lemonade .btn.btn-secondary:active,
.page-body.skin-lemonade .btn.btn-secondary:focus {
  background-color: #d59f2b;
  border-color: #cd9929;
}
.page-body.skin-lemonade .settings-pane {
  background: #ffcf67;
  color: #ffeec9;
}
.page-body.skin-lemonade .settings-pane > a[data-toggle="settings-pane"] {
  color: #ffeec9;
}
.page-body.skin-lemonade .settings-pane > a[data-toggle="settings-pane"]:hover {
  color: #FFF;
}
.page-body.skin-lemonade .settings-pane .user-info .user-details h3 a {
  color: #ffffff;
}
.page-body.skin-lemonade .settings-pane .user-info .user-details .user-title {
  color: rgba(255, 255, 255, 0.65);
}
.page-body.skin-lemonade .settings-pane .links-block.left-sep {
  border-left: 1px solid #f5c150;
}
.page-body.skin-lemonade .settings-pane .links-block h4 {
  color: #ffffff;
}
.page-body.skin-lemonade .settings-pane .links-block h4 span {
  display: inline-block;
  border-bottom: 1px solid #ffcf67;
}
.page-body.skin-lemonade .settings-pane .links-block h4 a {
  color: #ffffff;
}
.page-body.skin-lemonade .settings-pane .links-block h4 a:hover {
  color: #ffeec9;
}
.page-body.skin-lemonade .settings-pane .links-block ul li a {
  color: #ffeec9;
}
.page-body.skin-lemonade .settings-pane .links-block ul li a:hover {
  color: #ffffff;
}
.page-body.skin-lemonade .sidebar-menu {
  background: #f5c150;
}
.page-body.skin-lemonade .sidebar-menu .logo-env {
  border-bottom: 1px solid #ffcf67;
}
.page-body.skin-lemonade .sidebar-menu .logo-env .settings-icon a {
  color: #ffeec9;
}
.page-body.skin-lemonade .sidebar-menu .logo-env .settings-icon a:hover {
  color: #ffffff;
}
.page-body.skin-lemonade .sidebar-menu .main-menu a {
  color: #ffeec9;
  border-bottom: 1px solid #ffcf67;
}
.page-body.skin-lemonade .sidebar-menu .main-menu a:hover {
  color: #ffffff;
}
.page-body.skin-lemonade .sidebar-menu .main-menu li.active > a {
  color: #ffffff;
}
.page-body.skin-lemonade .sidebar-menu .main-menu li.has-sub > a:before {
  color: #fff7e6;
}
.page-body.skin-lemonade .sidebar-menu .ps-scrollbar-x-rail .ps-scrollbar-x,
.page-body.skin-lemonade .sidebar-menu .ps-scrollbar-y-rail .ps-scrollbar-y {
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.skin-lemonade .sidebar-menu.collapsed + .main-content .user-info-navbar .user-info-menu a[data-toggle="sidebar"] {
    color: #606161;
  }
  .page-body.skin-lemonade .sidebar-menu.collapsed .main-menu > li.active,
  .page-body.skin-lemonade .sidebar-menu.collapsed .main-menu > li li.active {
    background-color: #f4bc42;
  }
  .page-body.skin-lemonade .sidebar-menu.collapsed .main-menu > li > ul {
    background: #f5c150;
  }
}
.page-body.skin-lemonade .page-loading-overlay {
  background-color: #f5c150;
}
.page-body.skin-lemonade.login-page {
  background: #f5c150;
  color: #fff;
}
.page-body.skin-lemonade.login-page .login-form .login-header .logo span {
  color: #ffeec9;
}
.page-body.skin-lemonade.login-page .login-form .login-header p {
  color: #ffeec9;
}
.page-body.skin-lemonade.login-page .login-form .form-group .control-label {
  color: #ffeec9;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark {
  background: #f4b838;
  border: 1px solid transparent;
  color: #ffeec9;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark:-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark::-moz-placeholder {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .form-group .form-control.error {
  border-color: rgba(204, 63, 68, 0.5);
}
.page-body.skin-lemonade.login-page .login-form .form-group .btn.btn-dark {
  border-color: #ffcf67;
  background-color: #f5c150;
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .form-group .btn.btn-dark:hover {
  background-color: rgba(255, 207, 103, 0.3);
}
.page-body.skin-lemonade.login-page .login-form .login-footer {
  margin-top: 45px;
}
.page-body.skin-lemonade.login-page .login-form .login-footer a {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .login-footer a:hover {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .login-form .login-footer .info-links {
  font-size: 11px;
  margin-top: 5px;
  color: #ffeec9;
}
.page-body.skin-lemonade.login-page .login-form .login-footer .info-links a {
  color: #ffeec9;
}
.page-body.skin-lemonade.login-page .login-form .login-footer .info-links a:hover {
  color: #ffffff;
}
.page-body.skin-lemonade.login-page .external-login {
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
}
.page-body.skin-lemonade.login-page .external-login a {
  display: block;
  color: #fff;
  background: #2c2e2f;
  padding: 13px 20px;
  font-size: 15px;
  margin-bottom: 10px;
}
.page-body.skin-lemonade.login-page .external-login a i {
  margin-right: 5px;
}
.page-body.skin-lemonade.login-page .external-login a:hover {
  background: #454849;
}
.page-body.skin-lemonade.login-page .external-login a.facebook {
  background-color: #3b5998;
}
.page-body.skin-lemonade.login-page .external-login a.facebook:hover {
  background-color: #2d4373;
}
.page-body.skin-lemonade.login-page .external-login a.twitter {
  background-color: #55acee;
}
.page-body.skin-lemonade.login-page .external-login a.twitter:hover {
  background-color: #2795e9;
}
.page-body.skin-lemonade.login-page .external-login a.gplus {
  background-color: #dd4b39;
}
.page-body.skin-lemonade.login-page .external-login a.gplus:hover {
  background-color: #c23321;
}
.page-body.skin-lemonade.login-page.login-light {
  background: #eeeeee;
}
.page-body.skin-lemonade.login-page.login-light .errors-container,
.page-body.skin-lemonade.login-page.login-light .external-login {
  padding: 0;
}
.page-body.skin-lemonade.login-page.login-light .form-group label.error {
  color: rgba(151, 152, 152, 0.8);
}
.page-body.skin-lemonade.login-page.login-light .login-form {
  background: #fff;
  color: #979898;
}
.page-body.skin-lemonade.login-page.login-light .login-form .login-footer a {
  color: #68b828;
}
.page-body.skin-lemonade.login-page.login-light .login-form .login-footer a:hover {
  color: #508e1f;
}
.page-body.skin-lemonade.login-page.login-light .login-form .login-footer .info-links a {
  color: rgba(151, 152, 152, 0.7);
}
.page-body.skin-lemonade.login-page.login-light .login-form .login-footer .info-links a:hover {
  color: #979898;
}
@media screen and (max-width: 768px) {
  .page-body.skin-lemonade.login-page {
    padding-top: 0;
  }
  .page-body.skin-lemonade.login-page .login-form,
  .page-body.skin-lemonade.login-page .errors-container,
  .page-body.skin-lemonade.login-page .external-login {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-body.skin-lemonade.login-page .errors-container {
    margin-top: 20px;
  }
  .page-body.skin-lemonade.login-page.login-light .login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-body.skin-lemonade.login-page.login-light .errors-container,
  .page-body.skin-lemonade.login-page.login-light .external-login {
    padding-left: 0;
    padding-right: 0;
  }
  .page-body.skin-lemonade.login-page .row {
    margin: 0;
  }
}
.page-body.skin-lemonade.lockscreen-page {
  background: #f5c150;
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group p {
  color: #ffeec9;
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group h3 {
  color: #ffeec9;
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control {
  border: 1px solid #ffcf67;
  color: #ffffff;
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn {
  color: #ffffff;
  border: 1px solid #ffcf67;
}
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:hover,
.page-body.skin-lemonade.lockscreen-page .lockcreen-form .form-group .input-group .input-group-btn .btn:focus {
  background-color: rgba(255, 207, 103, 0.3);
}
.page-body.skin-lemonade.settings-pane-open .sidebar-menu .sidebar-menu-inner .logo-env .settings-icon {
  background: #ffcf67;
  -webkit-box-shadow: 0px 0px 0px 10px #ffcf67, 0px -10px 0px 10px #ffcf67, 0px -20px 0px 10px #ffcf67, 0px -30px 0px 10px #ffcf67;
  -moz-box-shadow: 0px 0px 0px 10px #ffcf67, 0px -10px 0px 10px #ffcf67, 0px -20px 0px 10px #ffcf67, 0px -30px 0px 10px #ffcf67;
  box-shadow: 0px 0px 0px 10px #ffcf67, 0px -10px 0px 10px #ffcf67, 0px -20px 0px 10px #ffcf67, 0px -30px 0px 10px #ffcf67;
}
.page-body.skin-lemonade .sidebar-user-info {
  background-color: #f4b838;
  border-bottom-color: #ffcf67;
}
.page-body.skin-lemonade .sidebar-user-info .user-profile span {
  color: #ffeec9;
}
.page-body.skin-lemonade .sidebar-user-info .user-profile span strong {
  color: #ffffff;
}
.page-body.skin-lemonade .sidebar-user-info .user-links {
  border-top-color: #ffcf67;
}
.page-body.skin-lemonade .sidebar-user-info .user-links li a {
  color: #ffeec9;
}
.page-body.skin-lemonade .sidebar-user-info .user-links li a:hover {
  color: #ffffff;
  background-color: rgba(255, 207, 103, 0.25);
}
.page-body.skin-lemonade .sidebar-user-info .user-links li.active a {
  color: #ffffff;
  background-color: rgba(255, 207, 103, 0.25);
}
.page-body.skin-lemonade .sidebar-user-info .user-links li + li a {
  border-left-color: #ffcf67;
}
@media screen and (min-width: 991px) {
  .sidebar-menu.collapsed .page-body.skin-lemonade .sidebar-user-info .sidebar-user-info-inner .user-profile span {
    background-color: #27292a;
  }
  .sidebar-menu.collapsed .page-body.skin-lemonade .sidebar-user-info .sidebar-user-info-inner .user-links {
    background-color: #27292a;
  }
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu {
  background: #558c89;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #ececea;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #ececea;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #ececea;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #5f9a97;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #5f9a97;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #ececea;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #6da5a2;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #558c89;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #ececea;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #6da5a2;
    border-top: 1px solid #77aba8;
    border-bottom: 1px solid #77aba8;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #649f9c;
    border-top-color: #6aa3a0;
    border-bottom-color: #6aa3a0;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #6aa3a0;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #6aa3a0;
    border-top-color: #6aa3a0;
    border-bottom-color: #6aa3a0;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #6aa3a0;
  }
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #5f9a97;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #ececea;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #5f9a97;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #5f9a97;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #5f9a97;
  border-color: #6da5a2;
  color: #ececea;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #5f9a97;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #5f9a97;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #ececea;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #5f9a97;
  border-color: #5f9a97;
}
.page-body.horizontal-menu-skin-aero .page-loading-overlay {
  background-color: #558c89;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu {
    background: #558c89;
    color: #ececea;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #ececea;
    border-color: #5f9a97;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #ececea;
  }
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-aero .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu {
  background: #2c3e50;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #a7bfd6;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #a7bfd6;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #a7bfd6;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #34495e;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #34495e;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #a7bfd6;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #3d566e;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #2c3e50;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #a7bfd6;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #3d566e;
    border-top: 1px solid #435d78;
    border-bottom: 1px solid #435d78;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #384e65;
    border-top-color: #3b536b;
    border-bottom-color: #3b536b;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #3b536b;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #3b536b;
    border-top-color: #3b536b;
    border-bottom-color: #3b536b;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #3b536b;
  }
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #34495e;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #a7bfd6;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #34495e;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #34495e;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #34495e;
  border-color: #3d566e;
  color: #a7bfd6;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #34495e;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #34495e;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #a7bfd6;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #34495e;
  border-color: #34495e;
}
.page-body.horizontal-menu-skin-navy .page-loading-overlay {
  background-color: #2c3e50;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu {
    background: #2c3e50;
    color: #a7bfd6;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #a7bfd6;
    border-color: #34495e;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #a7bfd6;
  }
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-navy .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu {
  background: #3b5998;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #8b9dc3;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #8b9dc3;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #8b9dc3;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #4160a0;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #4160a0;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #8b9dc3;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #486bb2;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #3b5998;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #8b9dc3;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #486bb2;
    border-top: 1px solid #5173b8;
    border-bottom: 1px solid #5173b8;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #4464a7;
    border-top-color: #4769af;
    border-bottom-color: #4769af;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #4769af;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #4769af;
    border-top-color: #4769af;
    border-bottom-color: #4769af;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #4769af;
  }
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #4160a0;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #8b9dc3;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #4160a0;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #4160a0;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #4160a0;
  border-color: #486bb2;
  color: #8b9dc3;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #4160a0;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #4160a0;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #8b9dc3;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #4160a0;
  border-color: #4160a0;
}
.page-body.horizontal-menu-skin-facebook .page-loading-overlay {
  background-color: #3b5998;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu {
    background: #3b5998;
    color: #8b9dc3;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #8b9dc3;
    border-color: #4160a0;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #8b9dc3;
  }
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu {
  background: #16a085;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #96ead9;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #96ead9;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #96ead9;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #1daf92;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #1daf92;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #96ead9;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #21c5a4;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #16a085;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #96ead9;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #21c5a4;
    border-top: 1px solid #23d2af;
    border-bottom: 1px solid #23d2af;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #1eb899;
    border-top-color: #20c1a1;
    border-bottom-color: #20c1a1;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #20c1a1;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #20c1a1;
    border-top-color: #20c1a1;
    border-bottom-color: #20c1a1;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #20c1a1;
  }
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #1daf92;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #96ead9;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #1daf92;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #1daf92;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #1daf92;
  border-color: #21c5a4;
  color: #96ead9;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #1daf92;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #1daf92;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #96ead9;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #1daf92;
  border-color: #1daf92;
}
.page-body.horizontal-menu-skin-turquoise .page-loading-overlay {
  background-color: #16a085;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu {
    background: #16a085;
    color: #96ead9;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #96ead9;
    border-color: #1daf92;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #96ead9;
  }
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu {
  background: #8cc657;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #95cd62;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #95cd62;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #a2d375;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #8cc657;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #a2d375;
    border-top: 1px solid #aad781;
    border-bottom: 1px solid #aad781;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #9acf6a;
    border-top-color: #9fd271;
    border-bottom-color: #9fd271;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #9fd271;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #9fd271;
    border-top-color: #9fd271;
    border-bottom-color: #9fd271;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #9fd271;
  }
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #95cd62;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #95cd62;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #95cd62;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #95cd62;
  border-color: #a2d375;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #95cd62;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #95cd62;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #95cd62;
  border-color: #95cd62;
}
.page-body.horizontal-menu-skin-lime .page-loading-overlay {
  background-color: #8cc657;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu {
    background: #8cc657;
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #ffffff;
    border-color: #95cd62;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-lime .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu {
  background: #27ae60;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #a2f9c7;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #a2f9c7;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #a2f9c7;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #2fbd6b;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #2fbd6b;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #a2f9c7;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #38ce77;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #27ae60;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #a2f9c7;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #38ce77;
    border-top: 1px solid #44d180;
    border-bottom: 1px solid #44d180;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #31c570;
    border-top-color: #34cc74;
    border-bottom-color: #34cc74;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #34cc74;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #34cc74;
    border-top-color: #34cc74;
    border-bottom-color: #34cc74;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #34cc74;
  }
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #2fbd6b;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #a2f9c7;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #2fbd6b;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #2fbd6b;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #2fbd6b;
  border-color: #38ce77;
  color: #a2f9c7;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #2fbd6b;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #2fbd6b;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #a2f9c7;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #2fbd6b;
  border-color: #2fbd6b;
}
.page-body.horizontal-menu-skin-green .page-loading-overlay {
  background-color: #27ae60;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu {
    background: #27ae60;
    color: #a2f9c7;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #a2f9c7;
    border-color: #2fbd6b;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #a2f9c7;
  }
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-green .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu {
  background: #795b95;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #c2afd4;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #c2afd4;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #c2afd4;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #81629f;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #81629f;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #c2afd4;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #8e72a9;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #795b95;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #c2afd4;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #8e72a9;
    border-top: 1px solid #957bae;
    border-bottom: 1px solid #957bae;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #8668a3;
    border-top-color: #8b6fa7;
    border-bottom-color: #8b6fa7;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #8b6fa7;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #8b6fa7;
    border-top-color: #8b6fa7;
    border-bottom-color: #8b6fa7;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #8b6fa7;
  }
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #81629f;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #c2afd4;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #81629f;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #81629f;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #81629f;
  border-color: #8e72a9;
  color: #c2afd4;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #81629f;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #81629f;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #c2afd4;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #81629f;
  border-color: #81629f;
}
.page-body.horizontal-menu-skin-purple .page-loading-overlay {
  background-color: #795b95;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu {
    background: #795b95;
    color: #c2afd4;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #c2afd4;
    border-color: #81629f;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #c2afd4;
  }
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-purple .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu {
  background: #ffffff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #666666;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #666666;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #95cd62;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #95cd62;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #eeeeee;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #eeeeee;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #fbfbfb;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #95cd62;
    background: #ffffff;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #fbfbfb;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #f3f3f3;
    border-top-color: #f8f8f8;
    border-bottom-color: #f8f8f8;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #f8f8f8;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #f8f8f8;
    border-top-color: #f8f8f8;
    border-bottom-color: #f8f8f8;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #f8f8f8;
  }
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #eeeeee;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #666666;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #eeeeee;
  color: #95cd62;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #95cd62;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #eeeeee;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #95cd62;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #eeeeee;
  border-color: #fbfbfb;
  color: #666666;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #95cd62;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #eeeeee;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #eeeeee;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #666666;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #95cd62;
}
.page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.page-body.horizontal-menu-skin-white .page-loading-overlay {
  background-color: #ffffff;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu {
    background: #ffffff;
    color: #666666;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #666666;
    border-color: #eeeeee;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #95cd62;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-white .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #95cd62;
  }
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu {
  background: #a8aba2;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #666666;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #666666;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #a40f37;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #a40f37;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #b8bbb3;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #b8bbb3;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #c5c7c0;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #a40f37;
    background: #a8aba2;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #c5c7c0;
    border-top: 1px solid #cccec9;
    border-bottom: 1px solid #cccec9;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #bdc0b8;
    border-top-color: #c2c5be;
    border-bottom-color: #c2c5be;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #c2c5be;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #c2c5be;
    border-top-color: #c2c5be;
    border-bottom-color: #c2c5be;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #c2c5be;
  }
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #b8bbb3;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #666666;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #b8bbb3;
  color: #a40f37;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #a40f37;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #b8bbb3;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #a40f37;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #b8bbb3;
  border-color: #c5c7c0;
  color: #666666;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #a40f37;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #b8bbb3;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #b8bbb3;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #666666;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #a40f37;
}
.page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #b8bbb3;
  border-color: #b8bbb3;
}
.page-body.horizontal-menu-skin-concrete .page-loading-overlay {
  background-color: #a8aba2;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu {
    background: #a8aba2;
    color: #666666;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #666666;
    border-color: #b8bbb3;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #a40f37;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #666666;
  }
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #a40f37;
  }
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu {
  background: #b63131;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #f7b2b2;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #f7b2b2;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #f7b2b2;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #c03737;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #c03737;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #f7b2b2;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #ca4646;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #b63131;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #f7b2b2;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #ca4646;
    border-top: 1px solid #ce5252;
    border-bottom: 1px solid #ce5252;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #c73a3a;
    border-top-color: #c94242;
    border-bottom-color: #c94242;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #c94242;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #c94242;
    border-top-color: #c94242;
    border-bottom-color: #c94242;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #c94242;
  }
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #c03737;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #f7b2b2;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #c03737;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #c03737;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #c03737;
  border-color: #ca4646;
  color: #f7b2b2;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #c03737;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #c03737;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #f7b2b2;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #c03737;
  border-color: #c03737;
}
.page-body.horizontal-menu-skin-watermelon .page-loading-overlay {
  background-color: #b63131;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu {
    background: #b63131;
    color: #f7b2b2;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #f7b2b2;
    border-color: #c03737;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #f7b2b2;
  }
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu {
  background: #f5c150;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.settings-pane-open .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #ffeec9;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"] i {
  color: #ffeec9;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-brand a[data-toggle="settings-pane"]:hover i {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub:hover > a:before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav.click-to-expand .has-sub.expanded > a:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav a {
    color: #ffeec9;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav li:hover > a,
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav li.active > a {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav > li {
    border: 0px;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active,
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav > li:hover {
    border-color: transparent;
    background-color: #ffcf67;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav > li.active + li:hover {
    border-left-color: transparent;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav > li > ul {
    border: none;
    -webkit-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    -moz-box-shadow: 0 2px 8px rgba(0,1,1,.05);
    box-shadow: 0 2px 8px rgba(0,1,1,.05);
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul {
    background: #ffcf67;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a {
    color: #ffeec9;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li > a:after {
    background: #ffd781;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.active > a {
    color: #ffffff;
    background: #f5c150;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li.has-sub > a:before {
    color: #ffeec9;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul {
    background: #ffd781;
    border-top: 1px solid #ffdc90;
    border-bottom: 1px solid #ffdc90;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul {
    background: #ffd271;
    border-top-color: #ffd57b;
    border-bottom-color: #ffd57b;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul a:after {
    background-color: #ffd57b;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul {
    background: #ffd57b;
    border-top-color: #ffd57b;
    border-bottom-color: #ffd57b;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner .navbar-nav ul > li ul ul ul a:after {
    background-color: #ffd57b;
  }
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li:hover {
  background: #ffcf67;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li i {
  color: #ffeec9;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > a {
  border: 0px;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover {
  background: #ffcf67;
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > a:hover i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:before {
  background: #68b828;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon:focus {
  background: #ffcf67;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > a.notification-icon.notification-icon-messages:before {
  background-color: #7c38bc;
}
.chat-open .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > a[data-toggle="chat"] i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon {
  background-color: #ffcf67;
  border-color: #ffd781;
  color: #ffeec9;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon i {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.open > a.notification-icon:before {
  display: block;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li > ul {
  border-color: #ffcf67;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a:focus {
  background-color: #ffcf67;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span {
  color: #ffeec9;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile > a span:hover {
  color: #ffffff;
}
.page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.user-profile.open > a {
  background-color: #ffcf67;
  border-color: #ffcf67;
}
.page-body.horizontal-menu-skin-lemonade .page-loading-overlay {
  background-color: #f5c150;
}
@media screen and (max-width: 768px) {
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu {
    background: #f5c150;
    color: #ffeec9;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .navbar-nav a {
    color: #ffeec9;
    border-color: #ffcf67;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .navbar-nav a:hover,
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .navbar-nav a:active,
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .navbar-nav a:focus {
    color: #ffffff;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a {
    color: #ffeec9;
  }
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:hover,
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:active,
  .page-body.horizontal-menu-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav.navbar-mobile a:focus {
    color: #ffffff;
  }
}
.page-body.user-info-navbar-skin-aero .user-info-navbar {
  background-color: #558c89;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #ececea;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li > a {
  color: #ececea;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #ececea;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #5f9a97;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #5f9a97;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #ececea;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #ececea;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #ececea;
  border-bottom: 1px solid #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #ececea;
  background-color: #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #5f9a97;
  color: #FFF;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #ececea;
  border-bottom: 1px solid #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #6da5a2;
}
.page-body.user-info-navbar-skin-aero .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-aero .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar {
  background-color: #2c3e50;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #a7bfd6;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li > a {
  color: #a7bfd6;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #a7bfd6;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #34495e;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #34495e;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #a7bfd6;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #a7bfd6;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #a7bfd6;
  border-bottom: 1px solid #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #a7bfd6;
  background-color: #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #34495e;
  color: #FFF;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #a7bfd6;
  border-bottom: 1px solid #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #3d566e;
}
.page-body.user-info-navbar-skin-navy .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-navy .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar {
  background-color: #3b5998;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #8b9dc3;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li > a {
  color: #8b9dc3;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #8b9dc3;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #4160a0;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #4160a0;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #8b9dc3;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #8b9dc3;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #8b9dc3;
  border-bottom: 1px solid #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #8b9dc3;
  background-color: #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #4160a0;
  color: #FFF;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #8b9dc3;
  border-bottom: 1px solid #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #486bb2;
}
.page-body.user-info-navbar-skin-facebook .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-facebook .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar {
  background-color: #16a085;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #96ead9;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li > a {
  color: #96ead9;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #96ead9;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #1daf92;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #1daf92;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #96ead9;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #96ead9;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #96ead9;
  border-bottom: 1px solid #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #96ead9;
  background-color: #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #1daf92;
  color: #FFF;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #96ead9;
  border-bottom: 1px solid #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #21c5a4;
}
.page-body.user-info-navbar-skin-turquoise .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-turquoise .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar {
  background-color: #8cc657;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li > a {
  color: #ffffff;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #95cd62;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #95cd62;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #ffffff;
  border-bottom: 1px solid #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #ffffff;
  background-color: #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #95cd62;
  color: #FFF;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #ffffff;
  border-bottom: 1px solid #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #a2d375;
}
.page-body.user-info-navbar-skin-lime .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-lime .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-green .user-info-navbar {
  background-color: #27ae60;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #a2f9c7;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li > a {
  color: #a2f9c7;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #a2f9c7;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #2fbd6b;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #2fbd6b;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #a2f9c7;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #a2f9c7;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #a2f9c7;
  border-bottom: 1px solid #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #a2f9c7;
  background-color: #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #2fbd6b;
  color: #FFF;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #a2f9c7;
  border-bottom: 1px solid #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #38ce77;
}
.page-body.user-info-navbar-skin-green .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-green .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar {
  background-color: #795b95;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #c2afd4;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li > a {
  color: #c2afd4;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #c2afd4;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #81629f;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #81629f;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #c2afd4;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #c2afd4;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #c2afd4;
  border-bottom: 1px solid #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #c2afd4;
  background-color: #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #81629f;
  color: #FFF;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #c2afd4;
  border-bottom: 1px solid #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #8e72a9;
}
.page-body.user-info-navbar-skin-purple .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-purple .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-white .user-info-navbar {
  background-color: #ffffff;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #666666;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li > a {
  color: #666666;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li > a:hover {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #666666;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #eeeeee;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li.open > a {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #eeeeee;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #666666;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #666666;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #666666;
  border-bottom: 1px solid #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #666666;
  background-color: #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #eeeeee;
  color: #FFF;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #666666;
  border-bottom: 1px solid #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #95cd62;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #fbfbfb;
}
.page-body.user-info-navbar-skin-white .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-white .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar {
  background-color: #a8aba2;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #666666;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li > a {
  color: #666666;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li > a:hover {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #666666;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #b8bbb3;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li.open > a {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #b8bbb3;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #666666;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #666666;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #666666;
  border-bottom: 1px solid #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #666666;
  background-color: #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #b8bbb3;
  color: #FFF;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #666666;
  border-bottom: 1px solid #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #a40f37;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #c5c7c0;
}
.page-body.user-info-navbar-skin-concrete .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-concrete .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar {
  background-color: #b63131;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #f7b2b2;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li > a {
  color: #f7b2b2;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #f7b2b2;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #c03737;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #c03737;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #f7b2b2;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #f7b2b2;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #f7b2b2;
  border-bottom: 1px solid #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #f7b2b2;
  background-color: #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #c03737;
  color: #FFF;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #f7b2b2;
  border-bottom: 1px solid #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #ca4646;
}
.page-body.user-info-navbar-skin-watermelon .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-watermelon .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar {
  background-color: #f5c150;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li {
  border: 0px solid transparent;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.hover-line:before {
  background: #ffeec9;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li > a {
  color: #ffeec9;
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li > a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li > form {
  border-bottom: 1px solid transparent;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .btn,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn {
  color: #ffeec9;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .btn:hover,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .btn:active,
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .btn:focus,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:active,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field {
  border: 1px solid #ffcf67;
  background: #f3f3f3;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
  opacity: 1;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field::-webkit-input-placeholder,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-webkit-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field:-moz-placeholder,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field::-moz-placeholder,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field::-moz-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field:-ms-input-placeholder,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:-ms-input-placeholder {
  color: #979898;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.search-form .search-field:focus + .btn,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li.search-form .search-field:focus + .btn {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.open {
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 15;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li.open > a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu {
  background: #ffcf67;
  border: 1px solid transparent;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a {
  color: #ffeec9;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:after,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:after {
  background: #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li a:hover,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu .dropdown-menu-list li.active a,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu .dropdown-menu-list li.active a {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu > .top a,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a {
  color: #ffeec9;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu > .top a:hover,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu > .top p,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .top p {
  color: #ffeec9;
  border-bottom: 1px solid #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu > .external:after,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external:after {
  background-color: #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu > .external a,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a {
  color: #ffeec9;
  background-color: #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu > .external a:hover,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu > .external a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li:after,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li:after {
  background-color: #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.messages .dropdown-menu-list li.active:after,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.messages .dropdown-menu-list li.active:after {
  background-color: #8dc63f;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list {
  max-height: 276px;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a > i {
  background: #ffcf67;
  color: #FFF;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li > a .line {
  padding-left: 45px;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.notifications .dropdown-menu-list > li.notification-secondary > a > i {
  background-color: #FFF;
  color: #444;
  -webkit-box-shadow: 0 0 0 1px #eeeeee;
  -moz-box-shadow: 0 0 0 1px #eeeeee;
  box-shadow: 0 0 0 1px #eeeeee;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a {
  color: #ffeec9;
  border-bottom: 1px solid #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li a:hover,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li a:hover {
  color: #ffffff;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last {
  background: #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last:after,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last:after {
  background: #ffd781;
}
.page-body.user-info-navbar-skin-lemonade .user-info-navbar .user-info-menu > li .dropdown-menu.user-profile-menu li.last > a,
.page-body.user-info-navbar-skin-lemonade .navbar.horizontal-menu .navbar-inner > .nav > li .dropdown-menu.user-profile-menu li.last > a {
  border-bottom: 0;
}
.table-large {
  max-width: none;
  word-wrap: break-word;
}
.validate-has-error .select2-selection__rendered,
.validate-has-error .select2-selection {
  border: 1px solid #a94442;
  border-radius: 4px;
}
span.validate-has-error {
  color: #a94442;
}
.background1 {
  background: #000000 url("/assets/images/background/leaderboard.jpg") center center;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
}
.background2 {
  background: #000000 url("/assets/images/background/flag-green.jpg") center center;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
}
.table-super-condensed > thead > tr > th,
.table-super-condensed > tbody > tr > th,
.table-super-condensed > tfoot > tr > th,
.table-super-condensed > thead > tr > td,
.table-super-condensed > tbody > tr > td,
.table-super-condensed > tfoot > tr > td {
  padding: 4px;
}
