.breadcrumb {
	background: none;
	
	li {
		
		i {
			margin-right: 4px;
		}
		
		a {
			color: @main-text-color;
		}
		
		&.active > * {
			color: darken(@main-text-color, 20%);
		}
	}
	
	&.bc-2 {
		background-color: #eeeeee;
	}
	
	&.bc-3 {
		border: 1px solid @default-border-color;
	}
	
	&.auto-hidden {
		&:extend(.clearfix all);
		
		li {
			display: block;
			float: left;
			
			&:before {
				display: block;
				float: left;
			}
			
			a {
				display: block;
				float: left;
				white-space: nowrap;
				overflow: hidden;
				&:extend(.transall);
				
				&.collapsed {					
					width: 45px !important;
					.text-overflow();
					//.text-overflow();
				}
			}
		}
	}
}