.label {
	position: relative;
	
	&.pull-right {
		margin-top: 4px;
	}
	
	&.pull-left {
		margin-top: 4px;
		margin-right: @base-padding/2;
	}
	
	&.label-primary {
		background-color: @brand-primary;
		color: #fff;
	}
	
	&.label-secondary {
		background-color: @secondary-color;
		color: #fff;
	}
	
	&.label-success {
		background-color: @brand-success;
		color: #fff;
	}
	
	&.label-danger {
		background-color: @brand-danger;
		color: #fff;
	}
	
	&.label-info {
		background-color: @brand-info;
		color: #fff;
	}
	
	&.label-warning {
		background-color: @brand-warning;
		color: #fff;
	}
	
	&.label-red {
		background-color: @xe-red;
		color: #fff;
	}
	
	&.label-purple {
		background-color: @xe-purple;
		color: #fff;
	}
	
	&.label-pink {
		background-color: @xe-pink;
		color: #fff;
	}
	
	&.label-yellow {
		background-color: @xe-yellow;
		color: #fff;
	}
	
	&.label-orange {
		background-color: @xe-orange;
		color: #fff;
	}
	
	&.label-turquoise {
		background-color: @xe-turquoise;
		color: #fff;
	}
	
	&.label-blue {
		background-color: @xe-blue;
		color: #fff;
	}
	
	&.label-black {
		background-color: @xe-black;
		color: #fff;
	}
	
	&.label-white {
		color: #333;
		.box-shadow(0px 0px 0px 1px @default-border-color);
	}
}

.line-height-default {
	
	li {
		line-height: 1.85;
	}
}