.modal {
	
	.modal-dialog {
		
		.modal-content {
			.box-shadow(none);
			border-color: #f5f5f5;
			padding: @default-padding;
			
			.modal-header {
				margin: 0;
				padding: 0;
				border-bottom-width: 2px;
				padding-bottom: @default-padding/2;
				
				.close {
					outline: none;
				}
			}
			
			.modal-body {
				padding: @base-margin 0;
			}
			
			.modal-footer {
				padding: 0;
				padding-top: @default-padding/2;
				
				.btn {
					margin-bottom: 0;
				}
			}
		}
	}
	
	// Animations
	&.scale {
		
		.modal-dialog {
			.opacity(0);
			.scale(.8);
			.transition(~"all 1000ms ease-in-out");
			.transform-origin(center bottom);
		}
		
		&.in .modal-dialog {
			.opacity(1);
			.scale(1);
		}
	}
}