.bootstrap-tagsinput {
	background-color: #fff;
	border: 1px solid @input-border;
	display: block;
	padding: 4px 6px;
	color: darken(@main-text-color, 10%);
	vertical-align: middle;
	max-width: 100%;
	line-height: 22px;
	cursor: text;

	input {
		border: none;
		box-shadow: none;
		outline: none;
		background-color: transparent;
		padding: 0;
		margin: 0;
		width: auto !important;
		max-width: inherit;

		&:focus {
			border: none;
			box-shadow: none;
		}
	}

	.tag {
		margin-right: 2px;
		color: white;

		[data-role="remove"] {
			margin-left:8px;
			cursor:pointer;

			&:after{
				content: "x";
				padding:0px 2px;
			}

			&:hover {
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
				&:active {
					box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
				}
			}
		}
	}
}
