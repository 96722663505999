html .dropzone {
	border: 1px solid lighten(@input-border, 2%);
	.border-radius(@border-radius-base);
}

.droppable-area {
	@h: 150px;
	
	display: inline-block;
	background: darken(#fafafa, 5%);
	border: 2px dashed darken(@input-border, 10%);
	width: @h;
	min-height: @h !important;
	height: @h;
	line-height: @h;
	text-align: center;
	font-size: 15px;
	color: darken(@main-text-color, 20%);
	margin-bottom: @default-padding;
	.border-radius(50%);
	
	&.dz-clickable {
		cursor: pointer;
	}
	
	&.dz-drag-hover {
		background: @secondary-color;
		color: #fff;
		border-style: solid;
		border-color: darken(@secondary-color, 10%);
		border-width: 5px;
		line-height: @h - 7;
		&:extend(.transall);
	}
}