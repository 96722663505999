//@import "../bs-less/variables.less";

// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)

@zindex-stickyHeader:      990;


// The dropdown menu (ul)
// ----------------------
.dropdown-menu {
    // Checkbox-rows within the dropdown menu
    > li.checkbox-row {
      padding: 5px 20px;
    }

    // links and checkbox-rows within the dropdown menu
    li.checkbox-row {
      display: block;
      clear: both;
      font-weight: normal;
      line-height: @line-height-computed;
      color: @dropdown-link-color;
      white-space: nowrap;

      label {
        font-weight: normal;
      }
        
      &, input, label {
          &:hover {
            cursor: pointer;
          }
      }
    }

    // Hover/Focus state for checkbox-rows
    .no-touch & > .checkbox-row {
      &:hover,
      &:active {
        text-decoration: none;
        color: @dropdown-link-hover-color;
        background-color: @dropdown-link-hover-bg;
      }
    }
}

//
// Tables
// --------------------------------------------------


.btn-toolbar {
	margin-bottom: @base-padding/2;
	
	.btn-default {
		background-color: @secondary-color;
		color: #fff;
		border: 1px solid transparent;
		
		&.dropdown-toggle {
			.box-shadow(none);
			border-color: transparent;
		}
	}
	
	.btn-primary {
		background-color: darken(@secondary-color, 10%);
	}
	
	.btn-group.open .dropdown-toggle {
		background-color: darken(@secondary-color, 10%);
		color: #fff;
	}
	
	.dropdown-menu {
		margin-top: 0;
		
		.checkbox-row {
			.user-select(none);
			
			.cbr-replaced {
				margin: 0;
				margin-right: @base-padding/2;
			}
			
			label {
				color: @main-text-color;
				font-size: @font-size-small;
				margin: 0;
			}
		}
	}
}

.lt-ie8 {
   .btn-toolbar {
       display: none;
   } 
}


// Customize Bootstrap's .table-responsive to give it a border-radius and bigger bottom margin.
.table-responsive {
  border-radius: @border-radius-small;
  border: 1px solid @table-border-color;
  margin-bottom: @line-height-computed;

  // Tighten up spacing
  > .table {
    margin-bottom: 0;
  }

  // Special overrides for the bordered tables
  > .table-bordered {
    border: 0;

    // Nuke the appropriate borders so that the parent can handle them
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }
      }
    }

    // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
    // chances are there will be only one `tr` in a `thead` and that would
    // remove the border altogether.
    > tbody,
    > tfoot {
      > tr:last-child {
        > th,
        > td {
          border-bottom: 0;
        }
      }
    }
  }
}


// Unlike Bootstrap's original .table-responsive, this is applied for all widths. (i.e. NOT only applied <768px)
// Other diffs:
// - Small border radius
// - Position: relative;
// - Bigger bottom margin
.table-responsive[data-pattern="priority-columns"] {
    position: relative;
    width: 100%;
    margin-bottom: @line-height-computed;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border-radius: @border-radius-small;
    border: 1px solid @table-border-color;
    -webkit-overflow-scrolling: touch;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
}
    
// Column priority
.mq.js .table-responsive[data-pattern="priority-columns"],
.mq.js.lt-ie10 .sticky-table-header {
    
    th[data-priority],
    td[data-priority] {
       display: none;
    }

    .lt-ie9& th[data-priority="1"], 
    .lt-ie9& td[data-priority="1"] {
        display: inline;
    }
    th[data-priority="1"], 
    td[data-priority="1"] {
        display: table-cell;
    }

    @media screen and (min-width: 480px) {
        .lt-ie9& th[data-priority="2"], 
        .lt-ie9& td[data-priority="2"] {
            display: inline;
        }
        th[data-priority="2"], 
        td[data-priority="2"] {
            display: table-cell;
        }
    }

    @media screen and (min-width: 640px) {
        .lt-ie9& th[data-priority="3"], 
        .lt-ie9& td[data-priority="3"] {
            display: inline;
        }
        th[data-priority="3"], 
        td[data-priority="3"] {
            display: table-cell;
        }
    }

    @media screen and (min-width: 800px) {
        .lt-ie9& th[data-priority="4"], 
        .lt-ie9& td[data-priority="4"] {
            display: inline;
        }
        th[data-priority="4"], 
        td[data-priority="4"] {
            display: table-cell;
        }
    }

    @media screen and (min-width: 960px) {
        .lt-ie9& th[data-priority="5"], 
        .lt-ie9& td[data-priority="5"] {
            display: inline;
        }
        th[data-priority="5"], 
        td[data-priority="5"] {
            display: table-cell;
        }
    }

    @media screen and (min-width: 1120px) {
        .lt-ie9& th[data-priority="6"], 
        .lt-ie9& td[data-priority="6"] {
            display: inline;
        }
        th[data-priority="6"], 
        td[data-priority="6"] {
            display: table-cell;
        }
    }
}

//table utility classes
table {

    &.table-small-font {
        font-size: @font-size-small;
        -webkit-text-size-adjust:none;
        line-height: 1.5em;
    }

    // focus
    &.focus-on tbody {
        tr:hover {
            cursor: pointer;
        }

        tr {
            &.unfocused {
                th, td { 
                    color: @gray-light;
                    color: rgba(0,0,0,0.4);
                }
            }
            &.focused {
                th, td { 
                    background-color: @secondary-color; 
                    color: #fff;
                    border-color: darken(@secondary-color, 2%);
                }
            }
        }
    }

    //hide cell
    th.col-hide,
    td.col-hide {
       display: none;
    }

    //show cell
    th.col-show,
    td.col-show {
       display: inline;
    }

    //show cell (ie9)
    .lt-ie9 & th.col-show,
    .lt-ie9 & td.col-show {
       display: table-cell;
    }

    // display all
    .lt-ie9 &.display-all {
        th, td {
            display: inline !important;
        }
    }

    &.display-all {
        th, td {
            display: table-cell !important;
        }
    }
}

//Sticky table header
.sticky-table-header {
  background-color: #fff;
  border: none;
  border-radius: 0;
  border-top: 1px solid @table-border-color;
  border-bottom: 1px solid @table-border-color;
  position: absolute;
  top: 0;
  visibility: hidden;
  z-index: @zindex-stickyHeader;
  overflow: hidden;
  min-width: 100%;

  &.fixed-solution {
      min-width: 0;
      position: fixed;
      
      .lt-ie10 & {
          border-left: 1px solid @table-border-color;
          border-right: 1px solid @table-border-color;
          box-sizing: content-box;
          border-bottom: none;
      }
  }
    
  &.border-radius-fix {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
  }
    
    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
}